<div
  class="login-cover"
  [style.background]="bgImage"
  [style.backgroundSize]="'cover'"
></div>
<div class="login-filter" [style.opacity]="bgFilterOpacity"></div>
<!-- <div class="back-cover"></div>
<particles
  [params]="particlesParams"
  [style]="particlesStyle"
  [width]="particlesWidth"
  [height]="particlesHeight"
>
</particles> -->
<app-signin
  [showConnections]="showSystems"
  [systems]="systems"
  [mode]="loginMode"
></app-signin>
