<div
  fxLayout="column"
  [ngClass]="{
    'config-hidden': configMode && !showEditor(resource.rightSets),
    'edit-mode':
      !creationMode &&
      config.attributeName &&
      !config.attributeName.endsWith('#')
  }"
  style="width: 100%"
>
  <div
    *ngIf="config.showDisplayName"
    fxLayout="column"
    style="font-weight: 400; margin-bottom: 20px"
  >
    <div
      *ngIf="config.showDisplayName"
      fxFlex="none"
      [matTooltip]="tooltip"
      class="primary-text-color"
    >
      {{ displayName | translate }}
    </div>
    <div
      *ngIf="config.showDescription"
      fxFlex="none"
      fxLayout="row"
      style="font-size: 0.75em; color: rgba(0, 0, 0, 0.54); height: 18px"
    >
      <div
        fxFlex="none"
        *ngIf="description"
        class="accent-text-color"
        style="margin-right: 10px"
        [matTooltip]="description | translate"
      >
        {{ description | translate | ellipsis: config.hintLimit }}
      </div>
      <div
        fxFlex="none"
        *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
        style="color: #d8bb2e"
      >
        <mat-icon
          [matTooltip]="'key_noWriteAccess' | translate"
          style="font-size: 14px; padding-top: 4px"
          >error</mat-icon
        >
      </div>
      <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
        <mat-icon
          [matTooltip]="'key_noReadAccess' | translate"
          style="color: red; padding-top: 4px; font-size: 14px"
          >error</mat-icon
        >
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <div fxFlex="auto"></div>
    <mat-button-toggle-group
      fxFlex="none"
      [(ngModel)]="displayMode"
      style="font-size: 12px; height: 32px"
      (change)="onDisplayModeChange()"
    >
      <mat-button-toggle value="builder">Builder</mat-button-toggle>
      <mat-button-toggle value="query">Query</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <mat-form-field
    *ngIf="configMode || showEditor(resource.rightSets)"
    appearance="fill"
    [ngClass]="{ 'component-hidden': displayMode !== 'query' }"
    style="margin-top: 10px"
  >
    <mat-label>{{ 'key_query' | translate }}</mat-label>
    <textarea
      matInput
      [disabled]="disabled(resource.rightSets)"
      [(ngModel)]="value"
    ></textarea>
    <mat-hint
      *ngIf="control && control.invalid"
      class="warn-color"
      [matTooltip]="control.errors['message'] | translate"
      >{{
        control.errors['message'] | translate | ellipsis: config.hintLimit
      }}</mat-hint
    >
  </mat-form-field>
  <div
    *ngIf="
      displayMode === 'builder' &&
      (xpathCanNotParse || (builder && !builder.canParse))
    "
    style="
      border: solid 2px bisque;
      margin-top: 20px;
      background-color: lightyellow;
      padding: 10px;
    "
  >
    {{ 'key_canNotVisualizeXpath' | translate }}
  </div>
  <app-xpath-builder
    #xpathBuilder
    *ngIf="configMode || showEditor(resource.rightSets)"
    [readOnly]="disabled(resource.rightSets)"
    [ngClass]="{
      'component-hidden':
        displayMode !== 'builder' ||
        xpathCanNotParse ||
        (builder && !builder.canParse)
    }"
    [schema]="config.schema"
    (queryChange)="onChange()"
  ></app-xpath-builder>
  <div
    *ngIf="configMode || showEditor(resource.rightSets)"
    fxLayout="row"
    fxLayoutGap="20px"
    style="margin-top: 20px"
  >
    <button
      *ngIf="config.showResults"
      [disabled]="!value"
      style="height: 36px"
      (click)="onShowResults()"
      mat-stroked-button
    >
      {{ 'key_viewResults' | translate }}
    </button>
    <button
      *ngIf="config.showResults"
      [disabled]="!value || disabled(resource.rightSets)"
      color="warn"
      style="height: 36px"
      (click)="onResetValue()"
      mat-stroked-button
    >
      {{ 'key_resetValue' | translate }}
    </button>
    <button
      *ngIf="config.showQuery"
      [disabled]="!value"
      (click)="onShowQuery()"
      mat-stroked-button
    >
      {{ 'key_viewQuery' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <div
      *ngIf="
        config.showResults && showResultsTable && config.showAttributePicker
      "
      style="font-size: 12px"
    >
      <app-attribute-picker
        #attributePicker
        [appearance]="'outline'"
        [objectType]="queryType"
        [multiple]="true"
        [placeHolder]="'l10n_selectAttributes' | translate"
        [preSelectedItems]="selectedAttributes"
        (attributeChange)="onAttributeChange($event)"
      ></app-attribute-picker>
    </div>
  </div>
  <div
    *ngIf="
      showEditor(resource.rightSets) && config.showResults && showResultsTable
    "
    style="margin-top: 20px"
  >
    <app-resource-table
      #resourceTable
      [config]="tableConfig"
    ></app-resource-table>
  </div>
</div>
