<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" style="font-weight: 400">
      <mat-checkbox
        fxFlex="50"
        name="chkDisablePreupdateTrigger"
        [(ngModel)]="disablePreupdateTrigger"
        >{{ 'key_disablePreupdateTrigger' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="50"
        name="chkDisablePostupdateTrigger"
        [(ngModel)]="disablePostupdateTrigger"
        >{{ 'key_disablePostupdateTrigger' | translate }}</mat-checkbox
      >
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 15px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="useRequestorAsActor"
        [ngModelOptions]="{ standalone: true }"
        (change)="onImpersonationChange()"
        >{{ 'key_useRequestorAsActor' | translate }}</mat-checkbox
      >
      <mat-checkbox
        *ngIf="useRequestorAsActor"
        fxFlex="50"
        [(ngModel)]="skipPermissionCheck"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_skipPermissionCheck' | translate }}</mat-checkbox
      >
    </div>
    <div
      *ngIf="false"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 10px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="waitForCompletion"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_waitForCompletion' | translate }}</mat-checkbox
      >
    </div>
    <div *ngIf="waitForCompletion" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field fxFlex="100px">
        <input
          matInput
          type="number"
          min="0"
          [placeholder]="'key_maxWaitTime' | translate"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="maxWaitTime"
        />
      </mat-form-field>
      <mat-form-field fxFlex="auto" style="margin-left: 20px">
        <mat-label>{{ 'key_waitForCompletionMessage' | translate }}</mat-label>
        <input
          matInput
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="waitForCompletionMessage"
        />
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-top: 20px; font-weight: 400"
    >
      {{ 'key_updateInstructions' | translate }}
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      class="accent-text-color"
      style="margin-top: 10px; margin-bottom: 20px"
    >
      {{ 'key_addUpdateEntriesDesc' | translate }}
    </div>
    <div
      dragula="UPDATERESOURCESENTRIES"
      [(dragulaModel)]="updateResourcesEntries"
    >
      <div
        *ngFor="let entry of updateResourcesEntries; let i = index"
        fxLayout="row"
        fxLayoutAlign="start center"
        style="margin-bottom: 10px"
      >
        <mat-icon
          fxFlex="none"
          class="accent-text-color handle"
          style="cursor: pointer; margin-right: 20px"
          >menu</mat-icon
        >
        <app-editor-text
          fxFlex="30"
          style="margin-right: 20px"
          [name]="'txtTargetLookup' + i"
          [(ngModel)]="entry.target"
          [config]="{
            isSimpleValue: true,
            hintLimit: 50,
            customDisplayName: 'key_targetLookup',
            updateOn: updateOnBlure,
            validationKey: 'workflow/UpdateResources/target'
          }"
          appExpressionValidator
        ></app-editor-text>
        <app-editor-text
          fxFlex="70"
          style="margin-top: -3px"
          [name]="'txtValueExpression' + i"
          [(ngModel)]="entry.valueexpression"
          [config]="{
            isSimpleValue: true,
            hintLimit: 50,
            customDisplayName: 'key_valueExpression',
            updateOn: updateOnBlure,
            validationKey: 'workflow/UpdateResources/valueExpression'
          }"
          appExpressionValidator
        ></app-editor-text>
        <mat-checkbox
          fxFlex="none"
          [name]="'chkAllowNull' + i"
          [(ngModel)]="entry.allownull"
          class="accent-text-color"
          [matTooltip]="'key_allowNull' | translate"
          style="margin-right: 0px; margin-left: 10px"
        ></mat-checkbox>
        <button
          mat-icon-button
          color="warn"
          fxFlex="none"
          [matTooltip]="'key_delete' | translate"
          style="margin-top: -3px"
          (click)="onRemoveEntry(i)"
        >
          <span class="material-icons-outlined">delete</span>
        </button>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 10px">
      <button mat-stroked-button color="primary" (click)="onAddEntry()">
        {{ 'key_addUpdateInstruction' | translate }}
      </button>
    </div>
  </div>
</form>
