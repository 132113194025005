<div>
  <mat-card *ngFor="let activity of activities" style="margin-top: 20px">
    <mat-card-content class="full-height">
      <div fxLayout="row">
        <div
          fxFlex="none"
          style="width: 60px; cursor: pointer"
          (click)="onClose(activity)"
        >
          <mat-icon style="font-size: 50px; color: #767981">{{
            activity.icon
          }}</mat-icon>
        </div>
        <div fxFlex="auto" fxLayout="column" style="width: 100%">
          <div fxFlex="none" style="font-size: 18px">
            {{ activity.name | translate }}
          </div>
          <div
            fxFlex="auto"
            style="font-size: 12px; line-height: 16px; margin-top: 5px"
          >
            {{ activity.description | translate }}
          </div>
        </div>
        <div fxFlex="none" style="margin-top: -15px; margin-bottom: 5px">
          <div
            style="margin: 10px; border-left: solid 1px lightgray; height: 100%"
          ></div>
        </div>
        <div
          fxFlex="none"
          fxLayout="column"
          fxLayoutAlign="center center"
          style="width: 60px"
        >
          <div fxFlex="none">
            <button mat-icon-button (click)="onClose(activity)">
              <mat-icon
                style="font-size: 40px; margin-left: -15px; color: #767981"
                >add</mat-icon
              >
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_addActivity' | translate"
  ></app-popup-title>
</ng-template>
