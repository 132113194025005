<kendo-splitter class="splitter">
  <kendo-splitter-pane [collapsible]="true" size="380px">
    <div
      fxLayout="column"
      style="
        margin: 5px;
        padding: 5px 10px;
        background-color: white;
        height: calc(100% - 10px);
      "
    >
      <div style="margin-top: 10px">
        <app-editor-identity
          #idpTargetResource
          [(ngModel)]="targetResource"
          [creationMode]="true"
          [config]="{
            showPhoto: true,
            popupWidth: 460,
            queryNormalSearch: '/*[starts-with(DisplayName,\'%SearchText%\')]',
            attributesToShow: [
              {
                field: 'DisplayName',
                width: 0,
                filterable: true,
                filter: 'text',
                sortable: true
              },
              {
                field: 'ObjectType',
                width: 0,
                filterable: true,
                filter: 'text',
                sortable: true
              }
            ]
          }"
        ></app-editor-identity>
      </div>
      <div style="margin-top: 30px">
        <app-editor-boolean
          [(ngModel)]="isEventHistory"
          [creationMode]="true"
          [config]="{
            controlType: 'radio',
            trueText: 'l10n_eventHistory',
            falseText: 'l10n_resourceHistory',
            initExpression: '\<true\>'
          }"
        ></app-editor-boolean>
      </div>
      <div
        fxLayout="column"
        style="margin-top: 20px"
        *ngIf="isEventHistory.value"
      >
        <div
          fxFlex="1 1 50"
          style="
            font-size: 0.75em;
            font-weight: 400;
            height: 18px;
            color: rgba(0, 0, 0, 0.54);
          "
        >
          {{ 'l10n_historyNumber' | translate }}
        </div>
        <mat-slider
          fxFlex="none"
          [max]="100"
          [min]="10"
          [step]="10"
          [thumbLabel]="true"
          [tickInterval]="10"
          [(ngModel)]="historyNumber"
          style="margin-top: 2px; padding-left: 0"
        >
        </mat-slider>
      </div>
      <div style="margin-top: 20px" *ngIf="isEventHistory.value">
        <app-editor-date
          [(ngModel)]="startDate"
          [creationMode]="true"
          [config]="{ showTime: true }"
        >
        </app-editor-date>
      </div>
      <div style="margin-top: 30px" *ngIf="isEventHistory.value">
        <app-editor-date
          [(ngModel)]="endDate"
          [creationMode]="true"
          [config]="{ showTime: true }"
        >
        </app-editor-date>
      </div>
      <div style="margin-top: 30px" *ngIf="!isEventHistory.value">
        <app-editor-date
          [(ngModel)]="statusDate"
          [creationMode]="true"
          [config]="{ showTime: true }"
        >
        </app-editor-date>
      </div>
      <div fxFlex="auto"></div>
      <div fxLayout="row" style="margin-bottom: 10px">
        <!-- <div fxFlex="auto"></div> -->
        <button
          mat-stroked-button
          color="primary"
          [disabled]="
            !targetResource.value ||
            (!isEventHistory.value && !statusDate.value)
          "
          (click)="onApply()"
        >
          {{ 'key_apply' | translate }}
        </button>
      </div>
    </div>
  </kendo-splitter-pane>
  <kendo-splitter-pane fxLayout="column">
    <div fxLayout="column" fxFlex="100" style="margin: 0 5px 0 5px">
      <div *ngIf="!previewMode">
        <app-object-history
          #objectHistory
          [dotSize]="62"
          (restore)="onRestore($event)"
        ></app-object-history>
      </div>
      <div
        *ngIf="previewMode"
        fxFlex="100"
        fxLayout="column"
        style="margin: 5px 0; background-color: white"
      >
        <div>
          <div
            class="preview-icon-layer accent-bg-color primary-contrast-color"
          >
            {{ 'key_preview' | translate }}
          </div>
        </div>
        <div style="height: 5px; background-color: #eeeeee"></div>
        <app-resource-preview
          #resourcePreview
          fxFlex="1 1 auto"
          style="background-color: white"
        ></app-resource-preview>
        <div
          *ngIf="!resourcePreview.isLoading"
          fxFlex="none"
          fxLayout="row"
          style="margin: 15px 10px"
        >
          <button
            mat-stroked-button
            color="warn"
            (click)="onExitPreview(false)"
          >
            {{ 'key_exitPreviewWithoutSave' | translate }}
          </button>
          <div fxFlex="auto"></div>
          <button
            mat-stroked-button
            color="primary"
            (click)="onExitPreview(true)"
          >
            {{ 'key_exitPreviewWithSave' | translate }}
          </button>
        </div>
      </div>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>
