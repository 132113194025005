<div fxLayout="column" class="info-body" [class.mat-elevation-z1]="true">
  <div fxFlex="none" fxLauout="row">
    <div
      fxFlex="none"
      class="info-icon-layer accent-bg-color primary-contrast-color"
    >
      <mat-icon fxFlex="none" class="info-icon">device_hub</mat-icon>
    </div>
    <div
      fxFlex="auto"
      class="primary-text-color"
      style="margin-left: 30px; font-size: 20px; line-height: 60px"
    >
      {{ 'key_structureView' | translate }}
    </div>
  </div>
  <div fxFlex="210px" style="margin-top: 20px; overflow: auto">
    <app-tree-view
      #orgStructure
      [name]="'userStructureView'"
      [spinnerMargin]="0"
      [config]="{
        sort: sortConfig,
        attributeStyles: attributeStyles,
        partialTree: true,
        pageSize: pageSize
      }"
      (afterInit)="onAfterTreeViewInit()"
    ></app-tree-view>
  </div>
  <div fxFlex="none" fxLayout="row" style="margin-top: 10px">
    <div fxFlex="auto"></div>
    <button mat-stroked-button (click)="onGotoOU()" *ngIf="hasOU">
      {{ 'key_gotoOU' | translate }}
    </button>
  </div>
</div>
