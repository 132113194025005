<div
  fxFlex="auto"
  *ngIf="configMode || showEditor(resource.rightSets)"
  [ngClass]="{ 'config-hidden': configMode && !showEditor(resource.rightSets) }"
  style="width: 100%; margin-bottom: -6px"
>
  <div
    fxLayout="column"
    [fxLayoutAlign]="config.verticalAlign + ' stretch'"
    [style.height.px]="config.height"
    [style.fontSize.px]="config.fontSize"
    [style.backgroundColor]="config.backgroundColor"
    [style.color]="config.color"
    [style.borderStyle]="config.borderStyle"
    [style.borderRadius.px]="config.borderRadius"
    [style.borderWidth.px]="config.borderWidth"
    [style.borderColor]="config.borderColor"
    [style.marginTop.px]="config.spaceTop"
    [style.marginBottom.px]="config.spaceBottom"
    [matTooltip]="config.toolTip | translate"
    style="width: 100%"
  >
    <div
      fxLayout="row"
      [fxLayoutAlign]="config.textAlign + ' center'"
      [style.paddingLeft.px]="config.spaceLeft"
      [style.paddingRight.px]="config.spaceRight"
      [style.paddingTop.px]="config.paddingTop"
      [style.paddingBottom.px]="config.paddingBottom"
      style="overflow: auto"
    >
      <div fxFlex="none" *ngIf="config.icon" style="margin-right: 5px">
        <mat-icon
          fxFlex="none"
          *ngIf="config.icon"
          style="margin-right: 10px"
          >{{ config.icon }}</mat-icon
        >
      </div>
      <div
        fxFlex="none"
        [innerHTML]="
          (config.isHyperLink && config.hyperLinkText
            ? config.hyperLinkText
            : value
          )
            | translate
            | safeHtml
        "
        [ngClass]="{
          'full-width-with-icon': config.icon,
          'full-width': !config.icon,
          'hyper-link': config.isHyperLink
        }"
        (click)="onHyperLink()"
      ></div>
    </div>
  </div>
  <!-- <input
    fxFlex="1 1 auto"
    [style.height.px]="config.height"
    [style.lineHeight.px]="config.height"
    [style.fontSize.px]="config.fontSize"
    [style.backgroundColor]="config.backgroundColor"
    [style.color]="config.color"
    [style.borderStyle]="config.borderStyle"
    [style.borderRadius.px]="config.borderRadius"
    [style.borderWidth.px]="config.borderWidth"
    [style.borderColor]="config.borderColor"
    [style.paddingLeft.px]="config.spaceLeft"
    [style.paddingRight.px]="config.spaceRight"
    [style.marginTop.px]="config.spaceTop"
    [style.marginBottom.px]="config.spaceBottom"
    [style.textAlign]="config.textAlign"
    [matTooltip]="config.toolTip | translate"
    [value]="value | translate"
    readonly
    style="width: 100%;"
  /> -->
</div>
