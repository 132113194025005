import { AfterViewInit, Component, Injector } from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { ResourceColumnConfig } from '../../models/componentContract.model';
import { AttributeResource } from '../../models/dataContract.model';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import { XpathEditorConfig } from '../../models/editorContract.model';

@Component({
  selector: 'app-editor-xpath-config',
  templateUrl: './editor-xpath-config.component.html',
  styleUrls: ['./editor-xpath-config.component.scss'],
})
export class EditorXpathConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: XpathEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  columnName: string;

  public selectedColumnAttribute: ResourceColumnConfig;
  public columnToAdd: string;

  constructor(injector: Injector, private dragula: DragulaService) {
    super(injector);

    try {
      this.dragula.createGroup('COLUMNS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngAfterViewInit() {
    this.initComponent();
  }

  onSelectColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedColumnAttribute = ca;
  }

  onAddColumn() {
    if (this.columnToAdd) {
      const pos = this.data.config.tableConfig.columns.findIndex(
        (item: ResourceColumnConfig) =>
          item.field.toLowerCase() === this.columnToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.tableConfig.columns.push({
          field: this.columnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        });
      }
    }
    this.columnToAdd = undefined;
  }

  onDeleteColumn(column: ResourceColumnConfig) {
    const index = this.data.config.tableConfig.columns.findIndex(
      (c) => c.field === column.field
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.config.tableConfig.columns.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (this.data.config.tableConfig.columns[lastColumnIndex].locked) {
          this.data.config.tableConfig.columns[lastColumnIndex].locked = false;
        }
      }

      // remove column
      this.data.config.tableConfig.columns.splice(index, 1);

      // set focus
      if (column.field === this.selectedColumnAttribute.field) {
        if (this.data.config.tableConfig.columns.length > 0) {
          this.selectedColumnAttribute =
            this.data.config.tableConfig.columns[0];
        } else {
          this.selectedColumnAttribute = undefined;
        }
      }
    }
  }

  onAddExportAttribute(name: string) {
    const douplicates = this.data.config.tableConfig.exportAttributes.filter(
      (element) => {
        return element.toLowerCase() === name.toLowerCase();
      }
    );
    if (douplicates && douplicates.length > 0) {
      return;
    } else {
      this.data.config.tableConfig.exportAttributes.push(name);
    }
  }

  onRemoveExportAttribute(attribute: string) {
    const index = this.data.config.tableConfig.exportAttributes.findIndex(
      (element) => {
        return element.toLowerCase() === attribute.toLowerCase();
      }
    );
    if (index >= 0) {
      this.data.config.tableConfig.exportAttributes.splice(index, 1);
    }
  }
}
