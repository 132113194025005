import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ReportSeparatorConfig } from '../../models/componentContract.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { NgForm } from '@angular/forms';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-report-separator-config',
  templateUrl: './report-separator-config.component.html',
  styleUrls: ['./report-separator-config.component.scss'],
})
export class ReportSeparatorConfigComponent implements OnInit, AfterViewInit {
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @ViewChild('reportCardGeneralForm')
  generalForm: NgForm;

  data: ReportSeparatorConfig;

  constructor(private windowRef: WindowRef) {}

  ngOnInit(): void {
    console.log();
  }

  ngAfterViewInit(): void {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  onResetToThemeColor() {
    this.data.backgroundColor = undefined;
    this.data.descriptionColor = undefined;
    this.data.iconColor = undefined;
    this.data.textColor = undefined;
  }

  onAddAllowedSet(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (!this.data.permissionSets) {
      this.data.permissionSets = [];
    }

    if ((value || '').trim()) {
      const index = this.data.permissionSets.indexOf(value.trim());
      if (index < 0) {
        this.data.permissionSets.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAllowedSet(setName: string) {
    const index = this.data.permissionSets.indexOf(setName);
    if (index >= 0) {
      this.data.permissionSets.splice(index, 1);
    }
  }

  onColorChange() {
    if (this.generalForm) {
      this.generalForm.form.markAsDirty();
    }
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    if (this.windowRef) {
      this.windowRef.close(this.data);
    }
  }
}
