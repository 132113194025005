import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import { Subscription, Observable, of, ReplaySubject } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';

import {
  AttributeResource,
  BroadcastEvent,
  AuthMode,
} from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';

import { ConfigService } from '../../services/config.service';
import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';

@Component({
  selector: 'app-attribute-picker',
  templateUrl: './attribute-picker.component.html',
  styleUrls: ['./attribute-picker.component.scss'],
})
export class AttributePickerComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  objectType = '';

  @Input()
  multiple = false;

  @Input()
  appearance: MatFormFieldAppearance = 'standard';

  @Input()
  placeHolder = '';

  @Input()
  tooltip = '';

  @Input()
  disabled = false;

  @Input()
  availableItems: Array<string> = [];

  @Input()
  preSelectedItems: Array<string> = [];

  @Output()
  attributeChange = new EventEmitter<any>();

  value: Array<AttributeResource> = [];

  attributes: Observable<Array<AttributeResource>> = of([]);

  generalAttributes: Array<AttributeResource> = [
    {
      displayName: 'Created Time',
      systemName: 'CreatedTime',
      dataType: 'DateTime',
    },
    {
      displayName: 'Description',
      systemName: 'Description',
      dataType: 'String',
    },
    {
      displayName: 'Display Name',
      systemName: 'DisplayName',
      dataType: 'String',
    },
    {
      displayName: 'Expiration Time',
      systemName: 'ExpirationTime',
      dataType: 'DateTime',
    },
    {
      displayName: 'Resource ID',
      systemName: 'ObjectID',
      dataType: 'String',
    },
  ];

  refreshToken = new ReplaySubject(undefined);

  constructor(
    private resource: ResourceService,
    private translate: TransService,
    private config: ConfigService,
    private swap: SwapService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.refreshToken
        .pipe(
          switchMap(() => {
            if (this.objectType === '*') {
              return of(null).pipe(
                tap(() => {
                  this.generalAttributes.forEach((a: AttributeResource) => {
                    let exists = false;
                    if (this.resource.authenticationMode === AuthMode.azure) {
                      exists =
                        this.preSelectedItems.findIndex((p: string) => {
                          return p.toLowerCase() === a.systemName.toLowerCase();
                        }) >= 0;
                    } else {
                      exists =
                        this.preSelectedItems.findIndex((p: string) => {
                          return p === a.systemName;
                        }) >= 0;
                    }
                    if (exists) {
                      this.value.push(a);
                    }
                  });
                  this.attributes = of(this.generalAttributes);
                })
              );
            } else {
              return this.resource
                .getType(
                  this.objectType,
                  this.config.getCulture(this.translate.currentCulture)
                )
                .pipe(
                  tap((result) => {
                    if (result) {
                      let entries: Array<AttributeResource> = [];
                      Object.keys(result).forEach((key) => {
                        if (result[key].dataType.toLowerCase() !== 'binary') {
                          if (
                            this.availableItems &&
                            this.availableItems.length > 0
                          ) {
                            const posA = this.availableItems.findIndex(
                              (a) =>
                                a.toLowerCase() ===
                                result[key].systemName.toLowerCase()
                            );
                            const posP = this.preSelectedItems.findIndex(
                              (a) =>
                                a.toLowerCase() ===
                                result[key].systemName.toLowerCase()
                            );
                            if (posA >= 0 || posP >= 0) {
                              entries.push(result[key]);
                            }
                          } else {
                            entries.push(result[key]);
                          }
                        }

                        if (
                          this.preSelectedItems.findIndex((p) => {
                            return this.resource.authenticationMode ===
                              AuthMode.azure
                              ? p.toLowerCase() === result[key].systemName
                              : p === result[key].systemName;
                          }) >= 0
                        ) {
                          for (const [
                            index,
                            attribute,
                          ] of this.value.entries()) {
                            if (
                              attribute.systemName === result[key].systemName
                            ) {
                              this.value.splice(index, 1);
                            }
                          }
                          this.value.push(result[key]);
                        }
                      });

                      for (const [index, attribute] of this.value.entries()) {
                        if (
                          entries.findIndex((e) => {
                            return e.systemName === attribute.systemName;
                          }) < 0
                        ) {
                          this.value.splice(index, 1);
                        }
                      }
                      entries = entries.sort((a, b) => {
                        if (a.displayName > b.displayName) {
                          return 1;
                        } else if (a.displayName < b.displayName) {
                          return -1;
                        }
                        return 0;
                      });

                      this.attributes = of(entries);
                    }
                  })
                );
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-language':
              this.refreshToken.next(undefined);
              break;
            default:
              break;
          }
        }
      })
    );

    if (this.objectType) {
      this.refreshToken.next(undefined);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  refresh() {
    this.refreshToken.next(undefined);
  }

  onSelectionChange() {
    if (this.attributeChange.observers.length > 0) {
      this.attributeChange.emit(this.value);
    }
  }
}
