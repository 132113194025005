import {
  Component,
  OnInit,
  AfterViewInit,
  forwardRef,
  Injector,
  Inject,
  LOCALE_ID,
  DoCheck,
  IterableDiffers,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgControl,
  FormControl,
} from '@angular/forms';

import { tap, switchMap, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import * as moment from 'moment';

import { AttributeEditor } from '../../models/dynamicEditor.interface';

import { DateEditorConfig } from '../../models/editorContract.model';
import { TransService } from '../../models/translation.model';

import { createDateEditorValidator } from '../../validators/validators';

import { SwapService } from '../../services/swap.service';
import { IntlService, CldrIntlService } from '@progress/kendo-angular-intl';
import { ConfigService } from '../../services/config.service';

import { EditorDateConfigComponent } from './editor-date-config.component';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';
import { EditorEvent } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-date',
  templateUrl: './editor-date.component.html',
  styleUrls: ['./editor-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorDateComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorDateComponent),
      multi: true,
    },
  ],
})
export class EditorDateComponent
  extends AttributeEditor
  implements OnInit, DoCheck, AfterViewInit
{
  private conf = new DateEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  differ: any;

  focused = false;

  dateValue: Date;

  get value() {
    return this.dateValue;
  }
  set value(value) {
    this.editorAttribute.value = value ? moment(value).format() : null;

    if (this.editorAttribute && this.editorAttribute.value) {
      let strValue: string = this.editorAttribute.value;
      // remove the +00:00 or -00:00 part
      strValue = strValue.substr(0, 19);
      this.dateValue = new Date(strValue);
    } else {
      this.dateValue = null;
    }

    // this.dateValue =
    //   this.editorAttribute && this.editorAttribute.value
    //     ? moment(this.editorAttribute.value).toDate()
    //     : null;

    this.propagateChange(this.editorAttribute);
  }

  get format() {
    const dFormat = this.config.dateFormat
      ? this.config.dateFormat
      : this.translate.instant('key_dateFormat');
    const tFormat = this.config.timeFormat
      ? this.config.timeFormat
      : this.translate.instant('key_timeFormat');

    return this.config.showTime ? `${dFormat} ${tFormat}` : dFormat;
  }

  // obsoletet, using pipe now
  get minDate() {
    if (typeof this.config.minDate === 'string') {
      this.config.minDate = new Date(this.config.minDate);
    }
    return this.config.minDate;
  }
  get maxDate() {
    if (typeof this.config.maxDate === 'string') {
      this.config.maxDate = new Date(this.config.maxDate);
    }
    return this.config.maxDate;
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  setConfig(config: any = null) {
    if (config) {
      const dt = moment(config.value).toDate();
      if (dt && !isNaN(dt.getTime())) {
        if (config.name === 'queryMinDate') {
          this.config.minDate = dt;
        } else if (config.name === 'queryMaxDate') {
          this.config.maxDate = dt;
        }
      } else {
        if (config.name === 'queryMinDate') {
          this.config.minDate = null;
        } else if (config.name === 'queryMaxDate') {
          this.config.maxDate = null;
        }
      }
    } else {
      if (this.config.useMinDate) {
        if (this.config.queryMinDate) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryMinDate',
            this.config.queryMinDate
          );
        } else if (this.config.configMinDate) {
          this.config.minDate = this.config.configMinDate;
        } else {
          this.config.minDate = null;
        }
      } else {
        this.config.minDate = null;
      }
      if (this.config.useMaxDate) {
        if (this.config.queryMaxDate) {
          this.swap.propagateEditorConfigChanged(
            this.config.attributeName,
            'queryMaxDate',
            this.config.queryMaxDate
          );
        } else if (this.config.configMaxDate) {
          this.config.maxDate = this.config.configMaxDate;
        } else {
          this.config.maxDate = null;
        }
      } else {
        this.config.maxDate = null;
      }
    }
  }

  constructor(
    private swap: SwapService,
    private translate: TransService,
    private configService: ConfigService,
    private differs: IterableDiffers,
    private window: WindowService,
    public injector: Injector,
    public intlService: IntlService,
    @Inject(LOCALE_ID) public localeId: string
  ) {
    super(injector);

    this.differ = this.differs.find([]).create(null);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setConfig();
      this.setDisplay();
    });
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.initComponent();
    }
  }

  ngDoCheck() {
    if (this.editorAttribute) {
      const changeOccurred = this.differ.diff(this.editorAttribute.values);

      if (changeOccurred) {
        this.dateValue =
          this.editorAttribute && this.editorAttribute.value
            ? moment(this.editorAttribute.value).toDate()
            : null;
      }
    }
  }

  ngAfterViewInit() {
    if (this.simpleMode) {
      this.setDisplay(null, true);
    } else {
      setTimeout(() => {
        // const ngControl: NgControl = this.injector.get<NgControl>(
        //   NgControl as Type<NgControl>
        // );
        const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
        if (ngControl) {
          this.control = ngControl.control as FormControl;
        }
      });

      setTimeout(() => {
        this.validationFn = createDateEditorValidator(this.config);
        this.applyConfig();

        if (!this.configMode) {
          if (this.creationMode) {
            this.validateValue();
          } else if (
            this.configService.getConfig('validateBeforeEditing', false)
          ) {
            this.validateValue();
          }
        }

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            const initValue = this.resolveExpression(
              this.config.initExpression
            );
            if (initValue instanceof Date) {
              this.value = initValue;
            } else {
              this.value = new Date(initValue);
            }
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
          }
        }
      });
    }
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (typeof this.config.minDate === 'string') {
      this.config.minDate = new Date(this.config.minDate);
    }
    if (typeof this.config.maxDate === 'string') {
      this.config.maxDate = new Date(this.config.maxDate);
    }
    if (typeof this.config.configMinDate === 'string') {
      this.config.configMinDate = new Date(this.config.configMinDate);
    }
    if (typeof this.config.configMaxDate === 'string') {
      this.config.configMaxDate = new Date(this.config.configMaxDate);
    }

    (this.intlService as CldrIntlService).localeId =
      this.configService.getCulture(this.translate.currentCulture);

    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new DateEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorDateConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createDateEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    this.focused = true;
    this.propagateTouched();
  }

  onBlur() {
    this.focused = false;
  }

  onChange() {
    this.ngDoCheck();

    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(this.value);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        this.value
      )
    );
  }

  // #endregion
}
