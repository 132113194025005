/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { Component, OnInit, DoCheck, OnDestroy, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BehaviorSubject, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import {
  Resource,
  BasicResource,
  MultivalueParameter,
  ActionMenuItem,
  AttributeResource,
} from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';

import {
  AttributeView,
  EditorResult,
  AttributeEditor,
} from '../../models/dynamicEditor.interface';

import { ConfigService } from '../../services/config.service';

import { EditorIdentitiesComponent } from '../editor-identities/editor-identities.component';
import { MatDialog } from '@angular/material/dialog';
import { EditorCreatorComponent } from '../editor-creator/editor-creator.component';
import { ModalType } from '../../models/componentContract.model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-attribute-view',
  templateUrl: './attribute-view.component.html',
  styleUrls: ['./attribute-view.component.scss'],
})
export class AttributeViewComponent
  extends AttributeView
  implements OnInit, OnDestroy, DoCheck
{
  private refreshToken = new BehaviorSubject(undefined);

  actionItems: Array<ActionMenuItem> = [
    {
      name: 'config',
      icon: 'settings',
      text: 'key_settings',
      color: 'primary',
    },
    {
      name: 'delete',
      icon: 'delete',
      text: 'key_delete',
      color: 'warn',
    },
  ];

  isMinimized = false;

  uniquenessCheckes: Array<{
    attributeName: string;
    objectType: Array<string>;
    condition: string;
  }> = [];

  fontSize = 16;

  loadAsReadonly = false;

  protected registerChangeHandler() {
    // const control = this.getControl('DisplayName');
    // if (control) {
    //   control.valueChanges.subscribe(val => {
    //     const editor = this.getEditor('Description');
    //     if (editor) {
    //       editor.value = 'TEST';
    //     }
    //   });
    // }
  }

  private addToController(
    attributeBackup: EditorResult[],
    attribute: any,
    attributeDef: any
  ) {
    const pos = attributeBackup.findIndex(
      (attr) => attr.attribute.systemName === attribute.systemName
    );

    const controller = new FormControl(attribute);
    this.attributeArray.push({
      type: attributeDef.editorType,
      config:
        pos >= 0 ? attributeBackup[pos].config : attributeDef.editorConfig,
      attribute,
      controller,
    });
    this.controls.push(controller);
  }

  private prepareAttributes(arrayBackup: EditorResult[]) {
    this.clearFormArray(this.controls);
    this.attributeArray.splice(0, this.attributeArray.length);

    const headerAttributes: Array<{
      attribute: AttributeResource;
      attributeDef: any;
    }> = [];
    const headerAttributesSorted: Array<{
      attribute: AttributeResource;
      attributeDef: any;
    }> = [];
    const normalAttributes: Array<{
      attribute: AttributeResource;
      attributeDef: any;
    }> = [];

    const headerAttributeNames: string[] = this.simpleMode
      ? this.config.getConfigEx('advancedViewSettings:headerAttributes', [])
      : [];

    this.attributeDefs.forEach((a) => {
      let attribute = this.utils.ExtraValue(
        this.currentResource,
        a.attributeName
      );

      if (!attribute) {
        attribute = {
          systemName: a.editorConfig.attributeName,
          dataType: 'string',
          multivalued: false,
          value: null,
          values: null,
        };
      }

      if (
        headerAttributeNames.findIndex(
          (a) => a.toLowerCase() === attribute.systemName.toLowerCase()
        ) >= 0
      ) {
        headerAttributes.push({ attribute, attributeDef: a });
      } else {
        normalAttributes.push({ attribute, attributeDef: a });
      }
    });

    headerAttributeNames.forEach((name: string) => {
      const pos = headerAttributes.findIndex(
        (a) =>
          a.attribute &&
          a.attribute.systemName &&
          a.attribute.systemName.toLowerCase() === name.toLowerCase()
      );
      if (pos >= 0) {
        headerAttributesSorted.push(headerAttributes[pos]);
      }
    });

    headerAttributesSorted.forEach((a) =>
      this.addToController(arrayBackup, a.attribute, a.attributeDef)
    );
    normalAttributes.forEach((a) =>
      this.addToController(arrayBackup, a.attribute, a.attributeDef)
    );

    this.registerChangeHandler();
  }

  constructor(
    private route: ActivatedRoute,
    private translate: TransService,
    private spinner: NgxUiLoaderService,
    private config: ConfigService,
    private dialog: MatDialog,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadAsReadonly = this.config.getConfigEx(
      'advancedViewSettings:loadAsReadonly',
      false
    );

    this.initComponent();

    this.fontSize = this.config.getConfig('attributeFontSize', 16);

    this.uniquenessCheckes = this.config.getConfig('uniquenessCheckes', []);

    let attributeArrayBackup: EditorResult[] = [];
    this.obsCurrentResource = this.refreshToken.pipe(
      switchMap(() => {
        return this.route.params.pipe(
          tap(() => {
            setTimeout(() => {
              if (!this.resourceToCreate) {
                this.spinner.startLoader('spinner_home');
              }
            });
          }),
          switchMap(() => {
            if (!this.attributeArray) {
              // return throwError(new Error('no attributes'));
              this.attributeArray = [];
            }

            this.initDisplayOptions(!this.simpleMode);

            attributeArrayBackup = Array.from(this.attributeArray);
            this.attributeArray.splice(0, this.attributeArray.length);

            // enabled access right check for identities, separator and button
            // a.editorType !== 'identities' &&
            // a.editorType !== 'separator' &&
            // a.editorType !== 'button' &&
            const attributes = this.attributeDefs
              .filter((a) => a.attributeName && !a.attributeName.endsWith('#'))
              .map((a) => a.attributeName);
            this.attributesToLoad.forEach((a) => {
              if (attributes.indexOf(a) < 0) {
                attributes.push(a);
              }
            });
            this.attributesToLoad =
              attributes.length > 0 ? attributes : ['DisplayName'];

            if (this.resourceToCreate) {
              return of(this.resourceToCreate);
            } else {
              const objectID = this.route.snapshot.paramMap.get('id');
              this.attributeInsertions = [];
              this.attributeRemovals = [];
              return this.resource.getResourceByID(
                objectID,
                this.tabName === '!advancedView!' ? [] : this.attributesToLoad,
                'full',
                this.config.getCulture(this.translate.currentCulture),
                'true',
                false,
                this.tabName === '!advancedView!' ? 'true' : 'false'
              );
            }
          }),
          tap((result: Resource) => {
            this.currentResource = result;
            this.prepareAttributes(attributeArrayBackup);
          })
        );
      })
    );

    this.subscription.add(
      this.obsCurrentResource.subscribe(
        () => {
          setTimeout(() => {
            if (!this.resourceToCreate) {
              this.spinner.stopLoader('spinner_home');
            }
            this.swap.broadcast({
              name: this.swap.EVENT_ATTRIBUTE_VIEW_AFTER_INIT,
            });
          });
        },
        () => {
          this.spinner.stopLoader('spinner_home');
        }
      )
    );
  }

  // ngOnChanges(changes: any) {
  //   if (changes.configMode && this.configMode === true) {
  //     this.isMinimized = false;
  //     this.refreshSelf();
  //   }
  // }

  ngDoCheck() {
    const changeAttribute = this.differ.diff(this.attributeDefs);

    if (changeAttribute && this.configMode === true) {
      setTimeout(() => {
        this.refreshSelf();
      });
    }
  }

  ngOnDestroy() {
    this.spinner.stopLoader('spinner_home');
    this.subscription.unsubscribe();
  }

  onIdentityDoubleClick(item: BasicResource) {
    this.swap.broadcast({ name: 'navigate-to-identity', parameter: item });
  }

  onIdentitiesDoubleClick(item: Resource) {
    this.swap.broadcast({ name: 'navigate-to-identity', parameter: item });
  }

  onAddIdentities(param: { attribute: string; values: Array<string> }) {
    const editor = this.getEditor(param.attribute) as EditorIdentitiesComponent;
    const resourceID = this.utils.ExtraValue(
      this.currentResource,
      'ObjectID:value'
    );
    const resourceType = this.utils.ExtraValue(
      this.currentResource,
      'ObjectType:value'
    );
    const resourceName = this.utils.ExtraValue(
      this.currentResource,
      'DisplayName:value'
    );
    const parameter: MultivalueParameter = {
      attribute: param.attribute,
      values: param.values,
      editor,
      resourceID,
      resourceType,
      resourceName,
    };

    this.swap.broadcast({ name: 'add-identities', parameter });
  }

  onRemoveIdentities(param: { attribute: string; values: Array<string> }) {
    const editor = this.getEditor(param.attribute) as EditorIdentitiesComponent;
    const resourceID = this.utils.ExtraValue(
      this.currentResource,
      'ObjectID:value'
    );
    const resourceType = this.utils.ExtraValue(
      this.currentResource,
      'ObjectType:value'
    );
    const parameter: MultivalueParameter = {
      attribute: param.attribute,
      values: param.values,
      editor,
      resourceID,
      resourceType,
    };

    this.swap.broadcast({ name: 'remove-identities', parameter });
  }

  onRemoveAllIdentities(param: { attribute: string; values: Array<string> }) {
    const editor = this.getEditor(param.attribute) as EditorIdentitiesComponent;
    const resourceID = this.utils.ExtraValue(
      this.currentResource,
      'ObjectID:value'
    );
    const resourceType = this.utils.ExtraValue(
      this.currentResource,
      'ObjectType:value'
    );
    const parameter: MultivalueParameter = {
      attribute: param.attribute,
      values: param.values,
      editor,
      resourceID,
      resourceType,
    };

    this.swap.broadcast({ name: 'remove-all-identities', parameter });
  }

  // refresh all
  refresh() {
    try {
      (this.route.params as BehaviorSubject<any>).next({
        id: this.utils.ExtraValue(this.currentResource, 'ObjectID:value'),
        selfRefresh: true,
      });
    } catch {}
  }

  // refresh self only
  refreshSelf() {
    this.refreshToken.next(undefined);
  }

  getEditor(attributeName: string): AttributeEditor {
    if (attributeName) {
      if (this.editors) {
        const attEditor = this.editors.find(
          (e) =>
            e.attribute &&
            e.attribute.systemName &&
            e.attribute.systemName.toLowerCase() === attributeName.toLowerCase()
        );
        if (attEditor) {
          return attEditor;
        } else {
          return this.editors.find(
            (e) =>
              e.config &&
              e.config.attributeName &&
              e.config.attributeName.toLowerCase() ===
                attributeName.toLowerCase()
          );
        }
      }
    }
    return null;
  }

  isDirty() {
    if (this.attributeArray) {
      return (
        this.attributeArray.findIndex((t) => t.controller.dirty === true) >= 0
      );
    }
    return false;
  }

  hasError() {
    if (this.attributeArray) {
      return (
        this.attributeArray.findIndex((t) => t.controller.valid === false) >= 0
      );
    }
    return false;
  }

  resetDisplayOptions() {
    this.attributeDefs.forEach((def: any) => {
      if (def.attributeName) {
        const dp = this.displayOptions[def.attributeName];
        if (dp) {
          if (
            def.editorConfig &&
            def.editorConfig.accessUsedFor === 'visibility'
          ) {
            if (
              def.editorConfig.accessDenied &&
              def.editorConfig.accessDenied.length > 0 &&
              this.resource.inPermissionSets(def.editorConfig.accessDenied)
            ) {
              dp.hideAttribute = true;
              dp.removeAttribute = def.editorConfig.hideFromDOM;
            } else if (
              this.resource.inPermissionSets(def.editorConfig.accessAllowed)
            ) {
              dp.hideAttribute = false;
              dp.removeAttribute = false;
            } else {
              dp.hideAttribute = true;
              dp.removeAttribute = def.editorConfig.hideFromDOM;
            }
          } else {
            dp.hideAttribute = false;
            dp.removeAttribute = false;
          }
        }
      }
    });
  }

  onAction(actionName: string, attribute: EditorResult) {
    switch (actionName) {
      case 'config':
        this.onConfig(attribute.attribute.systemName);
        break;
      case 'delete':
        this.onDelete(attribute);
        break;
      default:
        break;
    }
  }

  onResize() {
    const name = this.isMinimized ? 'maximize' : 'minimize';
    this.isMinimized = !this.isMinimized;
    this.swap.broadcast({ name, parameter: this.tabName });
  }

  onAddAttribute() {
    const dialogRef = this.dialog.open(EditorCreatorComponent, {
      minWidth: '420px',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result !== 'cancel') {
        if (
          this.attributeDefs.findIndex(
            (a: any) => a.attributeName === result.attributeName
          ) >= 0
        ) {
          this.modal.show(
            ModalType.error,
            'key_error',
            'l10n_attributeAlreadyExists'
          );
        } else {
          this.attributeDefs.push({
            attributeName: result.attributeName,
            editorType: result.type,
            editorConfig: {
              attributeName: result.attributeName,
              units: result.layoutUnits,
            },
          });
        }
      }
    });
  }

  onSave() {
    this.swap.broadcast({
      name: 'save-attribute',
      parameter: this.tabName,
    });
  }

  onAddValue(name: string, value: string | Array<string>) {
    const arrValue = Array.isArray(value) ? value : [value];

    arrValue.forEach((v: string) => {
      const pos = this.attributeRemovals.findIndex(
        (attr: any) => attr.name === name && attr.value === v
      );
      if (pos >= 0) {
        this.attributeRemovals.splice(pos, 1);
      } else {
        const foundIndex = this.attributeInsertions.findIndex(
          (attr: any) => attr.name === name && attr.value === v
        );
        if (foundIndex < 0) {
          this.attributeInsertions.push({ name, value: v });
        }
      }
    });

    // if (Array.isArray(value)) {
    //   const valuesToAdd = value.map((v: string) => {
    //     return { name, value: v };
    //   });
    //   this.attributeInsertions = [...this.attributeInsertions, ...valuesToAdd];

    //   this.attributeRemovals = this.attributeRemovals.filter((attr: any) => {
    //     value.findIndex((v: string) => attr.name === name && attr.value === v) <
    //       0;
    //   });
    // } else {
    //   this.attributeInsertions.push({ name, value });

    //   const pos = this.attributeRemovals.findIndex(
    //     (attr: any) => attr.name === name && attr.value === value
    //   );
    //   if (pos >= 0) {
    //     this.attributeRemovals.splice(pos, 1);
    //   }
    // }
  }

  onRemoveValue(name: string, value: string | Array<string>) {
    const arrValue = Array.isArray(value) ? value : [value];

    arrValue.forEach((v: string) => {
      const pos = this.attributeInsertions.findIndex(
        (attr: any) => attr.name === name && attr.value === v
      );
      if (pos >= 0) {
        this.attributeInsertions.splice(pos, 1);
      } else {
        const foundIndex = this.attributeRemovals.findIndex(
          (attr: any) => attr.name === name && attr.value === v
        );
        if (foundIndex < 0) {
          this.attributeRemovals.push({ name, value: v });
        }
      }
    });
    // if (Array.isArray(value)) {
    //   const valuesToAdd = value.map((v: string) => {
    //     return { name, value: v };
    //   });
    //   this.attributeRemovals.concat(valuesToAdd);

    //   this.attributeInsertions = this.attributeInsertions.filter(
    //     (attr: any) => {
    //       value.findIndex(
    //         (v: string) => attr.name === name && attr.value === v
    //       ) < 0;
    //     }
    //   );
    // } else {
    //   this.attributeRemovals.push({ name, value });

    //   const pos = this.attributeInsertions.findIndex(
    //     (attr: any) => attr.name === name && attr.value === value
    //   );
    //   if (pos >= 0) {
    //     this.attributeInsertions.splice(pos, 1);
    //   }
    // }
  }
}
