<div
  fxLayout="column"
  [style.backgroundColor]="
    localConfig.useThemeColor ? 'white' : localConfig.backgroundColor
  "
  class="full-size-relative"
  style="padding: 0 20px"
  ngxUiLoaderBlurred
  [blur]="blurLevel"
  [loaderId]="localConfig.name"
>
  <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="start center">
    <div fxFlex="none" style="cursor: pointer" (click)="onAction($event)">
      <mat-icon
        style="font-size: 52px; margin-top: 16px"
        [ngClass]="{ 'accent-color': localConfig.useThemeColor }"
        [style.color]="localConfig.useThemeColor ? '' : localConfig.iconColor"
      >
        {{ localConfig.iconText }}</mat-icon
      >
    </div>
    <div fxFlex="auto"></div>
    <div fxFlex="none" fxLayout="column" fxLayoutAlign="start end">
      <div
        fxFlex="none"
        [ngClass]="{ 'accent-text-color': localConfig.useThemeColor }"
        [style.color]="localConfig.useThemeColor ? '' : localConfig.textColor"
        style="margin-bottom: 2px"
      >
        {{ localConfig.title | translate }}
      </div>
      <div
        fxFlex="none"
        [ngClass]="{ 'primary-text-color': localConfig.useThemeColor }"
        [style.color]="
          localConfig.useThemeColor ? '' : localConfig.mainTextColor
        "
        style="margin-top: 2px; font-size: 28px"
      >
        {{ mainTextValue | translate }}
      </div>
    </div>
  </div>
  <mat-divider style="margin-bottom: 10px"></mat-divider>
  <div
    fxLayout="row center"
    style="cursor: pointer; margin-bottom: 5px"
    (click)="onUpdateNow()"
  >
    <mat-icon
      style="margin-right: 10px"
      [ngClass]="{ 'accent-text-color': localConfig.useThemeColor }"
      [style.color]="localConfig.useThemeColor ? '' : localConfig.textColor"
      >refresh</mat-icon
    >
    <div
      [ngClass]="{ 'accent-text-color': localConfig.useThemeColor }"
      [style.color]="localConfig.useThemeColor ? '' : localConfig.textColor"
    >
      {{ 'key_updateNow' | translate }}
    </div>
  </div>
</div>
<ngx-ui-loader
  [loaderId]="localConfig.name"
  [fgsType]="spinnerType.rectangleBounce"
  [fgsSize]="40"
  [fgsColor]="'white'"
  [overlayColor]="'rgba(50,50,50,.5)'"
  [pbColor]="'white'"
></ngx-ui-loader>
