import {
  Component,
  AfterViewInit,
  Injector,
  OnInit,
  DoCheck,
  ViewChild,
} from '@angular/core';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import {
  IdsEditorConfig,
  IterablesEditorConfig,
} from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';
import { DragulaService } from 'ng2-dragula';
import { ResourceColumnConfig } from '../../models/componentContract.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-editor-identities-config',
  templateUrl: './editor-identities-config.component.html',
  styleUrls: ['./editor-identities-config.component.scss'],
})
export class EditorIdentitiesConfigComponent
  extends AttributeEditorConfig
  implements OnInit, AfterViewInit, DoCheck
{
  @ViewChild('intermediateAction')
  actionForm: NgForm;

  attributeName: string;
  columnName: string;

  formIsValid = false;

  data: {
    component: DynamicEditor;
    config: IdsEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  sortConfig = new IterablesEditorConfig();
  attrDetailSorting = {
    dataType: 'Dictionary',
    displayName: '',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrDetailSorting',
    value: null,
    values: undefined,
  };

  intermediatConfig = new IterablesEditorConfig();
  attrIntermediat = {
    dataType: 'Dictionary',
    displayName: 'key_addAdditionaAttributes',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrIntermediat',
    value: null,
    values: undefined,
  };

  public selectedColumnAttribute: ResourceColumnConfig;
  public columnToAdd: string;

  public selectedPopupColumnAttribute: ResourceColumnConfig;
  public popupColumnToAdd: string;

  constructor(injector: Injector, private dragula: DragulaService) {
    super(injector);

    try {
      this.dragula.createGroup('POPUPATTRIBUTESTOSHOW', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });

      this.dragula.createGroup('COLUMNSTOSHOW', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit(): void {
    this.sortConfig.saveAs = 'object';
    this.sortConfig.iterableType = 'array';
    this.sortConfig.showDisplayName = false;
    this.sortConfig.properties = [
      {
        name: 'field',
        displayName: 'key_attributeName',
        type: 'text',
        isKey: true,
        required: true,
        width: 60,
        options: [],
      },
      {
        name: 'dir',
        displayName: 'key_order',
        type: 'select',
        isKey: false,
        width: 40,
        default: 'asc',
        options: [
          {
            text: 'key_ascending',
            value: 'asc',
          },
          {
            text: 'key_descending',
            value: 'desc',
          },
        ],
      },
    ];

    this.intermediatConfig.saveAs = 'object';
    this.intermediatConfig.iterableType = 'dictionary';
    this.intermediatConfig.properties = [
      {
        name: 'name',
        displayName: 'key_attributeName',
        type: 'text',
        isKey: true,
        required: true,
        validation: '^[a-zA-Z0-9]*$',
      },
      {
        name: 'value',
        displayName: 'key_attributeValue',
        type: 'text',
        isKey: false,
        required: true,
      },
    ];
  }

  ngAfterViewInit() {
    this.initComponent();

    if (this.data && this.data.config) {
      if (
        this.data.config.tableConfig &&
        this.data.config.tableConfig.initSort
      ) {
        if (Array.isArray(this.data.config.tableConfig.initSort)) {
          if (this.data.config.tableConfig.initSort.length > 0) {
            this.attrDetailSorting.value =
              this.data.config.tableConfig.initSort[0];
            this.attrDetailSorting.values =
              this.data.config.tableConfig.initSort;
          }
        } else {
          this.attrDetailSorting.value = this.data.config.tableConfig.initSort;
          this.attrDetailSorting.values = [
            this.data.config.tableConfig.initSort,
          ];
        }
      }

      if (this.data.config.intermediateAttributes) {
        this.attrIntermediat.value = this.data.config.intermediateAttributes;
      }
    }
  }

  ngDoCheck(): void {
    if (this.actionForm) {
      this.formIsValid = this.actionForm.valid;
    } else {
      this.formIsValid = true;
    }
  }

  onAdditionalAttributesChange() {
    this.data.config.intermediateAttributes = this.attrIntermediat.value;
  }

  onAddExportAttribute(name: string) {
    const douplicates = this.data.config.tableConfig.exportAttributes.filter(
      (element) => {
        return element.toLowerCase() === name.toLowerCase();
      }
    );
    if (douplicates && douplicates.length > 0) {
      return;
    } else {
      this.data.config.tableConfig.exportAttributes.push(name);
    }
  }

  onRemoveExportAttribute(attribute: string) {
    const index = this.data.config.tableConfig.exportAttributes.findIndex(
      (element) => {
        return element.toLowerCase() === attribute.toLowerCase();
      }
    );
    if (index >= 0) {
      this.data.config.tableConfig.exportAttributes.splice(index, 1);
    }
  }

  onAddLinkAction(event: MatChipInputEvent) {
    if (event && event.value) {
      const inputValue = event.value.trim();

      if (
        this.data.config.tableConfig.linkActions &&
        this.data.config.tableConfig.linkActions.length > 0
      ) {
        const index = this.data.config.tableConfig.linkActions.findIndex(
          (a: string) => {
            return a.toLowerCase() === inputValue.toLowerCase();
          }
        );
        if (index < 0) {
          this.data.config.tableConfig.linkActions.push(inputValue);
        }
      } else {
        this.data.config.tableConfig.linkActions = [inputValue];
      }
    }

    if (event.chipInput.inputElement) {
      event.chipInput.inputElement.value = '';
    }
  }

  onRemoveLinkAction(action: string) {
    const index = this.data.config.tableConfig.linkActions.indexOf(action);
    if (index >= 0) {
      this.data.config.tableConfig.linkActions.splice(index, 1);
    }
  }

  onSelectColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedColumnAttribute = ca;
  }

  onAddColumn() {
    if (this.columnToAdd) {
      const pos = this.data.config.tableConfig.columns.findIndex(
        (item: ResourceColumnConfig) =>
          item.field.toLowerCase() === this.columnToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.tableConfig.columns.push({
          field: this.columnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        });
      }
    }
    this.columnToAdd = undefined;
  }

  onDeleteColumn(column: ResourceColumnConfig) {
    const index = this.data.config.tableConfig.columns.findIndex(
      (c) => c.field === column.field
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.config.tableConfig.columns.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (this.data.config.tableConfig.columns[lastColumnIndex].locked) {
          this.data.config.tableConfig.columns[lastColumnIndex].locked = false;
        }
      }

      // remove column
      this.data.config.tableConfig.columns.splice(index, 1);

      // set focus
      if (column.field === this.selectedColumnAttribute.field) {
        if (this.data.config.tableConfig.columns.length > 0) {
          this.selectedColumnAttribute =
            this.data.config.tableConfig.columns[0];
        } else {
          this.selectedColumnAttribute = undefined;
        }
      }
    }
  }

  onSelectPopupColumnAttribute(ca: ResourceColumnConfig) {
    this.selectedPopupColumnAttribute = ca;
  }

  onAddPopupColumn() {
    if (this.popupColumnToAdd) {
      const pos = this.data.config.idpConfig.attributesToShow.findIndex(
        (item: ResourceColumnConfig) =>
          item.field.toLowerCase() === this.popupColumnToAdd.toLowerCase()
      );
      if (pos < 0) {
        this.data.config.idpConfig.attributesToShow.push({
          field: this.popupColumnToAdd,
          width: 0,
          filterable: false,
          filter: 'text',
          sortable: false,
          locked: false,
        });
      }
    }
    this.popupColumnToAdd = undefined;
  }

  onDeletePopupColumn(column: ResourceColumnConfig) {
    const index = this.data.config.idpConfig.attributesToShow.findIndex(
      (c) => c.field === column.field
    );
    // if after remove there is only one column left, which has "lock column" set to true,
    // than "lock column" must be set to false, before column is removed
    if (index >= 0) {
      if (this.data.config.idpConfig.attributesToShow.length === 2) {
        const lastColumnIndex = index === 0 ? 1 : 0;
        if (
          this.data.config.idpConfig.attributesToShow[lastColumnIndex].locked
        ) {
          this.data.config.idpConfig.attributesToShow[lastColumnIndex].locked =
            false;
        }
      }

      // remove column
      this.data.config.idpConfig.attributesToShow.splice(index, 1);

      // set focus
      if (column.field === this.selectedPopupColumnAttribute.field) {
        if (this.data.config.idpConfig.attributesToShow.length > 0) {
          this.selectedPopupColumnAttribute =
            this.data.config.idpConfig.attributesToShow[0];
        } else {
          this.selectedPopupColumnAttribute = undefined;
        }
      }
    }
  }

  onClose(): void {
    if (this.data && this.data.config && this.data.config.tableConfig) {
      this.data.config.tableConfig.initSort = this.attrDetailSorting.values;
    }
    if (this.windowRef) {
      this.windowRef.close(this.data);
    }
  }

  onEnableReferenceFilter(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.isReference === true) {
      selectedColumn.sortable = false;
    }
  }

  onEnableSorting(selectedColumn: ResourceColumnConfig) {
    if (selectedColumn.sortable) {
      selectedColumn.isReference = false;
    }
  }
}
