import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-delete-resource',
  templateUrl: './activity-delete-resource.component.html',
  styleUrls: ['./activity-delete-resource.component.scss'],
})
export class ActivityDeleteResourceComponent extends WorkflowActivityView {
  disablePostTrigger: boolean;
  disablePreTrigger: boolean;
  requestorAsActor: boolean;
  skipPermission: boolean;
  target: string;

  @Input()
  get disablePreupdateTrigger() {
    return this.disablePreTrigger;
  }
  set disablePreupdateTrigger(value: boolean) {
    this.disablePreTrigger = value;
    this.disablePreupdateTriggerChange.emit(this.disablePreTrigger);
  }
  @Output()
  disablePreupdateTriggerChange = new EventEmitter();

  @Input()
  get disablePostupdateTrigger() {
    return this.disablePostTrigger;
  }
  set disablePostupdateTrigger(value: boolean) {
    this.disablePostTrigger = value;
    this.disablePostupdateTriggerChange.emit(this.disablePostTrigger);
  }
  @Output()
  disablePostupdateTriggerChange = new EventEmitter();

  @Input()
  get useRequestorAsActor() {
    return this.requestorAsActor;
  }
  set useRequestorAsActor(value: boolean) {
    this.requestorAsActor = value;
    this.useRequestorAsActorChange.emit(this.requestorAsActor);
  }
  @Output()
  useRequestorAsActorChange = new EventEmitter();

  @Input()
  get skipPermissionCheck() {
    return this.skipPermission;
  }
  set skipPermissionCheck(value: boolean) {
    this.skipPermission = value;
    this.skipPermissionCheckChange.emit(this.skipPermission);
  }
  @Output()
  skipPermissionCheckChange = new EventEmitter();

  @Input()
  get deletionTarget() {
    return this.target;
  }
  set deletionTarget(value: string) {
    this.target = value;
    this.deletionTargetChange.emit(this.target);
  }
  @Output()
  deletionTargetChange = new EventEmitter();

  constructor() {
    super();
  }

  onImpersonationChange() {
    if (!this.useRequestorAsActor) {
      this.skipPermissionCheck = false;
    }
  }
}
