import { Component, Injector, AfterViewInit } from '@angular/core';

import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';

import { AttributeResource } from '../../models/dataContract.model';
import { SeparatorEditorConfig } from '../../models/editorContract.model';

@Component({
  selector: 'app-editor-separator-config',
  templateUrl: './editor-separator-config.component.html',
  styleUrls: ['./editor-separator-config.component.scss'],
})
export class EditorSeparatorConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: SeparatorEditorConfig;
    attribute: AttributeResource;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit() {
    this.initComponent();
  }
}
