import { Component, Injector, OnInit } from '@angular/core';
import { EditorEvent } from '../../models/dataContract.model';
import { CustomComponent } from '../../models/dynamicEditor.interface';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-data-flow-rule-type',
  templateUrl: './data-flow-rule-type.component.html',
  styleUrls: ['./data-flow-rule-type.component.scss'],
})
export class DataFlowRuleTypeComponent
  extends CustomComponent
  implements OnInit
{
  dataflowType = '';

  constructor(injector: Injector, private utils: UtilsService) {
    super(injector);
  }

  ngOnInit(): void {
    this.componentValue = 'SourceReferenceInTarget';

    if (this.currentResource) {
      this.dataflowType = this.utils.ExtraValue(
        this.currentResource,
        'dataflowtype:value'
      );
    }
  }

  onChange() {
    this.swap.editorEvent(
      new EditorEvent(
        'change',
        'dataflowtype',
        undefined,
        'dataflowrule',
        this.componentValue
      )
    );
  }
}
