<!-- ngIf will not be used any more, use ngClass for display control  -->
<!-- *ngIf="!config.isMultivalue && (configMode || showEditor(resource.rightSets))" -->
<mat-form-field
  fxFlex="auto"
  [appearance]="'fill'"
  [ngClass]="{
    'config-hidden': configMode && !showEditor(resource.rightSets),
    'edit-mode':
      !creationMode &&
      config.attributeName &&
      !config.attributeName.endsWith('#')
  }"
  style="width: 100%; margin-bottom: -6px"
>
  <textarea
    matInput
    spellcheck="false"
    [placeholder]="displayName | translate"
    [matTooltip]="tooltip"
    [(ngModel)]="value"
    (focus)="onFocuse()"
    (change)="onChange()"
    [disabled]="disabled(resource.rightSets)"
    [rows]="config.rows"
    [ngModelOptions]="{ updateOn: modelUpdateOn }"
  ></textarea>
  <mat-label>{{ displayName | translate }}</mat-label>
  <mat-hint align="start" *ngIf="!control || (control && control.valid)">
    <div fxLayout="row">
      <div
        fxFlex="auto"
        *ngIf="description"
        style="margin-right: 10px"
        [matTooltip]="description | translate"
      >
        {{ description | translate | ellipsis: config.hintLimit }}
      </div>
      <div
        fxFlex="auto"
        *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
        style="font-size: 14px; color: #d8bb2e"
      >
        <mat-icon [matTooltip]="'key_noWriteAccess' | translate"
          >error</mat-icon
        >
      </div>
      <div
        fxFlex="auto"
        *ngIf="!hideNoReadAccessMessage && !readAccess"
        style="font-size: 14px; color: red"
      >
        <mat-icon [matTooltip]="'key_noReadAccess' | translate">error</mat-icon>
      </div>
    </div>
  </mat-hint>
  <mat-hint
    *ngIf="control && control.invalid"
    class="warn-color"
    [matTooltip]="control.errors['message'] | translate"
    >{{
      control.errors['message'] | translate | ellipsis: config.hintLimit
    }}</mat-hint
  >
</mat-form-field>
