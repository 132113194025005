<div class="search-input" #anchor>
  <mat-form-field appearance="outline" style="width: 100%">
    <input
      #searchBox
      matInput
      autocomplete="off"
      [(ngModel)]="searchText"
      [placeholder]="'key_searchAllResources' | translate"
      (keyup)="onKeyUp($event)"
      (click)="onFocuse()"
    />
    <span matPrefix>
      <mat-icon class="search-icon accent-text-color">search</mat-icon>
    </span>
    <span matSuffix>
      <button
        mat-icon-button
        *ngIf="!autoComplete"
        [disabled]="!canSearch"
        [matTooltip]="'key_search' | translate"
        (click)="onSearch()"
        class="search-button-ie"
        style="margin-right: 5px"
      >
        <mat-icon
          class="accent-text-color"
          style="font-size: 22px"
          [ngClass]="{ 'disabled-button': !canSearch }"
          >input</mat-icon
        >
      </button>
    </span>
  </mat-form-field>
</div>
<kendo-popup
  #popup
  *ngIf="showPopup"
  [anchor]="{ nativeElement: anchor }"
  [animate]="false"
  class="popup-wrapper"
>
  <div fxLayout="column" [style.width.px]="popupWidth">
    <!-- history mode -->
    <div
      *ngIf="mode === 'history'"
      fxFlex="auto"
      fxLayout="column"
      style="margin: 0 10px"
    >
      <!-- search history -->
      <div
        fxFlex="auto"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <div fxFlex="none" class="result-title accent-text-color">
          {{ 'key_searchHistory' | translate }}
        </div>
        <mat-divider fxFlex="auto"></mat-divider>
      </div>
      <div *ngFor="let history of historyItems">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="result-item"
          [ngClass]="{ 'selected-result': history.selected }"
          (click)="onHistoryClicked(history.text)"
        >
          <mat-icon
            fxFlex="none"
            class="accent-text-color"
            style="margin-right: 10px"
            >history</mat-icon
          >
          <div fxFlex="auto" class="primary-text-color">
            {{ history.text }}
          </div>
        </div>
      </div>
      <div
        fxFlex="auto"
        class="no-results accent-text-color"
        *ngIf="!historyItems || historyItems.length === 0"
      >
        {{ 'key_noResults' | translate }}
      </div>
      <!-- search recent -->
      <div
        fxFlex="auto"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
      >
        <div fxFlex="none" class="result-title accent-text-color">
          {{ 'key_recent' | translate }}
        </div>
        <mat-divider fxFlex="auto"></mat-divider>
      </div>
      <div *ngFor="let recent of recentItems">
        <div
          fxLayout="row"
          fxLayoutAlign="start center"
          class="result-item"
          [ngClass]="{ 'selected-result': recent.selected }"
          (click)="onRecentClicked(recent)"
        >
          <mat-icon
            fxFlex="none"
            class="accent-text-color"
            style="margin-right: 10px"
            >{{ recent.icon }}</mat-icon
          >
          <div fxFlex="auto" class="primary-text-color">
            {{ recent.resource | extraValue: 'DisplayName' }}
          </div>
        </div>
      </div>
      <div
        fxFlex="auto"
        class="no-results accent-text-color"
        *ngIf="!recentItems || recentItems.length === 0"
      >
        {{ 'key_noResults' | translate }}
      </div>
    </div>
    <!-- search mode -->
    <div
      *ngIf="mode === 'search'"
      fxFlex="auto"
      fxLayout="column"
      style="margin: 0 10px"
    >
      <!-- search results -->
      <div fxFlex="auto">
        <ngx-masonry
          [options]="masonryOptions"
          [updateLayout]="updateMasonryLayout"
        >
          <div
            ngxMasonryItem
            class="masonry-item"
            *ngFor="let item of searchDefs"
          >
            <div
              fxFlex="auto"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px"
            >
              <div fxFlex="none" class="result-title accent-text-color">
                {{ item.text | translate }}
              </div>
              <mat-divider fxFlex="auto"></mat-divider>
              <a
                class="see-all-link primary-color"
                (click)="onSeeAll(item)"
                *ngIf="
                  (item.includeCount === undefined ||
                    item.includeCount === true) &&
                  item.results &&
                  item.results.hasMoreItems
                "
                >{{ 'key_seeMore' | translate }} ({{
                  item.results.totalCount
                }})</a
              >
              <a
                class="see-all-link primary-color"
                (click)="onSeeAll(item)"
                *ngIf="
                  item.includeCount === false &&
                  item.results &&
                  item.results.results &&
                  item.results.results.length > 0
                "
                >{{ 'key_seeMore' | translate }}</a
              >
            </div>
            <div
              fxFlex="auto"
              *ngIf="
                !item.isLoading &&
                item.results &&
                item.results.results &&
                item.results.results.length > 0
              "
            >
              <div *ngFor="let result of item.results.results">
                <div
                  fxLayout="row"
                  fxLayoutAlign="start center"
                  class="result-item"
                  [ngClass]="{ 'selected-result': result.selected }"
                  [matTooltip]="
                    item.tooltipAttribute
                      ? (result | extraValue: item.tooltipAttribute)
                      : undefined
                  "
                  (click)="onItemClicked(result, item)"
                >
                  <mat-icon
                    fxFlex="none"
                    class="accent-text-color"
                    style="margin-right: 10px"
                    >{{ item.icon }}</mat-icon
                  >
                  <div
                    *ngIf="
                      item.displayedAttributesInSearch;
                      else simpleAttribute
                    "
                    fxFlex="auto"
                    class="primary-text-color"
                  >
                    <div
                      fxFlex="auto"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                      class="primary-text-color"
                      style="margin-right: 20px"
                    >
                      <div [fxFlex]="getUnitWidth(item, true)">
                        {{
                          result
                            | extraValue: item.displayedAttributesInSearch.main
                        }}
                      </div>
                      <div
                        *ngFor="
                          let attributeToShow of item
                            .displayedAttributesInSearch.secondary
                        "
                        [fxFlex]="getUnitWidth(item)"
                      >
                        <div
                          *ngIf="result | examValue: attributeToShow"
                          fxLayout="column"
                          style="margin-left: 20px"
                        >
                          <div
                            style="font-size: 0.7em"
                            class="accent-text-color"
                          >
                            {{
                              attributeToShow
                                | translateAttribute: item.type:0
                                | async
                            }}
                          </div>
                          <div style="margin-top: -8px">
                            {{ result | extraValue: attributeToShow }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #simpleAttribute>
                    <div fxFlex="auto" class="primary-text-color">
                      {{ result | extraValue: 'DisplayName' }}
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div
              fxFlex="auto"
              class="no-results accent-text-color"
              *ngIf="
                !item.isLoading &&
                (!item.results ||
                  !item.results.results ||
                  item.results.results.length === 0)
              "
            >
              {{ 'key_noResults' | translate }}
            </div>
            <div
              fxFlex="auto"
              fxLayout="row"
              fxLayoutAlign="center center"
              *ngIf="item.isLoading"
              class="loading-results"
            >
              <mat-spinner [diameter]="30"></mat-spinner>
            </div>
          </div>
        </ngx-masonry>
      </div>
    </div>
    <!-- advanced search tip -->
    <mat-divider
      *ngIf="advancedSearchTip"
      fxFlex="auto"
      style="margin-top: 10px"
    ></mat-divider>
    <div
      *ngIf="advancedSearchTip"
      fxFlex="auto"
      class="primary-text-color"
      style="margin-top: 5px; font-size: 12px; margin-left: 10px"
    >
      {{ 'key_advancedSearchTip' | translate
      }}<a
        class="advanced-search-link primary-color"
        (click)="onAdvancedSearch()"
        >{{ 'key_advancedSearch' | translate }}</a
      >
    </div>
  </div>
</kendo-popup>
