import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FilterService,
  PopupCloseEvent,
  SinglePopupService,
} from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Subscription } from 'rxjs';
import { TransService } from '../../models/translation.model';
import * as moment from 'moment';

const closest = (node: any, predicate: any): any => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
})
export class GridFilterComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  public currentFilter: CompositeFilterDescriptor;

  @Input()
  public filterService: FilterService;

  @Input()
  public field: string;

  @Input()
  public filterType: string;

  @Input()
  public dateFormat: string;

  public filterText = '';
  public filterTextDisabled = false;
  public filterOperator: { text: string; value: string };
  public operators: Array<{ text: string; value: string }> = [];

  public filterBoolean: boolean;
  public filterDateBefore: Date;
  public filterDateAfter: Date;

  public popupSettings: any = {
    popupClass: 'date-range-filter',
  };

  get format() {
    if (this.dateFormat) {
      return this.dateFormat;
    } else {
      const dFormat = this.translate.instant('key_dateFormat');
      const tFormat = this.translate.instant('key_timeFormat');

      return `${dFormat} ${tFormat}`;
    }
  }

  private buildFilter() {
    let value: any;
    let operator: string;
    switch (this.filterType) {
      case 'text':
      case 'numeric':
        value = this.filterText;
        operator = this.filterOperator.value;
        break;
      case 'boolean':
        value = this.filterBoolean;
        operator = 'eq';
        break;
      default:
        break;
    }

    if (this.filterType === 'date') {
      const strBefore = this.filterDateBefore
        ? moment(this.filterDateBefore).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')
        : '';
      const strAfter = this.filterDateAfter
        ? moment(this.filterDateAfter).utc().format('YYYY-MM-DDTHH:mm:ss.SSS')
        : '';
      const filters = [];
      if (strBefore) {
        filters.push({
          field: this.field,
          operator: 'lt',
          value: strBefore,
        });
      }
      if (strAfter) {
        filters.push({
          field: this.field,
          operator: 'gt',
          value: strAfter,
        });
      }
      this.filterService.filter({
        filters,
        logic: 'and',
      });
    } else {
      this.filterService.filter({
        filters: [
          {
            field: this.field,
            operator,
            value,
          },
        ],
        logic: 'and',
      });
    }
  }

  constructor(
    private translate: TransService,
    private element: ElementRef,
    private popupService: SinglePopupService
  ) {
    this.subscription.add(
      this.popupService.onClose.subscribe((e: PopupCloseEvent) => {
        if (
          document.activeElement &&
          closest(
            document.activeElement,
            (node) =>
              node === this.element.nativeElement ||
              String(node.className).indexOf('date-range-filter') >= 0
          )
        ) {
          e.preventDefault();
        }
      })
    );
  }

  ngOnInit(): void {
    switch (this.filterType) {
      case 'text':
        this.filterOperator = {
          text: this.translate.instant('key_startsWith'),
          value: 'startswith',
        };
        this.operators = [
          {
            text: this.translate.instant('key_equal'),
            value: 'eq',
          },
          {
            text: this.translate.instant('key_notEqual'),
            value: 'neq',
          },
          {
            text: this.translate.instant('key_startsWith'),
            value: 'startswith',
          },
          {
            text: this.translate.instant('key_endsWith'),
            value: 'endswith',
          },
          {
            text: this.translate.instant('key_isEmpty'),
            value: 'isempty',
          },
          {
            text: this.translate.instant('key_isNotEmpty'),
            value: 'isnotempty',
          },
        ];
        break;
      case 'numeric':
      case 'date':
        this.operators = [
          {
            text: this.translate.instant('key_equal'),
            value: 'eq',
          },
          {
            text: this.translate.instant('key_notEqual'),
            value: 'neq',
          },
          {
            text: this.translate.instant('key_greaterThan'),
            value: 'gt',
          },
          {
            text: this.translate.instant('key_lessThan'),
            value: 'lt',
          },
        ];
        this.filterOperator = {
          text: this.translate.instant('key_equal'),
          value: 'eq',
        };
        break;
      default:
        break;
    }

    if (
      this.currentFilter &&
      this.currentFilter.filters &&
      this.currentFilter.filters.length > 0
    ) {
      if (this.filterType === 'date') {
        this.currentFilter.filters.forEach((f: any) => {
          if (f.value) {
            switch (f.operator) {
              case 'lt':
                this.filterDateBefore = moment.utc(f.value).local().toDate();
                break;
              case 'gt':
                this.filterDateAfter = moment.utc(f.value).local().toDate();
                break;
              default:
                break;
            }
          }
        });
      } else {
        this.filterText = this.currentFilter.filters[0]['value'];
        const operator = this.currentFilter.filters[0]['operator'];
        if (operator) {
          const pos = this.operators.findIndex((o) => o.value === operator);
          if (pos >= 0) {
            this.filterOperator = this.operators[pos];
            this.filterTextDisabled =
              this.filterOperator.value &&
              this.filterOperator.value.endsWith('empty');
          }
        }
        if (this.filterType === 'boolean' && this.filterText !== undefined) {
          this.filterBoolean = Boolean(this.filterText);
        }
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onOperatorChange() {
    this.filterText = '';

    this.filterTextDisabled =
      this.filterOperator &&
      this.filterOperator.value &&
      this.filterOperator.value.endsWith('empty');

    this.buildFilter();
  }

  onInput() {
    this.buildFilter();
  }

  onBooleanChange() {
    this.buildFilter();
  }

  onDateChange() {
    this.buildFilter();
  }
}
