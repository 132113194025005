<div
  *ngIf="bulkImportAllowed"
  fxLayout="row wrap"
  fxLayoutAlign="start start"
  class="ie-absolut"
  style="margin-bottom: 20px"
>
  <div
    fxFlex="100%"
    fxLayout="row"
    class="setting-header"
    style="cursor: pointer"
    (click)="onExpandBulkImport()"
  >
    <div>
      {{ 'key_bulkImport' | translate }}
    </div>
    <span class="material-icons-outlined" style="margin-left: 10px">{{
      bulkImportDisplayed ? 'expand_less' : 'expand_more'
    }}</span>
  </div>
  <div *ngFor="let importDef of bulkImportDef.items; index as i">
    <div *ngIf="bulkImportDisplayed && importItemAllowed(importDef.permission)">
      <input
        accept=".csv, text/plain"
        [id]="'upload' + i"
        style="display: none"
        type="file"
        (change)="onBulkImport($event, importDef)"
      />
      <label
        class="mat-stroked-button"
        style="margin: 0 10px 10px 10px; background-color: white"
        [style.width.px]="importDef.width ?? null"
        [for]="'upload' + i"
      >
        {{ importDef.name | translate }}
      </label>
    </div>
  </div>
</div>

<div *ngFor="let key of resourceKeys; let isFirst = first">
  <div
    *ngIf="allResources[key].enabled && permissionAllowed(allResources[key])"
    fxLayout="row wrap"
    fxLayoutAlign="start start"
    class="ie-absolut"
  >
    <div
      fxFlex="100%"
      class="setting-header"
      [ngClass]="{ 'additional-top': !isFirst }"
    >
      {{ allResources[key].name | translate }}
    </div>
    <div *ngFor="let action of allResources[key].items" fxFlex="none">
      <app-action-card
        *ngIf="action.enabled"
        [config]="action"
        (primaryAction)="onPrimaryAction(action.primaryAction)"
        (secondaryAction)="onSecondaryAction(action.secondaryAction)"
      ></app-action-card>
    </div>
  </div>
</div>
