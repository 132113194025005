<div
  *ngIf="isCompacted"
  class="label-compacted"
  [matTooltip]="'key_compactedViewDesc' | translate"
>
  <div>
    {{ 'key_compactedView' | translate }}
  </div>
  <div class="accent-bg-color" style="height: 3px; width: 100%"></div>
</div>
<button
  *ngIf="enableContextView"
  mat-stroked-button
  class="context-button"
  (click)="onShowFullContext()"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="auto"></div>
    <mat-icon style="margin-right: 10px">
      {{ graphType === 'simple' ? 'device_hub' : 'show_chart' }}
    </mat-icon>
    <div fxFlex="auto">
      {{
        (graphType === 'simple' ? 'key_contextView' : 'key_simpleView')
          | translate
      }}
    </div>
    <div fxFlex="auto"></div>
  </div>
</button>
<button mat-stroked-button class="center-button" (click)="onCenterGraph()">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div fxFlex="auto"></div>
    <mat-icon style="margin-right: 10px">adjust</mat-icon>
    <div fxFlex="auto">{{ 'key_centerGraph' | translate }}</div>
    <div fxFlex="auto"></div>
  </div>
</button>
<div fxLayout="column">
  <div fxFlex="none" class="diagram-area" [style.height.px]="graphHeight">
    <ngx-graph
      [layoutSettings]="{
        rankPadding: 240
      }"
      [draggingEnabled]="false"
      [nodes]="nodes"
      [links]="links"
      [update$]="update"
      [center$]="center"
      [zoomToFit$]="zoomToFit"
    >
      <ng-template #defsTemplate>
        <svg:marker
          id="arrow"
          viewBox="0 -5 10 10"
          refX="8"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
        </svg:marker>
      </ng-template>

      <ng-template #nodeTemplate let-node>
        <svg:g
          class="node"
          [matTooltip]="node.label"
          (mouseenter)="onEnterNode(node)"
          (mouseleave)="onleaveNode(node)"
          (click)="onNodeClick(node)"
        >
          <svg:circle
            [attr.cx]="node.dimension.width / 2"
            [attr.cy]="node.dimension.height / 2"
            [attr.r]="node.dimension.width / 2"
            [attr.stroke]="node.meta.origin ? 'slategray' : 'lightgray'"
            [attr.stroke-width]="node.meta.origin ? '4px' : '2px'"
            [attr.fill]="node.meta.selected ? 'bisque' : node.meta.bgColor"
          ></svg:circle>
          <svg
            [attr.width]="node.dimension.width"
            [attr.height]="node.dimension.height"
          >
            <image
              x="8"
              y="8"
              [attr.width]="node.dimension.width - 16"
              [attr.height]="node.dimension.height - 16"
              [attr.href]="node.meta.iconLink"
            ></image>
          </svg>
        </svg:g>
        <svg:g *ngIf="node.data.showPlaceholder">
          <svg:circle
            [attr.cx]="nodeSize + 5"
            [attr.cy]="nodeSize - nodeSize / 2"
            [attr.r]="nodeSize / 2 - 10"
            [attr.stroke]="'lightgray'"
            [attr.stroke-width]="'2px'"
            [attr.fill]="node.meta.selected ? 'bisque' : node.meta.bgColor"
          ></svg:circle>
          <svg>
            <image
              [attr.x]="nodeSize / 2 + 18"
              y="14"
              [attr.width]="nodeSize - 28"
              [attr.height]="nodeSize - 28"
              [attr.href]="workflowIconLink"
            ></image>
          </svg>
        </svg:g>
        <svg:g>
          <svg:circle
            *ngIf="node.meta.status === 'success'"
            [attr.cx]="nodeSize"
            [attr.cy]="nodeSize"
            r="5"
            stroke="darkgreen"
            stroke-width="2px"
            fill="seagreen"
          ></svg:circle>
          <svg:circle
            *ngIf="node.meta.status === 'error'"
            [attr.cx]="nodeSize"
            [attr.cy]="nodeSize"
            r="5"
            stroke="darkred"
            stroke-width="2px"
            fill="indianred"
          ></svg:circle>
          <svg:circle
            *ngIf="node.meta.status === 'warn'"
            [attr.cx]="nodeSize"
            [attr.cy]="nodeSize"
            r="5"
            stroke="darkgoldenrod"
            stroke-width="2px"
            fill="goldenrod"
          ></svg:circle>
        </svg:g>
      </ng-template>

      <ng-template #linkTemplate let-link>
        <svg:g class="edge">
          <svg:path
            class="line"
            stroke-width="2"
            marker-end="url(#arrow)"
          ></svg:path>
          <svg:text class="edge-label" text-anchor="middle" x="5">
            <textPath
              class="text-path"
              [attr.href]="'#' + link.id"
              [style.dominant-baseline]="link.dominantBaseline"
              startOffset="50%"
            >
              {{ link.label }}
            </textPath>
          </svg:text>
        </svg:g>
      </ng-template>
    </ngx-graph>
  </div>
  <div
    *ngIf="selectedNode"
    fxFlex="auto"
    fxLayout="column"
    class="event-detail"
  >
    <div fxLayout="row" style="margin-bottom: 10px">
      <div fxFlex="none" class="attribute-label">
        {{ 'key_eventName' | translate }}:
      </div>
      <div fxFlex="auto">
        <app-identity-link
          [identity]="{
            ObjectType: 'event',
            ObjectID: selectedNode.id,
            DisplayName: selectedNode.label
          }"
          [defaultAction]="defaultLinkAction"
          [linkActions]="['sideView', 'navigate']"
          [noneForm]="true"
          [readOnly]="readOnlyLink"
        ></app-identity-link>
      </div>
    </div>
    <div fxLayout="row" style="margin-bottom: 10px">
      <div fxFlex="none" class="attribute-label">
        {{ 'key_eventType' | translate }}:
      </div>
      <div fxFlex="50%">{{ selectedNode.data.eventtype }}</div>
      <div fxFlex="none" class="space-between"></div>
      <div fxFlex="none" class="attribute-label">
        {{ 'key_eventStatus' | translate }}:
      </div>
      <div fxFlex="50%">{{ selectedNode.data.status }}</div>
    </div>
    <div fxLayout="row" style="margin-bottom: 10px">
      <div fxFlex="none" class="attribute-label">
        {{ 'key_createdTime' | translate }}:
      </div>
      <div fxFlex="50%">{{ formatDate(selectedNode.data.createdtime) }}</div>
      <div fxFlex="none" class="space-between"></div>
      <div fxFlex="none" class="attribute-label">
        {{ 'key_startTime' | translate }}:
      </div>
      <div fxFlex="50%">{{ formatDate(selectedNode.data.starttime) }}</div>
    </div>
    <div fxLayout="row" style="margin-bottom: 10px">
      <div fxFlex="none" class="attribute-label">
        {{ 'key_committedTime' | translate }}:
      </div>
      <div fxFlex="50%">{{ formatDate(selectedNode.data.committedtime) }}</div>
      <div fxFlex="none" class="space-between"></div>
      <div fxFlex="none" class="attribute-label">
        {{ 'key_completedTime' | translate }}:
      </div>
      <div fxFlex="50%">{{ formatDate(selectedNode.data.completedtime) }}</div>
    </div>
    <div
      *ngIf="
        selectedNode.data.errormessage || selectedNode.data.detailedmessages
      "
      fxLayout="row"
    >
      <div
        *ngIf="selectedNode.data.errormessage"
        fxFlex="none"
        class="attribute-label"
      >
        {{ 'key_errorMessage' | translate }}:
      </div>
      <div *ngIf="selectedNode.data.errormessage" fxFlex="50%">
        <div
          style="cursor: pointer"
          (click)="
            onShowMessageInPopup(
              [selectedNode.data.errormessage],
              'key_error',
              true
            )
          "
        >
          <span class="material-icons-outlined">more_horiz</span>
        </div>
      </div>
      <div
        *ngIf="selectedNode.data.errormessage"
        fxFlex="none"
        class="space-between"
      ></div>
      <div
        *ngIf="selectedNode.data.detailedmessages"
        fxFlex="none"
        class="attribute-label"
      >
        {{ 'key_debugInfo' | translate }}:
      </div>
      <div *ngIf="selectedNode.data.detailedmessages" fxFlex="50%">
        <div
          style="cursor: pointer"
          (click)="
            onShowMessageInPopup(
              selectedNode.data.detailedmessages,
              'key_debugInfo'
            )
          "
        >
          <span class="material-icons-outlined">more_horiz</span>
        </div>
      </div>
    </div>
    <div
      fxLayout="row"
      *ngIf="selectedNode.data.requestorid || selectedNode.data.targetid"
      style="margin-bottom: 10px"
    >
      <div
        *ngIf="selectedNode.data.requestorid"
        fxFlex="none"
        class="attribute-label"
      >
        {{ 'key_requestor' | translate }}:
      </div>
      <div *ngIf="selectedNode.data.requestorid" fxFlex="50%">
        <app-identity-link
          [identity]="{
            ObjectType: 'person',
            ObjectID: selectedNode.data.requestorid,
            DisplayName: selectedNode.data.requestordisplayname
          }"
          [defaultAction]="defaultLinkAction"
          [linkActions]="['sideView', 'navigate']"
          [readOnly]="readOnlyLink"
        ></app-identity-link>
      </div>
      <div
        *ngIf="selectedNode.data.requestorid"
        fxFlex="none"
        class="space-between"
      ></div>
      <div
        *ngIf="selectedNode.data.targetid"
        fxFlex="none"
        class="attribute-label"
      >
        {{ 'key_target' | translate }}:
      </div>
      <div *ngIf="selectedNode.data.targetid" fxFlex="50%" fxLayout="row">
        <app-identity-link
          [identity]="{
            ObjectID: selectedNode.data.targetid
          }"
          [defaultAction]="defaultLinkAction"
          [linkActions]="['sideView', 'navigate']"
          [readOnly]="readOnlyLink"
        ></app-identity-link>
        <span
          class="material-icons-outlined accent-text-color"
          style="margin-left: 15px; cursor: pointer"
          [matTooltip]="'l10n_viewObjectHistory' | translate"
          (click)="onViewObjectHistory()"
          >history</span
        >
      </div>
    </div>
    <div class="attribute-label" style="margin-bottom: 10px">
      {{ 'key_details' | translate }}:
    </div>
    <div
      *ngIf="selectedNode.data.eventtype === 'WorkflowActivity'"
      fxLayout="column"
      fxLayoutGap="5px"
    >
      <div *ngIf="selectedNode.data.workflowid" fxLayout="row">
        <div fxFlex="none" class="normal-label">
          {{ 'key_workflow' | translate }}:
        </div>
        <div fxFlex="auto">
          <div
            *ngIf="
              selectedNode.data.workflowid ===
                '006069fd-d7db-4e56-88fd-b6cbba8a0b59';
              else workflowIdentity
            "
          >
            Dataflow rule
          </div>
          <ng-template #workflowIdentity>
            <app-identity-link
              [identity]="{
                ObjectID: selectedNode.data.workflowid
              }"
              [defaultAction]="defaultLinkAction"
              [linkActions]="['sideView', 'navigate']"
              [readOnly]="readOnlyLink"
            ></app-identity-link>
          </ng-template>
        </div>
      </div>
      <div fxLayout="row" *ngIf="selectedNode.data.triggerid">
        <div fxFlex="none" class="normal-label">
          {{ 'key_trigger' | translate }}:
        </div>
        <div
          *ngIf="
            selectedNode.data.triggerid.startsWith('00000000');
            else triggered
          "
          fxFlex="auto"
        >
          Manually triggered
        </div>
        <ng-template #triggered>
          <app-identity-link
            [identity]="{
              ObjectID: selectedNode.data.triggerid
            }"
            [defaultAction]="defaultLinkAction"
            [linkActions]="['sideView', 'navigate']"
            [readOnly]="readOnlyLink"
          ></app-identity-link>
        </ng-template>
      </div>
    </div>
    <div
      *ngIf="
        selectedNode.data.eventtype === 'Resource' &&
        selectedNode.data.attributeChanges &&
        selectedNode.data.attributeChanges.length > 0
      "
    >
      <app-resource-table
        #resourceTable
        [config]="tableConfig"
      ></app-resource-table>
    </div>
    <div
      *ngIf="
        selectedNode.data.eventtype === 'Resource' &&
        (selectedNode.data.attributes ||
          selectedNode.data.changedattributes ||
          selectedNode.data.multivalueChanges)
      "
      class="attribute-detail"
    >
      <div
        *ngIf="selectedNode.data.attributes"
        fxLayout="column"
        fxLayoutGap="5px"
      >
        <div *ngFor="let attribute of selectedNode.data.attributes">
          <div
            *ngIf="attribute.name && !attribute.name.startsWith('$')"
            fxLayout="row"
          >
            <div
              fxFlex="none"
              class="normal-label"
              [matTooltip]="attribute.name"
            >
              {{ attribute.displayName }}:
            </div>
            <div fxFlex="auto">
              <div
                *ngIf="
                  isGuid(attribute.name, attribute.after);
                  else fullNonGuid
                "
              >
                <app-identity-link
                  [identity]="{ ObjectID: attribute.after }"
                  [defaultAction]="defaultLinkAction"
                  [linkActions]="['sideView', 'navigate']"
                  [readOnly]="readOnlyLink"
                >
                </app-identity-link>
              </div>
              <ng-template #fullNonGuid>
                {{ formatDate(attribute.after) }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedNode.data.changedattributes"
        fxLayout="column"
        fxLayoutGap="5px"
      >
        <div *ngFor="let attribute of selectedNode.data.changedattributes">
          <div
            *ngIf="attribute.name && !attribute.name.startsWith('$')"
            fxLayout="row"
          >
            <div
              fxFlex="none"
              class="normal-label"
              [matTooltip]="attribute.name"
            >
              {{ attribute.displayName }}:
            </div>
            <div fxFlex="none" class="value-label">
              <div
                *ngIf="
                  isGuid(attribute.name, attribute.before);
                  else deltaNonBeforeGuid
                "
              >
                <app-identity-link
                  [identity]="{ ObjectID: attribute.before }"
                  [defaultAction]="defaultLinkAction"
                  [linkActions]="['sideView', 'navigate']"
                  [readOnly]="readOnlyLink"
                >
                </app-identity-link>
              </div>
              <ng-template #deltaNonBeforeGuid>
                {{ formatDate(attribute.before) }}
              </ng-template>
            </div>
            <div fxFlex="none" class="space-between"></div>
            <div fxFlex="none">
              <mat-icon>arrow_forward</mat-icon>
            </div>
            <div fxFlex="none" class="space-between"></div>
            <div fxFlex="none" class="value-label">
              <div
                *ngIf="
                  isGuid(attribute.name, attribute.after);
                  else deltaNonAfterGuid
                "
              >
                <app-identity-link
                  [identity]="{ ObjectID: attribute.after }"
                  [linkActions]="['sideView', 'navigate']"
                  [readOnly]="readOnlyLink"
                >
                </app-identity-link>
              </div>
              <ng-template #deltaNonAfterGuid>
                {{ formatDate(attribute.after) }}
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="selectedNode.data.multivalueChanges"
        fxLayout="column"
        fxLayoutGap="5px"
      >
        <div *ngFor="let change of selectedNode.data.multivalueChanges">
          <div
            *ngIf="change.name && !change.name.startsWith('$')"
            fxLayout="row"
          >
            <div fxFlex="none" class="normal-label" [matTooltip]="change.name">
              {{ change.displayName }}:
            </div>
            <div fxFlex="none" class="value-label"></div>
            <div fxFlex="none" class="space-between"></div>
            <div fxFlex="auto" fxLayout="column">
              <div *ngFor="let changedValue of change.changes" fxLayout="row">
                <div fxFlex="none">
                  <mat-icon>{{ changedValue.type }}</mat-icon>
                </div>
                <div fxFlex="none" class="space-between"></div>
                <div fxFlex="none" class="value-label">
                  <div
                    *ngIf="
                      isGuid(change.name, changedValue.value);
                      else deltaNonMultivalueGuid
                    "
                  >
                    <app-identity-link
                      [identity]="{ ObjectID: changedValue.value }"
                      [defaultAction]="defaultLinkAction"
                      [linkActions]="['sideView', 'navigate']"
                      [readOnly]="readOnlyLink"
                    >
                    </app-identity-link>
                  </div>
                  <ng-template #deltaNonMultivalueGuid>
                    {{ formatDate(changedValue.value) }}
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        selectedNode.data.approvalprocessids &&
        selectedNode.data.approvalprocessids.length > 0
      "
    >
      <div *ngFor="let approvalProcess of obsApprovalProcesses | async">
        <div style="font-size: 0.85em; margin-bottom: 20px">
          <app-approval-info
            [request]="approvalProcess"
            style="width: 100%"
          ></app-approval-info>
        </div>
      </div>
    </div>
  </div>
</div>
