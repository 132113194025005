<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="10px"
  fxLayoutAlign="center start"
  fxLayoutAlign.lt-md="start stretch"
>
  <div fxFlex="50">
    <div fxLayout="column">
      <button
        mat-stroked-button
        color="primary"
        style="width: 200px; margin-top: -5px; margin-bottom: 10px"
        (click)="onAddDelegator()"
      >
        {{ 'key_addDelegator' | translate }}
      </button>
      <div
        *ngIf="delegators.length > 0; else noDelegators"
        style="height: 500px; border: 1px solid lightgray"
      >
        <div *ngFor="let delegator of delegators">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            style="margin: 5px 10px; padding: 5px; background: #fafafa"
          >
            <div fxFlex="auto" fxLayout="column">
              <app-identity-link
                *ngFor="let target of delegator.targetrefs"
                [identity]="target"
                style="font-size: 15px"
              ></app-identity-link>
              <div fxLayout="row" style="height: 22px; color: #666666">
                <div [matTooltip]="'key_validFrom' | translate">
                  <mat-icon style="font-size: 14px; margin-top: 5px"
                    >hourglass_top</mat-icon
                  >
                </div>
                <div
                  *ngIf="delegator | examValue: 'ValidFrom'; else noValidFrom"
                  [matTooltip]="'key_validFrom' | translate"
                  style="font-size: 12px; margin-left: -5px"
                >
                  {{ delegator | extraValue: 'ValidFrom' }}
                </div>
                <ng-template #noValidFrom>
                  <div [matTooltip]="'key_validFrom' | translate">--</div>
                </ng-template>
                <div
                  [matTooltip]="'key_validTo' | translate"
                  style="margin-left: 20px"
                >
                  <mat-icon style="font-size: 14px; margin-top: 5px"
                    >hourglass_bottom</mat-icon
                  >
                </div>
                <div
                  *ngIf="delegator | examValue: 'ValidTo'; else noValidTo"
                  [matTooltip]="'key_validTo' | translate"
                  style="font-size: 12px"
                >
                  {{ delegator | extraValue: 'ValidTo' }}
                </div>
                <ng-template #noValidTo>
                  <div [matTooltip]="'key_validTo' | translate">--</div>
                </ng-template>
              </div>
            </div>
            <mat-slide-toggle
              fxFlex="none"
              [(ngModel)]="delegator.isenabled"
              style="font-size: 12px; margin-right: 15px"
              (change)="onToggleAssignment(delegator)"
              >{{ 'key_enabled' | translate }}</mat-slide-toggle
            >
            <mat-icon
              fxFlex="none"
              [matTooltip]="'key_delete' | translate"
              class="warn-color"
              style="cursor: pointer"
              (click)="onDeleteAssignment(delegator)"
              >delete_outline</mat-icon
            >
          </div>
        </div>
      </div>
      <ng-template #noDelegators>
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          style="height: 500px; border: 1px solid lightgray"
        >
          <mat-icon
            fxFlex="none"
            style="font-size: 100px; width: 100px; color: #cccccc"
            >widgets</mat-icon
          >
          <div
            fxFlex="none"
            style="
              margin-top: 50px;
              padding-top: 50px;
              font-size: 18px;
              color: #b0b0b0;
            "
          >
            {{ 'key_noAssignments' | translate }}
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div fxFlex="50" fxLayout="column">
    <div style="font-weight: 400; margin-bottom: 17px">
      {{ 'key_delegateFor' | translate }}
    </div>
    <app-resource-table [config]="tableConfig"></app-resource-table>
  </div>
</div>
