<div
  class="sidebar-filter"
  fxLayout="column"
  fxLayoutAlign="start center"
  [style.width.px]="currentSize"
  [style.backgroundColor]="sidebarSettings.backColor"
>
  <div class="logo" fxFlex="none">
    <a (click)="resize()">
      <img
        class="fullImage"
        [style.height.px]="expandedImageWidth"
        *ngIf="mode === 'expanded'"
        [src]="expandedImageSource"
      />
      <img
        class="iconImage"
        [style.height.px]="collapsedImageWidth"
        *ngIf="mode === 'collapsed'"
        [src]="collapsedImageSource"
      />
    </a>
  </div>
  <div class="sidebar-seperator" fxFlex="none"></div>
  <div
    class="sidebar-nav"
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="end center"
    (click)="resize()"
  >
    <mat-icon
      class="nav-icon"
      style="margin-right: -18px"
      [style.color]="sidebarSettings.iconColor"
      >{{ icon }}</mat-icon
    >
    <mat-icon
      class="nav-icon"
      style="margin-right: -5px"
      [style.color]="sidebarSettings.iconColor"
      >{{ icon }}</mat-icon
    >
  </div>
  <div class="sidebar-item" fxFlex="none" *ngFor="let item of sidebarItems">
    <div
      *ngIf="item.enabled"
      [ngClass]="{ 'sidebar-item-focus': isFocusedItem(item) }"
      (click)="onAction(item)"
    >
      <a
        [routerLink]=""
        fxLayout="row"
        [matTooltip]="mode === 'expanded' ? null : (item.title | translate)"
      >
        <i
          class="material-icons"
          fxFlex="none"
          matBadgeColor="warn"
          [matBadgeHidden]="!item.showBadge"
          [matBadge]="item.badge"
          [matBadgeSize]="item.badgeSize"
          [ngClass]="{ 'hide-badge-text': item.badgeSize === 'small' }"
          [style.color]="sidebarSettings.iconColor"
          >{{ item.icon }}</i
        >
        <div
          fxFlex="none"
          *ngIf="mode === 'expanded'"
          [style.color]="sidebarSettings.frontColor"
        >
          {{ item.title | translate }}
        </div>
      </a>
    </div>
  </div>
  <div fxFlex="auto"></div>
</div>
