import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { ResourceService } from '../core/services/resource.service';
import { XpathBuilderComponent } from '../core/components/xpath-builder/xpath-builder.component';
import { Subscription, EMPTY, of } from 'rxjs';
import {
  ResourceTableConfig,
  ModalType,
} from '../core/models/componentContract.model';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import {
  AttributeResource,
  AuthMode,
  MenuEvent,
  PopupConfig,
  XPathQuery,
} from '../core/models/dataContract.model';
import { AttributePickerComponent } from '../core/components/attribute-picker/attribute-picker.component';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';
import { switchMap, tap, finalize, catchError } from 'rxjs/operators';
import { ModalService } from '../core/services/modal.service';
import { UtilsService } from '../core/services/utils.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';
import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';
import { SwapService } from '../core/services/swap.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  @ViewChild('xpathBuilder') xBuilder: XpathBuilderComponent;

  @ViewChild('actionMenu')
  actionMenu: ActionMenuComponent;

  customViewSetting: any;
  errorMsg = '';

  queryType = '*';
  selectedAttributes: Array<string> = ['DisplayName'];

  tableConfig: ResourceTableConfig;
  savedQueries: {
    [id: string]: {
      query: XPathQuery;
      attributes: Array<string>;
      xpath: string;
    };
  } = {};
  selectedQuery: any;

  resultantQuery: string;

  get selectedItems() {
    if (this.resourceTable) {
      return this.resourceTable.selection.length > 0
        ? this.resourceTable.selection
        : undefined;
    } else {
      return undefined;
    }
  }

  private getObjectTypeFromQuery(query: string) {
    const endPos = query.indexOf('[');
    return query.substring(1, endPos > 0 ? endPos : query.length);
  }

  constructor(
    private resource: ResourceService,
    private modal: ModalService,
    private utils: UtilsService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.customViewSetting = this.resource.customViewSetting;
    if (this.customViewSetting.hasOwnProperty('savedQueries')) {
      this.savedQueries = this.customViewSetting.savedQueries;
    }

    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.cellPadding = 5;
    this.tableConfig.pageSize = 20;
    this.tableConfig.selectable = true;
    this.tableConfig.checkboxSelectOnly = false;
    this.tableConfig.selectMode = 'multiple';
    this.tableConfig.selectBoxWidth = 8;
    this.tableConfig.resizable = true;
    this.tableConfig.objectType = 'Person';
    // column attribute name should be case-sensitive because of the export-to-excel
    this.tableConfig.columns = [
      {
        field:
          this.resource.authenticationMode === AuthMode.azure
            ? 'displayname'
            : 'DisplayName',
        width: null,
        filterable: false,
        filter: 'text',
        sortable: true,
        locked: false,
      },
    ];

    this.resultantQuery = undefined;
  }

  ngAfterViewInit(): void {
    // Send build menu event
    setTimeout(() => {
      this.swap.menuEvent(
        new MenuEvent('build', 'search', '', null, this.actionMenu)
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSearch(
    builder: XpathBuilderComponent,
    attributePicker: AttributePickerComponent
  ) {
    if (builder && builder.query) {
      this.errorMsg = '';
      this.subscription.add(
        builder.getXpath().subscribe(
          (result: string) => {
            if (result) {
              this.tableConfig.query = result;
              this.resultantQuery = result;

              const objectType = this.getObjectTypeFromQuery(result);
              const reloadAttributePicker = this.queryType !== objectType;
              this.queryType = objectType;
              this.tableConfig.objectType =
                objectType && objectType !== '*' ? objectType : 'Person';
              if (reloadAttributePicker && attributePicker) {
                setTimeout(() => {
                  attributePicker.refresh();
                });
              }

              if (this.resourceTable) {
                if (reloadAttributePicker) {
                  this.resourceTable.config.columns = [
                    {
                      field:
                        this.resource.authenticationMode === AuthMode.azure
                          ? 'displayname'
                          : 'DisplayName',
                      width: 0,
                      filterable: false,
                      filter: 'text',
                      sortable: true,
                      locked: false,
                    },
                  ];
                }
                this.resourceTable.updateDataSource(true);
              }
            }
          },
          (error: any) => {
            this.errorMsg = error.error;
          }
        )
      );
    }
  }

  onQueryChange() {
    this.errorMsg = '';
  }

  onAttributeChange(attributes: Array<AttributeResource>) {
    if (!this.resourceTable) {
      return;
    }

    attributes.forEach((attribute) => {
      if (
        this.tableConfig.columns.findIndex(
          (col) =>
            col.field.toLowerCase() === attribute.systemName.toLowerCase()
        ) < 0
      ) {
        this.tableConfig.columns.push({
          field: attribute.systemName,
          width: 200,
          sortable: true,
        });
      }
    });

    this.tableConfig.columns = this.tableConfig.columns.filter(
      (col) =>
        attributes.findIndex(
          (a) => a.systemName.toLowerCase() === col.field.toLowerCase()
        ) >= 0
    );

    this.tableConfig.exportAttributes = this.tableConfig.columns.map(
      (c) => c.field
    );

    this.resourceTable.updateDataSource(true, false, true);
  }

  onSaveQuery(builder: XpathBuilderComponent) {
    let xpath = '';
    let queryName = '';
    let savingProgress: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      builder
        .getXpath()
        .pipe(
          switchMap((xpathResult: string) => {
            xpath = xpathResult;

            const popupConfig: PopupConfig = new PopupConfig();
            popupConfig.width = 420;
            popupConfig.title = 'l10n_saveQuery';
            popupConfig.style = 'outline';
            popupConfig.data = {
              queryName: {
                text: 'l10n_queryName',
                value: '',
                type: 'text',
                validation: `^[a-zA-Z0-9_.-]*$`,
                required: true,
                focused: true,
              },
            };

            return this.modal.popup(popupConfig);
          }),
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              queryName = windowResult.queryName.value;
              if (this.savedQueries.hasOwnProperty(queryName)) {
                const confirm = this.modal.show(
                  ModalType.confirm,
                  'key_confirmation',
                  'l10n_queryExists'
                );
                return confirm.afterClosed();
              } else {
                return of('yes');
              }
            }

            return EMPTY;
          }),
          switchMap((confirmResult: string) => {
            if (confirmResult && confirmResult === 'yes') {
              savingProgress = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );

              const query = this.utils.DeepCopy(builder.query);
              const attributes = this.resourceTable.config.columns.map(
                (c) => c.field
              );
              if (!this.customViewSetting.hasOwnProperty('savedQueries')) {
                this.customViewSetting.savedQueries = {};
              }
              this.customViewSetting.savedQueries[queryName] = {
                query,
                attributes,
                xpath,
              };
              this.savedQueries = this.customViewSetting.savedQueries;

              const objectToSave = {
                ObjectID: this.utils.ExtraValue(
                  this.resource.loginUser,
                  'ObjectID'
                ),
              };
              objectToSave[this.utils.attConfiguration] = JSON.stringify(
                this.customViewSetting
              );

              return this.resource.updateResource(objectToSave);
            }

            return EMPTY;
          }),
          finalize(() => {
            if (savingProgress) {
              savingProgress.close();
            }

            this.selectedQuery = null;
          })
        )
        .subscribe(
          () => {},
          (error) => {
            this.errorMsg = error.error;
          }
        )
    );
  }

  onApplyXPath(
    builder: XpathBuilderComponent,
    attributePicker: AttributePickerComponent
  ) {
    if (builder && this.resultantQuery) {
      this.errorMsg = '';
      this.subscription.add(
        this.resource
          .xpathToJson(this.resultantQuery)
          .pipe(
            tap((result: any) => {
              if (this.utils.validateXPathQuery(result)) {
                const objectType = this.getObjectTypeFromQuery(
                  this.resultantQuery
                );
                const reloadAttributePicker = this.queryType !== objectType;
                this.queryType = objectType;
                this.tableConfig.objectType =
                  objectType && objectType !== '*' ? objectType : 'Person';
                if (reloadAttributePicker && attributePicker) {
                  setTimeout(() => {
                    attributePicker.refresh();
                  });
                }

                builder.query = this.utils.DeepCopy(
                  this.utils.toXPathQuery(result)
                );
                builder.ngOnInit();
              } else {
                this.errorMsg = 'key_canNotVisualizeXpath';
              }
            }),
            catchError((err: any) => {
              this.errorMsg = err.error;
              return of(EMPTY);
            }),
            finalize(() => {
              if (this.resourceTable) {
                this.resourceTable.config.columns = [
                  {
                    field:
                      this.resource.authenticationMode === AuthMode.azure
                        ? 'displayname'
                        : 'DisplayName',
                    width: 0,
                    filterable: false,
                    filter: 'text',
                    sortable: true,
                    locked: false,
                  },
                ];
                this.resourceTable.config.query = this.resultantQuery;
                this.resourceTable.updateDataSource(true);
              }
            })
          )
          .subscribe()
      );
    }
  }

  onLoadQuery(
    builder: XpathBuilderComponent,
    attributePicker: AttributePickerComponent
  ) {
    if (builder && this.selectedQuery.value && this.selectedQuery.value.query) {
      this.resultantQuery = this.utils.DeepCopy(this.selectedQuery.value.xpath);
      builder.query = this.utils.DeepCopy(this.selectedQuery.value.query);
      builder.ngOnInit();
    }

    if (
      this.resourceTable &&
      this.selectedQuery.value &&
      this.selectedQuery.value.attributes &&
      this.selectedQuery.value.xpath
    ) {
      this.resourceTable.config.columns =
        this.selectedQuery.value.attributes.map((a: string) => {
          return {
            field: a,
            width: 0,
            sortable: true,
          };
        });
      this.resourceTable.config.query = this.selectedQuery.value.xpath;
      this.tableConfig.exportAttributes = this.resourceTable.config.columns.map(
        (c) => c.field
      );
      this.resourceTable.updateDataSource(true);

      const objectType = this.getObjectTypeFromQuery(
        this.resourceTable.config.query
      );
      this.queryType = objectType;
      this.tableConfig.objectType =
        objectType && objectType !== '*' ? objectType : 'Person';
      if (attributePicker) {
        attributePicker.preSelectedItems = this.tableConfig.exportAttributes;
        setTimeout(() => {
          attributePicker.refresh();
        });
      }
    }
  }

  onDeleteQuery() {
    let savingProgress: MatDialogRef<ModalComponent, any>;

    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_deleteQuery'
    );

    confirm
      .afterClosed()
      .pipe(
        switchMap((confirmResult) => {
          if (confirmResult && confirmResult === 'yes') {
            savingProgress = this.modal.show(
              ModalType.progress,
              'key_savingChanges',
              '',
              '300px'
            );

            if (
              this.customViewSetting.savedQueries.hasOwnProperty(
                this.selectedQuery.key
              )
            ) {
              delete this.customViewSetting.savedQueries[
                this.selectedQuery.key
              ];
            }
            this.savedQueries = this.customViewSetting.savedQueries;

            const objectToSave = {
              ObjectID: this.utils.ExtraValue(
                this.resource.loginUser,
                'ObjectID'
              ),
            };
            objectToSave[this.utils.attConfiguration] = JSON.stringify(
              this.customViewSetting
            );

            return this.resource.updateResource(objectToSave);
          } else {
            return EMPTY;
          }
        }),
        finalize(() => {
          if (savingProgress) {
            savingProgress.close();
          }
        })
      )
      .subscribe();
  }

  onMenuAction(actionName: string) {
    this.swap.menuEvent(
      new MenuEvent('action', 'search', actionName, {
        selectedItems: this.selectedItems,
        table: this.resourceTable,
      })
    );
  }

  onMenuOpen() {
    this.swap.menuEvent(
      new MenuEvent('open', 'search', '', this.selectedItems, this.actionMenu)
    );
  }
}
