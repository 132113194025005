<div>
  <div mat-dialog-content>
    <mat-tab-group class="config-tab-group">
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.attribute.systemName"
              />
            </mat-form-field>
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <div
              fxFlex="0 0 50"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <mat-form-field fxFlex="0 0 50">
                <input
                  matInput
                  disabled
                  [placeholder]="'key_type' | translate"
                  [(ngModel)]="data.config.name"
                />
              </mat-form-field>
              <mat-form-field fxFlex="0 0 45">
                <input
                  matInput
                  type="number"
                  min="0"
                  [placeholder]="'key_hintLimit' | translate"
                  [(ngModel)]="data.config.hintLimit"
                />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="0 0 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-bottom: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.isMultivalue"
            >
              {{ 'key_multivalue' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [disabled]="!data.config.isMultivalue"
              [(ngModel)]="data.config.showBulkImport"
            >
              {{ 'key_showBulkImport' | translate }}</mat-slide-toggle
            >
          </div>
          <div fxFlex="none" *ngIf="data.creationMode">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_valueExpression' | translate"
                [(ngModel)]="data.config.expression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_queryNormalSearch' | translate"
                [(ngModel)]="data.config.queryNormalSearch"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_queryExactSearch' | translate"
                [(ngModel)]="data.config.queryExactSearch"
              ></textarea>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              fxFlex="30"
              [(ngModel)]="data.config.allowEmptySearch"
            >
              {{ 'key_allowEmptySearch' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="70">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [disabled]="!data.config.allowEmptySearch"
                [required]="data.config.allowEmptySearch"
                [placeholder]="'key_queryEmptySearch' | translate"
                [(ngModel)]="data.config.queryEmptySearch"
              ></textarea>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <div fxFlex="1 1 33" fxLayout="column">
              <div
                fxFlex="none"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  padding-left: 8px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_lettersToTrigger' | translate }}
              </div>
              <mat-slider
                fxFlex="none"
                [max]="10"
                [min]="2"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="10"
                [(ngModel)]="data.config.lettersToTrigger"
                style="margin-top: 2px"
              >
              </mat-slider>
            </div>
            <div fxFlex="1 1 33" fxLayout="column">
              <div
                fxFlex="none"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  padding-left: 8px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_suggestionNumber' | translate }}
              </div>
              <mat-slider
                fxFlex="none"
                [max]="100"
                [min]="2"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="10"
                [(ngModel)]="data.config.suggestionNumber"
                style="margin-top: 2px"
              >
              </mat-slider>
            </div>
            <div fxFlex="1 1 33" fxLayout="column">
              <div
                fxFlex="none"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  padding-left: 8px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_maxSearchAllCount' | translate }}
              </div>
              <mat-slider
                fxFlex="none"
                [max]="500"
                [min]="20"
                [step]="10"
                [thumbLabel]="true"
                [tickInterval]="10"
                [(ngModel)]="data.config.emptySearchMaxCount"
                style="margin-top: 2px"
              >
              </mat-slider>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_popup' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40" style="font-weight: 400; margin-bottom: 20px">
              {{ 'key_attributesToShow' | translate }}
            </div>
            <mat-form-field fxFlex="60">
              <input
                matInput
                [placeholder]="'key_resourceType' | translate"
                [(ngModel)]="data.config.objectType"
              />
            </mat-form-field>
          </div>
          <div fxLayout="row" style="margin-bottom: 40px">
            <div fxFlex="40" fxLayout="column">
              <div
                fxFlex="auto"
                dragula="COLUMNS"
                [(dragulaModel)]="data.config.attributesToShow"
                style="overflow-y: auto"
              >
                <div
                  *ngFor="let column of data.config.attributesToShow"
                  fxLayout="row"
                  [ngClass]="{
                    'attribute-cloumn-selected':
                      selectedColumnAttribute &&
                      column.field === selectedColumnAttribute.field
                  }"
                  class="attribute-column"
                  style="padding-left: 10px; margin: 5px 0"
                >
                  <div
                    fxFlex="auto"
                    style="line-height: 34px; cursor: pointer"
                    (click)="onSelectColumnAttribute(column)"
                  >
                    {{ column.field }}
                  </div>
                  <button
                    fxFlex="none"
                    mat-icon-button
                    color="warn"
                    [disableRipple]="true"
                    [matTooltip]="'key_delete' | translate"
                    (click)="onDeleteColumn(column)"
                  >
                    <mat-icon style="font-size: 20px; margin-top: -8px"
                      >delete_outline</mat-icon
                    >
                  </button>
                  <div
                    fxFlex="none"
                    class="handle"
                    style="width: 36px; cursor: pointer; color: lightgray"
                  >
                    <mat-icon style="line-height: 34px">menu</mat-icon>
                  </div>
                </div>
              </div>
              <div fxFlex="none" fxLayout="row" fxLayoutAlign="start end">
                <mat-form-field
                  fxFlex="auto"
                  style="margin-bottom: -20px; margin-right: 0"
                >
                  <input
                    matInput
                    [placeholder]="'key_addAttributeColumn' | translate"
                    [(ngModel)]="columnToAdd"
                  />
                </mat-form-field>
                <button
                  mat-icon-button
                  color="primary"
                  [disabled]="!columnToAdd"
                  (click)="onAddColumn()"
                >
                  <mat-icon>playlist_add</mat-icon>
                </button>
              </div>
            </div>
            <div fxFlex="60" class="attribute-column-setting">
              <div
                *ngIf="selectedColumnAttribute"
                fxLayout="column"
                fxLayoutGap="10px"
                style="font-size: 12px; padding: 10px"
              >
                <mat-form-field>
                  <input
                    matInput
                    [placeholder]="'key_field' | translate"
                    [(ngModel)]="selectedColumnAttribute.field"
                  />
                </mat-form-field>
                <mat-form-field>
                  <input
                    matInput
                    [placeholder]="'key_title' | translate"
                    [(ngModel)]="selectedColumnAttribute.title"
                  />
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.sortable"
                      >{{ 'key_sortable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.locked"
                      >{{ 'key_locked' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="33">
                    <input
                      matInput
                      type="number"
                      min="-1"
                      max="800"
                      [placeholder]="'key_width' | translate"
                      [(ngModel)]="selectedColumnAttribute.width"
                    />
                  </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div fxFlex="33">
                    <mat-slide-toggle
                      [(ngModel)]="selectedColumnAttribute.filterable"
                      >{{ 'key_filterable' | translate }}</mat-slide-toggle
                    >
                  </div>
                  <mat-form-field fxFlex="76">
                    <mat-select
                      [disabled]="!selectedColumnAttribute.filterable"
                      [placeholder]="'key_filterType' | translate"
                      [(ngModel)]="selectedColumnAttribute.filter"
                    >
                      <mat-option value="text">text</mat-option>
                      <mat-option value="numeric">numeric</mat-option>
                      <mat-option value="boolean">boolean</mat-option>
                      <mat-option value="date">date</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.showPhoto"
            >
              {{ 'key_showPhoto' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                [disabled]="!data.config.showPhoto"
                [placeholder]="'key_photoAttribute' | translate"
                [(ngModel)]="data.config.photoAttribute"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                [disabled]="!data.config.showPhoto"
                [placeholder]="'key_photoPlaceHolder' | translate"
                [(ngModel)]="data.config.photoPlaceHolder"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="0"
                max="800"
                [placeholder]="'key_popupHeight' | translate"
                [(ngModel)]="data.config.popupHeight"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="0"
                max="800"
                [placeholder]="'key_popupWidth' | translate"
                [(ngModel)]="data.config.popupWidth"
              />
            </mat-form-field>
            <div fxFlex="1 1 33"></div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 33">
              <mat-select
                [placeholder]="'key_scrollType' | translate"
                [(ngModel)]="data.config.browserScrollMode"
              >
                <mat-option value="basic">{{
                  'key_basic' | translate
                }}</mat-option>
                <mat-option value="virtual">{{
                  'key_virtual' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="0"
                max="100"
                [placeholder]="'key_scrollHeight' | translate"
                [(ngModel)]="data.config.browserScrollHeight"
                [disabled]="data.config.browserScrollMode === 'basic'"
              />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 33">
              <input
                matInput
                type="number"
                min="1"
                max="500"
                [placeholder]="'key_pageSize' | translate"
                [(ngModel)]="data.config.emptySearchMaxCount"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
      [disabled]="data.config.allowEmptySearch && !data.config.queryEmptySearch"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
