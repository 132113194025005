<div fxLayout="column" class="config-dialog">
  <div fxFlex="auto" mat-dialog-content>
    <mat-tab-group style="height: 100%">
      <mat-tab [label]="'key_general' | translate">
        <form
          #reportCardGeneralForm="ngForm"
          autocomplete="off"
          (keydown.enter)="$event.preventDefault()"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="10px"
            class="first-dialog-item"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="icon"
                    [placeholder]="'key_stateCardIconText' | translate"
                    [(ngModel)]="data.icon"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.iconColor"
                  cpPosition="right"
                  [style.backgroundColor]="data.iconColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="backgroundColor"
                    [placeholder]="'key_backgroundColor' | translate"
                    [(ngModel)]="data.backgroundColor"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.backgroundColor"
                  cpPosition="left"
                  [style.backgroundColor]="data.backgroundColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="text"
                    [placeholder]="'key_text' | translate"
                    [(ngModel)]="data.text"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.textColor"
                  cpPosition="right"
                  [style.backgroundColor]="data.textColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
              <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="auto">
                  <input
                    matInput
                    name="description"
                    [placeholder]="'key_description' | translate"
                    [(ngModel)]="data.description"
                  />
                </mat-form-field>
                <div
                  fxFlex="0 0 auto"
                  [(colorPicker)]="data.descriptionColor"
                  cpPosition="left"
                  [style.backgroundColor]="data.descriptionColor"
                  class="color-cube"
                  (colorPickerChange)="onColorChange()"
                ></div>
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <mat-form-field fxFlex="50">
                <input
                  matInput
                  name="fontSize"
                  type="number"
                  min="10"
                  max="50"
                  [placeholder]="'key_fontSize' | translate"
                  [(ngModel)]="data.fontSize"
                />
              </mat-form-field>
              <button
                mat-stroked-button
                fxFlex="50"
                (click)="onResetToThemeColor()"
              >
                {{ 'key_resetToThemeColor' | translate }}
              </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
              <mat-slide-toggle
                name="chkShowDivider"
                fxFlex="50"
                [(ngModel)]="data.showDivider"
                style="font-size: 16px; margin-top: 20px; margin-bottom: 20px"
              >
                {{ 'key_showDivider' | translate }}</mat-slide-toggle
              >
            </div>
            <mat-form-field>
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.permissionSets"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_separatorConfig'"
  ></app-popup-title>
</ng-template>
