import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { ConfigService } from '../core/services/config.service';
import { SwapService } from '../core/services/swap.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  errorMessage: string;

  bgImage: string;
  bgFilterOpacity: number;

  constructor(
    private config: ConfigService,
    private auth: AuthService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.errorMessage = this.swap.getErrorMessage();

    this.bgImage = this.config.getConfigEx(
      'images:bgLogin',
      'assets/img/img-identity.jpg'
    );
    if (this.bgImage.indexOf('/') >= 0) {
      this.bgImage = `url(${this.bgImage})`;
    }
    this.bgFilterOpacity = this.config.getConfigEx(
      'images:bgLoginFilterOpacity',
      0.6
    );
  }

  onReturnToStart() {
    this.auth.logout(true);
  }
}
