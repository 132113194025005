import { Injectable } from '@angular/core';
import { ResourceService } from './resource.service';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root',
})
export class InfoService {
  private compareVersion(v1: string, v2: string) {
    const arrV1 = v1.split('.');
    const arrV2 = v2.split('.');

    if (arrV1.length === arrV2.length) {
      for (let i = 0; i < arrV1.length; i++) {
        if (arrV1[i] > arrV2[i]) {
          return -1;
        }
        if (arrV2[i] > arrV1[i]) {
          return 1;
        }
      }
      return 0;
    } else {
      return null;
    }
  }

  constructor(private resource: ResourceService, private utils: UtilsService) {}

  getUiConfigInfo() {
    const currentUiVersion = this.resource.primaryViewSetting
      ? this.resource.primaryViewSetting.version
      : undefined;

    if (currentUiVersion) {
      if (
        this.compareVersion(this.utils.configVersion, currentUiVersion) >= 0
      ) {
        return 'UI configuration is compatible';
      } else {
        return '[§key_incompatibleUiConfig] - <a href="/app/configconverter">[§key_gotoConverter]</a>';
      }
    } else {
      return '[§key_incompatibleUiConfig] - <a href="/app/configconverter">[§key_gotoConverter]</a>';
    }
  }

  getUiConfigColor() {
    const currentUiVersion = this.resource.primaryViewSetting.version;
    if (currentUiVersion) {
      if (
        this.compareVersion(this.utils.configVersion, currentUiVersion) >= 0
      ) {
        return 'green';
      } else {
        return 'red';
      }
    } else {
      return 'red';
    }
  }
}
