import { Component, Injector, AfterViewInit, OnInit } from '@angular/core';

import {
  DynamicEditor,
  AttributeEditorConfig,
} from '../../models/dynamicEditor.interface';

import {
  IterablesEditorConfig,
  TextEditorConfig,
} from '../../models/editorContract.model';
import {
  AttributeResource,
  AuthMode,
  ValidationSetting,
} from '../../models/dataContract.model';
import { DragulaService } from 'ng2-dragula';
import { ResourceService } from '../../services/resource.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-editor-text-config',
  templateUrl: './editor-text-config.component.html',
  styleUrls: ['./editor-text-config.component.scss'],
})
export class EditorTextConfigComponent
  extends AttributeEditorConfig
  implements OnInit, AfterViewInit
{
  textInput: string;
  valueInput: string;

  iterablesConfig = new IterablesEditorConfig();
  textConfig = new TextEditorConfig();

  validationType: string;

  data: {
    component: DynamicEditor;
    config: TextEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  validationTags = {
    dataType: 'Dictionary',
    displayName: '',
    description: '',
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: '',
    value: null,
  };

  allowedLookups = {
    dataType: 'Dictionary',
    displayName: 'key_allowedLookups',
    description: '',
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'allowedLookups',
    value: null,
    values: null,
  };

  allowedStringFormats = {
    dataType: 'Dictionary',
    displayName: 'key_allowedStringFormats',
    description: '',
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'allowedStringFormats',
    value: null,
    values: null,
  };

  get isCloud(): boolean {
    return this.resource.authenticationMode === AuthMode.azure;
  }

  constructor(
    injector: Injector,
    private dragula: DragulaService,
    private resource: ResourceService
  ) {
    super(injector);

    try {
      this.dragula.createGroup('TEXTAUTOOPTIONS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit(): void {
    this.iterablesConfig.saveAs = 'object';
    this.iterablesConfig.properties = [
      {
        name: 'name',
        displayName: 'key_name',
        type: 'text',
        isKey: true,
        required: true,
        width: 20,
        options: [],
      },
      {
        name: 'abbreviation',
        displayName: 'key_abbreviation',
        type: 'text',
        isKey: true,
        width: 20,
        options: [],
      },
      {
        name: 'description',
        displayName: 'key_description',
        type: 'text',
        isKey: false,
        width: 40,
        options: [],
      },
      {
        name: 'isActive',
        displayName: 'key_isActive',
        type: 'boolean',
        isKey: false,
        width: 20,
        options: [],
      },
    ];

    this.textConfig.isMultivalue = true;

    if (this.data && this.data.config) {
      if (this.data.config.validationSetting) {
        this.validationType = this.data.config.validationSetting.type ?? 'none';

        if (this.data.config.validationSetting.tags) {
          this.validationTags.value = this.data.config.validationSetting.tags;
        }

        if (this.data.config.validationSetting.allowedLookups) {
          this.allowedLookups.values =
            this.data.config.validationSetting.allowedLookups;
          if (
            this.allowedLookups.values &&
            this.allowedLookups.values.length > 0
          ) {
            this.allowedLookups.value = this.allowedLookups.values[0];
          }
        }
        if (this.data.config.validationSetting.allowedStringFormats) {
          this.allowedStringFormats.values =
            this.data.config.validationSetting.allowedStringFormats;
          if (
            this.allowedStringFormats.values &&
            this.allowedStringFormats.values.length > 0
          ) {
            this.allowedStringFormats.value =
              this.allowedStringFormats.values[0];
          }
        }
      } else {
        this.validationType = 'none';
      }
    }
  }

  ngAfterViewInit() {
    this.initComponent();
  }

  onChangeToMultivalue() {
    if (this.data.config.isMultivalue) {
      this.data.config.expression = '';
    }
  }

  onAddOption() {
    const douplicates = this.data.config.autoCompleteOptions.filter(
      (element) => {
        return (
          element.text.toLowerCase() === this.textInput.toLowerCase() ||
          element.value.toLowerCase() === this.valueInput.toLowerCase()
        );
      }
    );
    if (douplicates && douplicates.length > 0) {
      return;
    } else {
      this.data.config.autoCompleteOptions.push({
        text: this.textInput,
        value: this.valueInput,
      });
      this.textInput = undefined;
      this.valueInput = undefined;
    }
  }

  onRemoveOption(item: { text: string; value: string }) {
    const index = this.data.config.autoCompleteOptions.findIndex((element) => {
      return element.value.toLowerCase() === item.value.toLowerCase();
    });
    if (index >= 0) {
      this.data.config.autoCompleteOptions.splice(index, 1);
    }
  }

  onValidationTypeChange(event: MatRadioChange) {
    if (event.value === 'none') {
      if (this.data.config.validationSetting) {
        this.data.config.validationSetting.type = undefined;
      }
    } else {
      if (!this.data.config.validationSetting) {
        this.data.config.validationSetting = new ValidationSetting();
      }
      this.data.config.validationSetting.type = event.value;
    }
  }

  onValidationTagsChange() {
    this.data.config.validationSetting.tags = this.validationTags.value;
  }

  onAllowedLookupsChange() {
    this.data.config.validationSetting.allowedLookups =
      this.allowedLookups.values && this.allowedLookups.values.length > 0
        ? this.allowedLookups.values
        : null;
  }

  onAllowedStringFormatsChange() {
    this.data.config.validationSetting.allowedStringFormats =
      this.allowedStringFormats.values &&
      this.allowedStringFormats.values.length > 0
        ? this.allowedStringFormats.values
        : null;
  }
}
