import {
  Directive,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { fromEvent, merge, of, Subscription } from 'rxjs';
import { map, switchMap, delay } from 'rxjs/operators';

@Directive({
  selector: '[appDelayedHover]',
})
export class DelayedHoverDirective implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  delay = 3000;

  @Output('appDelayedHover')
  hoverEvent = new EventEmitter();

  constructor(private readonly element: ElementRef) {}

  ngOnInit() {
    const hide$ = fromEvent(this.element.nativeElement, 'mouseleave').pipe(
      map(() => false)
    );
    const show$ = fromEvent(this.element.nativeElement, 'mouseenter').pipe(
      map(() => true)
    );

    this.subscription.add(
      merge(hide$, show$)
        .pipe(
          switchMap((show) => {
            if (!show) {
              return of(false);
            }
            return of(true).pipe(delay(this.delay));
          })
        )
        .subscribe((show) => {
          if (show) {
            this.hoverEvent.emit();
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
