<button
  mat-icon-button
  *ngIf="mode === 'event' && entries.length > 0"
  color="primary"
  class="control-button"
  style="left: calc(100% - 120px)"
  [matTooltip]="'key_expandAll' | translate"
  (click)="expandAll()"
>
  <mat-icon>unfold_more</mat-icon>
</button>
<button
  mat-icon-button
  *ngIf="mode === 'event' && entries.length > 0"
  color="primary"
  class="control-button"
  style="left: calc(100% - 60px)"
  [matTooltip]="'key_collapseAll' | translate"
  (click)="collapseAll()"
>
  <mat-icon>unfold_less</mat-icon>
</button>
<div>
  <mgl-timeline
    *ngIf="mode === 'event'"
    [toggle]="toggle"
    [alternate]="alternate"
    [side]="side"
    [mobileWidthThreshold]="0"
  >
    <mgl-timeline-entry
      *ngFor="let entry of entries; let i = index"
      #entryComponent
    >
      <mgl-timeline-entry-header
        class="point"
        [ngClass]="{ 'less-padding-header': entry.changedAttributes }"
        (click)="onHeaderClick(entry)"
      >
        <div
          fxLayout="column"
          [ngClass]="{
            'timeline-header-left': getElementSide(i) === 'left',
            'timeline-header-right': getElementSide(i) === 'right'
          }"
        >
          <div>
            {{ entry | extraValue: 'DisplayName' }}
          </div>
          <div *ngIf="entry.changedAttributes" class="modified-attributes">
            {{ 'key_modifiedAttributes' | translate }}:
            {{ entry.changedAttributes }}
          </div>
        </div>
      </mgl-timeline-entry-header>
      <mgl-timeline-entry-content
        [class]="getContentClass(entry)"
        [expandAnimationTiming]="contentAnimation ? '200ms ease' : '0ms'"
        [collapseAnimationTiming]="contentAnimation ? '100ms ease' : '0ms'"
      >
        <div fxLayout="row">
          <div fxFlex="180px" style="font-weight: 400">
            {{ 'key_target' | translate }}:
          </div>
          <div fxFlex="auto">
            <app-identity-link
              [identity]="{
                ObjectID: entry.targetid,
                ObjectType: entry.targetobjecttype,
                DisplayName: entry.targetdisplayname
              }"
              [linkActions]="['popup', 'sideView', 'navigate']"
            ></app-identity-link>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="180px" style="font-weight: 400">
            {{ 'key_requestor' | translate }}:
          </div>
          <div fxFlex="auto">
            <app-identity-link
              [identity]="{
                ObjectID: entry.requestorid,
                ObjectType: 'Person',
                DisplayName: entry.requestordisplayname
              }"
              [linkActions]="['popup', 'sideView', 'navigate']"
            ></app-identity-link>
          </div>
        </div>
        <div fxLayout="row">
          <div fxFlex="180px" style="font-weight: 400">
            {{ 'key_eventName' | translate }}:
          </div>
          <div fxFlex="auto">
            <app-identity-link
              [identity]="{
                ObjectID: entry.id,
                ObjectType: 'event',
                DisplayName: entry.displayname
              }"
              [linkActions]="['sideView', 'navigate']"
              [noneForm]="true"
            ></app-identity-link>
          </div>
        </div>
        <div style="height: 24px; width: 100%"></div>
        <div style="font-weight: 400">{{ 'key_details' | translate }}:</div>
        <div *ngIf="entry.detail && entry.detail.type === 'delta'">
          <div *ngFor="let attribute of entry.detail.attributes">
            <div
              *ngIf="attribute.name && !attribute.name.startsWith('$')"
              fxLayout="row"
            >
              <div fxFlex="180px" [matTooltip]="attribute.name">
                {{ attribute.displayName }}:
              </div>
              <div fxFlex="50%">
                <div
                  *ngIf="
                    isGuid(attribute.name, attribute.before);
                    else deltaNonBeforeGuid
                  "
                >
                  <app-identity-link
                    [identity]="{ ObjectID: attribute.before }"
                    [linkActions]="['popup', 'sideView', 'navigate']"
                  ></app-identity-link>
                </div>
                <ng-template #deltaNonBeforeGuid>
                  {{ formatDate(attribute.before) }}
                </ng-template>
              </div>
              <div fxFlex="60px"><mat-icon>arrow_forward</mat-icon></div>
              <div fxFlex="50%">
                <div
                  *ngIf="
                    isGuid(attribute.name, attribute.after);
                    else deltaNonAfterGuid
                  "
                >
                  <app-identity-link
                    [identity]="{ ObjectID: attribute.after }"
                    [linkActions]="['popup', 'sideView', 'navigate']"
                  ></app-identity-link>
                </div>
                <ng-template #deltaNonAfterGuid>
                  {{ formatDate(attribute.after) }}
                </ng-template>
              </div>
            </div>
          </div>
          <div *ngFor="let change of entry.detail.multivalueChanges">
            <div
              *ngIf="change.name && !change.name.startsWith('$')"
              fxLayout="row"
            >
              <div fxFlex="180px" [matTooltip]="change.name">
                {{ change.displayName }}:
              </div>
              <div fxFlex="50%"></div>
              <div fxFlex="50%" fxLayout="column" style="margin-left: -60px">
                <div *ngFor="let changedValue of change.changes" fxLayout="row">
                  <div fxFlex="60px">
                    <mat-icon>{{ changedValue.type }}</mat-icon>
                  </div>
                  <div fxFlex="auto">
                    <div
                      *ngIf="
                        isGuid(change.name, changedValue.value);
                        else deltaNonMultivalueGuid
                      "
                    >
                      <app-identity-link
                        [identity]="{ ObjectID: changedValue.value }"
                        [linkActions]="['popup', 'sideView', 'navigate']"
                      >
                      </app-identity-link>
                    </div>
                    <ng-template #deltaNonMultivalueGuid>
                      {{ changedValue.value }}
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="height: 24px; width: 100%"></div>
          <a
            class="primary-color resource-link"
            (click)="onFullResourceView(entry, i)"
            >{{ 'key_viewFullResource' | translate }}</a
          >
        </div>
        <div *ngIf="entry.detail && entry.detail.type === 'full'">
          <div *ngFor="let attribute of entry.detail.attributes">
            <div
              *ngIf="attribute.name && !attribute.name.startsWith('$')"
              fxLayout="row"
            >
              <div fxFlex="180px" [matTooltip]="attribute.name">
                {{ attribute.displayName }}:
              </div>
              <div fxFlex="auto">
                <div *ngIf="attribute.multivalued; else nonMultivaluedFull">
                  <div fxLayout="column">
                    <div *ngFor="let multiValue of attribute.after">
                      <div
                        *ngIf="
                          isGuid(attribute.name, multiValue);
                          else fullNonMultivalueGuid
                        "
                      >
                        <app-identity-link
                          [identity]="{ ObjectID: multiValue }"
                          [linkActions]="['popup', 'sideView', 'navigate']"
                        >
                        </app-identity-link>
                      </div>
                      <ng-template #fullNonMultivalueGuid>
                        {{ formatDate(multiValue) }}
                      </ng-template>
                    </div>
                  </div>
                </div>
                <ng-template #nonMultivaluedFull>
                  <div
                    *ngIf="
                      isGuid(attribute.name, attribute.after);
                      else fullNonAfterGuid
                    "
                  >
                    <app-identity-link
                      [identity]="{ ObjectID: attribute.after }"
                      [linkActions]="['popup', 'sideView', 'navigate']"
                    >
                    </app-identity-link>
                  </div>
                  <ng-template #fullNonAfterGuid>
                    {{ formatDate(attribute.after) }}
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
          <div style="height: 24px; width: 100%"></div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="auto"></div>
            <a
              *ngIf="previewAllowed && i !== 0"
              fxFlex="none"
              class="primary-color resource-link"
              (click)="onRestoreFromEvent(entry)"
              >{{ 'key_previewAndRestore' | translate }}</a
            >
          </div>
        </div>
        <div *ngIf="entry.detail && entry.detail.type === 'resource'">
          <div *ngFor="let attribute of entry.detail.resource">
            <div
              *ngIf="attribute.name && !attribute.name.startsWith('$')"
              fxLayout="row"
            >
              <div fxFlex="180px" [matTooltip]="attribute.name">
                {{ attribute.displayName }}:
              </div>
              <div fxFlex="auto">
                <div *ngIf="attribute.multivalued; else nonMultivaluedResource">
                  <div fxLayout="column">
                    <div *ngFor="let multiValue of attribute.after">
                      <div
                        *ngIf="
                          isGuid(attribute.name, multiValue);
                          else resourceNonMultivalueGuid
                        "
                      >
                        <app-identity-link
                          [identity]="{ ObjectID: multiValue }"
                          [linkActions]="['popup', 'sideView', 'navigate']"
                        >
                        </app-identity-link>
                      </div>
                      <ng-template #resourceNonMultivalueGuid>
                        {{ formatDate(multiValue) }}
                      </ng-template>
                    </div>
                  </div>
                </div>
                <ng-template #nonMultivaluedResource>
                  <div
                    *ngIf="
                      isGuid(attribute.name, attribute.after);
                      else resourceNonAfterGuid
                    "
                  >
                    <app-identity-link
                      [identity]="{ ObjectID: attribute.after }"
                      [linkActions]="['popup', 'sideView', 'navigate']"
                    ></app-identity-link>
                  </div>
                  <ng-template #resourceNonAfterGuid>
                    {{ formatDate(attribute.after) }}
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
          <div style="height: 24px; width: 100%"></div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <a
              fxFlex="none"
              class="primary-color resource-link"
              (click)="onDeltaResourceView(entry, i)"
              >{{ 'key_viewDeltaResource' | translate }}</a
            >
            <div fxFlex="auto"></div>
            <a
              *ngIf="previewAllowed && i !== 0"
              fxFlex="none"
              class="primary-color resource-link"
              (click)="onRestoreFromEvent(entry)"
              >{{ 'key_previewAndRestore' | translate }}</a
            >
          </div>
        </div>
      </mgl-timeline-entry-content>
      <mgl-timeline-entry-dot
        [size]="dotSize"
        class="point"
        [class]="getDotClass(entry)"
        [ngClass]="{
          'timeline-dot-left': getElementSide(i) === 'left',
          'timeline-dot-right': getElementSide(i) === 'right'
        }"
        (click)="onHeaderClick(entry)"
        [expandAnimationTiming]="dotAnimation ? '200ms ease' : '0ms'"
        [collapseAnimationTiming]="dotAnimation ? '100ms ease' : '0ms'"
      >
        <mat-icon class="dot-icon">{{ getIcon(entry) }}</mat-icon>
      </mgl-timeline-entry-dot>
      <mgl-timeline-entry-side>
        <div
          fxLayout="row"
          fxLayoutGap="10px"
          [fxLayoutAlign]="
            getElementSide(i) === 'left' ? 'start center' : 'end center'
          "
          [ngClass]="{
            'timeline-side-left': getElementSide(i) === 'left',
            'timeline-side-right': getElementSide(i) === 'right'
          }"
          style="margin-top: 15px"
        >
          <div
            style="font-weight: 400"
            [fxFlexOrder]="getElementSide(i) === 'left' ? 0 : 1"
          >
            {{ 'key_committedTime' | translate }}:
            {{ formatDate(entry | extraValue: 'CommittedTime') }}
          </div>
          <app-action-menu
            *ngIf="
              linkedReports.length > 0 &&
              entry.detail &&
              (entry.detail.type === 'resource' || entry.detail.type === 'full')
            "
            class="info-button accent-bg-color primary-contrast-color"
            [fxFlexOrder]="getElementSide(i) === 'left' ? 1 : 0"
            [menuIcon]="'menu_open'"
            [menuItems]="reportMenuItems"
            (action)="onReportMenuAction($event, entry)"
          ></app-action-menu>
        </div>
      </mgl-timeline-entry-side>
    </mgl-timeline-entry>
  </mgl-timeline>
  <mgl-timeline-entry *ngIf="mode === 'event' && entries.length === 0">
    <mgl-timeline-entry-dot
      [size]="dotSize"
      class="dot-normal"
      (click)="onEmptyEntryClick($event)"
    >
      <mat-icon class="dot-icon">live_help</mat-icon>
    </mgl-timeline-entry-dot>
  </mgl-timeline-entry>
  <mgl-timeline
    *ngIf="mode === 'resource'"
    [toggle]="toggle"
    [alternate]="alternate"
    [side]="side"
    [mobileWidthThreshold]="0"
  >
  </mgl-timeline>
  <div
    fxLayout="row"
    fxLayoutAlign="center center"
    *ngIf="mode === 'resource' && status !== 'deleted'"
    class="status-menu"
  >
    <app-action-menu
      class="info-button accent-bg-color primary-contrast-color"
      [menuIcon]="'menu_open'"
      [menuItems]="reportMenuItems"
      (action)="onReportMenuAction($event, entries)"
    ></app-action-menu>
  </div>
  <div
    fxLayout="column"
    *ngIf="mode === 'resource'"
    class="status-layout"
    [class]="'status-' + status"
  >
    <div fxLayout="row" class="status-header" [class]="'status-' + status">
      <div>
        <mat-icon class="status-icon" [class]="'status-icon-' + status">{{
          status === 'created'
            ? 'create'
            : status === 'deleted'
            ? 'delete'
            : 'live_help'
        }}</mat-icon>
      </div>
      <div style="margin-left: 60px">
        <div *ngIf="status === 'deleted'">
          {{ 'key_resourceDeleted' | translate }}
        </div>
        <div *ngIf="status === 'notfound'">
          {{ 'key_resourceNotFound' | translate }}
        </div>
        <div *ngIf="status === 'created'">
          <app-identity-link
            [identity]="statusResource"
            [linkActions]="['popup', 'sideView', 'navigate']"
          ></app-identity-link>
        </div>
      </div>
      <div fxFlex="auto"></div>
      <div>
        {{ formatDate(statusTime) }}
      </div>
    </div>
    <div *ngIf="entries.length > 0" class="status-body">
      <div *ngFor="let attribute of entries">
        <div
          *ngIf="attribute.name && !attribute.name.startsWith('$')"
          fxLayout="row"
        >
          <div fxFlex="180px" [matTooltip]="attribute.name">
            {{ attribute.displayName }}:
          </div>
          <div fxFlex="auto">
            <div *ngIf="attribute.multivalued; else nonMultivaluedValue">
              <div fxLayout="column">
                <div *ngFor="let multiValue of attribute.value">
                  <div
                    *ngIf="
                      isGuid(attribute.name, multiValue);
                      else valueNonMultivalueGuid
                    "
                  >
                    <app-identity-link
                      [identity]="{ ObjectID: multiValue }"
                      [linkActions]="['popup', 'sideView', 'navigate']"
                    >
                    </app-identity-link>
                  </div>
                  <ng-template #valueNonMultivalueGuid>
                    {{ formatDate(multiValue) }}
                  </ng-template>
                </div>
              </div>
            </div>
            <ng-template #nonMultivaluedValue>
              <div
                *ngIf="
                  isGuid(attribute.name, attribute.value);
                  else nonValueGuid
                "
              >
                <app-identity-link
                  [identity]="{ ObjectID: attribute.value }"
                  [linkActions]="['popup', 'sideView', 'navigate']"
                ></app-identity-link>
              </div>
              <ng-template #nonValueGuid>
                {{ formatDate(attribute.value) }}
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
      <div
        *ngIf="!historySidenav"
        fxLayout="row"
        fxLayoutAlign="start center"
        style="margin-top: 20px"
      >
        <div fxFlex="auto"></div>
        <a
          *ngIf="previewAllowed"
          fxFlex="none"
          class="primary-color resource-link"
          (click)="onRestoreFromStatus(statusResource, statusTime)"
          >{{ 'key_previewAndRestore' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
