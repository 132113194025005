<kendo-splitter style="height: 100%; background-color: #eeeeee">
  <kendo-splitter-pane [collapsible]="true" size="50%">
    <kendo-splitter
      orientation="vertical"
      style="height: 100%; background-color: #eeeeee"
    >
      <kendo-splitter-pane size="75%">
        <div
          *ngIf="xBuilder && !xBuilder.canParse"
          style="
            border: solid 2px bisque;
            margin-top: 20px;
            background-color: lightyellow;
            padding: 10px;
          "
        >
          {{ 'key_canNotVisualizeXpath' | translate }}
        </div>
        <div
          style="margin: 5px"
          [ngClass]="{
            'component-hidden': !xBuilder || (xBuilder && !xBuilder.canParse)
          }"
        >
          <app-xpath-builder
            #xpathBuilder
            (queryChange)="onQueryChange()"
          ></app-xpath-builder>
        </div>
      </kendo-splitter-pane>
      <kendo-splitter-pane [resizable]="false" size="55px">
        <div fxLayout="row" style="margin: 10px 5px 5px 5px">
          <button
            mat-stroked-button
            class="primary-text-color"
            (click)="onSaveQuery(xBuilder)"
          >
            {{ 'l10n_saveQuery' | translate }}
          </button>
          <div fxFlex="auto"></div>
          <div
            *ngIf="errorMsg"
            class="warn-color"
            style="line-height: 36px; font-weight: 400"
          >
            {{ errorMsg | translate }}
          </div>
          <div fxFlex="auto"></div>
          <button
            mat-stroked-button
            color="primary"
            (click)="onSearch(xBuilder, attributePicker)"
          >
            {{ 'key_search' | translate }}
          </button>
        </div>
      </kendo-splitter-pane>
      <kendo-splitter-pane [collapsible]="true" class="primary-text-color">
        <div fxLayout="row" style="margin: 5px">
          <div
            fxFlex="0 0 160px"
            style="margin-right: 10px; font-weight: 400; line-height: 32px"
          >
            {{ 'l10n_availableQueries' | translate }}
          </div>
          <div>
            <mat-form-field appearance="outline">
              <mat-select [(ngModel)]="selectedQuery">
                <mat-option
                  *ngFor="let query of savedQueries | keyvalue"
                  [value]="query"
                  style="font-size: 14px; height: 32px"
                >
                  {{ query.key }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button
            mat-stroked-button
            [disabled]="!selectedQuery"
            (click)="onLoadQuery(xBuilder, attributePicker)"
            style="height: 32px; line-height: 32px; margin-left: 20px"
          >
            {{ 'key_load' | translate }}
          </button>
          <button
            mat-stroked-button
            color="warn"
            [disabled]="!selectedQuery"
            (click)="onDeleteQuery()"
            style="height: 32px; line-height: 32px; margin-left: 20px"
          >
            {{ 'key_delete' | translate }}
          </button>
        </div>
        <div fxLayout="row" style="margin: 5px">
          <div
            fxFlex="0 0 160px"
            style="margin-right: 10px; font-weight: 400; line-height: 32px"
          >
            {{ 'l10n_resultantXpath' | translate }}
          </div>
          <mat-form-field
            appearance="outline"
            fxFlex="auto"
            style="margin-top: 10px; margin-right: 10px"
          >
            <textarea
              matInput
              rows="4"
              [(ngModel)]="resultantQuery"
              class="xpath-text-area"
            ></textarea>
            <mat-icon
              matSuffix
              class="primary-text-color"
              style="
                font-size: 28px;
                position: absolute;
                top: -65px;
                left: -25px;
                cursor: pointer;
              "
              [matTooltip]="'key_apply' | translate"
              (click)="onApplyXPath(xBuilder, attributePicker)"
              >input</mat-icon
            >
          </mat-form-field>
          <!-- <div
            fxFlex="auto"
            style="
              border: lightgray solid 1px;
              height: 80px;
              margin-top: 10px;
              margin-right: 10px;
              border-radius: 5px;
            "
          ></div> -->
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </kendo-splitter-pane>
  <kendo-splitter-pane>
    <div fxLayout="column" style="height: 100%">
      <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <div style="margin: 5px; width: 50%">
          <app-attribute-picker
            #attributePicker
            [appearance]="'outline'"
            [objectType]="queryType"
            [multiple]="true"
            [placeHolder]="'l10n_selectAttributes' | translate"
            [preSelectedItems]="selectedAttributes"
            (attributeChange)="onAttributeChange($event)"
          ></app-attribute-picker>
        </div>
        <div fxFlex="auto"></div>
        <div fxFlex="none" style="margin: 5px">
          <app-action-menu
            #actionMenu
            [menuType]="'button'"
            [menuIcon]="'menu_open'"
            [menuText]="'key_action' | translate"
            [menuColor]="'primary'"
            (action)="onMenuAction($event)"
            (openMenu)="onMenuOpen()"
          ></app-action-menu>
        </div>
      </div>
      <div fxFlex="none" class="resource-table">
        <app-resource-table
          #resourceTable
          [config]="tableConfig"
        ></app-resource-table>
      </div>
    </div>
  </kendo-splitter-pane>
</kendo-splitter>
