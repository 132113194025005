<div fxLayout="column" class="resource-table">
  <div fxLayout="row" fxLayoutalign="start center" style="margin-bottom: 10px">
    <div fxFlex="none">
      <mat-radio-group
        [(ngModel)]="viewMode"
        (change)="onViewModeChange($event)"
      >
        <mat-radio-button value="table" style="margin-right: 10px"
          >Table view</mat-radio-button
        >
        <mat-radio-button value="tree">Tree view</mat-radio-button>
      </mat-radio-group>
    </div>
    <div
      *ngIf="viewMode === 'table'"
      style="margin-left: 60px; margin-bottom: -3px"
    >
      <mat-slide-toggle
        [(ngModel)]="includeArchiev"
        (change)="onIncludeArchievChange()"
        >{{ 'key_includeArchive' | translate }}</mat-slide-toggle
      >
    </div>
    <div
      *ngIf="viewMode === 'table'"
      style="margin-left: 60px; margin-bottom: -3px"
    >
      <mat-slide-toggle
        [(ngModel)]="includeSystemEvents"
        (change)="onIncludeSystemEvents()"
        >{{ 'key_includeSystemEvents' | translate }}</mat-slide-toggle
      >
    </div>
    <div fxFlex="auto"></div>
    <div
      *ngIf="viewMode === 'table'"
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
    >
      <mat-radio-group
        [(ngModel)]="eventMode"
        (change)="onEventModeChange($event)"
      >
        <mat-radio-button value="resource" style="margin-right: 10px"
          >Resource events</mat-radio-button
        >
        <mat-radio-button value="request">Request events</mat-radio-button>
      </mat-radio-group>
      <button mat-flat-button style="margin-left: 20px" (click)="onRefresh()">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span
            class="material-icons-outlined primary-color"
            style="margin-right: 10px"
            >refresh</span
          >
          <div style="font-weight: 400">
            {{ 'key_refresh' | translate }}
          </div>
        </div>
      </button>
    </div>
    <!-- <div *ngIf="viewMode === 'table'" fxFlex="none" style="margin-top: -5px">
      <mat-form-field appearance="outline" style="width: 260px">
        <mat-label>{{ 'l10n_requestTypes' | translate }}</mat-label>
        <mat-select
          multiple
          [(ngModel)]="allowedRequestTypes"
          [disabled]="eventMode !== 'request'"
        >
          <mat-option *ngFor="let type of requestTypes" [value]="type">{{
            type
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->
  </div>
  <app-resource-table
    #resourceTable
    *ngIf="viewMode === 'table'"
    [config]="tableConfig"
    [adjustApiData]="adjustEventData"
    [adjustApiPageSize]="adjustEventPageSize"
    [adjustApiStateChange]="adjustEventStateChange"
  ></app-resource-table>
  <app-tree-list
    *ngIf="viewMode === 'tree'"
    [config]="treeListConfig"
    [adjustRootApiData]="adjustTreeListData"
    [adjustChildrenApiData]="adjustTreeListData"
  ></app-tree-list>
</div>
