import { Component, OnInit, Injectable, OnDestroy } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';

import { EMPTY, Observable, of, Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';

import { StartupService } from './core/services/startup.service';
import { SwapService } from './core/services/swap.service';
import { AuthService } from './core/services/auth.service';
import { Router } from '@angular/router';
import { AuthMode } from './core/models/dataContract.model';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly destroying$ = new Subject<void>();

  private msalLogin = false;

  constructor(
    private startup: StartupService,
    private swap: SwapService,
    private msalBroadcast: MsalBroadcastService,
    private auth: AuthService,
    private router: Router
  ) {
    window.onresize = () => {
      this.swap.verifyWindowSize();
    };
  }

  ngOnInit() {
    this.msalBroadcast.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.msalLogin = true;
        const activeAccount = this.auth.setActiveAccount();
        if (activeAccount) {
          this.auth.initMsal({
            name: activeAccount.name,
            accountIdentifier: activeAccount.localAccountId,
            userName: activeAccount.username,
          });
        }
        this.startup
          .init(window.location.pathname)
          .pipe(
            catchError(() => {
              this.router.navigate(['/error']);
              return EMPTY;
            })
          )
          .subscribe();
      });

    this.msalBroadcast.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        const activeAccount = this.auth.setActiveAccount();
        if (!this.msalLogin && activeAccount) {
          this.auth.initMsal({
            name: activeAccount.name,
            accountIdentifier: activeAccount.localAccountId,
            userName: activeAccount.username,
          });
        }
        this.startup
          .init(window.location.pathname)
          .pipe(
            catchError(() => {
              this.router.navigate(['/error']);
              return EMPTY;
            })
          )
          .subscribe();
      });

    this.msalBroadcast.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.auth.clear();
        this.router.navigate(['/login']);
      });

    if (this.auth.authMode !== AuthMode.azure) {
      this.startup
        .init(window.location.pathname)
        .pipe(
          catchError(() => {
            this.router.navigate(['/error']);
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroying$.next(null);
    this.destroying$.complete();
  }
}

/*
  Mocked backend service for kendo uploader
  For further details, check https://angular.io/guide/http#writing-an-interceptor.
*/
@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url === 'uploadSaveUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
