<div
  class="full-size-relative"
  ngxUiLoaderBlurred
  [blur]="blurLevel"
  [loaderId]="localConfig.name"
  [style.paddingLeft.px]="localConfig.paddingLeft"
  [style.paddingRight.px]="localConfig.paddingRight"
  [style.paddingTop.px]="localConfig.paddingTop"
  [style.paddingBottom.px]="localConfig.paddingBottom"
>
  <div
    *ngIf="!componentItem"
    fxLayout="column"
    fxLayoutAlign="center center"
    style="height: 100%; width: 100%"
  >
    <mat-icon
      fxFlex="none"
      style="font-size: 100px; width: 100px; color: #cccccc"
      >widgets</mat-icon
    >
    <div
      fxFlex="none"
      style="
        margin-top: 50px;
        padding-top: 50px;
        font-size: 18px;
        color: #b0b0b0;
      "
    >
      {{ 'key_noComponentToLoad' | translate }}
    </div>
  </div>
  <ng-container appDynamicContainer></ng-container>
</div>
<ngx-ui-loader
  [loaderId]="localConfig.name"
  [fgsType]="spinnerType.rectangleBounce"
  [fgsSize]="40"
  [fgsColor]="'white'"
  [overlayColor]="'rgba(50,50,50,.5)'"
  [pbColor]="'white'"
></ngx-ui-loader>
