<ng-template #nestedList let-activities>
  <div
    *ngFor="let activity of activities; let i = index"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div
      fxFlex="auto"
      fxLayout="column"
      fxLayoutAlign="center center"
      style="margin-bottom: 10px"
    >
      <mat-icon class="accent-text-color">arrow_downward</mat-icon>
    </div>
    <div fxFlex="auto" style="margin-bottom: 20px">
      <mat-card style="padding: 10px">
        <mat-card-header>
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
            <div
              fxFlex="none"
              class="info-icon-layer"
              [ngClass]="{
                'accent-bg-color': activity.isenabled,
                'disabled-bg-color': !activity.isenabled
              }"
              [matTooltip]="getTooltip(activity)"
              (click)="toggleEnable(activity)"
            >
              <mat-icon
                class="info-icon"
                [ngClass]="{
                  'primary-contrast-color': activity.isenabled,
                  'disabled-text-color': !activity.isenabled
                }"
                >{{ getIcon(activity.type) }}
              </mat-icon>
            </div>
            <div fxFlex="auto">{{ activity.displayname }}</div>
            <div
              fxFlex="none"
              [matTooltip]="'key_settings' | translate"
              style="margin-left: 50px"
              (click)="configure(activity)"
            >
              <span
                class="material-icons-outlined div-button accent-text-color"
              >
                settings
              </span>
            </div>
            <div
              fxFlex="none"
              [matTooltip]="'key_remove' | translate"
              (click)="onRemoveActivity(activity)"
            >
              <span
                class="material-icons-outlined div-button accent-text-color"
                style="font-size: 22px"
              >
                delete_forever
              </span>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content
          *ngIf="isConditional(activity.type)"
          fxLayout="column"
          fxLayoutAlign="start center"
        >
          <div class="condition-start"></div>
          <div class="condition-branch"></div>
          <div fxLayout="row" fxLayoutAlign="center center">
            <div
              fxLayout="column"
              class="activity-content activity-container"
              style="min-width: 200px"
            >
              <div
                class="mask-top"
                style="text-align: center; line-height: 5px"
              >
                True
              </div>
              <div class="mask-bottom"></div>
              <div style="margin: 10px 0">
                <ng-container
                  *ngTemplateOutlet="
                    nestedList;
                    context: { $implicit: activity.activitiestrue }
                  "
                >
                </ng-container>
              </div>
              <div
                *ngIf="
                  activity.activitiestrue &&
                  activity.activitiestrue.length === 0
                "
                fxFlex="auto"
                fxLayout="column"
                fxLayoutAlign="center center"
                style="margin-bottom: 10px"
              >
                <mat-icon class="accent-text-color">arrow_downward</mat-icon>
                <div (click)="onAddActivity(activity, true)">
                  <span
                    [matTooltip]="'key_add' | translate"
                    class="material-icons-outlined div-add-button accent-text-color"
                  >
                    add_circle_outline
                  </span>
                </div>
              </div>
            </div>
            <div style="width: 100px"></div>
            <div
              fxLayout="column"
              class="activity-content activity-container"
              style="min-width: 200px"
            >
              <div
                class="mask-top"
                style="text-align: center; line-height: 5px"
              >
                False
              </div>
              <div class="mask-bottom"></div>
              <div style="margin: 10px 0">
                <ng-container
                  *ngTemplateOutlet="
                    nestedList;
                    context: { $implicit: activity.activitiesfalse }
                  "
                >
                </ng-container>
              </div>
              <div
                *ngIf="
                  activity.activitiesfalse &&
                  activity.activitiesfalse.length === 0
                "
                fxFlex="auto"
                fxLayout="column"
                fxLayoutAlign="center center"
                style="margin-bottom: 10px"
              >
                <mat-icon class="accent-text-color">arrow_downward</mat-icon>
                <div (click)="onAddActivity(activity, false)">
                  <span
                    [matTooltip]="'key_add' | translate"
                    class="material-icons-outlined div-add-button accent-text-color"
                  >
                    add_circle_outline
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-content
          *ngIf="isContainer(activity.type)"
          fxLayout="column"
          class="activity-content activity-container"
        >
          <div *ngIf="isContainer(activity.type)" class="mask-top"></div>
          <div *ngIf="isContainer(activity.type)" class="mask-bottom"></div>
          <div style="margin: 10px 0">
            <div
              [dragula]="
                hasMultiActivities(activity.type) ? 'ACTIVITIES' : null
              "
              [(dragulaModel)]="activity.activities"
              style="min-height: 20px"
            >
              <ng-container
                *ngTemplateOutlet="
                  nestedList;
                  context: { $implicit: activity.activities }
                "
              >
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="canAddNew(activity)"
            fxFlex="auto"
            fxLayout="column"
            fxLayoutAlign="center center"
            style="margin-bottom: 10px"
          >
            <mat-icon class="accent-text-color">arrow_downward</mat-icon>
            <div (click)="onAddActivity(activity)">
              <span
                [matTooltip]="'key_add' | translate"
                class="material-icons-outlined div-add-button accent-text-color"
              >
                add_circle_outline
              </span>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>
<div fxLayout="column" fxLayoutAlign="center center">
  <ng-container
    *ngTemplateOutlet="
      nestedList;
      context: { $implicit: workflowDescription ? [workflowDescription] : [] }
    "
  >
  </ng-container>
  <div
    *ngIf="!workflowDescription"
    fxLayout="column"
    fxLayoutAlign="center center"
    style="margin-bottom: 10px"
  >
    <mat-icon class="accent-text-color">arrow_downward</mat-icon>
    <span
      [matTooltip]="'key_add' | translate"
      class="material-icons-outlined div-add-button accent-text-color"
      (click)="onAddActivity(null)"
    >
      add_circle_outline
    </span>
  </div>
  <!-- <button
    fxFlex="auto"
    mat-stroked-button
    color="primary"
    (click)="onAction()"
    style="margin-bottom: 20px"
  >
    Action
  </button> -->
</div>
