<div
  class="full-size-relative"
  ngxUiLoaderBlurred
  [blur]="blurLevel"
  [loaderId]="localConfig.name"
>
  <div fxLayout="row">
    <div
      fxFlex="auto"
      style="
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0 0 10px;
        color: rgba(0, 0, 0, 0.54);
      "
    >
      {{ localConfig.title | translate }}
    </div>
    <div
      fxFlex="none"
      style="
        width: 36px;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.54);
        padding-top: 10px;
        margin-right: 10px;
      "
      [matTooltip]="'key_refresh' | translate"
      (click)="refresh()"
    >
      <mat-icon>refresh</mat-icon>
    </div>
  </div>
  <div
    *ngIf="approvalRequests.length === 0"
    fxLayout="column"
    fxLayoutAlign="center center"
    style="height: 90%; margin-top: 40px"
  >
    <mat-icon
      fxFlex="none"
      style="font-size: 100px; width: 100px; color: #cccccc"
      >flaky</mat-icon
    >
    <div
      fxFlex="none"
      style="
        margin-top: 50px;
        padding-top: 50px;
        font-size: 18px;
        color: #b0b0b0;
      "
    >
      {{ 'key_noPendingApprovals' | translate }}
    </div>
  </div>
  <div
    *ngIf="approvalRequests.length > 0"
    style="
      margin: 20px 10px 10px 10px;
      overflow-y: auto;
      height: calc(100% - 80px);
      padding-bottom: 10px;
    "
  >
    <mat-card
      *ngFor="let request of approvalRequests"
      style="
        border-left-style: solid;
        border-left-width: 5px;
        margin-top: 10px;
        margin-right: 5px;
        padding: 12px;
      "
      [ngStyle]="{
        'border-left-color':
          request.status === 'Authorizing'
            ? 'goldenrod'
            : request.status === 'Completed'
            ? 'green'
            : 'red'
      }"
    >
      <mat-card-header fxLayout="row" fxLayoutAlign="start center">
        <div
          fxFlex="none"
          style="
            width: 36px;
            margin-left: -30px;
            cursor: pointer;
            color: lightgray;
          "
          (click)="onExpandRequest(request)"
        >
          <mat-icon>{{
            request.showContent ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
          }}</mat-icon>
        </div>
        <div
          fxFlex="auto"
          fxLayout="column"
          style="margin-top: -8px; font-size: 14px"
        >
          <div
            fxLayout="row"
            style="font-size: 12px; color: darkgray; margin-bottom: -6px"
          >
            <div fxFlex="none">
              {{ request.createdTime }}
            </div>
            <div fxFlex="none" style="margin-left: 5px">
              {{ 'key_requestedBy' | translate }}
            </div>
            <div fxFlex="none" style="margin-left: 5px">
              <app-identity-link
                [identity]="{ ObjectID: request.creator }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <div *ngIf="request.title; else noTitle">
            {{ request.title }}
          </div>
          <ng-template #noTitle>{{ request.displayName }}</ng-template>
        </div>
        <div
          *ngIf="request.status === 'Authorizing' && localConfig.canApprove"
          fxFlex="none"
          style="width: 36px"
        >
          <button
            mat-icon-button
            color="primary"
            [matTooltip]="'key_approveRequest' | translate"
            (click)="onApprove(request, true)"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
        </div>
        <div
          *ngIf="request.status === 'Authorizing' && localConfig.canApprove"
          fxFlex="none"
          style="width: 36px"
        >
          <button
            mat-icon-button
            color="warn"
            [matTooltip]="'key_rejectRequest' | translate"
            (click)="onApprove(request, false)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </div>
      </mat-card-header>
      <mat-card-content
        *ngIf="request.showContent"
        fxLayout="column"
        style="margin-top: 5px; margin-bottom: 10px; font-size: 12px"
      >
        <div fxFlex="auto" fxLayout="column" fxLayoutAlign="start center">
          <div fxLayout="row" style="width: 90%; margin-bottom: 0">
            <div fxFlex="33" style="font-weight: 400">
              {{ 'key_target' | translate }}:
            </div>
            <div fxFlex="auto">
              <app-identity-link
                [identity]="{ ObjectID: request.target }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <div fxLayout="row" style="width: 90%; margin-bottom: 5px">
            <div fxFlex="33" style="font-weight: 400">
              {{ 'key_requestor' | translate }}:
            </div>
            <div fxFlex="auto">
              <app-identity-link
                [identity]="{ ObjectID: request.creator }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 90%">
            <div
              fxFlex="none"
              style="
                cursor: pointer;
                color: lightgray;
                margin-top: 10px;
                margin-left: -10px;
              "
              (click)="onShowDetail(request)"
            >
              <mat-icon>{{
                request.showDetail
                  ? 'keyboard_arrow_down'
                  : 'keyboard_arrow_right'
              }}</mat-icon>
            </div>
            <div
              fxFlex="none"
              style="color: darkgray; font-size: 14px; margin-right: 10px"
            >
              {{ 'key_details' | translate }}
            </div>
            <div
              fxFlex="auto"
              style="background-color: lightgray; height: 2px"
            ></div>
          </div>
          <div *ngIf="request.showDetail" style="width: 90%">
            <div
              *ngIf="request.content; else noContent"
              style="
                width: 90%;
                margin-top: 5px;
                background-color: rgba(0, 0, 0, 0.05);
                padding: 5px;
                border-radius: 5px;
              "
              [innerHTML]="request.content"
            ></div>
            <ng-template #noContent>
              <div fxLayout="row" style="width: 90%; margin-top: 5px">
                <div fxFlex="33" style="font-weight: 400">
                  {{ 'key_attribute' | translate }}
                </div>
                <div fxFlex="22" style="font-weight: 400">
                  {{ 'key_action' | translate }}
                </div>
                <div fxFlex="45" style="font-weight: 400">
                  {{ 'key_value' | translate }}
                </div>
              </div>
              <div
                *ngFor="let parameter of request.requestParameters"
                fxLayout="row"
                style="width: 90%; margin-top: 5px"
              >
                <div fxFlex="33" [matTooltip]="parameter.attribute">
                  {{ parameter.attributeName }}
                </div>
                <div fxFlex="22">{{ parameter.action }}</div>

                <div [ngSwitch]="getParameterType(parameter)" fxFlex="45">
                  <app-identity-link
                    *ngSwitchCase="'guid'"
                    [identity]="{ ObjectID: parameter.value }"
                    [linkActions]="['sideView', 'navigate']"
                  ></app-identity-link>
                  <div *ngSwitchCase="'text'">{{ parameter.value }}</div>
                  <div
                    *ngSwitchCase="'bin'"
                    style="cursor: pointer"
                    (click)="onShowPhoto(parameter.value)"
                  >
                    <span class="material-icons-outlined">more_horiz</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center" style="width: 90%">
            <div
              fxFlex="none"
              style="
                cursor: pointer;
                color: lightgray;
                margin-top: 10px;
                margin-left: -10px;
              "
              (click)="onShowResponse(request)"
            >
              <mat-icon>{{
                request.showResponse
                  ? 'keyboard_arrow_down'
                  : 'keyboard_arrow_right'
              }}</mat-icon>
            </div>
            <div
              fxFlex="none"
              style="color: darkgray; font-size: 14px; margin-right: 10px"
            >
              {{ 'key_approvalSteps' | translate }}
            </div>
            <div
              fxFlex="auto"
              style="background-color: lightgray; height: 2px"
            ></div>
          </div>
          <div *ngIf="request.showResponse" style="width: 90%">
            <div *ngFor="let approval of request.approvals">
              <div
                fxLayout="column"
                style="
                  margin-top: 5px;
                  padding: 5px 10px;
                  background-color: rgba(0, 0, 0, 0.07);
                  border-radius: 5px;
                "
              >
                <div fxLayout="row" fxLayoutAlign="start center">
                  <div
                    fxFlex="none"
                    [ngStyle]="{
                      'background-color':
                        approval.status === 'Pending'
                          ? 'goldenrod'
                          : approval.status === 'Approved'
                          ? 'green'
                          : 'red'
                    }"
                    style="
                      height: 10px;
                      width: 10px;
                      border-radius: 5px;
                      margin-right: 10px;
                    "
                  ></div>
                  <div fxFlex="30">{{ approval.status }}</div>
                  <div
                    fxFlex="none"
                    style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
                  >
                    <mat-icon
                      [matTooltip]="'key_duration' | translate"
                      style="font-size: 20px; line-height: 36px"
                      >schedule</mat-icon
                    >
                  </div>
                  <div fxFlex="auto">{{ approval.duration }}</div>
                  <div
                    fxFlex="none"
                    style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
                  >
                    <mat-icon
                      fontSet="material-icons-outlined"
                      class="clipboard-icon"
                      [matTooltip]="'key_threshold' | translate"
                      style="
                        font-size: 20px;
                        line-height: 36px;
                        cursor: pointer;
                      "
                      (click)="approval.expanded = !approval.expanded"
                      >admin_panel_settings</mat-icon
                    >
                  </div>
                  <div fxFlex="30px">{{ approval.threshold }}</div>
                </div>
              </div>
              <div
                *ngIf="approval.expanded"
                fxlayout="row"
                fxLayoutAlign="end center"
              >
                <div
                  fxFlex="none"
                  style="
                    height: 5px;
                    width: 2px;
                    background-color: rgba(0, 0, 0, 0.07);
                  "
                ></div>
                <div fxFlex="50px"></div>
              </div>
              <div
                *ngIf="approval.expanded"
                fxLayout="row"
                fxLayoutAlign="end center"
              >
                <div fxFlex="auto"></div>
                <div
                  fxFlex="50"
                  style="
                    background-color: rgba(0, 0, 0, 0.07);
                    border-radius: 5px;
                    padding: 5px 10px;
                  "
                >
                  <div
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    *ngFor="let approver of approval.approver"
                  >
                    <mat-icon
                      [matTooltip]="'key_approver' | translate"
                      style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
                      >how_to_reg</mat-icon
                    >
                    <app-identity-link
                      [identity]="{ ObjectID: approver }"
                      [linkActions]="['sideView', 'navigate']"
                    ></app-identity-link>
                  </div>
                </div>
              </div>
              <div
                *ngIf="approval.expanded"
                fxlayout="row"
                fxLayoutAlign="end center"
              >
                <div
                  fxFlex="none"
                  style="
                    height: 10px;
                    width: 2px;
                    background-color: rgba(0, 0, 0, 0.07);
                  "
                ></div>
                <div fxFlex="50px"></div>
              </div>
              <div *ngFor="let response of approval.responses | async">
                <div fxLayout="row">
                  <div fxFlex="30px"></div>
                  <div
                    fxFlex="20px"
                    style="
                      height: 20px;
                      border-left: rgba(0, 0, 0, 0.07) solid 2px;
                      border-bottom: rgba(0, 0, 0, 0.07) solid 2px;
                    "
                  ></div>
                  <div
                    fxFlex="auto"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    style="
                      background-color: rgba(0, 0, 0, 0.07);
                      border-radius: 5px;
                      padding: 5px 10px;
                      margin-top: 2px;
                    "
                  >
                    <div
                      fxFlex="none"
                      [ngStyle]="{
                        'background-color':
                          response.decision === 'Approved' ? 'seagreen' : 'red'
                      }"
                      style="
                        height: 10px;
                        width: 10px;
                        border-radius: 5px;
                        margin-right: 10px;
                      "
                    ></div>
                    <div fxFlex="auto">
                      <app-identity-link
                        [identity]="{ ObjectID: response.creator }"
                        [linkActions]="['sideView', 'navigate']"
                      ></app-identity-link>
                    </div>
                    <div
                      fxFlex="100px"
                      fxLayout="row"
                      fxLayoutAlign="start center"
                    >
                      <div>
                        {{ response.decision }}
                      </div>
                      <div
                        *ngIf="response.reason"
                        [matTooltip]="response.reason"
                      >
                        <mat-icon
                          fontSet="material-icons-outlined"
                          class="clipboard-icon"
                          style="
                            line-height: 32px;
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.6);
                            margin-left: 10px;
                          "
                          >announcement</mat-icon
                        >
                      </div>
                    </div>
                    <div fxFlex="120px">{{ response.createdTime }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<ngx-ui-loader
  [loaderId]="localConfig.name"
  [fgsType]="spinnerType.rectangleBounce"
  [fgsSize]="40"
  [fgsColor]="'white'"
  [overlayColor]="'rgba(50,50,50,.5)'"
  [pbColor]="'white'"
></ngx-ui-loader>
