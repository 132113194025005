import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowActivityView } from 'src/app/core/models/dynamicEditor.interface';

@Component({
  selector: 'app-activity-if-then-else',
  templateUrl: './activity-if-then-else.component.html',
  styleUrls: ['./activity-if-then-else.component.scss'],
})
export class ActivityIfThenElseComponent extends WorkflowActivityView {
  condition: string;

  @Input()
  get ifCondition() {
    return this.condition;
  }
  set ifCondition(value: string) {
    this.condition = value;
    this.ifConditionChange.emit(this.condition);
  }
  @Output()
  ifConditionChange = new EventEmitter();

  constructor() {
    super();
  }
}
