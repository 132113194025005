import {
  Component,
  OnInit,
  Injector,
  forwardRef,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { ButtonEditorConfig } from '../../models/editorContract.model';
import { SwapService } from '../../services/swap.service';
import { of } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorButtonConfigComponent } from './editor-button-config.component';
import { tap, switchMap, finalize } from 'rxjs/operators';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { EditorEvent } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-button',
  templateUrl: './editor-button.component.html',
  styleUrls: ['./editor-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorButtonComponent),
      multi: true,
    },
  ],
})
export class EditorButtonComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit
{
  private conf = new ButtonEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  stringValue: string;

  get value() {
    return this.stringValue ? this.stringValue : '';
  }
  set value(value: string) {
    this.stringValue = value;
    if (this.editorAttribute) {
      this.editorAttribute.value = value;
    }
  }

  @Output()
  clickButton = new EventEmitter();

  constructor(
    injector: Injector,
    private swap: SwapService,
    private window: WindowService
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.applyConfig();
    });
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new ButtonEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    this.value = this.config.text;

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorButtonConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  onClick() {
    if (this.clickButton.observers.length > 0) {
      this.clickButton.emit();
    }

    this.swap.editorEvent(
      new EditorEvent(
        'click',
        this.config.attributeName,
        this.currentID,
        this.currentType
      )
    );
  }
}
