import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SPINNER } from 'ngx-ui-loader';
import { MatSidenav } from '@angular/material/sidenav';

import {
  AuthMode,
  BroadcastEvent,
  NotificationDef,
  PullNotification,
  Resource,
} from '../core/models/dataContract.model';

import { SwapService } from '../core/services/swap.service';
import { ResourceService } from '../core/services/resource.service';
import { UtilsService } from '../core/services/utils.service';
import { ConfigService } from '../core/services/config.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { EventDispatcherService } from '../services/event-dispatcher.service';
import { AuthService } from '../core/services/auth.service';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { WindowService } from '@progress/kendo-angular-dialog';
import { HtmlCardComponent } from '../core/components/html-card/html-card.component';
import { ModalService } from '../core/services/modal.service';
import { ModalType } from '../core/models/componentContract.model';
import { WorkflowStarterComponent } from '../core/components/workflow-starter/workflow-starter.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @ViewChild('sidenav') sidenav: MatSidenav;

  adminMode = false;

  showOverlay = false;
  overlayOpacity = 0.32;
  overlayCounter = 0;

  spinnerType = SPINNER;

  sidebarStyle: any = {
    position: 'relative',
    'z-index': '1000',
  };

  autoComplete = true;

  showBreadCrumb = true;

  helpLink: string;

  showScrollbar = true;

  sidenavWidthinPercent = 80;

  get canLaunchWorkflow(): boolean {
    return (
      this.auth.authMode === AuthMode.azure &&
      this.resource.inPermissionSets(
        this.config.getConfig('launchWorkflowPermission', [])
      )
    );
  }

  // private cancelSideView(url: string, counter: number) {
  //   const pos = url.indexOf('//sidenav:');
  //   if (pos < 0 || counter < 0) {
  //     return;
  //   } else {
  //     this.location.back();
  //     setTimeout(() => {
  //       this.cancelSideView(this.router.url, counter - 1);
  //     }, this.config.getConfig('intervalMiddle', 500));
  //   }
  // }

  private closeSideView() {
    if (this.sidenav) {
      this.sidenav.close();
      this.sidenavWidthinPercent = 0;
    }
  }

  constructor(
    private swap: SwapService,
    private resource: ResourceService,
    private utils: UtilsService,
    private config: ConfigService,
    private router: Router,
    private route: ActivatedRoute,
    private events: EventDispatcherService,
    private title: Title,
    private auth: AuthService,
    private window: WindowService,
    private modal: ModalService,
    private snackbar: MatSnackBar
  ) {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          tap((val: NavigationEnd) => {
            if (val && (val.url === '/app' || val.url === '/app/home')) {
              this.showScrollbar = false;
            } else {
              this.showScrollbar = true;
            }

            if (
              !this.resource.loginUserIsAdmin(
                this.config.getConfig('adminSetName', 'Administrators')
              ) &&
              this.resource.primaryViewSetting &&
              this.resource.primaryViewSetting.dashboard &&
              this.resource.primaryViewSetting.dashboard.components
            ) {
              const index =
                this.resource.primaryViewSetting.dashboard.components.findIndex(
                  (item: any) => {
                    return (
                      item.componentConfig &&
                      item.componentConfig.showAsModal === true
                    );
                  }
                );
              if (index >= 0) {
                this.swap.broadcast({
                  name: 'show-overlay',
                  parameter: 0.85,
                });

                const setting =
                  this.resource.primaryViewSetting.dashboard.components[index];
                const windowRef = this.window.open({
                  content: HtmlCardComponent,
                  width: setting.cols * 60 - 10, // setting.cols * 50 + (setting.cols - 1) * 10
                  height: setting.rows * 60 - 10, // setting.rows * 50 + (setting.rows - 1) * 10
                });
                const windowIns = windowRef.content.instance;
                windowIns.config = setting.componentConfig;
                windowIns.windowRef = windowRef;
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngOnInit() {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    if (favIcon) {
      favIcon.href = this.config.getConfigEx('images:favIcon', 'favicon.ico');
    }
    this.title.setTitle(this.config.getConfig('pageTitle', 'Idabus'));

    this.autoComplete = this.config.getConfig('simpleSearchAutoComplete', true);

    this.showBreadCrumb = this.config.getConfig('showBreadCrumb', true);

    this.helpLink = this.config.getConfig('helpLinkUrl', null);

    this.events.init();

    this.adminMode = this.resource.isAdminViewSet;

    const keepAliveDuration = this.config.getConfig(
      'keepAliveDuration',
      3600000
    );
    const keepAliveCheckPeriod = this.config.getConfig(
      'keepAliveCheckPeriod',
      1800000
    );

    // expiration check
    this.subscription.add(
      this.route.url
        .pipe(
          tap(() => {
            if (this.sidenav && this.sidenav.opened) {
              this.closeSideView();
            }
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.resource
        .startApiTimeRecording(keepAliveCheckPeriod)
        .pipe(
          tap((diffSec: number) => {
            if (
              keepAliveDuration > 10000 &&
              diffSec >= keepAliveDuration - 10000
            ) {
              if (this.router.url) {
                let actUrl = this.router.url;
                const sidenavPos = actUrl.indexOf('/sidenav:');
                if (sidenavPos > 0) {
                  actUrl = actUrl.substring(0, sidenavPos).replace('(', '');
                } else {
                  const pos = actUrl.indexOf('?');
                  actUrl = pos > 0 ? actUrl.substring(0, pos) : this.router.url;
                }
                this.resource.apiActivationUrl = actUrl;
              }
              this.router.navigate(['/expired']);
            }
          })
        )
        .subscribe()
    );

    // notification check
    const pullNotification: PullNotification = this.config.getConfig(
      'pullNotification',
      null
    );
    if (
      pullNotification &&
      pullNotification.interval > 0 &&
      pullNotification.entries &&
      pullNotification.entries.length > 0
    ) {
      const notificationBatch = [];
      const defs = pullNotification.entries.filter(
        (entry) => entry.enabled && entry.name && entry.query
      );
      if (defs.length > 0) {
        defs.forEach((def: NotificationDef) => {
          notificationBatch.push(
            def.isObject
              ? this.resource.getResourceByQuery(
                  def.query,
                  [],
                  0,
                  0,
                  false,
                  [],
                  false,
                  true,
                  '',
                  '',
                  true
                )
              : this.resource.getResourceCount(def.query, '', '', true)
          );
        });

        this.subscription.add(
          this.resource
            .startApiTimeRecording(
              pullNotification.interval >= 5000
                ? pullNotification.interval
                : 5000
            )
            .pipe(
              switchMap(() => {
                return forkJoin(notificationBatch);
              }),
              tap((result: Array<any>) => {
                for (const [index, value] of result.entries()) {
                  this.swap.broadcast({
                    name: 'pull-notification',
                    parameter: {
                      name: defs[index].name,
                      hideMessage: defs[index].hideMessage,
                      value,
                    },
                  });
                }
              })
            )
            .subscribe()
        );
      }
    }

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-viewset':
              this.adminMode = this.resource.isAdminViewSet;
              break;
            case 'history-data':
              {
                if (this.sidenav && event.parameter) {
                  const objectID: string = this.utils.ExtraValue(
                    event.parameter.identity,
                    'ObjectID'
                  );
                  const objectType: string = this.utils.ExtraValue(
                    event.parameter.identity,
                    'ObjectType'
                  );
                  this.sidenavWidthinPercent = 50;
                  this.sidenav.open();
                  this.router.navigate(
                    [
                      {
                        outlets: {
                          sidenav: [
                            'historicalstatus',
                            objectType,
                            objectID,
                            event.parameter.timestamp,
                            event.parameter.reportName,
                          ],
                        },
                      },
                    ],
                    {
                      relativeTo: this.route,
                    }
                  );
                }
              }
              break;
            case 'show-sidenav':
              {
                if (this.sidenav && event.parameter) {
                  const objectType: string = this.utils.ExtraValue(
                    event.parameter.identity,
                    'ObjectType'
                  );
                  const objectID: string = this.utils.ExtraValue(
                    event.parameter.identity,
                    'ObjectID'
                  );
                  const navKey = event.parameter.navigationKey;
                  this.sidenavWidthinPercent = 80;
                  this.sidenav.open();
                  if (event.parameter.noneForm === true) {
                    this.router.navigate(
                      [
                        {
                          outlets: {
                            sidenav: [
                              navKey ? navKey : objectType.toLowerCase(),
                              objectID,
                            ],
                          },
                        },
                      ],
                      {
                        relativeTo: this.route,
                        queryParams: { readonly: event.parameter.readOnly },
                      }
                    );
                  } else {
                    this.router.navigate(
                      [
                        {
                          outlets: {
                            sidenav: [
                              'form',
                              navKey ? navKey : objectType.toLowerCase(),
                              objectID,
                            ],
                          },
                        },
                      ],
                      {
                        relativeTo: this.route,
                        queryParams: { readonly: event.parameter.readOnly },
                      }
                    );
                  }
                }
              }
              break;
            case 'close-sidenav':
              {
                if (event.parameter && event.parameter.path) {
                  this.router
                    .navigate([{ outlets: { sidenav: null } }], {
                      relativeTo: this.route,
                    })
                    .then((navigationResult: boolean) => {
                      if (navigationResult) {
                        if (event.parameter.queryParams) {
                          this.router.navigate([event.parameter.path], {
                            queryParams: event.parameter.queryParams,
                          });
                        } else if (event.parameter.extras) {
                          this.router.navigate(
                            [event.parameter.path],
                            event.parameter.extras
                          );
                        } else {
                          this.router.navigate([event.parameter.path]);
                        }
                        this.closeSideView();
                      }
                    });
                } else {
                  this.router
                    .navigate([{ outlets: { sidenav: null } }], {
                      relativeTo: this.route,
                    })
                    .then((navigationResult: boolean) => {
                      if (navigationResult) {
                        this.closeSideView();
                      }
                    });
                }
              }
              break;
            case 'show-overlay':
              {
                this.overlayCounter++;
                this.showOverlay = true;
                this.overlayOpacity = 0.32;
                if (event.parameter) {
                  this.overlayOpacity = event.parameter;
                }
              }
              break;
            case 'hide-overlay':
              {
                this.overlayCounter--;
                if (this.overlayCounter === 0) {
                  this.showOverlay = false;
                }
              }
              break;
            case 'system-message':
              {
                this.snackbar.open(event.parameter, 'OK');
              }
              break;
            default:
              break;
          }
        }
      })
    );

    if (this.auth.authMode === AuthMode.azure) {
      this.subscription.add(this.resource.startRefreshToken(60000).subscribe());
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onEnterEditMode() {
    this.swap.editMode = true;
    this.swap.broadcast({ name: 'start-edit', parameter: null });

    this.subscription.add(
      this.resource
        .isUISettingsUpToDate()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            }
          })
        )
        .subscribe()
    );
  }

  onExitEditMode() {
    this.swap.editMode = false;
    this.swap.broadcast({ name: 'exit-edit', parameter: null });
  }

  onEditAdd() {
    this.swap.broadcast({ name: 'edit-add', parameter: null });
  }

  onEditSave() {
    this.swap.editMode = false;
    this.swap.broadcast({ name: 'edit-save', parameter: null });
  }

  onEditSetting() {
    this.swap.broadcast({ name: 'edit-setting', parameter: null });
  }

  onResourceSelected(resource: Resource) {
    this.utils.NavigateToIdentity(resource);
  }

  onSidebarResized(event: string) {
    const floatingSidebar: boolean = this.config.getConfig(
      'floatingSidebar',
      true
    );
    if (floatingSidebar) {
      if (event === 'expanded') {
        this.sidebarStyle = {
          position: 'absolute',
          height: '100%',
          'z-index': '1000',
        };
      } else {
        this.sidebarStyle = {
          position: 'relative',
          'z-index': '1000',
        };
      }
    }
  }

  onBackdropClicked() {
    // const pos = this.router.url.indexOf('/sidenav:');
    // if (pos > 0) {
    //   const targetPath = this.router.url.substring(pos + 9).replace(')', '');
    //   console.log(targetPath);
    // }
    this.router
      .navigate([{ outlets: { sidenav: null } }], {
        relativeTo: this.route,
      })
      .then((navigationResult: boolean) => {
        if (navigationResult) {
          if (this.sidenav) {
            setTimeout(() => {
              this.closeSideView();
            });
          }
        }
      });
  }

  onMaximizeClicked() {
    const pos = this.router.url.indexOf('/sidenav:');
    if (pos > 0) {
      let targetPath = this.router.url.substring(pos + 9).replace(')', '');
      const p = targetPath.indexOf('?');
      let readonly = false;
      if (p > 0) {
        const params = targetPath.substring(p + 1);
        targetPath = targetPath.substring(0, p);
        if (params.toLowerCase() === 'readonly=true') {
          readonly = true;
        }
      }
      this.utils.NavigateToRoute('app/' + targetPath, { readonly: readonly });
    }
  }

  onHelp() {
    window.open(this.helpLink, '_blank');
  }

  onLaunchWorkflow() {
    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: WorkflowStarterComponent,
      width: 800,
      top: 50,
    });

    this.subscription.add(
      windowRef.result
        .pipe(
          finalize(() => {
            this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
          })
        )
        .subscribe()
    );
  }
}
