<div
  fxLayout="column"
  [ngClass]="{
    'config-hidden': configMode && !showEditor(resource.rightSets),
    'edit-mode':
      !creationMode &&
      config.attributeName &&
      !config.attributeName.endsWith('#')
  }"
  style="width: 100%"
>
  <div class="label-attribute-name-normal" style="margin-bottom: 10px">
    {{ displayName | translate }}
  </div>
  <div
    fxLayout="column"
    fxLayoutGap="10px grid"
    style="font-size: 0.75em; font-weight: 400; color: rgba(0, 0, 0, 0.54)"
  >
    <div *ngIf="control && control.invalid; else validated" class="warn-color">
      {{ control.errors['message'] | translate }}
    </div>
    <ng-template #validated>
      <div
        fxFlex="none"
        *ngIf="description"
        class="label-attribute-desc"
        [matTooltip]="description | translate"
      >
        {{ description | translate | ellipsis: config.hintLimit }}
      </div>
      <div
        fxFlex="none"
        *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
        style="color: #d8bb2e"
      >
        <mat-icon
          [matTooltip]="'key_noWriteAccess' | translate"
          style="font-size: 14px"
          >error</mat-icon
        >
      </div>
      <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
        <mat-icon
          [matTooltip]="'key_noReadAccess' | translate"
          style="color: red; padding-top: 4px; font-size: 14px"
          >error</mat-icon
        >
      </div>
    </ng-template>
  </div>
  <div
    *ngIf="
      disabled(resource.rightSets) &&
        (!iterableValue || iterableValue.length === 0);
      else iterableFields
    "
    class="background-bg-color label-attribute-desc"
    style="margin-top: 10px; padding: 10px; font-size: 0.75em"
  >
    {{ 'key_noData' | translate }}
  </div>
  <ng-template #iterableFields>
    <button
      *ngIf="!disabled(resource.rightSets) && config.editable"
      mat-stroked-button
      color="primary"
      class="add-button"
      [disabled]="config.maxCount && iterableValue.length >= config.maxCount"
      [matTooltip]="tooltip"
      (click)="onAddValue()"
    >
      {{ 'key_add' | translate }}
    </button>
    <div *ngIf="iterableValue" style="margin-top: 5px">
      <form autocomplete="off" #iterablesForm="ngForm">
        <div
          [dragula]="
            config.iterableType === 'array' ? 'ITERABLESCOLUMNS' : null
          "
          [(dragulaModel)]="iterableValue"
        >
          <div
            *ngFor="let itValue of iterableValue; let i = index"
            class="background-bg-color"
            style="margin-bottom: 15px"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="10px grid"
              style="padding: 5px; font-size: 0.75em"
            >
              <div
                *ngIf="config.iterableType === 'array'"
                fxFlex="none"
                class="ithandle disabled-text-color"
                style="cursor: pointer"
              >
                <mat-icon style="line-height: 34px">menu</mat-icon>
              </div>
              <div
                fxFlex="auto"
                fxLayout="row wrap"
                fxLayoutGap="10px grid"
                fxLayoutAlign="start center"
              >
                <div
                  *ngFor="let property of config.properties"
                  [fxFlex]="property.width ?? 0"
                  [ngStyle]="{
                    'margin-top.px': property.validationKey ? -4 : 0,
                    display: property.isHidden ? 'none' : 'block'
                  }"
                >
                  <div [ngSwitch]="property.type">
                    <div *ngSwitchCase="'text'" style="width: 100%">
                      <app-editor-text
                        *ngIf="property.validationKey; else noValidationKey"
                        [name]="property.name + i"
                        [(ngModel)]="itValue[property.name]"
                        [config]="{
                          isSimpleValue: true,
                          hintLimit: config.hintLimit,
                          customDisplayName: property.displayName,
                          required: property.isKey || property.required,
                          readOnly: disabled(resource.rightSets),
                          validationKey: property.validationKey,
                          updateOn: modelUpdateOn
                        }"
                        appExpressionValidator
                        (valueChange)="onValueChange()"
                      ></app-editor-text>
                      <ng-template #noValidationKey>
                        <mat-form-field style="width: 100%">
                          <mat-label>{{
                            property.displayName | translate
                          }}</mat-label>
                          <input
                            matInput
                            #textInput
                            autocomplete="off"
                            [name]="property.name + i"
                            [(ngModel)]="itValue[property.name]"
                            [pattern]="property.validation"
                            [disabled]="disabled(resource.rightSets)"
                            (change)="onValueChange()"
                            (keyup)="onKeyUp(textInput)"
                            [required]="property.required"
                          />
                          <div
                            *ngIf="textInput.alt === 'invalid'"
                            style="
                              position: absolute;
                              top: 26px;
                              font-size: 0.75em;
                            "
                            class="warn-color"
                          >
                            invalid format
                          </div>
                        </mat-form-field>
                      </ng-template>
                    </div>
                    <mat-form-field *ngSwitchCase="'multi'" style="width: 100%">
                      <mat-label>{{
                        property.displayName | translate
                      }}</mat-label>
                      <input
                        matInput
                        #textInput
                        autocomplete="off"
                        [name]="property.name + i"
                        [(ngModel)]="itValue[property.name]"
                        [pattern]="property.validation"
                        [disabled]="disabled(resource.rightSets)"
                        (change)="onValueChange()"
                        (keyup)="onKeyUp(textInput)"
                        [required]="property.required"
                      />
                      <div
                        *ngIf="textInput.alt === 'invalid'"
                        style="position: absolute; top: 26px; font-size: 0.75em"
                        class="warn-color"
                      >
                        invalid format
                      </div>
                    </mat-form-field>
                    <mat-form-field
                      *ngSwitchCase="'select'"
                      style="width: 100%"
                    >
                      <mat-label>{{
                        property.displayName | translate
                      }}</mat-label>
                      <mat-select
                        [name]="property.name + i"
                        [(ngModel)]="itValue[property.name]"
                        [disabled]="disabled(resource.rightSets)"
                        (selectionChange)="onValueChange()"
                      >
                        <mat-option
                          *ngFor="let option of property.options"
                          [value]="option.value"
                        >
                          {{ option.text | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-checkbox
                      *ngSwitchCase="'boolean'"
                      style="width: 100%"
                      [name]="property.name + i"
                      [disabled]="disabled(resource.rightSets)"
                      [(ngModel)]="itValue[property.name]"
                      (change)="onValueChange()"
                      >{{ property.displayName | translate }}</mat-checkbox
                    >
                  </div>
                </div>
              </div>
              <span
                *ngIf="
                  !configMode &&
                  !disabled(resource.rightSets) &&
                  config.editable
                "
                fxFlex="none"
                class="material-icons warn-color"
                style="cursor: pointer; font-size: 20px"
                [matTooltip]="'key_remove' | translate"
                (click)="onRemoveValue(i)"
                >delete_outline</span
              >
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
