<div>
  <!-- <h3 mat-dialog-title cdkDragHandle>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="auto">
        {{ 'l10n_inviteB2BUsers' | translate }}
      </div>
      <button mat-icon-button mat-dialog-close="cancel" tabindex="-1">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </h3> -->
  <div mat-dialog-content>
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="first-dialog-item"
      [style.padding-left.px]="leftPadding"
    >
      <form style="margin: 0 10px 10px 10px;">
        <div
          fxLayout="row grid"
          fxLayoutAlign="start center"
          fxLayoutGap="20px"
          style="width: 100%;"
        >
          <mat-form-field fxFlex="100">
            <input
              #txtEmail
              matInput
              [formControl]="fcMail"
              [placeholder]="'l10n_email' | translate"
            />
          </mat-form-field>
          <mat-form-field fxFlex="auto">
            <input
              matInput
              name="displayName"
              [placeholder]="'l10n_displayName' | translate"
              [(ngModel)]="invitedDisplayName"
            />
          </mat-form-field>
          <app-editor-identity
            #editor
            name="idpManager"
            [creationMode]="true"
            [ngModel]="invitationManager"
            [config]="idpConfig"
          ></app-editor-identity>
          <button
            fxFlex="none"
            mat-raised-button
            [disabled]="!fcMail.value || fcMail.invalid"
            (click)="onAddInvitedUser()"
          >
            {{ 'key_add' | translate }}
          </button>
        </div>
      </form>
      <div
        fxFelx="none"
        style="margin: 20px 0; max-height: 500px; overflow-y: auto;"
      >
        <mat-card
          *ngFor="let invitedUser of invitedUsers"
          style="
            border-left: solid darkseagreen 5px;
            margin-right: 5px;
            padding: 12px;
            margin-bottom: 10px;
          "
        >
          <mat-card-header fxLayout="row wrap" fxLayoutAlign="start center">
            <!-- <div fxFlex="none" class="handle" (click)="onToggleColumnDisplay(column)"
              style="width: 36px; margin-left: -20px; cursor: pointer; color: lightgray;">
              <mat-icon>menu</mat-icon>
            </div> -->
            <div fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="start center">
              <div fxFlex="auto" style="font-weight: 400;">
                {{ invitedUser.displayName }}
              </div>
              <button
                fxFlex="none"
                mat-icon-button
                color="warn"
                matTooltip="{{ 'key_delete' | translate }}"
                style="height: 24px; width: 24px; line-height: 24px;"
                (click)="onRemoveInvitedUser(invitedUser.email)"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <div
              fxFlex="1 0 100%"
              fxLayout="row"
              fxLayoutAlign="start center"
              style="font-size: 13px; color: darkgray;"
            >
              <mat-icon
                fxFlex="none"
                style="
                  font-size: 14px;
                  height: 14px;
                  width: 14px;
                  line-height: 14px;
                  margin-right: 10px;
                "
                >mail</mat-icon
              >
              <div fxFlex="40" style="margin-right: 20px;">
                {{ invitedUser.email }}
              </div>
              <mat-icon
                *ngIf="invitedUser.sponsorName"
                fxFlex="none"
                style="
                  font-size: 14px;
                  height: 14px;
                  width: 14px;
                  line-height: 14px;
                  margin-right: 10px;
                "
                >account_circle</mat-icon
              >
              <div *ngIf="invitedUser.sponsorName" fxFlex="40">
                {{ invitedUser.sponsorName }}
              </div>
            </div>
          </mat-card-header>
          <!-- <mat-card-content *ngIf="column.display" fxLayout="column" style="margin-top: 10px; font-size: 12px;">
          </mat-card-content> -->
        </mat-card>
      </div>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    [style.padding-left.px]="leftPadding"
    style="margin-top: -10px;"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [disabled]="!invitedUsers || invitedUsers.length === 0"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'l10n_inviteB2BUsers'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
