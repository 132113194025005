<div style="height: 100%; display: block">
  <!-- Loading Spinner -->
  <div
    ngxUiLoaderBlurred
    [blur]="blurLevel"
    loaderId="spinner_home"
    style="height: 100%; display: block"
  >
    <div *ngIf="errorMessage; else noError">
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
        style="height: 90%; margin-top: 20px"
      >
        <mat-card style="margin-top: 100px; width: 600px">
          <mat-card-content>
            <div
              fxLayout="column"
              fxLayoutAlign="start center"
              class="accent-text-color"
            >
              <div
                style="
                  font-size: 26px;
                  text-align: center;
                  margin-top: 20px;
                  margin-bottom: 50px;
                "
              >
                {{ 'key_wentWrong' | translate }}
              </div>
              <mat-icon
                fxFlex="none"
                style="font-size: 200px; margin-left: -200px"
                >web</mat-icon
              >
              <mat-icon
                fxFlex="none"
                style="font-size: 80px; margin-left: 160px; margin-top: -50px"
                >error_outline</mat-icon
              >
              <div
                fxFlex="none"
                fxLayout="row"
                fxLayoutAlign="center center"
                style="margin-top: 220px; width: 100%"
              >
                <div
                  *ngIf="errorMessage"
                  style="
                    margin-top: 16px;
                    padding: 6px 10px;
                    color: white;
                    border-radius: 4px;
                  "
                  class="warn-bg-color"
                >
                  {{ errorMessage | translate }}
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <ng-template #noError>
      <div fxLayout="row wrap" fxLayoutGap="10px grid" style="height: 100%">
        <!-- Brand/Structure View -->
        <div
          *ngIf="showBrand; else sidenavBrand"
          [fxFlex]="brandWidth"
          fxFlexOrder="2"
          fxFlex.lt-md="100"
          fxFlexOrder.lt-md="1"
        >
          <div fxLayout="column" fxLayout.lt-md="row" fxLayoutGap="10px grid">
            <app-brand-view
              *ngIf="
                uiSetting && uiSetting.brandView && uiSetting.brandView.enabled
              "
              fxFlex="auto"
              [options]="{
                attributes: uiSetting.brandView.attributes,
                mainAttribute: uiSetting.brandView.mainAttribute,
                photoAttribute: uiSetting.brandView.photoAttribute,
                secondaryAttribute: uiSetting.brandView.secondaryAttribute,
                showButtons: uiSetting.brandView.showButtons,
                useCustomComponent: uiSetting.brandView.useCustomComponent,
                customComponentID: uiSetting.brandView.customComponentID,
                customComponentData: uiSetting.brandView.customComponentData
              }"
            >
            </app-brand-view>
            <app-structure-view
              *ngIf="
                uiSetting &&
                uiSetting.structureView &&
                uiSetting.structureView.enabled
              "
              fxFlex="auto"
              [initQuery]="uiSetting.structureView.initQuery"
              [childrenQuery]="
                uiSetting.structureView.childrenQuery ?? defaultChildrenQuery
              "
            ></app-structure-view>
          </div>
        </div>

        <ng-template #sidenavBrand>
          <div style="width: 100%; position: absolute; z-index: 100">
            <app-brand-view
              *ngIf="
                uiSetting && uiSetting.brandView && uiSetting.brandView.enabled
              "
              fxFlex="auto"
              [iconMode]="true"
              [options]="{
                attributes: uiSetting.brandView.attributes,
                mainAttribute: uiSetting.brandView.mainAttribute,
                photoAttribute: uiSetting.brandView.photoAttribute,
                secondaryAttribute: uiSetting.brandView.secondaryAttribute,
                showButtons: uiSetting.brandView.showButtons,
                useCustomComponent: uiSetting.brandView.useCustomComponent,
                customComponentID: uiSetting.brandView.customComponentID,
                customComponentData: uiSetting.brandView.customComponentData,
                menuPositionX: 'before'
              }"
            >
            </app-brand-view>
          </div>
        </ng-template>

        <!-- Attribute Views -->
        <div
          [fxFlex]="detailWidth"
          fxFlexOrder="1"
          fxFlex.lt-md="100"
          fxFlexOrder.lt-md="2"
        >
          <!-- Tab View -->
          <app-tab-view
            #tabView
            *ngIf="viewSetting && viewSetting.type === 'tab'"
            [formName]="uiType"
            [icon]="viewSetting.icon ? viewSetting.icon : 'payment'"
            [(tabDefs)]="viewSetting.sections"
            [configMode]="configMode"
            [(editorResults)]="viewResults"
            [addtionalTabAttributes]="
              viewSetting.attributesToLoad
                ? viewSetting.attributesToLoad
                : ['DisplayName']
            "
            [statuses]="uiSetting.statuses"
          >
          </app-tab-view>

          <!-- Grid View -->
          <app-gridster-view
            #gridView
            *ngIf="viewSetting && viewSetting.type === 'gridster'"
            [(gridsterDefs)]="viewSetting.sections"
            [configMode]="configMode"
            [(editorResults)]="viewResults"
          >
          </app-gridster-view>
        </div>
      </div>
    </ng-template>
  </div>
</div>
