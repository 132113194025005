<div
  fxFlex="auto"
  *ngIf="configMode || showEditor(resource.rightSets)"
  [ngClass]="{ 'config-hidden': configMode && !showEditor(resource.rightSets) }"
  style="width: 100%"
>
  <div
    fxLayout="column"
    [style.backgroundColor]="config.backgroundColor"
    [style.borderStyle]="config.borderStyle"
    [style.borderRadius.px]="config.borderRadius"
    [style.borderWidth.px]="config.borderWidth"
    [style.borderColor]="config.borderColor"
    [style.marginTop.px]="config.marginTop"
    [style.marginBottom.px]="config.marginBottom"
    [style.paddingLeft.px]="config.paddingLeft"
    [style.paddingRight.px]="config.paddingRight"
    [style.paddingTop.px]="config.paddingTop"
    [style.paddingBottom.px]="config.paddingBottom"
    style="width: 100%"
  >
    <div
      *ngIf="!componentItem"
      fxLayout="column"
      fxLayoutAlign="center center"
      style="width: 100%"
    >
      <mat-icon
        fxFlex="none"
        style="font-size: 100px; width: 100px; color: #cccccc"
        >widgets</mat-icon
      >
      <div
        fxFlex="none"
        style="
          margin-top: 50px;
          padding-top: 50px;
          font-size: 18px;
          color: #b0b0b0;
        "
      >
        {{ 'key_noComponentToLoad' | translate }}
      </div>
    </div>
    <ng-container appDynamicContainer></ng-container>
  </div>
</div>
