import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { ConfigService } from '../../services/config.service';
import {
  IdentityEditorConfig,
  XpathEditorConfig,
} from '../../models/editorContract.model';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';
import { NgForm } from '@angular/forms';
import { EMPTY, Subscription } from 'rxjs';
import { ResourceService } from '../../services/resource.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EditorXpathComponent } from '../editor-xpath/editor-xpath.component';

@Component({
  selector: 'app-workflow-starter',
  templateUrl: './workflow-starter.component.html',
  styleUrls: ['./workflow-starter.component.scss'],
})
export class WorkflowStarterComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  // empty (async) or sync
  private executionMode = '';
  // empty (with ids) or xpath
  private executionMethod = '';
  private executionIDs: Array<string> = [];
  private executionXPath = '';
  private executionWorkflow = '';

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @ViewChild('targetForm') targetForm: NgForm;

  @ViewChild('xpbTarget') xpbTarget: EditorXpathComponent;

  @Input()
  hideTarget = false;

  @Input()
  hideWorkflow = false;

  @Input()
  launchTargetIDs: Array<string> = [];

  @Input()
  launchTargetXpath = '';

  @Input()
  launchWorkflowID = '';

  @Input()
  needConfirmation = true;

  step = 0;

  targetType = 'xpb';
  launchWorkflowWithXPath = true;
  launchMode = 'async';
  launchDisabled = false;

  errorMsg = '';
  showConfirmation = false;
  showResult = false;
  showLoading = false;
  resultEventID = '';

  xpbAttribute = {
    dataType: 'String',
    displayName: 'key_target',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'xpbAttribute',
    value: null,
    values: [],
  };

  idpAttribute = {
    dataType: 'Reference',
    displayName: 'key_target',
    multivalued: true,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'idpAttribute',
    value: null,
    values: [],
  };

  idpWorkflow = {
    dataType: 'Reference',
    displayName: 'key_workflow',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'idpWorkflow',
    value: null,
    values: [],
  };

  idpConfig: IdentityEditorConfig;
  idpWorkflowConfig: IdentityEditorConfig;
  xpbConfig: XpathEditorConfig;

  constructor(
    private windowRef: WindowRef,
    private config: ConfigService,
    private swap: SwapService,
    private utils: UtilsService,
    private resource: ResourceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.hideTarget) {
      if (this.hideWorkflow) {
        this.step = 2;
      } else {
        this.step = 1;
      }
    } else {
      this.step = 0;
    }

    this.launchWorkflowWithXPath = this.config.getConfig(
      'launchWorkflowWithXPath',
      true
    );
    this.targetType = this.launchWorkflowWithXPath ? 'xpb' : 'idp';

    this.idpConfig = {
      isMultivalue: true,
      lettersToTrigger: this.config.getConfig('lettersToTriggerSearch', 3),
      queryNormalSearch: `/*[starts-with(DisplayName,'%SearchText%')]`,
      allowEmptySearch: true,
      browserShowTypePicker: true,
      browserDefaultType: 'Person',
      attributesToShow: [
        {
          field: 'DisplayName',
          sortable: true,
          filterable: true,
          filter: 'text',
        },
        {
          field: 'ObjectType',
          sortable: true,
        },
      ],
    };

    const tagName = this.config.getConfig('executableWorkflowTag', '');
    this.idpWorkflowConfig = {
      isMultivalue: false,
      lettersToTrigger: this.config.getConfig('lettersToTriggerSearch', 3),
      queryNormalSearch: tagName
        ? `/workflow[tags='${tagName}' and starts-with(DisplayName,'%SearchText%')]`
        : `/workflow[starts-with(DisplayName,'%SearchText%')]`,
      allowEmptySearch: true,
      queryEmptySearch: tagName ? `/workflow[tags='${tagName}']` : `/workflow`,
      attributesToShow: [
        {
          field: 'DisplayName',
          sortable: true,
          filterable: true,
          filter: 'text',
        },
        {
          field: 'ObjectType',
          sortable: true,
        },
      ],
    };

    this.xpbConfig = {
      showDisplayName: false,
      showDescription: false,
      showQuery: false,
      displayMode: 'builder',
      showAttributePicker: false,
      tableConfig: {
        tableHeight: 160,
        fontSize: 12,
        linkDisabled: true,
        exportToExcel: false,
        exportToClipBoard: false,
      },
    };
  }

  ngAfterViewInit(): void {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }

    if (this.targetForm) {
      this.subscription.add(
        this.targetForm.statusChanges.subscribe((value: string) => {
          if (value === 'VALID') {
            this.launchDisabled = false;
          } else if (value === 'INVALID') {
            this.launchDisabled = true;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onOpenIdentityBrowser() {
    if (this.windowRef) {
      this.windowRef.window.location.nativeElement.style.display = 'none';
    }
  }

  onCloseIdentityBrowser() {
    if (this.windowRef) {
      this.windowRef.window.location.nativeElement.style.display = 'inherit';
    }
  }

  onNextStep() {
    if (this.hideWorkflow && this.step === 0) {
      this.step += 2;
    } else {
      this.step++;
    }
  }

  onSetStep(index: number) {
    if (index !== 2) {
      this.errorMsg = '';
    }
    this.step = index;
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onLaunchWorkflow() {
    this.errorMsg = '';
    this.showConfirmation = false;
    this.showResult = false;
    this.showLoading = false;
    this.resultEventID = '';

    // empty (async) or sync
    this.executionMode = this.launchMode === 'sync' ? 'sync' : '';
    // empty (with ids) or xpath
    this.executionMethod = '';
    this.executionIDs = [];
    this.executionXPath = '';
    this.executionWorkflow = '';

    let resolveTarget = false;

    // check target
    if (this.launchTargetIDs.length > 0) {
      this.executionIDs = this.launchTargetIDs;
    } else {
      if (this.targetType === 'xpb') {
        this.executionMethod = 'xpath';
        if (this.launchTargetXpath) {
          this.executionXPath = this.launchTargetXpath;
        } else if (this.xpbAttribute.value) {
          resolveTarget = true;
        } else {
          this.errorMsg = 'key_errorNoTarget';
        }
      } else if (this.targetType === 'idp') {
        if (this.idpAttribute.values && this.idpAttribute.values.length > 0) {
          this.executionIDs = this.idpAttribute.values.map((attr: any) => {
            return this.utils.ExtraValue(attr, 'ObjectID');
          });
        } else {
          this.errorMsg = 'key_errorNoTarget';
        }
      }
    }

    // check workflow
    if (this.launchWorkflowID) {
      this.executionWorkflow = this.launchWorkflowID;
    } else if (this.idpWorkflow.value) {
      this.executionWorkflow = this.utils.ExtraValue(
        this.idpWorkflow.value,
        'ObjectID'
      );
    } else {
      this.errorMsg = 'key_errorNoWorkflow';
    }

    if (this.xpbTarget && resolveTarget) {
      this.subscription.add(
        this.xpbTarget
          .onChangeObs()
          .pipe(
            catchError(() => {
              this.errorMsg = 'xpath cannot be resolved';
              return EMPTY;
            }),
            finalize(() => {
              this.executionXPath = this.xpbAttribute.value;
              if (!this.errorMsg) {
                if (this.needConfirmation) {
                  this.showConfirmation = true;
                } else {
                  this.onConfirmLaunch();
                }
              }
            })
          )
          .subscribe()
      );
    } else {
      if (!this.errorMsg) {
        if (this.needConfirmation) {
          this.showConfirmation = true;
        } else {
          this.onConfirmLaunch();
        }
      }
    }
  }

  onConfirmLaunch() {
    this.launchDisabled = true;
    this.showConfirmation = false;

    this.showLoading = this.launchMode === 'sync';

    this.subscription.add(
      this.resource
        .executeWorkflow(
          this.executionMethod,
          this.executionMode,
          this.executionIDs,
          this.executionXPath,
          this.executionWorkflow
        )
        .pipe(
          tap((result: any) => {
            if (typeof result === 'string') {
              this.resultEventID = result;
              this.showResult = true;
            } else {
              if (result.requestEventStatus === 'Success') {
                this.resultEventID = result.requestEventId;
                this.showResult = true;
              } else {
                console.log(result);
              }
            }
          }),
          catchError((err: any) => {
            this.errorMsg = err.error;
            return EMPTY;
          }),
          finalize(() => {
            this.launchDisabled = false;
            this.showLoading = false;
          })
        )
        .subscribe()
    );
  }

  onCancelLaunch() {
    this.showConfirmation = false;
  }

  onNavigateToEvent() {
    if (this.resultEventID) {
      this.utils.NavigateToRoute(`app/event/${this.resultEventID}`);
    }
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onNavigateToEventView() {
    this.router.navigate(['app/eventlist']);
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onSendToBackground() {
    this.showLoading = false;
  }
}
