import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { System } from '../core/models/dataContract.model';
import { ConfigService } from '../core/services/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  particlesStyle = {
    position: 'fixed',
    width: '100%',
    height: '100%',
    'z-index': -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: '#0f5b7d',
  };
  particlesParams = {
    particles: {
      number: {
        value: 6,
        density: {
          enable: true,
          value_area: 800,
        },
      },
      color: {
        value: '#1b1e34',
      },
      shape: {
        type: 'polygon',
        stroke: {
          width: 0,
          color: '#000',
        },
        polygon: {
          nb_sides: 6,
        },
      },
      opacity: {
        value: 0.3,
        random: true,
        anim: {
          enable: false,
          speed: 1,
          opacity_min: 0.1,
          sync: false,
        },
      },
      size: {
        value: 100,
        random: false,
        anim: {
          enable: true,
          speed: 10,
          size_min: 40,
          sync: false,
        },
      },
      line_linked: {
        enable: false,
        distance: 200,
        color: '#ffffff',
        opacity: 1,
        width: 2,
      },
      move: {
        enable: true,
        speed: 2,
        direction: 'none',
        random: false,
        straight: false,
        out_mode: 'out',
        bounce: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
    },
    interactivity: {
      detect_on: 'canvas',
      events: {
        onhover: {
          enable: false,
          mode: 'grab',
        },
        onclick: {
          enable: false,
          mode: 'push',
        },
        resize: true,
      },
      modes: {
        grab: {
          distance: 400,
          line_linked: {
            opacity: 1,
          },
        },
        bubble: {
          distance: 400,
          size: 40,
          duration: 2,
          opacity: 8,
          speed: 3,
        },
        repulse: {
          distance: 200,
          duration: 0.4,
        },
        push: {
          particles_nb: 4,
        },
        remove: {
          particles_nb: 2,
        },
      },
    },
    retina_detect: true,
  };
  particlesHeight = 100;
  particlesWidth = 100;

  bgImage: string;
  bgFilterOpacity: number;

  systemSetting: any;
  systems: Array<System> = [];
  showSystems = true;
  loginMode = 'mix';

  constructor(private config: ConfigService, private router: Router) {}

  ngOnInit() {
    if (!this.config.isLoaded) {
      this.router.navigate(['/']);
      return;
    }

    this.bgImage = this.config.getConfigEx(
      'images:bgLogin',
      'assets/img/img-identity.jpg'
    );
    if (this.bgImage.indexOf('/') >= 0) {
      this.bgImage = `url(${this.bgImage})`;
    }

    this.bgFilterOpacity = this.config.getConfigEx(
      'images:bgLoginFilterOpacity',
      0.6
    );

    this.systemSetting = this.config.getConfig('systems', undefined);

    if (!this.systemSetting) {
      this.showSystems = false;
      this.loginMode = 'mix';
      this.systems = [];
    } else {
      this.showSystems = this.systemSetting.enabled ?? false;
      this.loginMode = this.systemSetting.loginMode;
      this.systems = this.systemSetting.items;
    }
  }
}
