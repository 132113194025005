import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  ApprovalCloudRequest,
  BroadcastEvent,
  PopupConfig,
} from '../../models/dataContract.model';
import { ConfigService } from '../../services/config.service';
import { ModalService } from '../../services/modal.service';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-approval-info',
  templateUrl: './approval-info.component.html',
  styleUrls: ['./approval-info.component.scss'],
})
export class ApprovalInfoComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input()
  request: ApprovalCloudRequest;

  translationTrigger: number;

  private getAssginmentType(attributeName: string, value: string): string {
    if (
      attributeName &&
      attributeName.toLowerCase() !== 'objectid' &&
      this.utils.IsGuid(value)
    ) {
      return 'guid';
    }

    const photoAttributes: string[] = this.configService.getConfig(
      'photoAttributes',
      ['Photo']
    );
    if (
      photoAttributes &&
      attributeName &&
      value &&
      photoAttributes.findIndex(
        (attribute: string) =>
          attribute.toLowerCase() === attributeName.toLowerCase()
      ) >= 0
    ) {
      return 'bin';
    }

    return 'text';
  }

  private buildAttributeAssignments(request: ApprovalCloudRequest) {
    let assignmentArray: Array<{
      attribute: string;
      type: string;
      action: string;
      oldValue: string;
      newValue: string;
    }> = [];

    if (request.attributeAssignments) {
      Object.keys(request.attributeAssignments).forEach((key: string) => {
        assignmentArray.push({
          attribute: key,
          action: 'key_modify',
          type: this.getAssginmentType(key, request.attributeAssignments[key]),
          oldValue: request.previousAttributeAssignments
            ? request.previousAttributeAssignments[key]
            : '',
          newValue: request.attributeAssignments[key],
        });
      });
    }

    if (request.multivalueInsertions) {
      Object.keys(request.multivalueInsertions).forEach((key: string) => {
        if (
          request.multivalueInsertions[key] &&
          request.multivalueInsertions[key].length > 0
        ) {
          request.multivalueInsertions[key].forEach((value: string) => {
            assignmentArray.push({
              attribute: key,
              action: 'key_add',
              type: this.getAssginmentType(key, value),
              oldValue: null,
              newValue: value,
            });
          });
        }
      });
    }

    if (request.multivalueRemovals) {
      Object.keys(request.multivalueRemovals).forEach((key: string) => {
        if (
          request.multivalueRemovals[key] &&
          request.multivalueRemovals[key].length > 0
        ) {
          request.multivalueRemovals[key].forEach((value: string) => {
            assignmentArray.push({
              attribute: key,
              action: 'key_remove',
              type: this.getAssginmentType(key, value),
              oldValue: null,
              newValue: value,
            });
          });
        }
      });
    }

    assignmentArray = assignmentArray.sort((a, b) => {
      if (a.attribute > b.attribute) {
        return 1;
      } else if (a.attribute < b.attribute) {
        return -1;
      }
      return 0;
    });

    request.attributeChanges = assignmentArray;
  }

  constructor(
    private utils: UtilsService,
    private configService: ConfigService,
    private modal: ModalService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    if (this.request && this.request.showDetail) {
      this.buildAttributeAssignments(this.request);
    }

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-language':
              setTimeout(() => {
                this.translationTrigger = Math.random() * 10;
              });
              break;
            default:
              break;
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onShowDetail() {
    console.log(this.request);
    if (this.request) {
      this.request.showDetail = !this.request.showDetail;

      if (this.request.showDetail) {
        this.buildAttributeAssignments(this.request);
      }
    }
  }

  onShowResponse() {
    if (this.request) {
      this.request.showResponse = !this.request.showResponse;
    }
  }

  onShowPhoto(data: string) {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.noButton = true;
    popupConfig.data = {
      photo: {
        value: data,
        type: 'image',
      },
    };
    this.subscription.add(this.modal.popup(popupConfig).subscribe());
  }
}
