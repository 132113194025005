import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Resource } from 'src/app/core/models/dataContract.model';
import { IdentityEditorConfig } from 'src/app/core/models/editorContract.model';
import { ResourceService } from 'src/app/core/services/resource.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { EditorIdentityComponent } from '../../editor-identity/editor-identity.component';

@Component({
  selector: 'app-activity-template',
  templateUrl: './activity-template.component.html',
  styleUrls: ['./activity-template.component.scss'],
})
export class ActivityTemplateComponent implements AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  @ViewChild('idpWorkflowTemplate')
  idpWorkflowTemplate: EditorIdentityComponent;

  templateId: string;
  parameters: any;

  @Input()
  get workflowTemplateId() {
    return this.templateId;
  }
  set workflowTemplateId(value) {
    this.templateId = value;
    this.workflowTemplateIdChange.emit(this.templateId);
  }
  @Output()
  workflowTemplateIdChange = new EventEmitter();

  @Input()
  get inputParameters() {
    return this.parameters;
  }
  set inputParameters(value) {
    this.parameters = value;
    this.inputParametersChange.emit(this.parameters);
  }
  @Output()
  inputParametersChange = new EventEmitter();

  targetTemplate = {
    dataType: 'Reference',
    displayName: 'key_workflowTemplateId',
    description: '',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'TargetTemplate',
    value: null,
  };

  rdpConfig: IdentityEditorConfig = {
    showPhoto: true,
    popupWidth: 460,
    queryNormalSearch: `/WorkflowTemplate[starts-with(DisplayName,'%SearchText%')]`,
    allowEmptySearch: true,
    queryEmptySearch: '/WorkflowTemplate',
    attributesToShow: [
      {
        field: 'DisplayName',
        sortable: true,
        filterable: true,
        filter: 'text',
        width: 0,
      },
    ],
  };

  parameterConfig: Array<any> = [];

  private buildParameterConfig(conf: any) {
    if (conf) {
      for (const [key, value] of Object.entries(conf)) {
        value['paramKey'] = key;

        // value['paramValue'] = null;
        value['paramValue'] =
          this.inputParameters && this.inputParameters[key]
            ? this.inputParameters[key]
            : null;

        this.parameterConfig.push(value);
      }

      this.buildValue();
    }
  }

  private buildValue() {
    const resultValue = {};
    this.parameterConfig.forEach((config) => {
      resultValue[config.paramKey] = config.paramValue;
    });
    this.inputParameters = resultValue;
  }

  private getTemplateInfo(id: string) {
    this.subscription.add(
      this.resource
        .getResourceByID(id, ['displayname', 'inputparameters'])
        .pipe(
          tap((result: Resource) => {
            this.buildParameterConfig(
              this.utils.ExtraValue(result, 'inputparameters')
            );
          })
        )
        .subscribe()
    );
  }

  constructor(private utils: UtilsService, private resource: ResourceService) {}

  ngAfterViewInit(): void {
    if (this.workflowTemplateId && this.idpWorkflowTemplate) {
      this.idpWorkflowTemplate.value = this.workflowTemplateId;
      this.getTemplateInfo(this.workflowTemplateId);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onTemplateChange(template: Array<any>) {
    this.parameterConfig = [];
    this.inputParameters = null;

    if (template && template.length === 1) {
      this.workflowTemplateId = this.utils.ExtraValue(template[0], 'ObjectID');
    } else {
      this.workflowTemplateId = null;
    }

    // if (template && !this.utils.ExtraValue(template, 'ObjectType')) {
    //   this.workflowTemplateId = this.utils.ExtraValue(template, 'ObjectID');
    // } else {
    //   this.workflowTemplateId = this.targetTemplate.value
    //     ? this.utils.ExtraValue(this.targetTemplate.value, 'ObjectID')
    //     : null;
    // }

    if (this.workflowTemplateId) {
      this.getTemplateInfo(this.workflowTemplateId);
    }
  }

  onValueChange() {
    this.buildValue();
  }
}
