import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { Subscription } from 'rxjs';

import {
  ResourceTableConfig,
  ResourceColumnConfig,
} from '../core/models/componentContract.model';
import {
  SearchScope,
  BroadcastEvent,
  AttributeResource,
  MenuEvent,
} from '../core/models/dataContract.model';
import { TransService } from '../core/models/translation.model';

import { ConfigService } from '../core/services/config.service';
import { UtilsService } from '../core/services/utils.service';
import { SwapService } from '../core/services/swap.service';

import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';

@Component({
  selector: 'app-resource-list',
  templateUrl: './resource-list.component.html',
  styleUrls: ['./resource-list.component.scss'],
})
export class ResourceListComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription = new Subscription();

  private urlParams: Params;

  @ViewChild('searchBox')
  searchBox: ElementRef;

  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  @ViewChild('actionMenu')
  actionMenu: ActionMenuComponent;

  searchText = '';
  searchSkip: number;
  objectType: string;
  skip: number;

  showDetail = false;
  noConfig = false;

  tableConfig: ResourceTableConfig;
  searchScope: SearchScope;

  availableAttributes: Array<string> = [];
  selectedAttributes: Array<string> = [];

  autoComplete = this.config.getConfig('simpleSearchAutoComplete', true);
  lettersToTrigger: number = this.config.getConfig('lettersToTriggerSearch', 3);

  get selectedItems() {
    if (this.resourceTable) {
      return this.resourceTable.selection.length > 0
        ? this.resourceTable.selection
        : undefined;
    } else {
      return undefined;
    }
  }

  get canSearch() {
    const searchDef = this.utils.GetSearchDef(
      this.searchText,
      this.lettersToTrigger
    );
    return searchDef ? true : false;
  }

  constructor(
    private config: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TransService,
    private utils: UtilsService,
    private swap: SwapService
  ) {}

  ngOnInit() {
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.cellPadding = 5;
    this.tableConfig.checkboxSelectOnly = false;
    this.tableConfig.selectMode = 'multiple';
    this.tableConfig.selectBoxWidth = 8;
    this.tableConfig.resizable = true;
    this.tableConfig.pageSize = this.config.getConfig('pageSize', 50);
    this.tableConfig.linkActions = this.config.getConfig('linkActions', [
      'navigate',
      'sideView',
    ]);
    this.tableConfig.defaultLinkAction = this.config.getConfig(
      'defaultLinkAction',
      'navigate'
    );

    this.subscription.add(
      this.route.queryParams.subscribe((params) => {
        this.urlParams = params;
        if (params.search) {
          this.searchText = params.search;
        }
        const name = this.route.snapshot.paramMap.get('type');
        if (name) {
          const scope: Array<SearchScope> =
            this.config.getConfig('searchScopes');
          if (scope) {
            this.searchScope = scope.find(
              (s) => s.name.toLowerCase() === name.toLowerCase()
            );
            if (this.searchScope) {
              this.objectType = this.searchScope.type;
            } else {
              this.objectType = name;
              this.searchScope = new SearchScope({
                name,
                type: name,
                text: name,
                icon: 'fingerprint',
                query: `/${name}[starts-with(DisplayName,'%SearchText%')]`,
                exactQuery: `/${name}[DisplayName='%SearchText%']`,
                attributes: [
                  'DisplayName',
                  'ObjectType',
                  'CreatedTime',
                  'Description',
                ],
              });
            }
          }
        }
        this.searchSkip = +this.route.snapshot.paramMap.get('index') || 0;

        if (!this.objectType || !this.searchScope) {
          this.noConfig = true;
        } else {
          this.noConfig = false;
          this.objectType = this.searchScope.type;
          this.tableConfig.title = this.translate.instant(
            this.searchScope.text
          );
          this.tableConfig.objectType = this.searchScope.type;
          this.tableConfig.selectable = this.searchScope.selectable ?? true;
          this.tableConfig.navigationKey = this.searchScope.navigationKey;
          if (this.searchScope.pageSize) {
            this.tableConfig.pageSize = this.searchScope.pageSize;
          }
          if (this.searchScope.defaultLinkAction) {
            this.tableConfig.defaultLinkAction =
              this.searchScope.defaultLinkAction;
          }
          if (this.searchScope.linkActions) {
            this.tableConfig.linkActions = this.searchScope.linkActions;
          }
          if (this.searchText) {
            this.tableConfig.query = this.searchScope.query.replace(
              /%SearchText%/gi,
              this.searchText
            );
          } else {
            this.tableConfig.query = this.searchScope.typeQuery
              ? this.searchScope.typeQuery
              : `/${this.searchScope.type}`;
          }
          if (this.searchScope.columns) {
            this.searchScope.columns.forEach((c) => {
              this.selectedAttributes.push(c.field);
            });
            this.tableConfig.columns = this.searchScope.columns;
          } else {
            if (
              this.searchScope.attributes &&
              this.searchScope.attributes.length > 0
            ) {
              const columns: Array<ResourceColumnConfig> = [];
              this.searchScope.attributes.forEach((a) => {
                columns.push({
                  field: a,
                  width: 0,
                  sortable: true,
                });
                this.selectedAttributes.push(a);
              });
              this.tableConfig.columns = columns;
            }
          }
          if (this.searchScope.availableAttributes) {
            this.availableAttributes = [
              ...this.searchScope.availableAttributes,
            ];
          }
          if (this.searchScope.initSort) {
            this.tableConfig.initSort = this.searchScope.initSort;
          }
        }

        if (this.route.children && this.route.children.length > 0) {
          this.showDetail = true;
        }
      })
    );

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-list':
              {
                if (this.resourceTable) {
                  this.resourceTable.resetSelection();
                  this.resourceTable.updateDataSource();
                }
              }
              break;

            default:
              break;
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.searchText && this.searchBox) {
      this.searchBox.nativeElement.focus();
    }

    if (this.resourceTable && this.resourceTable.gridState) {
      this.resourceTable.gridState.skip = this.searchSkip;
    }

    // Send build menu event
    setTimeout(() => {
      this.swap.menuEvent(
        new MenuEvent(
          'build',
          'resourceList',
          '',
          null,
          this.actionMenu,
          this.route.snapshot.paramMap.get('type')
        )
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onKeyUp(event: KeyboardEvent) {
    switch (event.key) {
      case 'Enter':
        this.onSearch();
        break;
      case 'Escape':
      case 'Esc':
        return;
      case 'Backspace':
      case 'Delete':
      case 'Del':
        if (this.searchText === '') {
          this.tableConfig.query = this.searchScope.typeQuery
            ? this.searchScope.typeQuery
            : `/${this.searchScope.type}`;
          if (this.resourceTable) {
            this.resourceTable.updateDataSource(true);
          }
        } else {
          if (this.autoComplete) {
            this.onSearch();
          }
        }
        break;
      default:
        if (this.autoComplete && event.key.length === 1) {
          this.onSearch();
        }
        break;
    }
  }

  onSearch() {
    if (!this.canSearch) {
      return;
    }

    const searchDef = this.utils.GetSearchDef(
      this.utils.encodeSearchText(this.searchText),
      this.lettersToTrigger
    );
    if (searchDef) {
      this.tableConfig.query = searchDef.exactSearch
        ? this.searchScope.exactQuery.replace(/%SearchText%/gi, searchDef.text)
        : this.searchScope.query.replace(/%SearchText%/gi, searchDef.text);

      if (this.resourceTable) {
        this.resourceTable.updateDataSource(true);
      }
    }
  }

  onResourceTableAction(event: BroadcastEvent) {
    if (!this.showDetail) {
      this.showDetail = true;
    }

    if (event.parameter && event.parameter.resource) {
      if (this.resourceTable && this.resourceTable.gridState) {
        this.utils.NavigateToIdentity(
          event.parameter.resource,
          `resourcelist/${this.objectType}/${this.resourceTable.gridState.skip}`,
          event.parameter.navigationKey
        );
      } else {
        this.utils.NavigateToIdentity(
          event.parameter.resource,
          `resourcelist/${this.objectType}`,
          event.parameter.navigationKey
        );
      }
    }
  }

  onAttributeChange(attributes: Array<AttributeResource>) {
    if (!this.resourceTable) {
      return;
    }

    attributes.forEach((attribute) => {
      if (
        this.tableConfig.columns.findIndex(
          (col) =>
            col.field.toLowerCase() === attribute.systemName.toLowerCase()
        ) < 0
      ) {
        let filterType: 'boolean' | 'numeric' | 'text' | 'date' = 'text';
        let filterReference = false;
        switch (attribute.dataType) {
          case 'Reference':
            filterReference = true;
            break;
          case 'DateTime':
            filterType = 'date';
            break;
          case 'Integer':
            filterType = 'numeric';
            break;
          case 'Boolean':
            filterType = 'boolean';
            break;
          default:
            filterType = 'text';
            break;
        }
        this.tableConfig.columns.push({
          field: attribute.systemName,
          width: 250,
          sortable: true,
          filterable: true,
          filter: filterType,
          isReference: filterReference,
        });
      }
    });

    this.tableConfig.columns = this.tableConfig.columns.filter(
      (col) =>
        attributes.findIndex(
          (a) => a.systemName.toLowerCase() === col.field.toLowerCase()
        ) >= 0
    );

    this.resourceTable.updateDataSource(true, false, true);
  }

  onCloseDetail() {
    this.showDetail = false;
    this.router.navigate([
      `app/resourcelist/${this.objectType}/${this.searchSkip}`,
    ]);
  }

  onMenuAction(actionName: string) {
    this.swap.menuEvent(
      new MenuEvent(
        'action',
        'resourceList',
        actionName,
        {
          scope: this.searchScope.name,
          selectedItems:
            this.searchScope.selectable === false
              ? undefined
              : this.selectedItems,
          searchSkip: this.searchSkip,
          table: this.resourceTable,
          params: this.searchText
            ? { ...this.urlParams, search: this.searchText }
            : this.urlParams,
        },
        null,
        this.route.snapshot.paramMap.get('type')
      )
    );
  }

  onMenuOpen() {
    this.swap.menuEvent(
      new MenuEvent(
        'open',
        'resourceList',
        '',
        this.searchScope.selectable === false ? undefined : this.selectedItems,
        this.actionMenu,
        this.route.snapshot.paramMap.get('type')
      )
    );
  }
}
