import { Component, AfterViewInit, Injector } from '@angular/core';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import { ButtonEditorConfig } from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-button-config',
  templateUrl: './editor-button-config.component.html',
  styleUrls: ['./editor-button-config.component.scss'],
})
export class EditorButtonConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: ButtonEditorConfig;
    attribute: AttributeResource;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit() {
    this.initComponent();
  }
}
