<!-- Dialog -->
<div fxFill fxLayout="column">
  <!-- Photo-Cropper -->
  <div>
    <!-- Image-Cropper -->
    <image-cropper
      [aspectRatio]="aspectRatio"
      [backgroundColor]="'white'"
      [containWithinAspectRatio]="false"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      format="jpeg"
      style="width: 455px"
      (cropperReady)="cropperReady()"
      (imageCropped)="imageCropped($event)"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <!-- Cropped Image -->
    <!-- <img [src]="croppedImage" style="display: none;" /> -->
  </div>

  <!-- Buttons -->
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <!-- Hidden File-Upload -->
    <input
      #Upload
      (change)="fileChangeEvent($event, Upload)"
      accept="image/x-png,image/gif,image/jpeg"
      id="Upload"
      style="display: none"
      type="file"
    />

    <!-- Upload Button -->
    <label
      [title]="'l10n_changePhoto' | translate"
      class="button mat-stroked-button"
      for="Upload"
    >
      {{ 'key_selectImage' | translate }}
    </label>

    <!-- Gap -->
    <div fxFlex="auto"></div>

    <!-- Submit -->
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>

    <!-- Cancel -->
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>

<!-- Post-Window-Configuration -->
<ng-template #titleBar let-win>
  <app-popup-title
    [canResize]="false"
    [title]="'key_photoCropperConfig'"
    [win]="win"
  ></app-popup-title>
</ng-template>
