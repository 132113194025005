// Angular
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

// Components
import { PhotoCropperComponent } from '../photo-cropper/photo-cropper.component';

// Extensions
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';

// Services & Models
import { AttributeResource } from '../../models/dataContract.model';
import { ModalService } from '../../services/modal.service';
import { ModalType } from '../../models/componentContract.model';
import { SwapService } from '../../services/swap.service';
import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { ConfigService } from '../../services/config.service';

// Component
@Component({
  templateUrl: './photo-editor.component.html',
  selector: 'app-photo-editor',
  styleUrls: ['./photo-editor.component.scss'],
})

// PhotoEditor
export class PhotoEditorComponent extends AttributeEditor implements OnInit {
  // Bindings
  @Input() initials: string;
  @Input() photoAttribute: AttributeResource;
  @Output() photoDeleted = new EventEmitter();
  @Output() photoUpdated = new EventEmitter<string>();

  // Values
  canEdit: boolean;
  photoHeight = 130;
  photoWidth: number;

  // Privates
  private subscription: Subscription = new Subscription();

  // Constructor
  constructor(
    injector: Injector,
    private modal: ModalService,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService
  ) {
    super(injector);
  }

  // Angular Functions
  ngOnInit(): void {
    this.photoWidth =
      this.photoHeight *
      this.configService.getConfig('photoWidthToHeightRatio', 1);
    // Set photo permissions
    if (this.photoAttribute && this.photoAttribute.permissionHint) {
      this.canEdit = this.photoAttribute.permissionHint.indexOf('Modify') > -1;
    }
  }

  // User Functions
  onPhotoChange() {
    // Show background overlay
    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    // Open Photo-Cropper configuration
    const windowRef = this.window.open({
      content: PhotoCropperComponent,
      resizable: false,
      width: 500,
      top: this.configService.getConfig('wizardTopPosition', null),
    });

    // Analyze window result
    windowRef.result.subscribe((result) => {
      // Hide background overlay
      this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });

      if (result instanceof WindowCloseResult) {
        // Cancel
      } else {
        // Submit
        this.photoUpdated.emit(result);
      }
    });
  }
  onPhotoDelete() {
    // Constants
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'l10n_confirmDeletePhoto'
    );

    // Event-Emitter for photo DELETION after confirmation
    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((dialogResult: string) => {
            if (dialogResult && dialogResult === 'yes') {
              this.photoDeleted.emit();
              return EMPTY;
            } else {
              return EMPTY;
            }
          })
        )
        .subscribe()
    );
  }
}
