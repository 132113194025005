<div fxLayout="column" fxLayoutAlign="start center">
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 90%">
    <div
      fxFlex="none"
      style="
        cursor: pointer;
        color: lightgray;
        margin-top: 10px;
        margin-left: -10px;
      "
      (click)="onShowDetail()"
    >
      <mat-icon>{{
        request.showDetail ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</mat-icon>
    </div>
    <div
      fxFlex="none"
      style="color: darkgray; font-size: 14px; margin-right: 10px"
    >
      {{ 'key_details' | translate }}
    </div>
    <div fxFlex="auto" style="background-color: lightgray; height: 2px"></div>
  </div>
  <div *ngIf="request.showDetail" style="width: 90%">
    <div
      *ngIf="request.content; else noContent"
      style="
        width: 90%;
        margin-top: 5px;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 5px;
        border-radius: 5px;
      "
      [innerHTML]="request.content"
    ></div>
    <ng-template #noContent>
      <div fxLayout="row" style="width: 90%; margin-top: 5px">
        <div fxFlex="25" style="font-weight: 400">
          {{ 'key_attribute' | translate }}
        </div>
        <div fxFlex="15" style="font-weight: 400; padding-left: 10px">
          {{ 'key_action' | translate }}
        </div>
        <div fxFlex="30" style="font-weight: 400; padding-left: 10px">
          {{ 'key_oldValue' | translate }}
        </div>
        <div fxFlex="30" style="font-weight: 400; padding-left: 10px">
          {{ 'key_newValue' | translate }}
        </div>
      </div>
      <div *ngIf="request.attributeChanges">
        <div
          *ngFor="let assignment of request.attributeChanges"
          fxLayout="row"
          style="width: 90%; margin-top: 5px"
        >
          <div fxFlex="25" [matTooltip]="assignment.attribute">
            {{
              assignment.attribute
                | translateAttribute
                  : request.targetObjectType
                  : translationTrigger
                | async
            }}
          </div>
          <div fxFlex="15" style="padding-left: 10px">
            {{ assignment.action | translate }}
          </div>
          <div fxFlex="30" style="padding-left: 10px">
            <div [ngSwitch]="assignment.type">
              <app-identity-link
                *ngSwitchCase="'guid'"
                [identity]="{ ObjectID: assignment.oldValue }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
              <div *ngSwitchCase="'text'">
                {{ assignment.oldValue }}
              </div>
              <div
                *ngSwitchCase="'bin'"
                style="cursor: pointer"
                (click)="onShowPhoto(assignment.oldValue)"
              >
                <span
                  *ngIf="assignment.oldValue"
                  class="material-icons-outlined"
                  >more_horiz</span
                >
              </div>
            </div>
          </div>
          <div fxFlex="30" style="padding-left: 10px">
            <div [ngSwitch]="assignment.type">
              <app-identity-link
                *ngSwitchCase="'guid'"
                [identity]="{ ObjectID: assignment.newValue }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
              <div *ngSwitchCase="'text'">
                {{ assignment.newValue }}
              </div>
              <div
                *ngSwitchCase="'bin'"
                style="cursor: pointer"
                (click)="onShowPhoto(assignment.newValue)"
              >
                <span
                  *ngIf="assignment.newValue"
                  class="material-icons-outlined"
                  >more_horiz</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 90%">
    <div
      fxFlex="none"
      style="
        cursor: pointer;
        color: lightgray;
        margin-top: 10px;
        margin-left: -10px;
      "
      (click)="onShowResponse()"
    >
      <mat-icon>{{
        request.showResponse ? 'keyboard_arrow_down' : 'keyboard_arrow_right'
      }}</mat-icon>
    </div>
    <div
      fxFlex="none"
      style="color: darkgray; font-size: 14px; margin-right: 10px"
    >
      {{ 'key_approvalSteps' | translate }}
    </div>
    <div fxFlex="auto" style="background-color: lightgray; height: 2px"></div>
  </div>
  <div *ngIf="request.showResponse" style="width: 90%">
    <div *ngFor="let approval of request.approvals">
      <div
        fxLayout="column"
        style="
          margin-top: 5px;
          padding: 5px 10px;
          background-color: rgba(0, 0, 0, 0.07);
          border-radius: 5px;
        "
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <div
            fxFlex="none"
            [ngStyle]="{
              'background-color':
                approval.approvalDecision === null
                  ? 'goldenrod'
                  : approval.approvalDecision === 'Approved'
                  ? 'green'
                  : 'red'
            }"
            style="
              height: 10px;
              width: 10px;
              border-radius: 5px;
              margin-right: 10px;
            "
          ></div>
          <div fxFlex="auto" style="margin-right: 10px">
            {{ approval.approvalTitle }}
          </div>
          <div
            fxFlex="none"
            style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
          >
            <mat-icon
              [matTooltip]="'key_deadline' | translate"
              style="font-size: 20px; line-height: 36px"
              >schedule</mat-icon
            >
          </div>
          <div fxFlex="120px">
            {{
              approval.approvalStages[approval.escalationCount]
                | extraValue: 'expirationTime'
            }}
          </div>
          <div
            fxFlex="none"
            style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
          >
            <mat-icon
              fontSet="material-icons-outlined"
              class="clipboard-icon"
              [matTooltip]="'key_threshold' | translate"
              style="font-size: 20px; line-height: 36px; cursor: pointer"
              (click)="approval.expanded = !approval.expanded"
              >admin_panel_settings</mat-icon
            >
          </div>
          <div fxFlex="30px">
            {{ approval.approvalStages[approval.escalationCount].threshold }}
          </div>
        </div>
      </div>
      <div *ngIf="approval.expanded" fxlayout="row" fxLayoutAlign="end center">
        <div
          fxFlex="none"
          style="height: 5px; width: 2px; background-color: rgba(0, 0, 0, 0.07)"
        ></div>
        <div fxFlex="50px"></div>
      </div>
      <div *ngIf="approval.expanded" fxLayout="row" fxLayoutAlign="end center">
        <div fxFlex="auto"></div>
        <div
          fxFlex="200px"
          style="
            background-color: rgba(0, 0, 0, 0.07);
            border-radius: 5px;
            padding: 5px 10px;
          "
        >
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            *ngFor="let approver of approval.currentApprovers"
          >
            <mat-icon
              [matTooltip]="'key_approver' | translate"
              style="color: rgba(0, 0, 0, 0.6); margin-right: 10px"
              >how_to_reg</mat-icon
            >
            <app-identity-link
              [identity]="{ ObjectID: approver }"
              [linkActions]="['sideView', 'navigate']"
            ></app-identity-link>
          </div>
        </div>
      </div>
      <div *ngIf="approval.expanded" fxlayout="row" fxLayoutAlign="end center">
        <div
          fxFlex="none"
          style="
            height: 10px;
            width: 2px;
            background-color: rgba(0, 0, 0, 0.07);
          "
        ></div>
        <div fxFlex="50px"></div>
      </div>
      <div *ngFor="let response of approval.responses">
        <div fxLayout="row">
          <div fxFlex="30px"></div>
          <div
            fxFlex="20px"
            style="
              height: 20px;
              border-left: rgba(0, 0, 0, 0.07) solid 2px;
              border-bottom: rgba(0, 0, 0, 0.07) solid 2px;
            "
          ></div>
          <div
            fxFlex="auto"
            fxLayout="row"
            fxLayoutAlign="start center"
            style="
              background-color: rgba(0, 0, 0, 0.07);
              border-radius: 5px;
              padding: 5px 10px;
              margin-top: 2px;
            "
          >
            <div
              fxFlex="none"
              [ngStyle]="{
                'background-color':
                  response.approvalDecision === 'Approved' ? 'seagreen' : 'red'
              }"
              style="
                height: 10px;
                width: 10px;
                border-radius: 5px;
                margin-right: 10px;
              "
            ></div>
            <div fxFlex="auto" fxLayout="row" fxLayoutAlign="start center">
              <app-identity-link
                [identity]="{ ObjectID: response.approver }"
                [linkActions]="['sideView', 'navigate']"
              ></app-identity-link>
              <div *ngIf="response.isEmergencyResponse">
                <mat-icon
                  [matTooltip]="'key_emergencyApprovers' | translate"
                  fontSet="material-icons-outlined"
                  class="clipboard-icon"
                  style="
                    font-size: 16px;
                    margin-left: 10px;
                    margin-top: 8px;
                    color: rgba(0, 0, 0, 0.6);
                  "
                  >medical_services</mat-icon
                >
              </div>
              <div *ngIf="response.onBehalfOfDisplayName">
                <mat-icon
                  [matTooltip]="
                    ('key_onBehalfOf' | translate) +
                    ': ' +
                    response.onBehalfOfDisplayName
                  "
                  fontSet="material-icons-outlined"
                  class="clipboard-icon"
                  style="
                    font-size: 16px;
                    margin-left: 10px;
                    margin-top: 8px;
                    color: rgba(0, 0, 0, 0.6);
                  "
                  >contact_mail</mat-icon
                >
              </div>
            </div>
            <div fxFlex="100px" fxLayout="row" fxLayoutAlign="start center">
              <div>
                {{ response.approvalDecision }}
              </div>
              <div
                *ngIf="response.approvalReason"
                [matTooltip]="response.approvalReason"
              >
                <mat-icon
                  fontSet="material-icons-outlined"
                  class="clipboard-icon"
                  style="
                    line-height: 32px;
                    font-size: 16px;
                    color: rgba(0, 0, 0, 0.6);
                    margin-left: 10px;
                  "
                  >announcement</mat-icon
                >
              </div>
            </div>
            <div fxFlex="120px">
              {{ response | extraValue: 'responseCreationTime' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
