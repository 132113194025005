import {
  Component,
  OnInit,
  forwardRef,
  Injector,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { of, Subscription } from 'rxjs';
import { tap, switchMap, finalize } from 'rxjs/operators';

import { AttributeEditor } from '../../models/dynamicEditor.interface';

import { SeparatorEditorConfig } from '../../models/editorContract.model';

import { SwapService } from '../../services/swap.service';

import { EditorSeparatorConfigComponent } from './editor-separator-config.component';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-editor-separator',
  templateUrl: './editor-separator.component.html',
  styleUrls: ['./editor-separator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorSeparatorComponent),
      multi: true,
    },
  ],
})
export class EditorSeparatorComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  private conf = new SeparatorEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  stringValue: string;

  get value() {
    return this.stringValue ? this.stringValue : '';
  }
  set value(value: string) {
    this.stringValue = value;
    if (this.editorAttribute) {
      this.editorAttribute.value = value;
    }
  }

  constructor(
    private swap: SwapService,
    private window: WindowService,
    public injector: Injector
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit() {
    this.initComponent();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.applyConfig();

      if (this.creationMode && !this.configMode && this.config.initExpression) {
        this.value = this.resolveExpression(this.config.initExpression);
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new SeparatorEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    if (this.config.textMode === 'text') {
      this.value = this.config.text;
    } else {
      if (this.config.query && this.config.attributeToShow) {
        this.subscription.add(
          this.resource
            .getResourceByQuery(this.resolveExpression(this.config.query), [
              this.config.attributeToShow,
            ])
            .pipe(
              tap((resources) => {
                if (
                  resources &&
                  resources.results &&
                  resources.results.length > 0
                ) {
                  if (resources.results.length === 1) {
                    const extractedValue = this.utils.ExtraValue(
                      resources.results[0],
                      `${this.config.attributeToShow}:value`
                    );
                    this.value = extractedValue ? extractedValue : '';
                  } else {
                    const arr: Array<string> = [];
                    resources.results.forEach((r) => {
                      const v = this.utils.ExtraValue(
                        r,
                        `${this.config.attributeToShow}:value`
                      );
                      if (v && arr.indexOf(v) < 0) {
                        arr.push(v);
                      }
                    });
                    this.value =
                      arr.length > 0 ? arr.join(this.config.separator) : '';
                  }
                } else {
                  this.value = '';
                }
              })
            )
            .subscribe()
        );
      } else {
        this.value = this.config.text;
      }
    }

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorSeparatorConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  onHyperLink() {
    if (this.value && this.config.isHyperLink) {
      window.open(this.value, '_blank');
    }
  }

  // #endregion
}
