import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  AfterViewInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  GridsterConfig,
  GridType,
  CompactType,
  DisplayGrid,
} from 'angular-gridster2';
import { EditorResult } from '../core/models/dynamicEditor.interface';
import { Subscription } from 'rxjs';
import { SwapService } from '../core/services/swap.service';
import { BroadcastEvent } from '../core/models/dataContract.model';
import { AttributeViewComponent } from '../core/components/attribute-view/attribute-view.component';

@Component({
  selector: 'app-gridster-view',
  templateUrl: './gridster-view.component.html',
  styleUrls: ['./gridster-view.component.scss'],
})
export class GridsterViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  private subscription: Subscription = new Subscription();

  @ViewChildren('attributeView')
  attributeViews: QueryList<AttributeViewComponent>;

  gdConfig: GridsterConfig = {
    gridType: GridType.VerticalFixed,
    compactType: CompactType.CompactUp,
    margin: 10,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 840,
    minCols: 1,
    maxCols: 100,
    minRows: 1,
    maxRows: 100,
    maxItemCols: 100,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 1,
    maxItemArea: 2500,
    minItemArea: 1,
    defaultItemCols: 1,
    defaultItemRows: 1,
    fixedColWidth: 50,
    fixedRowHeight: 50,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false,
    },
    swap: true,
    pushItems: true,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.None,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false,
  };

  gdRowsBackup: { [name: string]: number } = {};

  gridsterDefinition: Array<any>;
  @Input()
  get gridsterDefs() {
    return this.gridsterDefinition;
  }
  set gridsterDefs(value) {
    this.gridsterDefinition = value;
    this.gridsterDefsChange.emit(this.gridsterDefinition);
  }
  @Output()
  gridsterDefsChange = new EventEmitter();

  results: { [key: string]: Array<EditorResult> } = {};
  @Input()
  get editorResults() {
    return this.results;
  }
  set editorResults(value) {
    this.results = value;
    this.editorResultsChange.emit(this.results);
  }
  @Output()
  editorResultsChange = new EventEmitter();

  @Input()
  configMode = false;

  private toNormalSize(itemName: string) {
    let inc = 0;
    let xAxis = 0;
    let yAxis = 0;
    this.gridsterDefs.map((g) => {
      if (g.name === itemName) {
        g.rows = this.gdRowsBackup[g.name];
        inc = g.rows - 1;
        xAxis = g.x;
        yAxis = g.y;
      }
      return g;
    });
    this.gridsterDefs.map((g) => {
      if (g.name !== itemName && g.x === xAxis && g.y > yAxis) {
        g.y += inc;
      }
    });
    if (this.gdConfig.api) {
      this.gdConfig.api.optionsChanged();
    }
  }

  constructor(private swap: SwapService) {}

  ngOnInit() {
    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'minimize':
              this.gridsterDefs.map((g) => {
                if (g.name === event.parameter) {
                  this.gdRowsBackup[g.name] = g.rows;
                  g.rows = 1;
                }
                return g;
              });
              if (this.gdConfig.api) {
                this.gdConfig.api.optionsChanged();
              }
              break;
            case 'maximize':
              {
                this.toNormalSize(event.parameter);
              }
              break;
            default:
              break;
          }
        }
      })
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.gdConfig.api) {
        this.gdConfig.api.optionsChanged();
      }
    }, 200);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.configMode) {
      for (const key in this.gdRowsBackup) {
        if (this.gdRowsBackup.hasOwnProperty(key)) {
          // this.gridsterDefs.map(g => {
          //   if (g.name === key) {
          //     g.rows = this.gdRowsBackup[key];
          //   }
          //   return g;
          // });
          this.toNormalSize(key);
        }
      }

      if (this.gdConfig) {
        if (changes.configMode.currentValue) {
          this.gdConfig.draggable.enabled = true;
          this.gdConfig.resizable.enabled = true;
          this.gdConfig.displayGrid = DisplayGrid.Always;
          if (this.gdConfig.api) {
            this.gdConfig.api.optionsChanged();
          }
        } else {
          this.gdConfig.draggable.enabled = false;
          this.gdConfig.resizable.enabled = false;
          this.gdConfig.displayGrid = DisplayGrid.None;
          if (this.gdConfig.api) {
            this.gdConfig.api.optionsChanged();
          }
        }
      }
    }
  }

  getEditor(attributeName: string, tabName: string) {
    if (this.attributeViews) {
      const attrView = this.attributeViews.find((a) => a.tabName === tabName);
      if (attrView) {
        return attrView.getEditor(attributeName);
      }
    }

    return null;
  }
}
