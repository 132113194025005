import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-activity-placeholder',
  templateUrl: './activity-placeholder.component.html',
  styleUrls: ['./activity-placeholder.component.scss'],
})
export class ActivityPlaceholderComponent {
  activityXoml: string;

  @Input()
  get originalActivity() {
    return this.activityXoml;
  }
  set originalActivity(value: string) {
    this.activityXoml = value;
    this.originalActivityChange.emit(this.activityXoml);
  }
  @Output()
  originalActivityChange = new EventEmitter();

  constructor() {}
}
