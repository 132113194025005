import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-activity-add-delay',
  templateUrl: './activity-add-delay.component.html',
  styleUrls: ['./activity-add-delay.component.scss'],
})
export class ActivityAddDelayComponent {
  delay: string;
  delayTime = new Date(2000, 1, 1, 0, 0, 0);

  delayHours = 0;
  delayMinutes = 0;
  delaySeconds = 0;

  @Input()
  get value() {
    return this.delay;
  }
  set value(value) {
    // this.delay = value.startsWith('12') ? `00${value.substr(2)}` : value;
    // this.delayTime = this.stringToDate(this.delay);
    // this.valueChange.emit(this.delay);

    this.delay = value;

    if (this.delay) {
      const arrNumber = this.delay.split(':');
      if (arrNumber.length === 3) {
        const numHours = parseInt(arrNumber[0], 10);
        const numMinutes = parseInt(arrNumber[1], 10);
        const numSeconds = parseInt(arrNumber[2], 10);
        this.delayHours = isNaN(numHours) ? 0 : numHours;
        this.delayMinutes = isNaN(numMinutes) ? 0 : numMinutes;
        this.delaySeconds = isNaN(numSeconds) ? 0 : numSeconds;
      }
    }

    this.valueChange.emit(this.delay);
  }
  @Output()
  valueChange = new EventEmitter();

  private getTime() {
    return `${String(this.delayHours).padStart(2, '0')}:${String(
      this.delayMinutes
    ).padStart(2, '0')}:${String(this.delaySeconds).padStart(2, '0')}`;
  }

  constructor() {}

  onHoursChange() {
    if (!this.delayHours || this.delayHours < 0) {
      this.delayHours = 0;
    }
    this.value = this.getTime();
  }

  onMinutesChange() {
    if (!this.delayMinutes || this.delayMinutes < 0) {
      this.delayMinutes = 0;
    }
    if (this.delayMinutes > 59) {
      this.delayMinutes = 60;
    }
    this.value = this.getTime();
  }

  onSecondsChange() {
    if (!this.delaySeconds || this.delaySeconds < 0) {
      this.delaySeconds = 0;
    }
    if (this.delaySeconds > 59) {
      this.delaySeconds = 60;
    }
    this.value = this.getTime();
  }
}
