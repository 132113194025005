import {
  Component,
  OnInit,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';

import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import { switchMap, expand, reduce, tap } from 'rxjs/operators';
import {
  TreeViewComponent as kendoTreeViewComponent,
  TreeItemLookup,
} from '@progress/kendo-angular-treeview';

import { TreeViewConfig } from '../../models/componentContract.model';
import {
  Resource,
  ResourceSet,
  TreeNode,
} from '../../models/dataContract.model';

import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { SwapService } from '../../services/swap.service';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit {
  @ViewChild('treeView') treeView: kendoTreeViewComponent;

  @Input() name = 'tree-view';

  @Input() spinnerMargin = -200;

  @Input()
  expandedKeys: Array<string> = ['0'];

  @Input()
  selectedKeys: Array<string> = ['0'];

  private componentConfig: TreeViewConfig;
  @Input()
  get config() {
    return this.componentConfig;
  }
  set config(value) {
    this.componentConfig = value;
  }

  @Output()
  selectionChange = new EventEmitter<any>();

  @Output()
  afterInit = new EventEmitter<any>();

  treeData: Observable<TreeNode[]>;
  hasFilter = false;
  isFiltering = false;
  isEmpty = false;

  expandedKeysBackup: Array<string>;
  selectedKeysBackup: Array<string>;

  objectType = '';

  private getAttributesToLoad(): string[] {
    let attributesToLoad = ['DisplayName'];
    if (this.config.attributeStyles) {
      let styleAttributes = this.config.attributeStyles.map((a) => a.attribute);
      styleAttributes = styleAttributes.filter((item, index) => {
        return styleAttributes.indexOf(item) === index;
      });
      attributesToLoad = attributesToLoad.concat(
        styleAttributes.filter((item) => attributesToLoad.indexOf(item) < 0)
      );
    }
    return attributesToLoad;
  }

  private sortOverwrite(data: Resource[]): Resource[] {
    if (data) {
      const sortMode: string = this.configService.getConfig(
        'structureViewSortOverwrite',
        null
      );
      if (sortMode) {
        data.sort((a, b) => {
          switch (sortMode.toLowerCase()) {
            case 'displaynameasc':
              if (a.displayname > b.displayname) {
                return 1;
              } else {
                return -1;
              }
            case 'displaynameascignorecase':
              if (a.displayname.toLowerCase() > b.displayname.toLowerCase()) {
                return 1;
              } else {
                return -1;
              }
            case 'displaynamedesc':
              if (a.displayname > b.displayname) {
                return -1;
              } else {
                return 1;
              }
            case 'displaynamedescignorecase':
              if (a.displayname.toLowerCase() > b.displayname.toLowerCase()) {
                return -1;
              } else {
                return 1;
              }
            default:
              return 1;
          }
        });
      }
    }

    return data;
  }

  prepareTreeNode(data: Resource): TreeNode {
    if (data) {
      this.objectType = this.utils.ExtraValue(data, 'ObjectType');
      const item: TreeNode = {
        DisplayName: this.utils.ExtraValue(data, 'DisplayName'),
        ObjectID: this.utils.ExtraValue(data, 'ObjectID'),
        ObjectType: this.objectType,
        Icon: 'account_tree',
      };
      for (const attributeStyle of this.config.attributeStyles) {
        if (
          this.utils.ExtraValue(data, attributeStyle.attribute) ===
          attributeStyle.value
        ) {
          item.Color = attributeStyle.color && attributeStyle.color;
          item.Icon = attributeStyle.icon && attributeStyle.icon;
          item.Tip = attributeStyle.tip && attributeStyle.tip;
          break;
        }
      }

      return item;
    }

    return null;
  }

  private prepareTreeNodes(data: Resource[]): TreeNode[] {
    if (data) {
      this.sortOverwrite(data);

      const retVal: TreeNode[] = [];
      data.forEach((r) => {
        const item = this.prepareTreeNode(r);
        retVal.push(item);
      });

      return retVal;
    }

    return [];
  }

  private filterTree(node: any) {
    const id = this.utils.ExtraValue(node, 'ObjectID');

    return this.resource
      .getResourceByQuery(
        `/*[ObjectID='${id}']/ocgParentRef`,
        this.getAttributesToLoad(),
        this.config.pageSize,
        0,
        false,
        this.config.sort
      )
      .pipe(
        expand((result: ResourceSet) => {
          if (result && result.results && result.results.length > 0) {
            const parentID = this.utils.ExtraValue(
              result.results[0],
              'ObjectID'
            );
            return this.resource.getResourceByQuery(
              `/*[ObjectID='${parentID}']/ocgParentRef`,
              this.getAttributesToLoad(),
              this.config.pageSize,
              0,
              false,
              this.config.sort
            );
          }

          return EMPTY;
        }),
        reduce((acc, val) => {
          if (val.results && val.results.length > 0) {
            acc.results.push(val.results[0]);
          }
          return acc;
        }),
        switchMap((result: ResourceSet) => {
          let retVal: any;
          let currentVal: any;
          if (result && result.results && result.results.length === 0) {
            retVal = node;
          } else {
            for (let i = result.results.length - 1; i >= 0; i--) {
              if (i === result.results.length - 1) {
                retVal = this.prepareTreeNode(result.results[i]);
                currentVal = retVal;
              }
              if (i - 1 >= 0) {
                const theNode = this.prepareTreeNode(result.results[i - 1]);
                currentVal.items = [theNode];
                currentVal = theNode;
              }
            }
            currentVal.items = [this.prepareTreeNode(node)];
          }

          return of(retVal);
        })
      );
  }

  private buildFilteredData(query: string, selectNode = false) {
    return this.resource
      .getResourceByQuery(
        query,
        this.getAttributesToLoad(),
        this.config.pageSize,
        0,
        false,
        this.config.sort
      )
      .pipe(
        switchMap((result: ResourceSet) => {
          if (result && result.results && result.results.length > 0) {
            const observableBatch = [];
            result.results.forEach((item) => {
              observableBatch.push(this.filterTree(item));
            });
            return forkJoin(observableBatch);
          } else {
            return of([]);
          }
        }),
        tap((result: Array<any>) => {
          this.expandedKeys = [];
          result.forEach((tree, index) => {
            if (tree && tree.items) {
              this.expandedKeys.push(index.toString());
              let nodes = tree.items;
              let path = index.toString();
              while (nodes && nodes.length > 0 && nodes[0].items) {
                this.expandedKeys.push(`${path}_0`);
                nodes = nodes[0].items;
                path = `${path}_0`;
              }
            }
          });
          if ((this.config.partialTree || selectNode) && result.length === 1) {
            this.selectedKeys = [
              `${this.expandedKeys[this.expandedKeys.length - 1]}_0`,
            ];
          }
          this.isEmpty = result.length > 0 ? false : true;
          this.isFiltering = false;
          if (this.afterInit.observers.length > 0) {
            this.afterInit.emit();
          }
        })
      );
  }

  constructor(
    private resource: ResourceService,
    private utils: UtilsService,
    private swap: SwapService,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    const initConfig = new TreeViewConfig();
    this.utils.CopyInto(this.config, initConfig, true, true);
    this.config = initConfig;

    this.hasFilter = false;
    this.isEmpty = false;

    if (this.expandedKeysBackup) {
      this.expandedKeys = this.expandedKeysBackup;
    }
    if (this.selectedKeysBackup) {
      this.selectedKeys = this.selectedKeysBackup;
    }

    if (this.config.partialTree) {
      if (this.config.initQuery) {
        this.isFiltering = true;
        this.treeData = this.buildFilteredData(this.config.initQuery);
        this.hasFilter = true;
      }
    } else {
      this.isFiltering = true;

      if (this.config && this.config.initQuery) {
        this.treeData = this.resource
          .getResourceByQuery(
            this.config.initQuery,
            this.getAttributesToLoad(),
            this.config.pageSize,
            0,
            false,
            this.config.sort
          )
          .pipe(
            switchMap((result: ResourceSet) => {
              this.isFiltering = false;
              return of(this.prepareTreeNodes(result.results));
            }),
            tap(() => {
              if (this.afterInit.observers.length > 0) {
                this.afterInit.emit();
              }
            })
          );
      }
    }
  }

  isExpanded = (node: any, index: string) => {
    return index === '0' ? true : false;
  };

  fetchChildren = (node: any): Observable<any[]> => {
    if (this.hasFilter && node.items) {
      return of(node.items);
    } else {
      if (this.config && this.config.childrenQuery) {
        if (node.ObjectID) {
          const query = this.config.childrenQuery.replace(
            new RegExp(`%ParentID%`, 'gi'),
            node.ObjectID
          );
          return this.resource
            .getResourceByQuery(
              query,
              this.getAttributesToLoad(),
              this.config.pageSize,
              0,
              false,
              this.config.sort
            )
            .pipe(
              switchMap((result: ResourceSet) => {
                return of(this.prepareTreeNodes(result.results));
              })
            );
        }
      }
    }

    return of([]);
  };

  hasChildren = (): boolean => {
    return true;
  };

  filter(term: string, selectNode = false) {
    this.expandedKeysBackup = this.expandedKeys;
    this.selectedKeysBackup = this.selectedKeys;

    this.isFiltering = true;

    const regEx =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    const query = term.match(regEx)
      ? `/*[ObjectID='${term}']${this.config.customFilter}`
      : `/${this.objectType}[starts-with(DisplayName,'${term}')]${this.config.customFilter}`;
    this.treeData = this.buildFilteredData(query, selectNode);
    this.hasFilter = true;
    this.selectedKeys = [];
  }

  refresh() {
    this.initComponent();
  }

  onSelectionChange(item: any) {
    if (this.selectionChange.observers.length > 0) {
      this.selectionChange.emit(item);
    }
  }

  onExpand() {
    if (!this.hasFilter) {
      this.swap.expansionState[this.name] = this.expandedKeys;
    }
  }

  onCollapse(node: any) {
    if (!this.hasFilter) {
      this.swap.expansionState[this.name] = this.expandedKeys;
    } else {
      if (node && node.dataItem) {
        delete node.dataItem.items;
      }
    }
  }

  getSelectedItem(): TreeItemLookup {
    if (this.treeView && this.selectedKeys && this.selectedKeys.length > 0) {
      const result = this.treeView.itemLookup(this.selectedKeys[0]);
      return result;
    }

    return null;
  }

  setSelectedItem(index: string) {
    this.selectedKeys = [index];
  }

  setExpansion(state: Array<string>) {
    if (!this.hasFilter) {
      this.expandedKeys = state;
    }
  }

  setExpansionWithString(state: string) {
    if (!this.hasFilter && state) {
      const stateList = state.split('_');
      const expansionState: Array<string> = [];
      let previousState: string;
      stateList.forEach((item, index) => {
        if (index === 0) {
          expansionState.push(item);
          previousState = item;
        } else {
          const currentState = `${previousState}_${item}`;
          expansionState.push(currentState);
          previousState = currentState;
        }
      });
      this.expandedKeys = expansionState;
    }
  }

  // onTest() {
  //   const lookUp = this.treeView.itemLookup('0_0');
  //   console.log(lookUp);

  //   this.treeData = this.buildFilteredData('administration');
  // }
}
