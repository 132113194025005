import { Component } from '@angular/core';

@Component({
  selector: 'app-inpage-error',
  templateUrl: './inpage-error.component.html',
  styleUrls: ['./inpage-error.component.scss'],
})
export class InpageErrorComponent {
  constructor() {}
}
