import {
  AfterViewInit,
  Component,
  forwardRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  NgControl,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { DragulaService } from 'ng2-dragula';
import { of, Subscription } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { EditorEvent, ModelUpdateMode } from '../../models/dataContract.model';
import { AttributeEditor } from '../../models/dynamicEditor.interface';
import {
  IterablesEditorConfig,
  IterablesProperty,
} from '../../models/editorContract.model';
import { TransService } from '../../models/translation.model';
import { ConfigService } from '../../services/config.service';
import { SwapService } from '../../services/swap.service';
import { createIterablesEditorValidator } from '../../validators/validators';
import { EditorIterablesConfigComponent } from './editor-iterables-config.component';

@Component({
  selector: 'app-editor-iterables',
  templateUrl: './editor-iterables.component.html',
  styleUrls: ['./editor-iterables.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorIterablesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorIterablesComponent),
      multi: true,
    },
  ],
})
export class EditorIterablesComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  @ViewChild('iterablesForm') iterablesForm: NgForm;

  private conf = new IterablesEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  public iterableValue: Array<any> = [];

  public newValue: any;

  get value() {
    if (!this.editorAttribute || !this.editorAttribute.value) {
      return null;
    }

    if (
      this.editorAttribute.multivalued &&
      this.config.saveAs === 'object' &&
      this.config.iterableType === 'array'
    ) {
      return this.editorAttribute.values;
    } else {
      return this.editorAttribute.value;
    }
  }
  set value(value) {
    if (
      this.editorAttribute.multivalued &&
      this.config.saveAs === 'object' &&
      this.config.iterableType === 'array'
    ) {
      this.editorAttribute.values = value;
      this.editorAttribute.value = value && value.length > 0 ? value[0] : null;
    } else {
      this.editorAttribute.value = value;
    }

    this.propagateChange(this.editorAttribute);
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  modelUpdateOn: ModelUpdateMode = this.configService.getConfig(
    'modelUpdateOn',
    'change'
  );

  private buildIterableValue() {
    if (this.value) {
      let resultValue: any;
      switch (this.config.saveAs) {
        case 'object':
          if (this.config.iterableType === 'array') {
            resultValue = JSON.parse(JSON.stringify(this.value));
          } else {
            resultValue = { ...this.value };
          }
          break;
        case 'text':
        default:
          resultValue = JSON.parse(this.value);
          break;
      }

      if (Array.isArray(resultValue)) {
        this.iterableValue = resultValue;
      } else {
        const keyProp = this.config.properties.find((p) => p.isKey === true);
        const keyName = keyProp.name;
        for (const [key, value] of Object.entries(resultValue)) {
          value[keyName] = key;
          this.iterableValue.push(value);
        }
      }
    }
  }

  private buildSaveValue() {
    // if (
    //   this.iterablesForm &&
    //   (this.iterablesForm.invalid || this.iterablesForm.status === 'INVALID')
    // ) {
    //   this.setError(true, this.translate.instant('key_invalidFormat'));
    // } else {
    //   this.setError(false);
    // }

    let objectResult: any = {};
    switch (this.config.iterableType) {
      case 'dictionary':
        {
          const keyProp = this.config.properties.find((p) => p.isKey === true);

          const uniqueValues = new Set(
            this.iterableValue.map((v) => v[keyProp.name])
          );
          if (uniqueValues.size < this.iterableValue.length) {
            this.setError(
              true,
              this.translate.instant('key_duplicateKeysNotAllowed')
            );
          } else {
            if (this.control && !this.control.invalid) {
              this.setError(false);
            }
          }

          this.iterableValue.forEach((item) => {
            const itemValue = { ...item };
            Object.keys(itemValue).forEach((k: string) => {
              const pos = this.config.properties.findIndex(
                (p) => p.name === k && p.type === 'multi'
              );
              if (pos >= 0) {
                itemValue[k] = itemValue[k] ? itemValue[k].split(',') : null;
              }
            });
            objectResult[item[keyProp.name]] = itemValue;
            delete itemValue[keyProp.name];
          });
        }
        break;
      case 'array':
      default:
        objectResult = this.iterableValue.map((entry: any) => {
          Object.keys(entry).forEach((k: string) => {
            const pos = this.config.properties.findIndex(
              (p) => p.name === k && p.type === 'multi'
            );
            if (pos >= 0) {
              if (typeof entry[k] === 'string') {
                entry[k] = entry[k] ? entry[k].split(',') : null;
              }
            }
          });
          return entry;
        });
        break;
    }

    // if (this.iterablesForm && this.iterablesForm.status === 'INVALID') {
    //   this.setError(true, this.translate.instant('key_invalidFormat'));
    // }

    switch (this.config.saveAs) {
      case 'object':
        if (!objectResult || Object.keys(objectResult).length === 0) {
          this.value = null;
        } else {
          this.value = objectResult;
        }
        break;
      case 'text':
      default:
        if (!objectResult || Object.keys(objectResult).length === 0) {
          this.value = null;
        } else {
          this.value = JSON.stringify(objectResult);
        }
        break;
    }
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService,
    private translate: TransService,
    private dragula: DragulaService
  ) {
    super(injector);

    try {
      this.dragula.createGroup('ITERABLESCOLUMNS', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('ithandle') ||
            (handle.parentNode as Element).classList.contains('ithandle')
          );
        },
      });
    } catch {}

    this.subscription.add(
      this.dragula.drop('ITERABLESCOLUMNS').subscribe(() => {
        setTimeout(() => {
          this.buildSaveValue();
          this.valueChange.emit();
        });
      })
    );
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    this.initComponent();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setError(false);

      const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
      if (ngControl) {
        this.control = ngControl.control as FormControl;
      }

      this.buildIterableValue();

      this.validationFn = createIterablesEditorValidator(this.config);
      this.applyConfig();

      if (this.creationMode && !this.configMode) {
        if (this.config.initExpression) {
          const resolved = this.resolveExpression(this.config.initExpression);
          if (resolved) {
            if (typeof resolved === 'string') {
              if (resolved.toLowerCase() === 'true') {
                this.value = true;
              } else {
                this.value = false;
              }
            } else if (typeof resolved === 'boolean') {
              this.value = resolved;
            } else {
              this.value = false;
            }
          } else {
            this.value = false;
          }
          // trigger init value building for wizard view
          // this doesn't apply for editing view because initExpression doesn't exist
          this.swap.editorEvent(
            new EditorEvent(
              'change',
              this.config.attributeName,
              this.currentID,
              this.currentType,
              this.value
            )
          );
        } else {
          this.value = false;
        }
      }

      if (this.iterablesForm) {
        this.subscription.add(
          this.iterablesForm.statusChanges.subscribe((value: string) => {
            if (value === 'VALID') {
              this.setError(false);
              this.validateValue();
            } else if (value === 'INVALID') {
              this.setError(true, this.translate.instant('key_invalidFormat'));
              this.validateValue();
            }
          })
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // #region AttributeEditor implementation

  initComponent() {
    this.setError(false);

    this.validationFn = createIterablesEditorValidator(this.config);

    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new IterablesEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    if (this.config.updateOn) {
      if (
        String(this.config.updateOn) !== 'null' &&
        String(this.config.updateOn) !== 'undefined'
      ) {
        this.modelUpdateOn = this.config.updateOn;
      }
    }

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorIterablesConfigComponent,
      width: 720,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createIterablesEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region event handler

  onAddValue() {
    const valueToAdd = {};
    this.config.properties.forEach((p: IterablesProperty) => {
      if (p.default) {
        if (p.type === 'boolean') {
          valueToAdd[p.name] = String(p.default).toLowerCase() === 'true';
        } else {
          valueToAdd[p.name] = p.default;
        }
      }
    });

    this.iterableValue.push(valueToAdd);

    setTimeout(() => {
      this.buildSaveValue();
    });
  }

  onRemoveValue(pos: number) {
    this.iterableValue.splice(pos, 1);

    setTimeout(() => {
      this.buildSaveValue();
      this.valueChange.emit();
    });
  }

  onValueChange() {
    setTimeout(() => {
      this.buildSaveValue();
      this.valueChange.emit();
    });
  }

  onKeyUp(inputElement: HTMLInputElement) {
    if (inputElement.className.includes('ng-invalid')) {
      inputElement.alt = 'invalid';
    } else {
      inputElement.alt = '';
    }
  }

  // #endregion
}
