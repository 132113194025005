import { Component, OnDestroy, OnInit } from '@angular/core';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EMPTY, Subscription, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { DragulaService } from 'ng2-dragula';
import { WindowCloseResult } from '@progress/kendo-angular-dialog';

import { ModalType } from '../core/models/componentContract.model';
import {
  BrandConfig,
  FormConfig,
  PopupButtonController,
  PopupConfig,
  StructureConfig,
} from '../core/models/dataContract.model';

import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';
import { UtilsService } from '../core/services/utils.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TransService } from '../core/models/translation.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';

@Component({
  selector: 'app-view-setting',
  templateUrl: './view-setting.component.html',
  styleUrls: ['./view-setting.component.scss'],
})
export class ViewSettingComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  formTypes = ['tab' /*'gridster'*/];

  showForm: boolean;
  availableForms: Array<FormConfig> = [];
  selectedForm: FormConfig;
  backupForm: FormConfig;

  get hasAdminRight() {
    return this.resource.isAdminViewSet;
  }

  private backupSelection() {
    if (this.backupForm) {
      const replaceIndex = this.availableForms.findIndex(
        (f) => f.name === this.backupForm.name
      );
      if (replaceIndex >= 0) {
        this.availableForms[replaceIndex] = this.utils.DeepCopy(
          this.backupForm
        );
      }
    }
    this.backupForm = this.utils.DeepCopy(this.selectedForm);
  }

  constructor(
    private dragula: DragulaService,
    private resource: ResourceService,
    private modal: ModalService,
    private utils: UtilsService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar,
    private translate: TransService
  ) {
    try {
      this.dragula.createGroup('BRANDATTRIBUTES', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
      this.dragula.createGroup('STATUSATTRIBUTES', {
        moves: (el, container, handle) => {
          return (
            handle.classList.contains('handle') ||
            (handle.parentNode as Element).classList.contains('handle')
          );
        },
      });
    } catch {}
  }

  ngOnInit(): void {
    this.initForms();

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );

    this.subscription.add(
      this.resource
        .isUISettingsUpToDate()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initForms() {
    const viewSetting = this.utils.DeepCopy(
      this.resource.primaryViewSetting.editingView
    );
    if (viewSetting) {
      this.availableForms.splice(0, this.availableForms.length);
      Object.keys(viewSetting).forEach((setting) => {
        const item: FormConfig = viewSetting[setting];
        item.name = setting;
        if (!item.structureView) {
          item.structureView = new StructureConfig(false);
        }
        if (!item.brandView) {
          item.brandView = new BrandConfig(false);
        }
        if (!item.attributesToLoad) {
          item.attributesToLoad = ['DisplayName'];
        }
        this.availableForms.push(item);
      });
    } else {
      this.availableForms = [];
    }
  }

  onFormSelectionChange() {
    this.backupSelection();

    if (this.selectedForm) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
  }

  onAddForm() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'l10n_createForm';
    popupConfig.style = 'outline';
    // dictionary key order is the display order, javascript orders the keys automatically
    popupConfig.data = {
      a_name: {
        text: 'key_name',
        value: '',
        type: 'text',
        validation: `^[a-z0-9_.-]*$`,
        required: true,
        focused: true,
      },
      a_resourceType: {
        text: 'key_resourceType',
        value: '',
        type: 'text',
        validation: `^[a-zA-Z0-9_.-]*$`,
        required: true,
      },
      a_type: {
        text: 'key_type',
        value: 'tab',
        options: this.formTypes,
        type: 'select',
      },
      b_icon: {
        text: 'key_stateCardIconText',
        value: '',
        type: 'text',
        validation: `^[a-z0-9_.-]*$`,
      },
      c_enableBrandView: {
        text: 'l10n_brandView',
        value: false,
        type: 'boolean',
      },
      c_enableStructureView: {
        text: 'l10n_structureView',
        value: false,
        type: 'boolean',
      },
    };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              const prg = this.modal.show(
                ModalType.progress,
                'key_processing',
                '',
                '300px'
              );

              const viewSetting = this.resource.primaryViewSetting.editingView;
              if (
                Object.keys(viewSetting).findIndex(
                  (key) =>
                    key.toLowerCase() ===
                    windowResult.a_name.value.toLowerCase()
                ) >= 0
              ) {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'l10n_formAlreadyExists'
                );
                if (prg) {
                  prg.close();
                }
                return EMPTY;
              }

              viewSetting[windowResult.a_name.value.toLowerCase()] = {
                objectType: windowResult.a_resourceType.value,
                type: windowResult.a_type.value,
                icon: windowResult.b_icon.value,
                brandView: new BrandConfig(
                  windowResult.c_enableBrandView.value
                ),
                structureView: new StructureConfig(
                  windowResult.c_enableStructureView.value
                ),
                sections: [
                  {
                    cols: 2,
                    rows: 2,
                    x: 0,
                    y: 0,
                    name: 'general',
                    displayName: 'key_general',
                    columnNumber: 2,
                    attributes: [],
                  },
                ],
              };

              return this.resource.updateUISettings().pipe(
                tap((updateResult: any) => {
                  if (updateResult === 'expired') {
                    this.modal.show(
                      ModalType.error,
                      'key_warning',
                      'key_uiRefreshNeeded'
                    );
                  } else {
                    this.initForms();
                    this.selectedForm = this.availableForms.find(
                      (w) =>
                        w.name.toLowerCase() ===
                        windowResult.a_name.value.toLowerCase()
                    );
                    this.onFormSelectionChange();
                  }
                }),
                catchError((error: any) => {
                  this.modal.show(
                    ModalType.error,
                    'key_error',
                    this.utils.getServiceError(error)
                  );
                  return EMPTY;
                }),
                finalize(() => {
                  if (prg) {
                    prg.close();
                  }
                })
              );
            }

            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onRemoveForm() {
    const confirm = this.modal.show(
      ModalType.confirm,
      'key_confirmation',
      'key_confirmDeleteResource'
    );

    this.subscription.add(
      confirm
        .afterClosed()
        .pipe(
          switchMap((result) => {
            if (result && result === 'yes') {
              const prg = this.modal.show(
                ModalType.progress,
                'key_processing',
                '',
                '300px'
              );

              const viewSetting = this.resource.primaryViewSetting.editingView;
              delete viewSetting[this.selectedForm.name.toLowerCase()];

              return this.resource.updateUISettings().pipe(
                tap((updateResult: any) => {
                  if (updateResult === 'expired') {
                    this.modal.show(
                      ModalType.error,
                      'key_warning',
                      'key_uiRefreshNeeded'
                    );
                  } else {
                    this.showForm = false;
                    this.selectedForm = null;
                    this.initForms();
                  }
                }),
                catchError((error: any) => {
                  this.modal.show(
                    ModalType.error,
                    'key_error',
                    this.utils.getServiceError(error)
                  );
                  return EMPTY;
                }),
                finalize(() => {
                  if (prg) {
                    prg.close();
                  }
                })
              );
            } else {
              return EMPTY;
            }
          })
        )
        .subscribe()
    );
  }

  onSaveForm() {
    if (this.selectedForm) {
      if (
        this.selectedForm.brandView &&
        this.selectedForm.brandView.attributes
      ) {
        // filter empty entries
        this.selectedForm.brandView.attributes =
          this.selectedForm.brandView.attributes.filter((a) => a.name !== '');
        // filter douplicated entries
        this.selectedForm.brandView.attributes =
          this.selectedForm.brandView.attributes.filter(
            (att, index, self) =>
              index === self.findIndex((t) => t.name === att.name)
          );
      }

      const prg = this.modal.show(
        ModalType.progress,
        'key_processing',
        '',
        '300px'
      );

      const viewSetting = this.resource.primaryViewSetting.editingView;

      viewSetting[this.selectedForm.name] = this.selectedForm;

      this.subscription.add(
        this.resource
          .updateUISettings()
          .pipe(
            tap((updateResult: any) => {
              if (updateResult === 'expired') {
                this.modal.show(
                  ModalType.error,
                  'key_warning',
                  'key_uiRefreshNeeded'
                );
              } else {
                this.backupForm = this.utils.DeepCopy(this.selectedForm);
              }
            }),
            catchError((error: any) => {
              this.modal.show(
                ModalType.error,
                'key_error',
                this.utils.getServiceError(error)
              );
              return EMPTY;
            }),
            finalize(() => {
              if (prg) {
                prg.close();
              }
            })
          )
          .subscribe(
            () => {},
            (err) => {
              console.log(err);
            }
          )
      );
    }
  }

  onAddAttributeToLoad(event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if ((value || '').trim()) {
      const index = this.selectedForm.attributesToLoad.indexOf(value.trim());
      if (index < 0) {
        this.selectedForm.attributesToLoad.push(value.trim());
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  onRemoveAttributeToLoad(attribute: string) {
    const index = this.selectedForm.attributesToLoad.indexOf(attribute);
    if (index >= 0) {
      this.selectedForm.attributesToLoad.splice(index, 1);
    }
  }

  onAddBrandAttribute() {
    if (
      this.selectedForm &&
      this.selectedForm.brandView &&
      this.selectedForm.brandView.attributes
    ) {
      this.selectedForm.brandView.attributes.push({
        name: '',
        icon: '',
        iconAlt: '',
      });
    }
  }

  onRemoveBrandAttribute(index: number) {
    if (
      this.selectedForm &&
      this.selectedForm.brandView &&
      this.selectedForm.brandView.attributes
    ) {
      this.selectedForm.brandView.attributes.splice(index, 1);
    }
  }

  onAddStatus() {
    if (this.selectedForm) {
      if (this.selectedForm.statuses) {
        this.selectedForm.statuses.push({
          attributeName: '',
          attributeValue: '',
          text: '',
          color: 'primary',
        });
      } else {
        this.selectedForm.statuses = [
          {
            attributeName: '',
            attributeValue: '',
            text: '',
            color: 'primary',
          },
        ];
      }
    }
  }

  onRemoveStatus(index: number) {
    if (this.selectedForm && this.selectedForm.statuses) {
      this.selectedForm.statuses.splice(index, 1);
    }
  }

  onExportForm() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_export';
    popupConfig.style = 'outline';
    popupConfig.singleButton = true;
    popupConfig.confirmButton = new PopupButtonController(
      'key_export',
      'primary'
    );
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: this.selectedForm.name.toLowerCase(),
        type: 'text',
        required: true,
        focused: true,
        validation: '^[a-z0-9]*$',
      },
    };

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          tap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              const viewSetting = this.resource.primaryViewSetting.editingView;
              const objExport = {
                editingView: {},
              };
              objExport.editingView[windowResult.name.value] =
                viewSetting[this.selectedForm.name.toLowerCase()];

              this.clipboard.copy(
                this.utils.stringifyComponentConfig(objExport)
              );
            }
          })
        )
        .subscribe()
    );
  }

  onImportForm() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_import';
    popupConfig.style = 'outline';
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: undefined,
        type: 'text',
        required: false,
        focused: false,
        validation: '^[a-z0-9]*$',
      },
      settings: {
        text: 'key_settings',
        value: '',
        type: 'textarea',
        rows: 6,
        required: true,
        focused: true,
      },
    };

    let settingsToImport: any;
    let keyName = '';
    let customName = '';
    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              if (
                windowResult &&
                windowResult.settings &&
                windowResult.settings.value
              ) {
                settingsToImport = JSON.parse(windowResult.settings.value);
                if (settingsToImport.editingView) {
                  if (Object.keys(settingsToImport.editingView).length === 1) {
                    keyName = Object.keys(settingsToImport.editingView)[0];
                    customName = windowResult.name.value ?? keyName;
                    const keyPos = Object.keys(
                      this.resource.primaryViewSetting.editingView
                    ).findIndex(
                      (key: string) =>
                        key.toLowerCase() === customName.toLowerCase()
                    );
                    if (keyPos >= 0) {
                      return this.modal
                        .show(
                          ModalType.confirm,
                          'key_confirmation',
                          `${this.translate.instant(
                            'key_confirmOverwriteFormSettingsFor'
                          )} ${customName}?`
                        )
                        .afterClosed();
                    } else {
                      return of('yes');
                    }
                  }
                }

                return throwError({
                  message: this.translate.instant('key_invalidSetting'),
                });
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidSetting'
                );
              }
            }
            return EMPTY;
          }),
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              this.resource.primaryViewSetting.editingView[customName] =
                settingsToImport.editingView[keyName];
              process = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              this.initForms();
              this.selectedForm = this.availableForms.find(
                (w) => w.name.toLowerCase() === customName.toLowerCase()
              );
              this.onFormSelectionChange();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }
}
