import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { tap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService, SPINNER } from 'ngx-ui-loader';

import { DynamicComponent } from '../../models/dynamicComponent.interface';
import {
  ReportCardConfig,
  StateCardConfig,
} from '../../models/componentContract.model';

import { ExtraValuePipe } from '../../pipes/extra-value.pipe';

import { ResourceService } from '../../services/resource.service';
import { UtilsService } from '../../services/utils.service';
import { SwapService } from '../../services/swap.service';

import { StateCardConfigComponent } from './state-card-config.component';
import { BroadcastEvent } from '../../models/dataContract.model';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-state-card',
  templateUrl: './state-card.component.html',
  styleUrls: ['./state-card.component.scss'],
})
export class StateCardComponent implements OnInit, DynamicComponent {
  @Input()
  config: StateCardConfig;

  @Input()
  name = undefined;
  @Input()
  permissionSets = undefined;
  @Input()
  iconText = 'public';
  @Input()
  iconColor = 'darkseagreen';
  @Input()
  backgroundColor = 'white';
  @Input()
  textColor = 'darkgray';
  @Input()
  mainTextColor = 'black';
  @Input()
  title = 'title';
  @Input()
  mainText = 'text';
  @Input()
  queryMode = 'counter';
  @Input()
  queryAttribute = undefined;
  @Input()
  query: string = undefined;

  @Output()
  action = new EventEmitter();

  localConfig: StateCardConfig;
  mainTextValue: string;
  spinnerType = SPINNER;

  blurLevel = this.configService.getConfig('blurLevel', 1);

  constructor(
    private dialog: MatDialog,
    private spinner: NgxUiLoaderService,
    private resource: ResourceService,
    private utils: UtilsService,
    private extraValuePipe: ExtraValuePipe,
    private router: Router,
    private swap: SwapService,
    private configService: ConfigService
  ) {}

  ngOnInit() {
    this.initComponent();
  }

  resize() {}

  initComponent() {
    this.localConfig = new StateCardConfig();

    this.utils.CopyInto(this, this.localConfig, true);

    this.utils.CopyInto(this.config, this.localConfig, true, true);

    this.updateDataSource();

    return this.localConfig;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.localConfig);

    const dialogRef = this.dialog.open(StateCardConfigComponent, {
      minWidth: '420px',
      data: {
        component: this,
        config: this.localConfig,
      },
    });

    return dialogRef.afterClosed().pipe(
      tap((result) => {
        if (!result || (result && result === 'cancel')) {
          this.localConfig = configCopy;
        }
        this.updateDataSource();
      }),
      switchMap(() => {
        return of(this.localConfig);
      })
    );
  }

  updateDataSource() {
    if (this.localConfig.query) {
      this.spinner.startLoader(this.localConfig.name);

      setTimeout(() => {
        if (this.localConfig.queryMode === 'counter') {
          this.resource
            .getResourceCount(
              this.resource.resolveLoginID(this.localConfig.query)
            )
            .subscribe(
              (result) => {
                this.mainTextValue = this.localConfig.mainText.replace(
                  /\{0\}/g,
                  result.toString()
                );
                this.spinner.stopLoader(this.localConfig.name);
              },
              () => {
                this.spinner.stopLoader(this.localConfig.name);
              }
            );
        } else {
          this.resource
            .getResourceByQuery(
              this.resource.resolveLoginID(this.localConfig.query),
              [this.localConfig.queryAttribute],
              1,
              0,
              true
            )
            .subscribe(
              (result) => {
                if (result && result.results && result.results.length > 0) {
                  let value = this.extraValuePipe.transform(
                    result.results[0],
                    `${this.localConfig.queryAttribute}:DisplayName`
                  );
                  value = value ? value : 'n.a.';
                  this.mainTextValue = this.localConfig.mainText.replace(
                    /\{0\}/g,
                    value
                  );
                }
                this.spinner.stopLoader(this.localConfig.name);
              },
              () => {
                this.mainTextValue = 'err.';
                this.spinner.stopLoader(this.localConfig.name);
              }
            );
        }
      }, 500);
    } else {
      this.mainTextValue = this.localConfig.mainText;
    }
  }

  onUpdateNow() {
    this.updateDataSource();
  }

  onAction(e: Event) {
    if (this.swap.editMode) {
      e.preventDefault();
      return;
    }

    if (this.action.observers.length > 0) {
      this.action.emit(this.localConfig.action);
    } else if (this.localConfig.action) {
      if (this.localConfig.action.startsWith('/')) {
        if (this.localConfig.action.toLowerCase().startsWith('/app/report/')) {
          const reportName = this.localConfig.action.substring(
            this.localConfig.action.lastIndexOf('/') + 1
          );
          if (
            this.resource.primaryViewSetting &&
            this.resource.primaryViewSetting.reports &&
            this.resource.primaryViewSetting.reports.components
          ) {
            const componentArray: Array<any> =
              this.resource.primaryViewSetting.reports.components;
            const componentConfig = componentArray.find((r: any) => {
              return r.name === reportName;
            });
            if (componentConfig && componentConfig.componentConfig) {
              this.router.navigate([this.localConfig.action], {
                state: componentConfig.componentConfig,
              });
            }
          }
        } else {
          this.router.navigate([this.localConfig.action]);
        }
      } else {
        this.swap.cardEvent(
          new BroadcastEvent(this.localConfig.action, 'primary')
        );
      }
    }
  }
}
