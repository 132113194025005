import { AfterViewInit, Component, Injector } from '@angular/core';
import { AttributeResource } from '../../models/dataContract.model';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import { ObjectEditorConfig } from '../../models/editorContract.model';

@Component({
  selector: 'app-editor-object-config',
  templateUrl: './editor-object-config.component.html',
  styleUrls: ['./editor-object-config.component.scss'],
})
export class EditorObjectConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: ObjectEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit(): void {
    this.initComponent();
  }
}
