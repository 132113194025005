<div class="config-dialog">
  <div mat-dialog-content>
    <mat-tab-group>
      <mat-tab [label]="'key_general' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                disabled
                [placeholder]="'key_attributeName' | translate"
                [(ngModel)]="data.attribute.systemName"
              />
            </mat-form-field>
            <div fxFlex="1 1 50" fxLayout="column">
              <div
                fxFlex="1 1 50"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutUnits' | translate }}
              </div>
              <mat-slider
                *ngIf="data.viewMode === 'tab'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.units"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
              <mat-slider
                *ngIf="data.viewMode === 'gridster'"
                fxFlex="none"
                [max]="12"
                [min]="1"
                [step]="1"
                [thumbLabel]="true"
                [tickInterval]="1"
                [(ngModel)]="data.config.gridsterUnits"
                style="margin-top: 2px; padding-left: 0"
              >
              </mat-slider>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px grid"
          >
            <div
              fxFlex="0 0 50"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="20px"
            >
              <mat-form-field fxFlex="0 0 50">
                <input
                  matInput
                  disabled
                  [placeholder]="'key_type' | translate"
                  [(ngModel)]="data.config.name"
                />
              </mat-form-field>
              <mat-form-field fxFlex="0 0 45">
                <input
                  matInput
                  type="number"
                  min="0"
                  [placeholder]="'key_hintLimit' | translate"
                  [(ngModel)]="data.config.hintLimit"
                />
              </mat-form-field>
            </div>
            <mat-form-field fxFlex="0 0 50">
              <mat-select
                [placeholder]="'key_tooltipContent' | translate"
                [(ngModel)]="data.config.tooltipContent"
              >
                <mat-option value="systemname">{{
                  'key_systemName' | translate
                }}</mat-option>
                <mat-option value="description">{{
                  'key_description' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showTooltip"
            >
              {{ 'key_showToolTip' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideIfNoAccess"
            >
              {{ 'key_hideIfNoAccess' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 30px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.readOnly"
            >
              {{ 'key_readOnly' | translate }}</mat-slide-toggle
            >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.required"
              [disabled]="data.config.requiredFromSchema"
            >
              {{ 'key_required' | translate }}</mat-slide-toggle
            >
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
            style="margin-top: 20px"
          >
            <mat-slide-toggle
              #bolShowDisplayName
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDisplayName"
            >
              {{ 'key_showDisplayName' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDisplayName' | translate"
                [(ngModel)]="data.config.customDisplayName"
                [disabled]="!bolShowDisplayName.checked"
              />
            </mat-form-field>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-slide-toggle
              #bolShowDescription
              fxFlex="1 1 50"
              [(ngModel)]="data.config.showDescription"
            >
              {{ 'key_showDescription' | translate }}</mat-slide-toggle
            >
            <mat-form-field fxFlex="1 1 50">
              <input
                matInput
                [placeholder]="'key_customDescription' | translate"
                [(ngModel)]="data.config.customDescription"
                [disabled]="!bolShowDescription.checked"
              />
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_advanced' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 33">
              <mat-select
                [placeholder]="'key_type' | translate"
                [(ngModel)]="data.config.controlType"
              >
                <mat-option value="combo">{{
                  'key_comboBox' | translate
                }}</mat-option>
                <mat-option value="radio">{{
                  'key_radioButton' | translate
                }}</mat-option>
                <mat-option value="text">{{
                  'key_text' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-slide-toggle
              fxFlex="1 1 33"
              [(ngModel)]="data.config.allowEmpty"
              style="padding-left: 20px"
            >
              {{ 'key_allowEmpty' | translate }}</mat-slide-toggle
            >
            <div fxFlex="1 1 33">
              <mat-form-field fxFlex="auto">
                <input
                  matInput
                  [placeholder]="'key_emptyText' | translate"
                  [(ngModel)]="data.config.emptyText"
                />
              </mat-form-field>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            *ngIf="data.config.controlType === 'radio'"
            fxLayoutAlign="start center"
            fxLayoutGap="20px"
          >
            <mat-form-field fxFlex="1 1 50">
              <mat-select
                [placeholder]="'key_layout' | translate"
                [(ngModel)]="data.config.radioButtonLayout"
              >
                <mat-option value="row">{{ 'key_row' | translate }}</mat-option>
                <mat-option value="column">{{
                  'key_column' | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div fxFlex="1 1 50" fxLayout="column" style="padding-left: 20px">
              <div
                fxFlex="none"
                style="
                  font-size: 12px;
                  font-weight: 400;
                  height: 18px;
                  padding-left: 8px;
                  color: rgba(0, 0, 0, 0.54);
                "
              >
                {{ 'key_layoutGap' | translate }}
              </div>
              <mat-slider
                fxFlex="none"
                [max]="100"
                [min]="10"
                [step]="10"
                [thumbLabel]="true"
                [tickInterval]="10"
                [(ngModel)]="data.config.radioButtonSpace"
                style="margin-top: 2px"
              >
              </mat-slider>
            </div>
          </div>
          <div fxFlex="none" *ngIf="data.creationMode">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_initExpression' | translate"
                [(ngModel)]="data.config.initExpression"
              ></textarea>
            </mat-form-field>
          </div>
          <div fxFlex="none" style="margin-top: 20px">
            <mat-form-field fxFlex="auto">
              <textarea
                matInput
                [matTextareaAutosize]="true"
                [matAutosizeMinRows]="1"
                [matAutosizeMaxRows]="6"
                [placeholder]="'key_valueExpression' | translate"
                [(ngModel)]="data.config.expression"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_display' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 15px">
            <mat-radio-group [(ngModel)]="data.config.accessUsedFor">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="visibility">{{
                  'key_useForVisibility' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="editability">{{
                  'key_useForEditability' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clDeniedSets>
                <mat-chip
                  *ngFor="let deniedSet of data.config.accessDenied"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveDeniedSet(deniedSet)"
                >
                  {{ deniedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_deniedSets' | translate"
                  [matChipInputFor]="clDeniedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddDeniedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <mat-chip-list #clAllowedSets>
                <mat-chip
                  *ngFor="let alowedSet of data.config.accessAllowed"
                  [selectable]="true"
                  [removable]="true"
                  (removed)="onRemoveAllowedSet(alowedSet)"
                >
                  {{ alowedSet }}
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="'key_allowedSets' | translate"
                  [matChipInputFor]="clAllowedSets"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="true"
                  (matChipInputTokenEnd)="onAddAllowedSet($event)"
                />
              </mat-chip-list>
            </mat-form-field>
          </div>
          <div fxFlex="none">
            <mat-form-field fxFlex="auto">
              <input
                matInput
                [placeholder]="'key_accessExpression' | translate"
                [(ngModel)]="data.config.accessExpression"
              />
            </mat-form-field>
          </div>
          <div
            *ngIf="data.config.accessUsedFor === 'visibility'"
            fxFlex="none"
            style="margin-top: 15px"
          >
            <mat-slide-toggle
              fxFlex="1 1 50"
              [(ngModel)]="data.config.hideFromDOM"
            >
              {{ 'key_hideFromDOM' | translate }}</mat-slide-toggle
            >
          </div>
        </div>
      </mat-tab>
      <mat-tab [label]="'key_data' | translate">
        <div
          fxLayout="column"
          fxLayoutAlign="start stretch"
          class="first-dialog-item"
        >
          <div fxFlex="none" style="margin-bottom: 20px">
            <mat-radio-group [(ngModel)]="data.config.dataMode">
              <div fxLayout="row">
                <mat-radio-button fxFlex="1 1 33" value="static">{{
                  'key_static' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="config">{{
                  'key_config' | translate
                }}</mat-radio-button>
                <mat-radio-button fxFlex="1 1 33" value="query">{{
                  'key_query' | translate
                }}</mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div fxFlex="none" *ngIf="data.config.dataMode === 'static'">
            <div fxLayout="column" fxLayoutGap="20px" style="max-height: 300px">
              <mat-list fxFlex="none">
                <mat-list-item
                  style="
                    border: solid lightgray;
                    height: 38px;
                    margin-top: -2px;
                    font-weight: 400;
                    color: gray;
                  "
                >
                  <div fxLayout="row" fxLayoutGap="20px" style="width: 100%">
                    <div fxFlex="0 0 20px"></div>
                    <div fxFlex="1 1 50">
                      {{ 'key_text' | translate }}
                    </div>
                    <div fxFlex="1 1 50">
                      {{ 'key_value' | translate }}
                    </div>
                    <div fxFlex="0 0 20px"></div>
                  </div>
                </mat-list-item>
                <div
                  dragula="SELECTOPTIONS"
                  [(dragulaModel)]="data.config.options"
                >
                  <mat-list-item
                    *ngFor="let option of data.config.options"
                    style="
                      border: solid lightgray;
                      height: 38px;
                      margin-top: -2px;
                      padding: 5px 0;
                      color: gray;
                    "
                  >
                    <div fxLayout="row" fxLayoutGap="20px" style="width: 100%">
                      <div fxFlex="0 0 20px" class="handle">
                        <mat-icon style="margin-left: -10px; cursor: pointer"
                          >menu</mat-icon
                        >
                      </div>
                      <div fxFlex="1 1 50">
                        {{ option.text }}
                      </div>
                      <div fxFlex="1 1 50">
                        {{ option.value }}
                      </div>
                      <div fxFlex="0 0 20px">
                        <mat-icon
                          (click)="onRemoveOption(option)"
                          style="cursor: pointer"
                          >clear</mat-icon
                        >
                      </div>
                    </div>
                  </mat-list-item>
                </div>
              </mat-list>
              <div
                fxFlex="none"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="20px"
              >
                <mat-form-field fxFlex="1 1 50">
                  <input
                    matInput
                    [placeholder]="'key_text' | translate"
                    [(ngModel)]="textInput"
                  />
                </mat-form-field>
                <mat-form-field fxFlex="1 1 50">
                  <input
                    matInput
                    [placeholder]="'key_value' | translate"
                    [(ngModel)]="valueInput"
                  />
                </mat-form-field>
                <button
                  fxFlex="auto"
                  mat-stroked-button
                  [disabled]="!textInput || !valueInput"
                  (click)="onAddOption()"
                  style="height: 36px; width: 200px"
                >
                  {{ 'key_add' | translate }}
                </button>
              </div>
            </div>
          </div>
          <div
            fxFlex="none"
            fxLayout="row"
            *ngIf="data.config.dataMode === 'config'"
          >
            <mat-form-field fxFlex="1 1 100">
              <input
                matInput
                [placeholder]="'key_configKey' | translate"
                [(ngModel)]="data.config.configKey"
                required
              />
            </mat-form-field>
          </div>
          <div fxFlex="none" *ngIf="data.config.dataMode === 'query'">
            <div fxLayout="column" fxLayoutGap="10px">
              <mat-form-field fxFlex="none">
                <input
                  matInput
                  [placeholder]="'key_query' | translate"
                  [(ngModel)]="data.config.query"
                  required
                />
              </mat-form-field>
              <div fxFlex="none" fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="33">
                  <mat-form-field>
                    <input
                      matInput
                      [placeholder]="'key_textAttribute' | translate"
                      [(ngModel)]="data.config.textAttribute"
                      required
                    />
                  </mat-form-field>
                </div>
                <div fxFlex="33">
                  <mat-form-field>
                    <input
                      matInput
                      [placeholder]="'key_valueAttribute' | translate"
                      [(ngModel)]="data.config.valueAttribute"
                      required
                    />
                  </mat-form-field>
                </div>
                <div fxFlex="33">
                  <mat-form-field>
                    <input
                      matInput
                      [placeholder]="'key_separator' | translate"
                      [(ngModel)]="data.config.separator"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      (click)="onClose()"
      [disabled]="isDisabled()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      style="margin-left: 20px"
      (click)="onExportSetting()"
    >
      {{ 'key_exportSetting' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'key_editorConfig'"
    [leftPadding]="leftPadding"
  ></app-popup-title>
</ng-template>
