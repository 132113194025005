import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limit: number): unknown {
    if (value && limit > 0) {
      if (value.length > limit) {
        return `${value.substring(0, limit)}...`;
      } else {
        return value;
      }
    }

    return value;
  }
}
