<div *ngIf="simpleMode && loadAsReadonly; else fullMode">
  <div *ngIf="simpleEditing; else simpleReading">
    <div fxLayout="row" [matTooltip]="tooltip" style="font-weight: 400">
      <input type="checkbox" [disabled]="config.readOnly" [(ngModel)]="value" />
      <div
        style="margin-left: 10px"
        [ngClass]="{ 'disabled-text-color': config.readOnly }"
      >
        {{ displayName | translate }}
      </div>
    </div>
  </div>
  <ng-template #simpleReading>
    <div [matTooltip]="tooltip" style="font-weight: 400">
      <div class="accent-text-color" style="font-size: 0.75em">
        {{ displayName | translate }}
      </div>
      <div
        *ngIf="
          value === undefined || value === null || value === '';
          else showValue
        "
        style="cursor: pointer"
        (click)="onStartEdit()"
      >
        {{ 'key_noValuePlaceHolder' | translate }}
      </div>
      <ng-template #showValue>
        <div style="cursor: pointer" (click)="onStartEdit()">{{ value }}</div>
      </ng-template>
    </div>
  </ng-template>
</div>
<ng-template #fullMode>
  <!-- ngIf will not be used any more, use ngClass for display control  -->
  <!-- *ngIf="configMode || showEditor(resource.rightSets)" -->
  <div
    fxLayout="column"
    [ngClass]="{
      'config-hidden': configMode && !showEditor(resource.rightSets),
      'edit-mode':
        !creationMode &&
        config.attributeName &&
        !config.attributeName.endsWith('#')
    }"
    style="width: 100%"
  >
    <div style="height: 12px"></div>
    <mat-checkbox
      *ngIf="config.controlType === 'checkbox'"
      [matTooltip]="tooltip"
      [(ngModel)]="value"
      (focus)="onFocuse()"
      (change)="onChange()"
      [disabled]="disabled(resource.rightSets)"
      [labelPosition]="config.textAlign"
      [indeterminate]="
        config.enableIndeterminated && (value === undefined || value === null)
      "
      style="font-weight: 400"
    >
      <div class="label-attribute-name-normal">
        {{ displayName | translate }}
      </div>
    </mat-checkbox>
    <mat-slide-toggle
      *ngIf="config.controlType === 'slider'"
      [matTooltip]="tooltip"
      [(ngModel)]="value"
      (focus)="onFocuse()"
      (change)="onChange()"
      [disabled]="disabled(resource.rightSets)"
      [labelPosition]="config.textAlign"
      style="font-weight: 400"
    >
      <div class="label-attribute-name-normal">
        {{ displayName | translate }}
      </div></mat-slide-toggle
    >
    <div
      *ngIf="config.controlType === 'radio'"
      fxLayout="column"
      style="margin-top: -16px"
    >
      <div fxFlex="none" class="label-attribute-name">
        {{ displayName | translate }}
      </div>
      <mat-radio-group
        fxFlex="none"
        [(ngModel)]="value"
        [matTooltip]="tooltip"
        [disabled]="disabled(resource.rightSets)"
        (focus)="onFocuse()"
        (change)="onChange()"
      >
        <div
          [fxLayout]="config.radioButtonLayout"
          [fxLayoutGap]="config.radioButtonSpace.toString() + 'px'"
        >
          <mat-radio-button
            fxFlex="none"
            [value]="true"
            style="font-weight: 400"
          >
            {{ config.trueText | translate }}</mat-radio-button
          >
          <mat-radio-button
            fxFlex="none"
            [value]="false"
            style="font-weight: 400"
          >
            {{ config.falseText | translate }}</mat-radio-button
          >
        </div>
      </mat-radio-group>
    </div>
    <div
      style="
        font-size: 0.75em;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
        height: 18px;
        padding-top: 2px;
      "
    >
      <div fxLayout="row">
        <div
          fxFlex="auto"
          *ngIf="description"
          class="label-attribute-desc"
          [matTooltip]="description | translate"
        >
          {{ description | translate | ellipsis: config.hintLimit }}
        </div>
        <div
          fxFlex="none"
          *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
          style="color: #d8bb2e"
        >
          <mat-icon
            [matTooltip]="'key_noWriteAccess' | translate"
            style="font-size: 14px"
            >error</mat-icon
          >
        </div>
        <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
          <mat-icon
            [matTooltip]="'key_noReadAccess' | translate"
            style="color: red; padding-top: 4px; font-size: 14px"
            >error</mat-icon
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>
