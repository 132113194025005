<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_approvalConfig' | translate }}
  </h3>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      class="first-dialog-item"
    >
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_title' | translate"
          [(ngModel)]="data.config.title"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_mode' | translate"
          [(ngModel)]="data.config.type"
        >
          <mat-option value="approver">{{
            'key_approver' | translate
          }}</mat-option>
          <mat-option value="requestor">{{
            'key_requestor' | translate
          }}</mat-option>
          <mat-option value="emergency">{{
            'key_emergencyApprovers' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      style="margin-bottom: 25px; margin-top: 15px; font-size: 0.85em"
    >
      <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.showAll">
        {{ 'key_showAllApprovals' | translate }}</mat-slide-toggle
      >
      <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.canApprove">
        {{ 'key_canApprove' | translate }}</mat-slide-toggle
      >
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      style="margin-bottom: 25px; margin-top: 15px; font-size: 0.85em"
    >
      <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.reasonRequired">
        {{ 'key_reasonRequired' | translate }}</mat-slide-toggle
      >
      <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.includeDelegates">
        {{ 'key_includeDelegates' | translate }}</mat-slide-toggle
      >
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <input
          matInput
          type="number"
          min="1"
          max="50"
          [placeholder]="'key_itemLimit' | translate"
          [(ngModel)]="data.config.limit"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_defaultContent' | translate"
          [(ngModel)]="data.config.defaultContent"
        >
          <mat-option value="">--</mat-option>
          <mat-option value="detail">{{
            'key_changeDetail' | translate
          }}</mat-option>
          <mat-option value="response">{{
            'key_response' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_sortAttribute' | translate"
          [(ngModel)]="data.config.sortAttribute"
        >
          <mat-option value="RequestCreationTime">{{
            'key_requestCreationTime' | translate
          }}</mat-option>
          <mat-option value="TargetDisplayName">{{
            'key_targetDisplayName' | translate
          }}</mat-option>
          <mat-option value="RequestorDisplayName">{{
            'key_requestorDisplayName' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_sortOrder' | translate"
          [(ngModel)]="data.config.sortOrder"
        >
          <mat-option value="Ascending">{{
            'key_ascending' | translate
          }}</mat-option>
          <mat-option value="Descending">{{
            'key_descending' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_titleAttribute' | translate"
          [(ngModel)]="data.config.titleAttribute"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_contentAttribute' | translate"
          [(ngModel)]="data.config.contentAttribute"
        />
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button fxFlex="none" mat-stroked-button (click)="onRefresh()">
      {{ 'key_apply' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
