<div fxLayout="row wrip" fxLayoutGap="10px grid">
  <div
    *ngFor="let element of config.data | keyvalue"
    [ngSwitch]="element.value.type"
    [fxFlex]="element.value.width || 100"
    [style.display]="element.value.visible === false ? 'none' : 'block'"
  >
    <mat-form-field
      *ngSwitchCase="'text'"
      [appearance]="config.style"
      style="width: 100%"
    >
      <mat-label>{{ element.value.text | translate }}</mat-label>
      <input
        matInput
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
        (keydown)="onKeyDown($event, element.value)"
      />
      <mat-hint>{{ element.value.hint | translate }}</mat-hint>
    </mat-form-field>
    <mat-form-field
      *ngSwitchCase="'textarea'"
      [appearance]="config.style"
      style="width: 100%"
    >
      <mat-label>{{ element.value.text | translate }}</mat-label>
      <!-- <input
        matInput
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
        (keydown)="onKeyDown($event, element.value)"
      /> -->
      <textarea
        matInput
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
        (keydown)="onKeyDown($event, element.value)"
        [rows]="element.value.rows ? element.value.rows : 3"
      ></textarea>
    </mat-form-field>
    <mat-form-field
      *ngSwitchCase="'number'"
      [appearance]="config.style"
      style="width: 100%"
    >
      <mat-label>{{ element.value.text | translate }}</mat-label>
      <input
        matInput
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        type="number"
        [(ngModel)]="element.value.value"
      />
    </mat-form-field>
    <mat-form-field
      *ngSwitchCase="'select'"
      [appearance]="config.style"
      style="width: 100%"
    >
      <mat-label>{{ element.value.text | translate }}</mat-label>
      <mat-select
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
      >
        <mat-option
          *ngFor="let option of element.value.options"
          [value]="option"
          >{{ option }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field
      *ngSwitchCase="'objectselect'"
      [appearance]="config.style"
      style="width: 100%"
    >
      <mat-label>{{ element.value.text | translate }}</mat-label>
      <mat-select
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
      >
        <mat-option
          *ngFor="let option of element.value.options"
          [value]="option.value"
          >{{ option.text | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div *ngSwitchCase="'boolean'" style="width: 100%; margin-bottom: 18px">
      <mat-checkbox
        appAutoFocus
        [enableAutoFocus]="element.value.focused === true"
        [(ngModel)]="element.value.value"
        >{{ element.value.text | translate }}</mat-checkbox
      >
    </div>
    <img
      *ngSwitchCase="'image'"
      [src]="'data:image/png;base64,' + element.value.value | safeUrl"
      style="width: 100%"
    />
    <div *ngSwitchCase="'list'" style="width: 100%">
      <div
        *ngFor="let item of element.value.value"
        fxLayout="row"
        fxLayoutAlign="start center"
      >
        <span
          fxFlex="none"
          class="material-icons-outlined"
          style="margin-left: -5px"
        >
          navigate_next
        </span>
        <div
          fxFlex="auto"
          [ngClass]="{
            'list-item-warn': element.value.options.indexOf('warn') >= 0,
            'list-item-info': element.value.options.indexOf('info') >= 0
          }"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'reference'" style="margin-top: 2px">
      <app-editor-identity
        [config]="element.value.config"
        [(ngModel)]="element.value.value"
      ></app-editor-identity>
    </div>
    <div *ngSwitchCase="'date'" style="margin-top: 2px">
      <app-editor-date
        [config]="element.value.config"
        [(ngModel)]="element.value.value"
      >
      </app-editor-date>
    </div>
  </div>
  <div
    *ngIf="!config.noButton"
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="start center"
    style="margin-top: 10px"
  >
    <div *ngIf="config.singleButton" fxFlex="auto"></div>
    <button
      mat-stroked-button
      [color]="config.confirmButton.color"
      [disabled]="!canSubmit()"
      (click)="onClose()"
    >
      {{ config.confirmButton.text | translate }}
    </button>
    <div *ngIf="!config.singleButton" fxFlex="auto"></div>
    <button
      *ngIf="!config.singleButton"
      mat-stroked-button
      [color]="config.cancelButton.color"
      (click)="onCancel()"
    >
      {{ config.cancelButton.text | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title [win]="win" [title]="config.title"></app-popup-title>
</ng-template>
