<div
  class="k-overlay"
  *ngIf="showOverlay"
  [style.opacity]="overlayOpacity"
></div>
<div kendoWindowContainer></div>
<mat-sidenav-container
  (backdropClick)="onBackdropClicked()"
  style="background-color: transparent"
>
  <mat-sidenav-content>
    <div class="wrapper" fxLayout="row" fxLayoutAlign="start stretch">
      <div fxFlex="0 0 auto">
        <div style="width: 60px"></div>
        <div [ngStyle]="sidebarStyle" style="height: 100%">
          <div class="sidebar" style="height: 100%">
            <app-sidebar (resized)="onSidebarResized($event)"></app-sidebar>
          </div>
        </div>
      </div>

      <div fxFlex="1 1 auto" fxLayout="column" style="width: calc(100% - 60px)">
        <div class="topbar" fxFlex="0 0 60px">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            style="padding: 10px 20px"
          >
            <!-- <app-search
          fxFlex="auto"
          (selectResource)="onResourceSelected($event)"
        ></app-search> -->
            <app-simple-search
              fxFlex="auto"
              [searchLimit]="5"
              [autoComplete]="autoComplete"
            ></app-simple-search>
            <div *ngIf="adminMode" fxFlex="none" style="margin-left: 20px">
              <app-edit-menu
                [buttonColor]="'slategray'"
                [direction]="'row'"
                (edit)="onEnterEditMode()"
                (cancel)="onExitEditMode()"
                (add)="onEditAdd()"
                (save)="onEditSave()"
                (setting)="onEditSetting()"
              ></app-edit-menu>
            </div>
            <span
              *ngIf="canLaunchWorkflow"
              style="font-size: 30px; margin-left: 20px; cursor: pointer"
              class="material-icons-outlined accent-text-color"
              [matTooltip]="'key_launchWorkflow' | translate"
              (click)="onLaunchWorkflow()"
              >view_in_ar</span
            >
            <span
              *ngIf="helpLink"
              style="font-size: 34px; margin-left: 20px; cursor: pointer"
              class="material-icons-outlined accent-text-color"
              [matTooltip]="'key_help' | translate"
              (click)="onHelp()"
              >help_outline</span
            >
            <app-account fxFlex="none" style="margin-left: 20px"></app-account>
          </div>
        </div>
        <app-bread-crumb
          *ngIf="showBreadCrumb"
          style="position: absolute; top: 70px; left: 80px; z-index: 2"
        ></app-bread-crumb>
        <div
          class="main"
          fxFlex="1 1 auto"
          [ngClass]="{
            'show-scroll': showScrollbar,
            'hide-scroll': !showScrollbar
          }"
        >
          <router-outlet></router-outlet>
          <ngx-ui-loader
            loaderId="spinner_home"
            [fgsType]="spinnerType.rectangleBounce"
            [fgsSize]="40"
            [fgsColor]="'white'"
            [overlayColor]="'rgba(50,50,50,.5)'"
            [pbColor]="'white'"
          ></ngx-ui-loader>
        </div>
        <!-- <div class="footer" fxFlex="0 0 30px">
      <div style="font-size: 1px;">
        Icons made by
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik"
          >Freepik</a
        >
        from
        <a href="https://www.flaticon.com/" title="Flaticon"
          >www.flaticon.com</a
        >
        is licensed by
        <a
          href="http://creativecommons.org/licenses/by/3.0/"
          title="Creative Commons BY 3.0"
          target="_blank"
          >CC 3.0 BY</a
        >
      </div>
    </div> -->
      </div>
    </div>
  </mat-sidenav-content>
  <mat-sidenav
    #sidenav
    position="end"
    class="sidenav"
    [style.width.%]="sidenavWidthinPercent"
    disableClose
  >
    <div style="position: absolute; right: 40px; top: 45px; z-index: 120">
      <button mat-icon-button color="accent" (click)="onBackdropClicked()">
        <mat-icon style="font-size: 32px; margin-left: -8px; margin-top: -2px"
          >close</mat-icon
        >
      </button>
    </div>
    <div style="position: absolute; right: 80px; top: 45px; z-index: 120">
      <button mat-icon-button color="accent" (click)="onMaximizeClicked()">
        <mat-icon style="font-size: 30px; margin-left: -6px; margin-top: -2px"
          >web_asset</mat-icon
        >
      </button>
    </div>
    <div class="sidenav-content">
      <router-outlet name="sidenav"></router-outlet>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
