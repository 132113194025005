import { Component, Injector, AfterViewInit } from '@angular/core';

import {
  DynamicEditor,
  AttributeEditorConfig,
} from '../../models/dynamicEditor.interface';

import { BooleanEditorConfig } from '../../models/editorContract.model';
import { AttributeResource } from '../../models/dataContract.model';

@Component({
  selector: 'app-editor-boolean-config',
  templateUrl: './editor-boolean-config.component.html',
  styleUrls: ['./editor-boolean-config.component.scss'],
})
export class EditorBooleanConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: BooleanEditorConfig;
    attribute: AttributeResource;
    creationMode: boolean;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit() {
    this.initComponent();
  }
}
