<div fxLayout="row" fxLayoutAlign="start center" class="accent-text-color">
  <span
    class="material-icons-outlined"
    [matTooltip]="'key_homePage' | translate"
    style="cursor: pointer"
    (click)="onHomeClicked()"
    >home_work</span
  >
  <div
    *ngIf="utils.navigationPath.length === 0"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="material-icons-outlined bread-arrow">navigate_next</span>
    <div class="bread" (click)="onHomeClicked()">
      {{ 'key_homePage' | translate }}
    </div>
  </div>
  <div
    *ngFor="let item of utils.navigationPath"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <span class="material-icons-outlined bread-arrow">navigate_next</span>
    <span
      class="material-icons-outlined"
      style="font-size: 16px; margin-top: -2px; margin-right: 5px"
      >{{ getIcon(item.objectType) }}</span
    >
    <div
      class="bread"
      [matTooltip]="lengthExceeded(item.displayName) ? item.displayName : null"
      (click)="onItemClicked(item)"
    >
      {{ buildDisplayName(item.displayName) }}
    </div>
  </div>
</div>
