import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ModelUpdateMode,
  WFActivity,
} from 'src/app/core/models/dataContract.model';
import { IterablesEditorConfig } from 'src/app/core/models/editorContract.model';
import { ActivityIndexService } from 'src/app/core/services/activity-index.service';
import { ConfigService } from 'src/app/core/services/config.service';
import { ResourceService } from 'src/app/core/services/resource.service';

@Component({
  selector: 'app-activity-general',
  templateUrl: './activity-general.component.html',
  styleUrls: ['./activity-general.component.scss'],
})
export class ActivityGeneralComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private subscription = new Subscription();

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  data: WFActivity & any;

  debugInfoToEnable: Array<string>;
  debugInfoToDisable: Array<string>;

  isActivityValid = true;

  attrXpathQuery = {
    dataType: 'Dictionary',
    displayName: 'key_xpathQueries',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrXpathQuery',
    value: null,
  };
  attrWorkflowData = {
    dataType: 'Dictionary',
    displayName: 'key_workflowDataExpressions',
    multivalued: false,
    permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
    systemName: 'attrWorkflowData',
    value: null,
  };
  configXpathQuery = new IterablesEditorConfig();
  configWorkflowExpression = new IterablesEditorConfig();

  detailMessageEnabled = false;

  hasGeneralSettings = false;

  get typeName() {
    return this.data && this.data.type ? this.act.getName(this.data.type) : '';
  }

  constructor(
    private windowRef: WindowRef,
    private resource: ResourceService,
    private act: ActivityIndexService,
    private config: ConfigService
  ) {}

  ngOnInit(): void {
    this.configXpathQuery.saveAs = 'object';
    this.configXpathQuery.hintLimit = 80;
    this.configXpathQuery.updateOn = ModelUpdateMode.blur;
    this.configXpathQuery.properties = [
      {
        name: 'key',
        displayName: 'key_key',
        type: 'text',
        isKey: true,
        width: 30,
        validation: '^[a-zA-Z0-9_]+$',
        options: [],
      },
      {
        name: 'value',
        displayName: 'key_value',
        type: 'text',
        isKey: false,
        width: 70,
        validationKey: 'workflow/addDelay/xpathQueries',
        options: [],
      },
    ];
    this.configWorkflowExpression.saveAs = 'object';
    this.configWorkflowExpression.hintLimit = 80;
    this.configWorkflowExpression.updateOn = ModelUpdateMode.blur;
    this.configWorkflowExpression.properties = [
      {
        name: 'key',
        displayName: 'key_key',
        type: 'text',
        isKey: true,
        width: 30,
        validation: '^[a-zA-Z0-9_]+$',
        options: [],
      },
      {
        name: 'value',
        displayName: 'key_value',
        type: 'text',
        isKey: false,
        width: 70,
        validationKey: 'workflow/addDelay/workflowDataExpressions',
        options: [],
      },
    ];

    if (this.data && this.data.xpathqueries) {
      this.attrXpathQuery.value = this.data.xpathqueries;
    }
    if (this.data && this.data.workflowdataexpressions) {
      this.attrWorkflowData.value = this.data.workflowdataexpressions;
    }

    if (this.data && this.data.id) {
      let foundInList = false;

      if (this.debugInfoToEnable) {
        if (this.debugInfoToEnable.indexOf(this.data.id) >= 0) {
          this.detailMessageEnabled = true;
          foundInList = true;
        }
      } else if (this.debugInfoToDisable) {
        if (this.debugInfoToDisable.indexOf(this.data.id) >= 0) {
          this.detailMessageEnabled = false;
          foundInList = true;
        }
      }

      if (!foundInList) {
        this.subscription.add(
          this.resource
            .isWorkflowDetailMessageEnabled(this.data.id)
            .pipe(
              tap((result: boolean) => {
                this.detailMessageEnabled = result;
              })
            )
            .subscribe()
        );
      }
    }
  }

  ngAfterViewInit() {
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }

    if (
      this.data.executioncondition ||
      (this.attrXpathQuery && this.attrXpathQuery.value) ||
      (this.attrWorkflowData && this.attrWorkflowData.value)
    ) {
      this.hasGeneralSettings = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    setTimeout(() => {
      if (this.isActivityValid) {
        if (this.windowRef) {
          this.windowRef.close(this.data);
        }
      }
    }, this.config.getConfig('intervalTiny', 100));
  }

  onXpathQueryChange() {
    this.data.xpathqueries = this.attrXpathQuery.value;
  }

  onWorkflowDataChange() {
    this.data.workflowdataexpressions = this.attrWorkflowData.value;
  }

  onShowDebugInfoChange() {
    this.data.showdebuginfo = this.detailMessageEnabled;
  }

  onActivityValidationChange(isValid: boolean) {
    this.isActivityValid = isValid;
  }
}
