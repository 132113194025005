import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { BroadcastEvent, TypeResource } from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';
import { ConfigService } from '../../services/config.service';
import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';

@Component({
  selector: 'app-type-picker',
  templateUrl: './type-picker.component.html',
  styleUrls: ['./type-picker.component.scss'],
})
export class TypePickerComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  multiple = false;

  @Input()
  appearance: MatFormFieldAppearance = 'standard';

  @Input()
  placeHolder = '';

  @Input()
  tooltip = '';

  @Input()
  disabled = false;

  @Input()
  preSelectedItems: Array<string> = [];

  @Output()
  typeChange = new EventEmitter<any>();

  @Input()
  value = '';

  types: Observable<Array<TypeResource>> = of([]);

  refreshToken = new ReplaySubject(undefined);

  constructor(
    private resource: ResourceService,
    private config: ConfigService,
    private translate: TransService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.refreshToken
        .pipe(
          switchMap(() => {
            return this.resource.getTypes(
              this.config.getCulture(this.translate.currentCulture)
            );
          }),
          tap((resultTypes: { [id: string]: TypeResource }) => {
            if (resultTypes) {
              let entries: Array<TypeResource> = [];
              Object.keys(resultTypes).forEach((key: string) => {
                if (
                  this.preSelectedItems.findIndex((p) => {
                    return (
                      p.toLowerCase() ===
                      resultTypes[key].systemName.toLowerCase()
                    );
                  }) < 0
                ) {
                  entries.push(resultTypes[key]);
                }
              });
              entries = entries.sort((a, b) => {
                if (a.displayName > b.displayName) {
                  return 1;
                } else if (a.displayName < b.displayName) {
                  return -1;
                }
                return 0;
              });

              this.types = of(entries);
            }
          })
        )
        .subscribe()
    );

    this.refreshToken.next(undefined);

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-language':
              this.refreshToken.next(undefined);
              break;
            default:
              break;
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  refresh() {
    this.refreshToken.next(undefined);
  }

  onSelectionChange() {
    if (this.typeChange.observers.length > 0) {
      this.typeChange.emit(this.value);
    }
  }
}
