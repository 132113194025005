import {
  Component,
  OnInit,
  AfterViewInit,
  Injector,
  forwardRef,
} from '@angular/core';
import {
  NgControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';

import { tap, switchMap, finalize } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { SelectEditorConfig } from '../../models/editorContract.model';

import { createSelectEditorValidator } from '../../validators/validators';

import { SwapService } from '../../services/swap.service';
import { ConfigService } from '../../services/config.service';

import { EditorSelectConfigComponent } from './editor-select-config.component';
import { ResourceSet, EditorEvent } from '../../models/dataContract.model';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-editor-select',
  templateUrl: './editor-select.component.html',
  styleUrls: ['./editor-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorSelectComponent),
      multi: true,
    },
  ],
})
export class EditorSelectComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit
{
  private conf = new SelectEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  dataSource: Observable<Array<{ value: string; text: string }>> = of([]);

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  setConfig(config: any = null) {
    switch (this.config.dataMode) {
      case 'static':
        this.dataSource = of(this.config.options);
        break;
      case 'config':
        if (this.config.configKey) {
          this.dataSource = of(
            this.configService.getConfig(this.config.configKey, [])
          );
        }
        break;
      case 'query':
        {
          const theQuery =
            config && config.value && String(config.value)
              ? String(config.value)
              : this.config.query;
          if (
            theQuery &&
            this.config.valueAttribute &&
            this.config.textAttribute
          ) {
            const regEx = /\[#\w+(-\w+)?#?\]/g;
            const match = regEx.exec(theQuery);
            if (match && match.length > 0) {
              this.swap.propagateEditorConfigChanged(
                this.config.attributeName,
                'query',
                theQuery
              );
              return;
            }

            const attributeNames = [this.config.valueAttribute];
            if (this.config.valueAttribute !== this.config.textAttribute) {
              attributeNames.push(this.config.textAttribute);
            }
            this.dataSource = this.resource
              .getResourceByQuery(theQuery, attributeNames)
              .pipe(
                switchMap((resources: ResourceSet) => {
                  if (
                    resources &&
                    resources.results &&
                    resources.results.length > 0
                  ) {
                    const retVal: Array<{ text: string; value: string }> = [];
                    if (this.config.separator) {
                      const texts: string = this.utils.ExtraValue(
                        resources.results[0],
                        `${this.config.textAttribute}:value`
                      );
                      const values: string = this.utils.ExtraValue(
                        resources.results[0],
                        `${this.config.valueAttribute}:value`
                      );
                      if (texts && values) {
                        const textArray = texts.split(this.config.separator);
                        const valueArray = values.split(this.config.separator);
                        if (
                          textArray.length > 0 &&
                          textArray.length === valueArray.length
                        ) {
                          textArray.forEach((text, index) => {
                            retVal.push({
                              text: text.trim(),
                              value: valueArray[index].trim(),
                            });
                          });
                        }
                      }
                    } else {
                      resources.results.forEach((data) => {
                        retVal.push({
                          text: this.utils.ExtraValue(
                            data,
                            `${this.config.textAttribute}:value`
                          ),
                          value: this.utils.ExtraValue(
                            data,
                            `${this.config.valueAttribute}:value`
                          ),
                        });
                      });
                    }
                    return of(retVal);
                  }
                  return of([]);
                })
              );
          }
        }
        break;
      default:
        break;
    }
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setConfig();
      this.setDisplay();
    });
  }

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private configService: ConfigService,
    private window: WindowService
  ) {
    super(injector);
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.initComponent();
    }
  }

  ngAfterViewInit() {
    if (this.simpleMode) {
      this.setDisplay(null, true);
    } else {
      setTimeout(() => {
        // const ngControl: NgControl = this.injector.get<NgControl>(
        //   NgControl as Type<NgControl>
        // );
        const ngControl: NgControl = this.injector.get<NgControl>(NgControl);
        if (ngControl) {
          this.control = ngControl.control as FormControl;
        }
      });

      setTimeout(() => {
        this.validationFn = createSelectEditorValidator(this.config);
        this.applyConfig();

        if (!this.configMode) {
          if (this.creationMode) {
            this.validateValue();
          } else if (
            this.configService.getConfig('validateBeforeEditing', false)
          ) {
            this.validateValue();
          }
        }

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            this.value = this.resolveExpression(this.config.initExpression);
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
          }
        }
      });
    }
  }

  // #region AttributeEditor implementation

  initComponent() {
    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new SelectEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    this.setConfig();

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorSelectConfigComponent,
      width: 700,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createSelectEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    this.propagateTouched();
  }

  onChange() {
    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(this.value);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        this.value
      )
    );
  }

  // #endregion
}
