<div
  [@flyIn]="animChooseSystem"
  class="login-form"
  [class.mat-elevation-z8]="true"
  style="height: 80px; width: 440px; margin-top: 200px"
>
  <div
    class="login-header"
    [class.mat-elevation-z8]="true"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
    style="height: 80px; margin-left: 60px; margin-right: 60px"
  >
    <div fxFlex="1 1 100" class="login-brand" style="margin-top: 0px">
      {{ 'key_chooseSystems' | translate }}
    </div>
  </div>
  <div style="margin-top: 50px">
    <div *ngFor="let system of systems">
      <mat-card *ngIf="system.enabled" style="margin-top: 20px">
        <mat-card-content>
          <div fxLayout="row">
            <div fxFlex="none" style="width: 60px">
              <mat-icon class="accent-text-color" style="font-size: 50px">{{
                system.icon
              }}</mat-icon>
            </div>
            <div fxFlex="auto" fxLayout="column" style="width: 100%">
              <div fxFlex="none" style="font-size: 18px">
                {{ system.displayName | translate }}
              </div>
              <div
                fxFlex="auto"
                class="accent-text-color"
                style="font-size: 12px; line-height: 16px; margin-top: 5px"
              >
                {{ system.description | translate }}
              </div>
            </div>
            <div
              *ngIf="enableVerifiedId && system.useVerifiedId"
              fxFlex="none"
              style="margin-left: 10px; margin-top: 8px"
            >
              <button mat-icon-button (click)="onVerifyId(system)">
                <mat-icon
                  class="accent-text-color"
                  style="font-size: 40px; margin-left: -15px"
                  >qr_code_2</mat-icon
                >
              </button>
            </div>
            <div fxFlex="none" style="margin-top: -15px; margin-bottom: 5px">
              <div
                style="
                  margin: 10px;
                  border-left: solid 1px lightgray;
                  height: 100%;
                "
              ></div>
            </div>
            <div
              fxFlex="none"
              fxLayout="column"
              fxLayoutAlign="center center"
              style="width: 60px"
            >
              <div fxFlex="none">
                <button mat-icon-button (click)="onGotoSystem(system)">
                  <mat-icon
                    class="accent-text-color"
                    style="font-size: 40px; margin-left: -15px"
                    >keyboard_arrow_right</mat-icon
                  >
                </button>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

<div *ngIf="useVerifiedId; else noVerifiedId">
  <div
    [@flyIn]="animSignin"
    class="qr-form"
    [class.mat-elevation-z8]="true"
    fxLayout="column"
    fxLayoutAlign="center center"
  >
    <div *ngIf="presentationError" style="font-size: 1.2em" class="warn-color">
      {{ presentationError }}
    </div>
    <div
      *ngIf="presentationState && !presentationError"
      style="font-size: 1.2em; margin-bottom: 20px"
    >
      {{ presentationState }}
    </div>
    <mat-spinner *ngIf="presentationLoading" fxFlex="1 0 none"></mat-spinner>
    <img
      *ngIf="
        presentationQR &&
        !presentationError &&
        !presentationCompleted &&
        !presentationState
      "
      [src]="presentationQR"
    />
    <div style="cursor: pointer; position: absolute; right: 20px; bottom: -5px">
      <mat-icon
        matTooltip="{{ 'key_goBack' | translate }}"
        matTooltipPosition="left"
        class="accent-text-color"
        (click)="onGoBack()"
        >backspace</mat-icon
      >
    </div>
  </div>
</div>
<ng-template #noVerifiedId>
  <div
    [@flyIn]="animSignin"
    [@classicLogin]="classicLogin"
    class="login-form"
    [class.mat-elevation-z8]="true"
  >
    <div
      class="login-header"
      [class.mat-elevation-z8]="true"
      fxLayout="row wrap"
      fxLayoutAlign="center start"
    >
      <div fxFlex="1 1 100" class="login-brand">
        {{ 'key_login' | translate }}
      </div>
      <div
        *ngIf="displayMode === 'mix' || displayMode === 'windows'"
        fxFlex="auto"
        fxLayout="column"
        fxLayoutAlign="start center"
        class="login-icon"
      >
        <div fxFlex="auto">
          <fa-icon
            [icon]="['fab', 'windows']"
            matTooltip="{{ 'key_windowsLogin' | translate }}"
            (click)="onWindowsLogin()"
          ></fa-icon>
        </div>
        <div fxFlex="auto" class="login-spinner" *ngIf="signingWindows">
          <mat-spinner [diameter]="20" color="white"></mat-spinner>
        </div>
      </div>
      <div
        *ngIf="displayMode === 'mix' || displayMode === 'basic'"
        fxFlex="auto"
        fxLayout="column"
        fxLayoutAlign="start center"
        class="login-icon"
      >
        <div fxFlex="auto">
          <fa-icon
            icon="user-alt"
            matTooltip="{{ 'key_basicLogin' | translate }}"
            (click)="onClassicLogin()"
          ></fa-icon>
        </div>
        <div fxFlex="auto" class="login-spinner" *ngIf="signingBasic">
          <mat-spinner [diameter]="20" color="white"></mat-spinner>
        </div>
      </div>
      <!-- <div fxFlex="auto" class="login-icon">
      <fa-icon
        icon="cloud"
        matTooltip="{{ 'key_azureLogin' | translate }}"
        (click)="onAzureLogin()"
      ></fa-icon>
    </div> -->
    </div>
    <div *ngIf="classicLogin === 'collapsed'" class="login-footer">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div
          fxFlex="none"
          style="color: indianred; margin-top: -30px; margin-left: 10px"
          *ngIf="invalidUser"
        >
          {{ 'key_invalidUser' | translate }}
        </div>
        <div fxFlex="auto"></div>
        <div
          *ngIf="showConnections"
          fxFlex="none"
          style="
            float: right;
            margin-top: -22px;
            margin-right: 10px;
            cursor: pointer;
          "
        >
          <mat-icon
            matTooltip="{{ 'key_goBack' | translate }}"
            matTooltipPosition="left"
            class="accent-text-color"
            (click)="onGoBack()"
            >backspace</mat-icon
          >
        </div>
      </div>
    </div>
    <div
      @loginForm
      *ngIf="classicLogin === 'expanded'"
      class="login-input"
      fxLayout="column"
    >
      <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <fa-icon
          fxFlex="0 0 auto"
          class="login-input-icon accent-text-color"
          icon="user-circle"
        ></fa-icon>
        <mat-form-field fxFlex="auto">
          <input
            #txtUserName
            matInput
            [placeholder]="'key_userName' | translate"
            [(ngModel)]="userName"
            (input)="onInputChange()"
          />
        </mat-form-field>
      </div>
      <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
        <fa-icon
          fxFlex="0 0 auto"
          class="login-input-icon accent-text-color"
          icon="unlock-alt"
        ></fa-icon>
        <mat-form-field fxFlex="auto">
          <input
            matInput
            [placeholder]="'key_password' | translate"
            [(ngModel)]="password"
            [type]="hidePwd ? 'password' : 'text'"
            (input)="onInputChange()"
            (keyup.enter)="onBasicLogin()"
          />
          <mat-icon
            matSuffix
            (click)="hidePwd = !hidePwd"
            class="accent-text-color"
            style="cursor: pointer"
            >{{ hidePwd ? 'visibility_off' : 'visibility' }}</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="login-error" *ngIf="invalidUser">
        {{ 'key_invalidUser' | translate }}
      </div>
      <div fxFlex="none" fxLayout="row" fxLayoutAlign="center center">
        <button
          class="login-button"
          mat-stroked-button
          color="primary"
          (click)="onBasicLogin()"
          [disabled]="!userName || !password"
        >
          {{ 'key_submit' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
