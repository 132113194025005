import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  MatMenuTrigger,
  MenuPositionX,
  MenuPositionY,
} from '@angular/material/menu';
import { ActionMenuItem } from '../../models/dataContract.model';
import { ResourceService } from '../../services/resource.service';

@Component({
  selector: 'app-action-menu',
  templateUrl: './action-menu.component.html',
  styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent implements OnInit {
  private reopening = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input()
  menuType = 'icon';

  @Input()
  menuIcon = '';

  @Input()
  menuText = '';

  @Input()
  menuColor = '';

  @Input()
  menuTooltip = '';

  @Input()
  menuItems: Array<ActionMenuItem> = [];

  @Input()
  isLoading = false;

  @Input()
  xPosition: MenuPositionX;

  @Input()
  yPosition: MenuPositionY;

  @Input()
  iconMode = false;

  @Output()
  action = new EventEmitter();

  @Output()
  openMenu = new EventEmitter();

  constructor(private resource: ResourceService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {}

  showItem(permissionSets: Array<string>) {
    if (!permissionSets || permissionSets.length === 0) {
      return true;
    }
    return this.resource.rightSets.some((r) => permissionSets.indexOf(r) >= 0);
  }

  onMenuItemClick(name: string) {
    this.action.emit(name);
  }

  onMenuOpen() {
    if (this.reopening) {
      this.reopening = false;
    } else {
      this.openMenu.emit();
    }
  }

  reopen() {
    if (this.trigger) {
      this.reopening = true;
      this.trigger.closeMenu();
      this.trigger.openMenu();
    }
  }
}
