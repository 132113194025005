import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-historical-status',
  templateUrl: './historical-status.component.html',
  styleUrls: ['./historical-status.component.scss'],
})
export class HistoricalStatusComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  targetType = '';
  targetID = '';
  timestamp = '';
  reportName = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.params
        .pipe(
          tap(() => {
            this.targetType = this.route.snapshot.paramMap.get('type');
            this.targetID = this.route.snapshot.paramMap.get('id');
            this.timestamp = this.route.snapshot.paramMap.get('timestamp');
            this.reportName = this.route.snapshot.paramMap.get('report');
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
