<div fxLayout="column" style="height: 100%; width: 100%; margin-top: -10px">
  <div fxFlex="auto">
    <kendo-splitter
      style="height: 100%; background-color: #eeeeee; border-width: 0"
    >
      <kendo-splitter-pane
        style="padding-bottom: 20px; background-color: white"
      >
        <div
          *ngIf="!noConfig"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="search-input"
        >
          <mat-form-field
            fxFlex="300px"
            appearance="outline"
            style="font-size: 12px; margin-right: 20px; margin-left: 20px"
          >
            <mat-label>{{ 'key_search' | translate }}</mat-label>
            <input
              #searchBox
              matInput
              [(ngModel)]="searchText"
              style="font-size: 14px"
              (keyup)="onKeyUp($event)"
            />
            <span matSuffix>
              <button
                mat-icon-button
                *ngIf="!autoComplete"
                [disabled]="!canSearch"
                [matTooltip]="'key_search' | translate"
                (click)="onSearch()"
                class="search-button-ie"
                style="margin-right: 5px"
              >
                <mat-icon
                  class="accent-text-color"
                  style="font-size: 22px"
                  [ngClass]="{ 'disabled-button': !canSearch }"
                  >input</mat-icon
                >
              </button>
            </span>
          </mat-form-field>
          <div
            class="accent-text-color"
            style="margin-top: -15px; margin-right: 20px"
          >
            {{ 'l10n_selectAttributes' | translate }}
          </div>
          <div fxFlex="auto" style="font-size: 12px">
            <app-attribute-picker
              [appearance]="'outline'"
              [objectType]="searchScope.type"
              [multiple]="true"
              [availableItems]="availableAttributes"
              [preSelectedItems]="selectedAttributes"
              (attributeChange)="onAttributeChange($event)"
            ></app-attribute-picker>
          </div>
          <div fxFlex="auto"></div>
          <div fxFlex="none" style="margin-bottom: 10px; margin-right: 20px">
            <app-action-menu
              #actionMenu
              [menuType]="'button'"
              [menuIcon]="'menu_open'"
              [menuText]="'key_action' | translate"
              [menuColor]="'primary'"
              (action)="onMenuAction($event)"
              (openMenu)="onMenuOpen()"
            ></app-action-menu>
          </div>
        </div>
        <div
          *ngIf="noConfig"
          fxLayout="column"
          fxLayoutAlign="center center"
          style="height: 90%; margin-top: 40px"
        >
          <mat-icon
            fxFlex="none"
            style="font-size: 100px; width: 100px; color: #cccccc"
            >contact_support</mat-icon
          >
          <div
            fxFlex="none"
            style="
              margin-top: 50px;
              padding-top: 50px;
              font-size: 18px;
              color: #b0b0b0;
            "
          >
            {{ 'key_noConfiguration' | translate }}
          </div>
        </div>
        <div *ngIf="!noConfig" class="resource-table">
          <app-resource-table
            #resourceTable
            [config]="tableConfig"
          ></app-resource-table>
        </div>
      </kendo-splitter-pane>
      <kendo-splitter-pane
        [collapsible]="true"
        [collapsed]="!showDetail"
        size="50%"
        style="padding: 50px 0 0 10px; overflow: hidden"
      >
        <!-- <div style="float: right; margin-top: -650px; color: #b0b0b0;">
          <button mat-icon-button (click)="onCloseDetail()">
            <mat-icon>close</mat-icon>
          </button>
        </div> -->
        <div style="margin-top: -20px; padding-bottom: 20px">
          <router-outlet></router-outlet>
        </div>
      </kendo-splitter-pane>
    </kendo-splitter>
  </div>
</div>
