import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomisationDemoComponent } from './components/customisation-demo/customisation-demo.component';

const routes: Routes = [
  {
    path: 'customdemo',
    component: CustomisationDemoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CustomRoutingModule {}
