import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';

//#region offboarding implementation

import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';

import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, delay, finalize, switchMap, tap } from 'rxjs/operators';

import { AttributeResource, ResourceSet } from 'src/app/core/models/dataContract.model';
import { ModalType } from 'src/app/core/models/componentContract.model';
import { IdentityEditorConfig } from 'src/app/core/models/editorContract.model';

import { ResourceService } from 'src/app/core/services/resource.service';
import { UtilsService } from 'src/app/core/services/utils.service';
import { ModalService } from 'src/app/core/services/modal.service';

import { ModalComponent } from 'src/app/core/components/modal/modal.component';

//#endregion

import { CustomComponent } from 'src/app/core/models/dynamicEditor.interface';
import { TreeViewComponent } from 'src/app/core/components/tree-view/tree-view.component';

//#region offboarding implementation

class groupReassignment {
  group: { DisplayName: string; ObjectType: string; ObjectID: string };
  owner: AttributeResource;
  isDisplayedOwner: boolean;
}

//#endregion

@Component({
  selector: 'app-customisation-demo',
  templateUrl: './customisation-demo.component.html',
  styleUrls: ['./customisation-demo.component.scss'],
})
export class CustomisationDemoComponent
  extends CustomComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('orgStructure', { static: true }) orgStructure: TreeViewComponent;

  constructor(private injector: Injector, private utils: UtilsService) {
    super(injector);
  }

  ngOnInit(): void {
    //#region offboarding implementation
    // this.initComponent();
    //#endregion
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.orgStructure && this.componentValue) {
        const currentID = this.utils.ExtraValue(this.componentValue, 'ObjectID');
        if (currentID) {
          const query = `/ocgAssignment[ocgLinkTargetRef='${currentID}' and ocgObjectType='OUAssignment' and ocgPriority=1]/ocgLinkSourceRef`;
          this.orgStructure.config.initQuery = query;
          this.orgStructure.refresh();
          // this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //#region offboarding implementation

  // idpConfig = new IdentityEditorConfig();

  // constructor(
  //   injector: Injector,
  //   private resource: ResourceService,
  //   private utils: UtilsService,
  //   private modal: ModalService
  // ) {
  //   super(injector);
  // }

  // reassignGroups: Array<groupReassignment> = [];

  // isActive = false;

  // get canReassign() {
  //   if (!this.reassignGroups || this.reassignGroups.length === 0) {
  //     return true;
  //   }

  //   return this.reassignGroups.findIndex((r) => r.owner.value === null) < 0;
  // }

  // get displayContent() {
  //   if (this.isActive) {
  //     return 'assigned';
  //   } else if (this.reassignGroups.length === 0) {
  //     return 'empty';
  //   } else {
  //     return 'assign';
  //   }
  // }

  // initComponent() {
  //   this.reassignGroups = [];
  //   this.isActive = this.utils.ExtraValue(this.currentResource, 'ocgIsActive:value');
  //   const originID = this.utils.ExtraValue(this.currentResource, 'ocgOriginRef:value:ObjectID');
  //   this.idpConfig = {
  //     showPhoto: true,
  //     popupWidth: 460,
  //     attributesToShow: [
  //       { name: 'DisplayName', width: 0 },
  //       { name: 'AccountName', width: 0 },
  //     ],
  //     queryNormalSearch: `/Person[starts-with(DisplayName,'%SearchText%') and not(ObjectID='${originID}')]`,
  //   };
  //   if (originID) {
  //     if (this.componentValue) {
  //       this.reassignGroups = JSON.parse(this.componentValue);
  //     } else {
  //       const queryOwner = `/Group[Owner='${originID}']`;
  //       this.subscription.add(
  //         this.resource
  //           .getResourceByQuery(queryOwner, ['DisplayName', 'Owner', 'DisplayedOwner'])
  //           .pipe(
  //             tap((result: ResourceSet) => {
  //               if (result.totalCount > 0) {
  //                 result.results.forEach((res) => {
  //                   this.reassignGroups.push({
  //                     group: {
  //                       DisplayName: res.DisplayName,
  //                       ObjectType: res.ObjectType,
  //                       ObjectID: res.ObjectID,
  //                     },
  //                     owner: {
  //                       displayName: '',
  //                       multivalued: true,
  //                       systemName: '',
  //                       dataType: 'Reference',
  //                       permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
  //                       value: null,
  //                       values: null,
  //                     },
  //                     isDisplayedOwner: res.DisplayedOwner === originID,
  //                   });
  //                 });
  //               }
  //             })
  //           )
  //           .subscribe()
  //       );
  //     }
  //   }
  // }

  // onOwnerChange() {
  //   this.componentValue = JSON.stringify(this.reassignGroups);
  // }

  // onReassign() {
  //   const confirm = this.modal.show(
  //     ModalType.confirm,
  //     'key_confirmation',
  //     'Are you sure to submit all assignments?'
  //   );
  //   let process: MatDialogRef<ModalComponent, any>;
  //   this.subscription.add(
  //     confirm
  //       .afterClosed()
  //       .pipe(
  //         // add new owner
  //         switchMap((dialogResult: string) => {
  //           if (dialogResult === 'yes') {
  //             const observableBatch = [];
  //             process = this.modal.show(ModalType.progress, 'key_processing', '', '300px');
  //             if (this.reassignGroups.length === 0) {
  //               return of('noneed');
  //             }
  //             this.reassignGroups.forEach((g) => {
  //               const originID = this.utils.ExtraValue(
  //                 this.currentResource,
  //                 'ocgOriginRef:value:ObjectID'
  //               );
  //               const groupID = this.utils.ExtraValue(g.group, 'ObjectID:value');
  //               const ownerID = this.utils.ExtraValue(g.owner, 'value:ObjectID');
  //               if (originID && groupID && ownerID) {
  //                 observableBatch.push(this.resource.addResourceValue(groupID, 'Owner', [ownerID]));
  //               }
  //             });
  //             return forkJoin(observableBatch);
  //           } else {
  //             return EMPTY;
  //           }
  //         }),
  //         delay(2000),
  //         // set displayed owner
  //         switchMap((httpResult: any) => {
  //           if (httpResult === 'noneed') {
  //             return of('noneed');
  //           }
  //           if (httpResult !== EMPTY) {
  //             const observableBatch = [];
  //             this.reassignGroups.forEach((g) => {
  //               const groupID = this.utils.ExtraValue(g.group, 'ObjectID:value');
  //               const ownerID = this.utils.ExtraValue(g.owner, 'value:ObjectID');
  //               if (groupID && ownerID) {
  //                 if (g.isDisplayedOwner) {
  //                   const groupToUpdate = this.utils.DeepCopy(g.group);
  //                   groupToUpdate.DisplayedOwner = ownerID;
  //                   observableBatch.push(this.resource.updateResource(groupToUpdate));
  //                 }
  //               }
  //             });
  //             return forkJoin(observableBatch);
  //           } else {
  //             return EMPTY;
  //           }
  //         }),
  //         delay(2000),
  //         // remove old owner
  //         switchMap((httpResult: any) => {
  //           if (httpResult === 'noneed') {
  //             return of('noneed');
  //           }
  //           if (httpResult !== EMPTY) {
  //             const observableBatch = [];
  //             this.reassignGroups.forEach((g) => {
  //               const originID = this.utils.ExtraValue(
  //                 this.currentResource,
  //                 'ocgOriginRef:value:ObjectID'
  //               );
  //               const groupID = this.utils.ExtraValue(g.group, 'ObjectID:value');
  //               if (originID && groupID) {
  //                 observableBatch.push(
  //                   this.resource.removeResourceValue(groupID, 'Owner', [originID])
  //                 );
  //               }
  //             });
  //             return forkJoin(observableBatch);
  //           } else {
  //             return EMPTY;
  //           }
  //         }),
  //         // update transfer object
  //         switchMap((httpResult: any) => {
  //           if (httpResult !== EMPTY) {
  //             const transferToUpdate = {
  //               ObjectType: this.utils.ExtraValue(this.currentResource, 'ObjectType:value'),
  //               ObjectID: this.utils.ExtraValue(this.currentResource, 'ObjectID:value'),
  //               ocgIsActive: true,
  //             };
  //             return this.resource.updateResource(transferToUpdate);
  //           } else {
  //             return EMPTY;
  //           }
  //         }),
  //         catchError((err: HttpErrorResponse) => {
  //           this.modal.show(ModalType.error, 'key_error', err.error);
  //           if (process) {
  //             process.close();
  //           }
  //           return EMPTY;
  //         }),
  //         finalize(() => {
  //           if (process) {
  //             process.close();
  //           }
  //           this.swap.broadcast({ name: 'refresh-attribute' });
  //         })
  //       )
  //       .subscribe()
  //   );
  // }

  //#endregion

  //#region example code for custom component

  onSelectionChange(event: any) {
    if (event && event.dataItem && event.dataItem.DisplayName) {
      this.componentValue = event.dataItem.DisplayName;
    }
  }

  //#endregion
}
