import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridType,
} from 'angular-gridster2';
import { EMPTY, Subscription, of } from 'rxjs';

import { BroadcastEvent, PopupConfig } from '../core/models/dataContract.model';
import {
  ModalType,
  ReportCardConfig,
  ReportSeparatorConfig,
} from '../core/models/componentContract.model';
import { GridsterComponentItem } from '../core/models/dynamicComponent.interface';

import { ResourceService } from '../core/services/resource.service';
import { SwapService } from '../core/services/swap.service';
import { UtilsService } from '../core/services/utils.service';
import { ModalService } from '../core/services/modal.service';

import { ReportCardComponent } from '../core/components/report-card/report-card.component';

import {
  WindowCloseResult,
  WindowRef,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { ReportCardConfigComponent } from '../core/components/report-card/report-card-config.component';
import { ConfigService } from '../core/services/config.service';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ReportCreatorComponent } from '../core/components/report-creator/report-creator.component';
import { TranslateService } from '@ngx-translate/core';
import { ReportSeparatorConfigComponent } from '../core/components/report-separator-config/report-separator-config.component';

import * as moment from 'moment';
import { ReportSeparatorComponent } from '../core/components/report-separator/report-separator.component';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../core/components/modal/modal.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  private compactType = CompactType.None;

  @ViewChildren('reportCard')
  reportCards: QueryList<ReportCardComponent>;

  @ViewChildren('reportSeparator')
  reportSeparators: QueryList<ReportSeparatorComponent>;

  editMode = false;
  buttonColor = 'slategray';

  gdOptions: GridsterConfig;

  gdItems: Array<GridsterComponentItem> = null;

  private loadGridsterItems(loadHiddenItems: boolean) {
    this.gdItems = [];

    const viewSetting = this.utils.parseComponentConfig(
      this.utils.DeepCopy(this.resource.primaryViewString)
    );
    if (viewSetting && viewSetting.reports && viewSetting.reports.components) {
      if (loadHiddenItems) {
        this.gdItems = this.utils.parseComponentConfig(
          this.utils.DeepCopy(this.resource.primaryViewString)
        ).reports.components as Array<GridsterComponentItem>;
      } else {
        viewSetting.reports.components.forEach(
          (item: GridsterComponentItem) => {
            if (
              this.resource.inPermissionSets(
                item.componentConfig.permissionSets
              )
            ) {
              this.gdItems.push(item);
            }
          }
        );
      }
    }
  }

  private exportReports() {
    if (
      this.resource.primaryViewSetting &&
      this.resource.primaryViewSetting.reports
    ) {
      this.clipboard.copy(
        JSON.stringify(this.resource.primaryViewSetting.reports)
      );
    } else {
      this.modal.show(ModalType.info, 'key_info', 'key_noConfigToExport');
    }
  }

  private importReports() {
    const popupConfig: PopupConfig = new PopupConfig();
    popupConfig.title = 'key_import';
    popupConfig.style = 'outline';
    popupConfig.data = {
      name: {
        text: 'key_name',
        value: undefined,
        type: 'text',
        required: false,
        focused: false,
        validation: '^[a-z0-9]*$',
      },
      settings: {
        text: 'key_settings',
        value: '',
        type: 'textarea',
        rows: 6,
        required: true,
        focused: true,
      },
    };

    let settingsToImport: any;
    let process: MatDialogRef<ModalComponent, any>;
    let importType = '';
    let customName = '';
    let reportPos = -1;

    this.subscription.add(
      this.modal
        .popup(popupConfig)
        .pipe(
          switchMap((windowResult: any) => {
            if (!(windowResult instanceof WindowCloseResult)) {
              settingsToImport = JSON.parse(windowResult.settings.value);
              if (settingsToImport.type === 'report') {
                importType = 'part';
                customName = windowResult.name.value;
                if (customName) {
                  settingsToImport.name = customName;
                }
                settingsToImport = {
                  x: 0,
                  y: 0,
                  cols: 6,
                  rows: 2,
                  name: customName ?? settingsToImport.name,
                  componentType: '',
                  componentConfig: settingsToImport,
                };
                reportPos = this.gdItems.findIndex(
                  (item: GridsterComponentItem) => {
                    return item.name === settingsToImport.name;
                  }
                );
                if (reportPos >= 0) {
                  return this.modal
                    .show(
                      ModalType.confirm,
                      'key_confirmation',
                      `${this.translate.instant(
                        'key_confirmOverwriteReportingSettingsFor'
                      )} ${settingsToImport.name}?`
                    )
                    .afterClosed();
                } else {
                  return of('yes');
                }
              } else if (settingsToImport.components) {
                importType = 'full';
                return this.modal
                  .show(
                    ModalType.confirm,
                    'key_confirmation',
                    'key_confirmImportSettings'
                  )
                  .afterClosed();
              } else {
                this.modal.show(
                  ModalType.error,
                  'key_error',
                  'key_invalidSetting'
                );
              }
            }
            return EMPTY;
          }),
          switchMap((dialogResult: any) => {
            if (dialogResult && dialogResult === 'yes') {
              process = this.modal.show(
                ModalType.progress,
                'key_savingChanges',
                '',
                '300px'
              );
              if (importType === 'full') {
                this.resource.primaryViewSetting.reports = settingsToImport;
              } else {
                if (
                  this.resource.primaryViewSetting.reports &&
                  this.resource.primaryViewSetting.reports.components
                ) {
                  if (reportPos >= 0) {
                    this.resource.primaryViewSetting.reports.components[
                      reportPos
                    ] = settingsToImport;
                  } else {
                    this.resource.primaryViewSetting.reports.components.push(
                      settingsToImport
                    );
                  }
                } else {
                  this.resource.primaryViewSetting.reports = {
                    components: [settingsToImport],
                  };
                }
              }
              return this.resource.updateUISettings();
            }
            return EMPTY;
          }),
          tap((updateResult: string) => {
            if (updateResult === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_error',
                'key_uiRefreshNeeded'
              );
            } else {
              location.reload();
            }
          }),
          catchError((error: any) => {
            this.modal.show(
              ModalType.error,
              'key_error',
              this.utils.getServiceError(error)
            );
            return EMPTY;
          }),
          finalize(() => {
            if (process) {
              process.close();
            }
          })
        )
        .subscribe()
    );
  }

  constructor(
    private swap: SwapService,
    private resource: ResourceService,
    private utils: UtilsService,
    private modal: ModalService,
    private window: WindowService,
    private configService: ConfigService,
    private translate: TranslateService,
    private clipboard: ClipboardService,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.editMode = this.swap.editMode;

    this.gdOptions = {
      gridType: GridType.Fixed,
      compactType: this.compactType,
      margin: 10,
      outerMargin: true,
      outerMarginTop: null,
      outerMarginRight: null,
      outerMarginBottom: null,
      outerMarginLeft: null,
      useTransformPositioning: true,
      mobileBreakpoint: 640,
      minCols: 1,
      maxCols: 100,
      minRows: 1,
      maxRows: 100,
      maxItemCols: 100,
      minItemCols: 1,
      maxItemRows: 100,
      minItemRows: 1,
      maxItemArea: 2500,
      minItemArea: 1,
      defaultItemCols: 1,
      defaultItemRows: 1,
      fixedColWidth: 50,
      fixedRowHeight: 50,
      keepFixedHeightInMobile: false,
      keepFixedWidthInMobile: false,
      scrollSensitivity: 10,
      scrollSpeed: 20,
      enableEmptyCellClick: false,
      enableEmptyCellContextMenu: false,
      enableEmptyCellDrop: false,
      enableEmptyCellDrag: false,
      emptyCellDragMaxCols: 50,
      emptyCellDragMaxRows: 50,
      ignoreMarginInRow: false,
      draggable: {
        enabled: true,
      },
      resizable: {
        enabled: true,
      },
      swap: true,
      pushItems: true,
      disablePushOnDrag: false,
      disablePushOnResize: false,
      pushDirections: { north: true, east: true, south: true, west: true },
      pushResizeItems: false,
      displayGrid: DisplayGrid.Always,
      disableWindowResize: false,
      disableWarnings: false,
      scrollToNewItems: false,
    };

    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'start-edit':
              this.editMode = this.resource.isAdminViewSet ? true : false;
              this.onGridsterEdit();
              break;
            case 'exit-edit':
              this.editMode = false;
              this.onGridsterCancel();
              break;
            case 'edit-add':
              if (this.gdItems) {
                this.onGridsterAdd();
              }
              break;
            case 'edit-save':
              if (this.gdItems) {
                this.onGridsterSave();
              }
              break;
            default:
              break;
          }
        }
      })
    );

    this.subscription.add(
      this.clipboard.copyResponse$.subscribe((res: IClipboardResponse) => {
        if (res.isSuccess) {
          this.snackbar.open(this.translate.instant('key_textCopied'), 'OK', {
            duration: 2000,
          });
        }
      })
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.editMode) {
        this.onGridsterEdit();
      } else {
        this.loadGridsterItems(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.swap.broadcast({ name: 'exit-edit-mode', parameter: true });
    this.subscription.unsubscribe();
  }

  hasPermission(item: GridsterComponentItem) {
    if (item && item.componentConfig) {
      return this.resource.inPermissionSets(
        item.componentConfig.permissionSets
      );
    }
    return true;
  }

  onGridsterEdit() {
    this.loadGridsterItems(true);

    this.gdOptions.compactType = CompactType.None;
    this.gdOptions.draggable.enabled = true;
    this.gdOptions.resizable.enabled = true;
    this.gdOptions.displayGrid = DisplayGrid.Always;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }
  }

  onGridsterCancel() {
    const viewSetting = this.utils.parseComponentConfig(
      this.utils.DeepCopy(this.resource.primaryViewString)
    );

    if (viewSetting && viewSetting.reports) {
      this.resource.primaryViewSetting.reports = viewSetting.reports;
    }

    this.loadGridsterItems(false);

    this.gdOptions.compactType = this.compactType;
    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }
  }

  onGridsterAdd() {
    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    let dialogRef: WindowRef;

    const windowRef = this.window.open({
      content: ReportCreatorComponent,
      width: 600,
      height: 520,
      title: this.translate.instant('key_addReport'),
    });

    let keyName = '';

    this.subscription.add(
      windowRef.result
        .pipe(
          switchMap((result: any) => {
            if (!(result instanceof WindowCloseResult)) {
              keyName = result.name;
              switch (result.name) {
                case 'key_report': {
                  const itemConfig = new ReportCardConfig();
                  dialogRef = this.window.open({
                    content: ReportCardConfigComponent,
                    width: 800,
                    height: 680,
                    top: this.configService.getConfig(
                      'wizardTopPosition',
                      null
                    ),
                  });
                  const windowIns = dialogRef.content.instance;
                  windowIns.data = itemConfig;
                  windowIns.existingReportNames = this.gdItems
                    ? this.gdItems.map((item) => item.name)
                    : [];

                  return dialogRef.result;
                }
                case 'key_separator': {
                  const itemConfig = new ReportSeparatorConfig();
                  itemConfig.name = `sep${moment().format('YYYYMMDDHHmmss')}`;
                  dialogRef = this.window.open({
                    content: ReportSeparatorConfigComponent,
                    width: 800,
                    height: 680,
                    top: this.configService.getConfig(
                      'wizardTopPosition',
                      null
                    ),
                  });
                  const windowIns = dialogRef.content.instance;
                  windowIns.data = itemConfig;

                  return dialogRef.result;
                }
                case 'key_importReports': {
                  this.importReports();
                  return EMPTY;
                }
                case 'key_exportReports': {
                  this.exportReports();
                  return EMPTY;
                }
                default:
                  return EMPTY;
              }
            } else {
              return EMPTY;
            }
          }),
          tap((dialogResult: any) => {
            if (!(dialogResult instanceof WindowCloseResult)) {
              dialogResult = this.utils.removeUndefinedProperty(dialogResult);
              const gdItem: GridsterComponentItem = {
                x: 0,
                y: 0,
                cols: 5,
                rows: dialogResult.type === 'separator' ? 1 : 2,
                name: dialogResult.name,
                componentType: dialogResult.type,
                componentConfig: dialogResult,
              };
              this.gdItems.push(gdItem);
            }
          }),
          finalize(() => {
            if (keyName !== 'key_importReports') {
              this.swap.broadcast({
                name: 'hide-overlay',
                parameter: undefined,
              });
            }
          })
        )
        .subscribe()
    );
  }

  onGridsterSave() {
    this.resource.primaryViewSetting.reports = {
      components: this.gdItems,
    };
    this.resource.primaryViewString = this.utils.stringifyComponentConfig(
      this.resource.primaryViewSetting
    );
    this.resource.primaryViewSet[this.utils.attConfiguration] =
      this.resource.primaryViewString;

    this.gdOptions.compactType = this.compactType;
    this.gdOptions.draggable.enabled = false;
    this.gdOptions.resizable.enabled = false;
    this.gdOptions.displayGrid = DisplayGrid.None;
    if (this.gdOptions.api) {
      this.gdOptions.api.optionsChanged();
    }

    const process = this.modal.show(
      ModalType.progress,
      'key_savingChanges',
      '',
      '300px'
    );
    this.subscription.add(
      this.resource
        .updateUISettings()
        .pipe(
          tap((result: string) => {
            if (result === 'expired') {
              this.modal.show(
                ModalType.error,
                'key_warning',
                'key_uiRefreshNeeded'
              );
            } else {
              this.loadGridsterItems(false);
            }
          }),
          finalize(() => {
            process.close();
          }),
          catchError((err: HttpErrorResponse) => {
            process.close();
            this.modal.show(ModalType.error, 'key_error', err.message, '360px');
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  onGridsterConfig(event: Event, item: GridsterComponentItem) {
    if (item && item.componentConfig) {
      event.preventDefault();
      event.stopPropagation();

      switch (item.componentConfig.type) {
        case undefined:
        case '':
        case 'report':
          {
            const reportCardInstance = this.reportCards.find(
              (report: ReportCardComponent) => {
                return report.config.name === item.componentConfig.name;
              }
            );

            if (reportCardInstance) {
              reportCardInstance.existingReportNames = this.gdItems
                ? this.gdItems.map((entry) => entry.name)
                : [];

              this.subscription.add(
                reportCardInstance
                  .configure()
                  .subscribe((reportConfig: ReportCardConfig) => {
                    if (reportConfig && this.gdItems) {
                      const pos = this.gdItems.findIndex(
                        (entry: GridsterComponentItem) => {
                          return entry.name === item.name;
                        }
                      );
                      if (pos >= 0) {
                        reportConfig =
                          this.utils.removeUndefinedProperty(reportConfig);
                        this.gdItems[pos].componentConfig = reportConfig;
                        this.gdItems[pos].name = reportConfig.name;
                      }
                    }
                  })
              );
            }
          }
          break;
        case 'separator':
          {
            const separatorInstance = this.reportSeparators.find(
              (separator: ReportSeparatorComponent) => {
                return separator.config.name === item.componentConfig.name;
              }
            );

            if (separatorInstance) {
              this.subscription.add(
                separatorInstance
                  .configure()
                  .subscribe((separatorConfig: ReportSeparatorConfig) => {
                    if (separatorConfig && this.gdItems) {
                      const pos = this.gdItems.findIndex(
                        (entry: GridsterComponentItem) => {
                          return entry.name === item.name;
                        }
                      );
                      if (pos >= 0) {
                        separatorConfig =
                          this.utils.removeUndefinedProperty(separatorConfig);
                        this.gdItems[pos].componentConfig = separatorConfig;
                        this.gdItems[pos].name = separatorConfig.name;
                      }
                    }
                  })
              );
            }
          }
          break;
        default:
          break;
      }
    }
  }

  onGridsterDelete(event: Event, item: GridsterComponentItem) {
    event.preventDefault();
    event.stopPropagation();
    this.gdItems.splice(this.gdItems.indexOf(item), 1);
  }
}
