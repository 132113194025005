import { Injectable } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';
import { EMPTY, forkJoin } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import {
  WindowService,
  WindowCloseResult,
} from '@progress/kendo-angular-dialog';

import { Resource } from '../core/models/dataContract.model';
import { TransService } from '../core/models/translation.model';
import { ModalType } from '../core/models/componentContract.model';

import { ModalService } from '../core/services/modal.service';
import { ResourceService } from '../core/services/resource.service';

import { B2bCreationComponent } from '../b2b-creation/b2b-creation.component';
import { ModalComponent } from '../core/components/modal/modal.component';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private modal: ModalService,
    private resource: ResourceService,
    private translate: TransService,
    private window: WindowService
  ) {}

  createB2BUser() {
    let progress: MatDialogRef<ModalComponent, any>;

    const windowRef = this.window.open({
      title: this.translate.instant('l10n_inviteB2BUsers'),
      content: B2bCreationComponent,
      titleBarContent: null,
      width: 600,
    });
    return windowRef.result.pipe(
      switchMap((result: any) => {
        if (result instanceof WindowCloseResult) {
          return EMPTY;
        } else {
          progress = this.modal.show(
            ModalType.progress,
            'key_savingChanges',
            '',
            '300px'
          );

          const observableBatch = [];
          result.forEach((user: any) => {
            const invitedUser: Resource = {
              DisplayName: user.displayName,
              ObjectType: 'Person',
              Email: user.email,
              ocgObjectType: 'b2b',
              ocgObjectStatus: 'PendingAcceptance',
            };
            if (user.sponsorID) {
              invitedUser.Manager = user.sponsorID;
            }
            observableBatch.push(this.resource.createResource(invitedUser));
          });

          return forkJoin(observableBatch);
        }
      }),
      finalize(() => {
        if (progress) {
          progress.close();
        }
      })
    );
  }
}
