<!-- Approval View -->
<div
  fxFill
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px"
>
  <!-- Approvals -->
  <div fxFlex="50" class="widget">
    <!-- Settings -->
    <div *ngIf="editMode" class="widget-config">
      <button
        mat-mini-fab
        [style.background]="buttonColor"
        (click)="onConfig($event, isCloud ? ApprovalsCloud : Approvals)"
      >
        <mat-icon>settings</mat-icon>
      </button>
    </div>

    <!-- Widget -->
    <div *ngIf="isCloud; else approversOnPrem">
      <app-approval-check-cloud
        #ApprovalsCloud
        [config]="approvalViewConfig.approvals"
      ></app-approval-check-cloud>
    </div>
    <ng-template #approversOnPrem>
      <app-approval-check
        #Approvals
        [config]="approvalViewConfig.approvals"
      ></app-approval-check>
    </ng-template>
  </div>

  <!-- Requests -->
  <div class="widget" fxFlex="50">
    <!-- Settings -->
    <div *ngIf="editMode" class="widget-config">
      <button
        mat-mini-fab
        [style.background]="buttonColor"
        (click)="onConfig($event, isCloud ? RequestsCloud : Requests)"
      >
        <mat-icon>settings</mat-icon>
      </button>
    </div>

    <!-- Widget -->
    <div *ngIf="isCloud; else requestsOnPrem">
      <app-approval-check-cloud
        #RequestsCloud
        [config]="approvalViewConfig.requests"
      ></app-approval-check-cloud>
    </div>
    <ng-template #requestsOnPrem>
      <app-approval-check
        #Requests
        [config]="approvalViewConfig.requests"
      ></app-approval-check>
    </ng-template>
  </div>
</div>
