import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  Injector,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { TransService } from '../core/models/translation.model';

@Component({
  selector: 'app-popup-assignment',
  templateUrl: './popup-assignment.component.html',
  styleUrls: ['./popup-assignment.component.scss'],
})
export class PopupAssignmentComponent implements OnInit, AfterViewInit {
  private windowRef: WindowRef;

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  validFrom: Date;
  validTo: Date;

  format: string;

  constructor(private injector: Injector, private translate: TransService) {}

  ngOnInit(): void {
    const dateFormat = this.translate.instant('key_dateFormat');
    const timeFormat = this.translate.instant('key_timeFormat');
    this.format = `${dateFormat} ${timeFormat}`;
  }

  ngAfterViewInit() {
    this.windowRef = this.injector.get(WindowRef);
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    if (this.windowRef) {
      this.windowRef.close({
        validFrom: this.validFrom,
        validTo: this.validTo,
      });
    }
  }
}
