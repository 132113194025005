<div
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 90%; margin-top: 20px"
>
  <mat-icon fxFlex="none" style="font-size: 100px; width: 100px; color: #cccccc"
    >error</mat-icon
  >
  <div
    fxFlex="none"
    style="margin-top: 50px; padding-top: 50px; font-size: 18px; color: #b0b0b0"
  >
    {{ 'key_wentWrong' | translate }}
  </div>
</div>
