/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanDeactivate,
} from '@angular/router';

import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: (
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RouteGuardService
  implements CanActivate, CanDeactivate<CanComponentDeactivate>
{
  constructor(private router: Router, private resource: ResourceService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.resource.isLoaded && this.resource.isConfigured) {
      return true;
    }

    return false;
  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return component.canDeactivate(currentRoute, currentState, nextState);
  }
}
