<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" style="font-weight: 400">
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="disablePreupdateTrigger"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_disablePreupdateTrigger' | translate }}</mat-checkbox
      >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="disablePostupdateTrigger"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_disablePostupdateTrigger' | translate }}</mat-checkbox
      >
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      style="font-weight: 400; margin-top: 15px; margin-bottom: 10px"
    >
      <mat-checkbox
        fxFlex="50"
        [(ngModel)]="useRequestorAsActor"
        [ngModelOptions]="{ standalone: true }"
        (change)="onImpersonationChange()"
        >{{ 'key_useRequestorAsActor' | translate }}</mat-checkbox
      >
      <mat-checkbox
        *ngIf="useRequestorAsActor"
        fxFlex="50"
        [(ngModel)]="skipPermissionCheck"
        [ngModelOptions]="{ standalone: true }"
        >{{ 'key_skipPermissionCheck' | translate }}</mat-checkbox
      >
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 20px">
      <app-editor-text
        fxFlex="100"
        name="txtDeletionTarget"
        [(ngModel)]="deletionTarget"
        [config]="{
          isSimpleValue: true,
          hintLimit: 100,
          customDisplayName: 'key_deletionTarget',
          customDescription: 'key_deletionTargetDesc',
          updateOn: updateOnBlure,
          validationKey: 'workflow/deleteresources/deletiontarget'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
