import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataStateChangeEvent } from '@progress/kendo-angular-treelist';
import { BehaviorSubject, EMPTY, Observable, of, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import {
  ResourceColumnConfig,
  TreeListConfig,
} from '../../models/componentContract.model';
import { AuthMode, BroadcastEvent } from '../../models/dataContract.model';
import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { AuthService } from '../../services/auth.service';
import { ResourceService } from '../../services/resource.service';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.scss'],
})
export class TreeListComponent implements OnInit, OnDestroy, DynamicComponent {
  private subscription: Subscription = new Subscription();

  private refreshToken = new BehaviorSubject(undefined);

  private pagingToken: string;

  private cachedData: Array<any>;

  @Input()
  config: TreeListConfig;

  @Input()
  adjustRootApiData: (data: any) => Array<any>;

  @Input()
  adjustChildrenApiData: (data: any) => Array<any>;

  get allColumns() {
    if (!this.localConfig) {
      return [];
    }
    return this.localConfig.columns.concat(this.localConfig.dynamicColumns);
  }

  get isCloud(): boolean {
    return this.auth.authMode === AuthMode.azure;
  }

  localConfig: TreeListConfig;

  rootResources: Observable<Array<any>>;

  translationTrigger: number;

  pageIndex = 1;
  maxPageIndex = 1;

  treeListLoading = false;

  constructor(
    private utils: UtilsService,
    private resource: ResourceService,
    private auth: AuthService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.initComponent();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  resize() {}

  initComponent() {
    this.localConfig = new TreeListConfig();

    this.utils.CopyInto(this.config, this.localConfig, true, true);

    this.rootResources = this.refreshToken.pipe(
      switchMap(() => {
        if (
          this.localConfig.apiRootResources &&
          this.localConfig.apiRootResources.path
        ) {
          return this.resource.callApi(
            this.localConfig.apiRootResources.method,
            this.localConfig.apiRootResources.path,
            this.localConfig.apiRootResources.param,
            this.localConfig.apiRootResources.body,
            this.localConfig.apiRootResources.header
          );
        } else {
          return of([]);
        }
      }),
      switchMap((result: any) => {
        if (result) {
          if (
            result.continuationToken &&
            result.continuationToken !== this.pagingToken
          ) {
            if (this.pagingToken) {
              this.resource.removeRefreshToken(this.pagingToken);
            }
            this.pagingToken = result.continuationToken;
            this.resource.registerRefreshToken({
              name: this.localConfig.apiRootResources.pathRefresh,
              path: this.localConfig.apiRootResources.pathRefresh,
              token: this.pagingToken,
            });
          }
          if (this.adjustRootApiData) {
            this.cachedData = this.adjustRootApiData(result);
            return of(this.cachedData);
          } else {
            this.cachedData = result;
            return of(result);
          }
        } else {
          return of([]);
        }
      })
    );

    this.subscription.add(
      this.swap.broadcasted.subscribe((event: BroadcastEvent) => {
        if (event) {
          switch (event.name) {
            case 'refresh-language':
              setTimeout(() => {
                this.translationTrigger = Math.random() * 10;
              });
              break;
            default:
              break;
          }
        }
      })
    );

    return this.localConfig;
  }

  configure() {
    return EMPTY;
  }

  updateDataSource() {
    this.refreshToken.next(undefined);
  }

  getStatusSetting(data: any, col: ResourceColumnConfig) {
    if (!data) {
      return col.fallbackStatus;
    }

    const itemValue = this.utils.ExtraValue(data, col.field + ':value');
    if (!itemValue || !col.showStatus[String(itemValue).toLowerCase()]) {
      return col.fallbackStatus ? col.fallbackStatus : { color: 'goldenrod' };
    }

    return col.showStatus[String(itemValue).toLowerCase()];
  }

  hasChildren = (item: any): boolean => {
    return item[this.localConfig.childCounterName] > 0;
  };

  fetchChildren = (item: any): Observable<Array<any>> => {
    if (
      this.localConfig.apiChildrenResources &&
      this.localConfig.apiChildrenQueryPath
    ) {
      const childrenQuery = this.utils.getObjectProperty(
        this.localConfig.apiChildrenResources,
        this.localConfig.apiChildrenQueryPath
      );
      const apiDefinition = this.utils.DeepCopy(
        this.localConfig.apiChildrenResources
      );
      if (childrenQuery) {
        this.utils.setObjectProperty(
          apiDefinition,
          this.localConfig.apiChildrenQueryPath,
          childrenQuery.replace(
            /\[#ParentID\]/gi,
            item[this.localConfig.idName]
          )
        );
      }
      return this.resource
        .callApi(
          apiDefinition.method,
          apiDefinition.path,
          apiDefinition.param,
          apiDefinition.body,
          apiDefinition.header
        )
        .pipe(
          switchMap((result: any) => {
            if (this.adjustChildrenApiData) {
              return of(this.adjustChildrenApiData(result));
            } else {
              return of(result);
            }
            // if (result && result.results) {
            //   return of(result.results);
            // } else {
            //   return of([]);
            // }
          })
        );
    } else {
      return of([]);
    }
  };

  fetchNextPage(mode: string) {
    switch (mode) {
      case 'next':
        {
          if (
            this.localConfig.apiRootResources &&
            this.localConfig.apiRootResources.pathContinue
          ) {
            this.treeListLoading = true;
            this.rootResources = this.resource
              .callApi('GET', this.localConfig.apiRootResources.pathContinue, {
                continuationToken: this.pagingToken,
                pageSize: this.localConfig.pageSize,
              })
              .pipe(
                switchMap((result: any) => {
                  if (result) {
                    if (this.adjustRootApiData) {
                      const adjustedData = this.adjustRootApiData(result);
                      this.cachedData = this.cachedData.concat(adjustedData);
                      return of(adjustedData);
                    } else {
                      this.cachedData = this.cachedData.concat(result);
                      return of(result);
                    }
                  } else {
                    return of([]);
                  }
                }),
                finalize(() => {
                  this.treeListLoading = false;
                })
              );
          }
        }
        break;
      case 'cache':
        {
          this.rootResources = of(
            this.cachedData.slice(
              (this.pageIndex - 1) * this.localConfig.pageSize,
              this.pageIndex * this.localConfig.pageSize
            )
          );
        }
        break;
      default:
        break;
    }
  }

  dataStateChange(state: DataStateChangeEvent): void {
    console.log(state);
  }

  onPagePrevious() {
    this.pageIndex--;
    this.fetchNextPage('cache');
  }

  onPageNext() {
    this.pageIndex++;
    if (this.pageIndex > this.maxPageIndex) {
      this.maxPageIndex = this.pageIndex;
      this.fetchNextPage('next');
    } else {
      this.fetchNextPage('cache');
    }
  }
}
