<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_approvalConfig' | translate }}
  </h3>
  <div
    mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="10px"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="20px"
      class="first-dialog-item"
    >
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_title' | translate"
          [(ngModel)]="data.config.title"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_mode' | translate"
          [(ngModel)]="data.config.mode"
        >
          <mat-option value="onprem">{{ 'key_onPrem' | translate }}</mat-option>
          <mat-option value="cloud">{{ 'key_cloud' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-slide-toggle
        fxFlex="50"
        [(ngModel)]="data.config.showPendingApprovalsOnly"
      >
        {{ 'key_pendingApprovalsOnly' | translate }}</mat-slide-toggle
      >
      <mat-form-field fxFlex="50">
        <input
          matInput
          type="number"
          min="1"
          max="50"
          [placeholder]="'key_itemLimit' | translate"
          [(ngModel)]="data.config.limit"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-slide-toggle fxFlex="50" [(ngModel)]="data.config.canApprove">
        {{ 'key_canApprove' | translate }}</mat-slide-toggle
      >
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_defaultContent' | translate"
          [(ngModel)]="data.config.defaultContent"
        >
          <mat-option value="">--</mat-option>
          <mat-option value="detail">{{
            'key_changeDetail' | translate
          }}</mat-option>
          <mat-option value="response">{{
            'key_response' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_sortAttribute' | translate"
          [(ngModel)]="data.config.sortAttribute"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <mat-select
          [placeholder]="'key_sortOrder' | translate"
          [(ngModel)]="data.config.sortOrder"
        >
          <mat-option value="asc">{{ 'key_ascending' | translate }}</mat-option>
          <mat-option value="desc">{{
            'key_descending' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_titleAttribute' | translate"
          [(ngModel)]="data.config.titleAttribute"
        />
      </mat-form-field>
      <mat-form-field fxFlex="50">
        <input
          matInput
          [placeholder]="'key_contentAttribute' | translate"
          [(ngModel)]="data.config.contentAttribute"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field fxFlex="100">
        <textarea
          matInput
          [matTextareaAutosize]="true"
          [matAutosizeMinRows]="1"
          [matAutosizeMaxRows]="3"
          [placeholder]="'key_queryPendingApprovals' | translate"
          [(ngModel)]="data.config.queryPendingApprovals"
        ></textarea>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field fxFlex="100">
        <textarea
          matInput
          [matTextareaAutosize]="true"
          [matAutosizeMinRows]="1"
          [matAutosizeMaxRows]="3"
          [placeholder]="'key_queryAllApprovals' | translate"
          [(ngModel)]="data.config.queryAllApprovals"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="data.config"
    >
      {{ 'key_submit' | translate }}
    </button>
    <button fxFlex="none" mat-stroked-button (click)="onRefresh()">
      {{ 'key_apply' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
