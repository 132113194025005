<div fxFlex="auto" fxLayout="column" style="margin: 20px">
  <div
    *ngIf="loadingPreview"
    fxFlex="100"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <mat-spinner [diameter]="120" [strokeWidth]="3"></mat-spinner>
  </div>
  <div fxFlex="100" [ngClass]="{ 'hidden-element': loadingPreview }">
    <div
      *ngIf="!errorMessage; else hasError"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin-bottom: 20px"
    >
      <mat-button-toggle-group
        [(ngModel)]="previewContent"
        (change)="refresh()"
        style="font-size: 13px; font-weight: 400"
      >
        <mat-button-toggle value="graph">{{
          'key_graphView' | translate
        }}</mat-button-toggle>
        <mat-button-toggle value="structure">{{
          'key_treeView' | translate
        }}</mat-button-toggle>
      </mat-button-toggle-group>
      <div fxFlex="auto"></div>
      <div
        *ngIf="!previewEventFinished"
        class="warn-color"
        style="margin-right: 20px"
      >
        {{ 'key_previewEventPending' | translate }}
      </div>
      <button mat-stroked-button (click)="refresh()">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <span class="material-icons-outlined">refresh</span>
          <div>{{ 'key_refresh' | translate }}</div>
        </div>
      </button>
    </div>
    <ng-template #hasError>
      <div fxLayout="row" fxLayoutAlign="center center" style="height: 100%">
        <div fxLayout="row" fxLayoutAlign="center start">
          <span
            class="material-icons-outlined warn-color"
            style="margin-right: 10px"
          >
            error_outline
          </span>
          <div class="warn-color" style="max-width: 600px">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </ng-template>
    <app-event-graph
      #eventGraph
      *ngIf="previewContent === 'graph'"
      [ngClass]="{
        'hidden-element': previewContent !== 'graph'
      }"
      [enableContextView]="false"
      [defaultLinkAction]="'sideView'"
    ></app-event-graph>
    <app-tree-list
      #eventTree
      *ngIf="previewContent === 'structure'"
      [config]="treeListConfig"
      [adjustRootApiData]="adjustRootData"
      [adjustChildrenApiData]="adjustChildrenData"
    ></app-tree-list>
  </div>
</div>
