<div class="config-dialog">
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <div *ngIf="!data.creationMode" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="none" style="font-size: 18px; margin-right: 20px">
        {{ 'l10n_viewMode' | translate }}
      </div>
      <mat-button-toggle-group
        #viewMode
        disabled
        fxFlex="none"
        [(ngModel)]="data.viewSetting.type"
      >
        <mat-button-toggle value="tab">
          <mat-icon>tab</mat-icon> tab
        </mat-button-toggle>
        <mat-button-toggle value="gridster">
          <mat-icon>grid_on</mat-icon> grid
        </mat-button-toggle>
      </mat-button-toggle-group>
      <div fxFlex="auto"></div>
      <mat-form-field fxFlex="none" style="margin-top: 20px">
        <input
          matInput
          [placeholder]="'l10n_viewIcon' | translate"
          [(ngModel)]="data.viewSetting.icon"
        />
      </mat-form-field>
    </div>
    <hr
      *ngIf="!data.creationMode"
      style="border: 0; border-top: 1px solid #eee; margin: 0 0 20px 0"
    />
    <div fxFlex="auto" fxLayout="row">
      <button
        mat-stroked-button
        color="primary"
        fxFlex="none"
        style="margin-bottom: 10px"
        (click)="onAddTab()"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon>playlist_add</mat-icon>
          <div style="margin-left: 10px">
            {{
              (data.creationMode ? 'key_addStep' : 'l10n_addTab') | translate
            }}
          </div>
        </div>
      </button>
      <div fxFlex="auto"></div>
      <button
        mat-stroked-button
        fxFlex="none"
        style="margin-bottom: 10px"
        (click)="showDisplaySettings = !showDisplaySettings"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <mat-icon fontSet="material-icons-outlined">toggle_off</mat-icon>
          <div style="margin-left: 10px">
            {{ 'key_displaySettings' | translate }}
          </div>
        </div>
      </button>
    </div>
    <div
      fxFlex="auto"
      dragula="VIEWSECTIONS"
      [(dragulaModel)]="data.viewSetting.sections"
      style="overflow-y: auto; height: 360px"
    >
      <mat-card
        *ngFor="let section of data.viewSetting.sections"
        style="
          border-left: solid darkseagreen 5px;
          margin-top: 10px;
          margin-right: 5px;
          padding: 10px 0 0 0;
        "
      >
        <mat-card-header fxLayout="column">
          <div
            fxLayout="row"
            fxLayoutAlign="start center"
            style="margin-left: 30px"
          >
            <div
              fxFlex="none"
              class="handle"
              style="
                width: 36px;
                margin-left: -20px;
                margin-right: 20px;
                cursor: move;
                color: lightgray;
              "
            >
              <mat-icon>menu</mat-icon>
            </div>
            <div fxFlex="auto">
              <mat-form-field>
                <input
                  matInput
                  required
                  [placeholder]="'key_name' | translate"
                  [(ngModel)]="section.name"
                />
              </mat-form-field>
            </div>
            <div fxFlex="auto">
              <mat-form-field>
                <input
                  matInput
                  required
                  [placeholder]="'key_displayName' | translate"
                  [(ngModel)]="section.displayName"
                />
              </mat-form-field>
            </div>
            <button
              mat-icon-button
              color="warn"
              [disabled]="data.viewSetting.sections.length === 1"
              (click)="onRemoveTab(section)"
            >
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
          <mat-form-field
            *ngIf="showDisplaySettings"
            style="margin-left: 65px; margin-right: 20px"
          >
            <mat-chip-list #clDeniedSets>
              <mat-chip
                *ngFor="let deniedSet of section.accessDenied"
                [selectable]="true"
                [removable]="true"
                (removed)="onRemoveDeniedSet(deniedSet, section)"
                style="font-size: 0.75em"
              >
                {{ deniedSet }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                [placeholder]="'key_deniedSets' | translate"
                [matChipInputFor]="clDeniedSets"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="onAddDeniedSet($event, section)"
              />
            </mat-chip-list>
          </mat-form-field>
          <mat-form-field
            *ngIf="showDisplaySettings"
            style="margin-left: 65px; margin-right: 20px"
          >
            <mat-chip-list #clAllowedSets>
              <mat-chip
                *ngFor="let alowedSet of section.accessAllowed"
                [selectable]="true"
                [removable]="true"
                (removed)="onRemoveAllowedSet(alowedSet, section)"
                style="font-size: 0.75em"
              >
                {{ alowedSet }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
              <input
                [placeholder]="'key_allowedSets' | translate"
                [matChipInputFor]="clAllowedSets"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="true"
                (matChipInputTokenEnd)="onAddAllowedSet($event, section)"
              />
            </mat-chip-list>
          </mat-form-field>
          <!-- </div> -->
        </mat-card-header>
      </mat-card>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [disabled]="sectionsInvalid()"
      (click)="onClose()"
    >
      {{ 'key_submit' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button (click)="onCancel()">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
<ng-template #titleBar let-win>
  <app-popup-title
    [win]="win"
    [title]="'l10n_config'"
    [leftPadding]="0"
  ></app-popup-title>
</ng-template>
