import { Input, Directive } from '@angular/core';
import {
  FormControl,
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  Validator,
} from '@angular/forms';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import * as moment from 'moment';

import {
  TextEditorConfig,
  BooleanEditorConfig,
  SelectEditorConfig,
  DateEditorConfig,
  IdentityEditorConfig,
  IdentitiesEditorConfig,
  IterablesEditorConfig,
  FrameEditorConfig,
  XpathEditorConfig,
} from '../models/editorContract.model';

import { ResourceService } from '../services/resource.service';
import {
  ApiValidationConfig,
  AuthMode,
  ExpressionValidationResult,
  ExpressionValidationSpecification,
  ValidationSetting,
} from '../models/dataContract.model';
import { EditorTextComponent } from '../components/editor-text/editor-text.component';
import { UtilsService } from '../services/utils.service';

export function createTextEditorValidator(config: TextEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    let value: string;
    if (c.value) {
      if (typeof c.value === 'string') {
        value = c.value;
      } else if (c.value.value) {
        if (typeof c.value.value === 'string') {
          value = c.value.value;
        } else {
          value = c.value.value.ObjectID ?? c.value.value.objectid;
        }
      }
    }
    const values = c.value && c.value.values ? c.value.values : null;

    if (!value && (!config || !config.required)) {
      return null;
    }

    if (config && config.required) {
      if (config.isMultivalue && !values) {
        return { message: 'key_valueRequired' };
      }
      if (!config.isMultivalue && !value) {
        return { message: 'key_valueRequired' };
      }
    }

    if (config && config.validation) {
      const regEx = new RegExp(config.validation);
      if (config.isMultivalue) {
        for (const item of values) {
          if (!regEx.test(item)) {
            return {
              message: config.customErrorMsg
                ? config.customErrorMsg
                : 'key_restrictionViolation',
            };
          }
        }
      } else {
        if (!regEx.test(value)) {
          return {
            message: config.customErrorMsg
              ? config.customErrorMsg
              : 'key_restrictionViolation',
          };
        }
      }
    }

    return null;
  };
}

export function createBooleanEditorValidator(config: BooleanEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createSelectEditorValidator(config: SelectEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createDateEditorValidator(config: DateEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    if (config && c.value && c.value.value) {
      if (config.minDate) {
        if (moment(c.value.value).toDate() < config.minDate) {
          return {
            message: config.customErrorMsg
              ? config.customErrorMsg
              : 'key_restrictionViolation',
          };
        }
      }
      if (config.maxDate) {
        if (moment(c.value.value).toDate() > config.maxDate) {
          return {
            message: config.customErrorMsg
              ? config.customErrorMsg
              : 'key_restrictionViolation',
          };
        }
      }
    }

    return null;
  };
}

export function createIdentityEditorValidator(config: IdentityEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (
      config &&
      config.required &&
      (!c.value || !c.value.value || c.value.value.length === 0)
    ) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createIdentitiesEditorValidator(
  config: IdentitiesEditorConfig
) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (
      config &&
      config.required &&
      (!c.value || !c.value.value || c.value.value.length === 0)
    ) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createFrameEditorValidator(config: FrameEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createIterablesEditorValidator(config: IterablesEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

export function createObjectEditorValidator(config: IterablesEditorConfig) {
  return (c: FormControl) => {
    if (config && config.hasError) {
      return { message: config.errorMsg ? config.errorMsg : '' };
    }

    if (config && config.required && (!c.value || !c.value.value)) {
      return { message: 'key_valueRequired' };
    }

    return null;
  };
}

@Directive({
  selector: '[appUniquenessValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: UniquenessValidatorDirective,
      multi: true,
    },
  ],
})
export class UniquenessValidatorDirective implements AsyncValidator {
  @Input('appUniquenessValidator')
  options: {
    objectType: string;
    config: Array<{
      attributeName: string;
      objectType: Array<string>;
      condition: string;
    }>;
    creationMode: boolean;
    currentID: any;
  };

  constructor(private resource: ResourceService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    try {
      if (!this.options.creationMode && control.untouched) {
        return of(null);
      }
      const name = control.value.systemName.toLowerCase();
      const conditions = this.options.config.filter(
        (o) =>
          o.attributeName.toLowerCase() === name &&
          o.objectType.findIndex(
            (d) => d.toLowerCase() === this.options.objectType.toLowerCase()
          ) >= 0
      );
      if (conditions.length > 0) {
        const observableBatch = [];
        conditions.forEach((condition) => {
          let searchQuery = condition.condition.replace(
            /%SearchText%/gi,
            control.value.value
          );
          if (this.options.currentID) {
            searchQuery = `${searchQuery}[ObjectID!='${this.options.currentID}']`;
          }
          observableBatch.push(this.resource.resourceExists(searchQuery));
        });
        return forkJoin(observableBatch).pipe(
          switchMap((results: Array<boolean>) => {
            for (const result of results) {
              if (result === true) {
                return of({ message: 'value is not unique' });
              }
            }
            return of(null);
          })
        );
      } else {
        return of(null);
      }

      // if (control.untouched) {
      //   return of(null);
      // }

      // const name = control.value.systemName.toLowerCase();
      // if (this.options.hasOwnProperty(name)) {
      //   const searchQuery =
      //     this.resource.authenticationMode === AuthMode.azure
      //       ? this.options[name].toLowerCase()
      //       : this.options[name];
      //   return this.resource
      //     .getResourceCount(
      //       searchQuery.replace(/%SearchText%/gi, control.value.value)
      //     )
      //     .pipe(
      //       switchMap((count: number) => {
      //         if (count) {
      //           return of({ message: 'value is not unique' });
      //         } else {
      //           return of(null);
      //         }
      //       })
      //     );
      // } else return of(null);
    } catch {
      return of(null);
    }
  }
}

@Directive({
  selector: '[appDistinctValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DistinctValidatorDirective,
      multi: true,
    },
  ],
})
export class DistinctValidatorDirective implements Validator {
  @Input('appDistinctValidator')
  options: Array<string>;

  validate(control: AbstractControl): ValidationErrors {
    try {
      if (!control.dirty) {
        return null;
      }
      if (!this.options || this.options.length === 0) {
        return null;
      }
      if (
        control.value === '' ||
        control.value === undefined ||
        control.value === null
      ) {
        return null;
      }
      if (this.options.indexOf(control.value) >= 0) {
        return { distinct: 'value is not unique' };
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }
}

@Directive({
  selector: '[appApiValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ApiValidatorDirective,
      multi: true,
    },
  ],
})
export class ApiValidatorDirective implements AsyncValidator {
  @Input('appApiValidator')
  options: ApiValidationConfig;

  constructor(private resource: ResourceService, private utils: UtilsService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    try {
      if (control.value && this.options) {
        const ops = this.utils.DeepCopy(this.options);
        if (ops.params) {
          Object.keys(ops.params).forEach((key: string) => {
            if (typeof ops.params[key] === 'string') {
              ops.params[key] = String(ops.params[key]).replace(
                /%ValidationValue%/gi,
                control.value
              );
            }
          });
        }
        if (ops.body) {
          Object.keys(ops.body).forEach((key: string) => {
            if (typeof ops.body[key] === 'string') {
              ops.body[key] = String(ops.body[key]).replace(
                /%ValidationValue%/gi,
                control.value
              );
            }
          });
        }
        return this.resource.validateViaApi(ops).pipe(
          switchMap((result: ExpressionValidationResult) => {
            if (result.isValid) {
              return of(null);
            } else {
              if (result.explanation) {
                return of({ message: result.explanation });
              } else {
                return of({ message: 'key_invalidXpath' });
              }
            }
          }),
          catchError(() => {
            return of({ message: 'key_unknownError' });
          })
        );
      } else {
        return of(null);
      }
    } catch {
      return of(null);
    }
  }
}

@Directive({
  selector: '[appXpathValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: XpathValidatorDirective,
      multi: true,
    },
  ],
})
export class XpathValidatorDirective implements AsyncValidator {
  @Input('appXpathValidator')
  options: XpathEditorConfig;

  constructor(private resource: ResourceService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    try {
      if (control.value && control.value.value) {
        let checkValue: string = control.value.value;
        if (this.options.prefix) {
          checkValue = checkValue.replace(this.options.prefix, '');
        }
        if (this.options.surfix) {
          checkValue = checkValue.replace(this.options.surfix, '');
        }
        if (checkValue) {
          if (this.resource.authenticationMode === AuthMode.azure) {
            return this.resource
              .validateExpression({
                expression: checkValue,
                validationSpecification: {
                  isRequired: false,
                  allowFunctionExpression: false,
                  allowXPath: true,
                  xPathMustBeSimple: false,
                  allowInterpolatedString: false,
                  allowedLookups: null,
                  allowedStringFormats: null,
                },
              })
              .pipe(
                switchMap((result: ExpressionValidationResult) => {
                  if (result.isValid) {
                    return of(null);
                  } else {
                    if (result.explanation) {
                      return of({ message: result.explanation });
                    } else {
                      return of({ message: 'key_invalidXpath' });
                    }
                  }
                }),
                catchError(() => {
                  return of({ message: 'key_invalidXpath' });
                })
              );
          } else {
            return this.resource.xpathToJson(checkValue).pipe(
              switchMap(() => {
                return of(null);
              }),
              catchError(() => {
                return of({ message: 'key_invalidXpath' });
              })
            );
          }
        } else {
          return of(null);
        }
      } else {
        return of(null);
      }
    } catch {
      return of(null);
    }
  }
}

@Directive({
  selector: '[appExpressionValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ExpressionValidatorDirective,
      multi: true,
    },
  ],
})
export class ExpressionValidatorDirective implements AsyncValidator {
  constructor(
    private resource: ResourceService,
    private host: EditorTextComponent
  ) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (
      !this.host ||
      !this.host.config ||
      !this.host.validationSetting ||
      !this.host.validationSetting.tags ||
      this.host.validationSetting.tags.length === 0
    ) {
      return of(null);
    }

    try {
      let checkValue: string;
      if (control.value) {
        if (typeof control.value === 'string') {
          checkValue = control.value;
        } else if (
          control.value.value &&
          typeof control.value.value === 'string'
        ) {
          checkValue = control.value.value;
        }
      }
      const tags = this.host.validationSetting.tags;
      if (this.resource.authenticationMode === AuthMode.azure) {
        if (this.host.config.validationKey) {
          return this.resource
            .getValidationInfo(this.host.config.validationKey)
            .pipe(
              switchMap((info: ExpressionValidationSpecification) => {
                info.allowFunctionExpression =
                  tags.findIndex(
                    (t) =>
                      t.isActive &&
                      t.name &&
                      t.name.toLowerCase() === 'function'
                  ) >= 0;
                info.allowInterpolatedString =
                  tags.findIndex(
                    (t) =>
                      t.isActive && t.name && t.name.toLowerCase() === 'string'
                  ) >= 0;
                info.allowXPath =
                  tags.findIndex(
                    (t) =>
                      t.isActive &&
                      t.name &&
                      (t.name.toLowerCase() === 'xpath' ||
                        t.name.toLowerCase() === 'simplexpath')
                  ) >= 0;

                return this.resource.validateExpression({
                  expression: checkValue,
                  validationSpecification: info,
                });
              }),
              switchMap((result: ExpressionValidationResult) => {
                if (result.isValid) {
                  return of(null);
                } else {
                  if (result.explanation) {
                    return of({ message: result.explanation });
                  } else {
                    return of({ message: 'key_invalidExpression' });
                  }
                }
              }),
              catchError(() => {
                return of({ message: 'key_failedToCallValidationService' });
              })
            );
        } else {
          const validationSpec = {
            isRequired:
              tags.findIndex(
                (t) =>
                  t.isActive && t.name && t.name.toLowerCase() === 'required'
              ) >= 0,
            allowFunctionExpression:
              tags.findIndex(
                (t) =>
                  t.isActive && t.name && t.name.toLowerCase() === 'function'
              ) >= 0,
            allowXPath:
              tags.findIndex(
                (t) =>
                  t.isActive &&
                  t.name &&
                  (t.name.toLowerCase() === 'xpath' ||
                    t.name.toLowerCase() === 'simplexpath')
              ) >= 0,
            xPathMustBeSimple:
              tags.findIndex(
                (t) =>
                  t.isActive && t.name && t.name.toLowerCase() === 'simplexpath'
              ) >= 0,
            allowInterpolatedString:
              tags.findIndex(
                (t) => t.isActive && t.name && t.name.toLowerCase() === 'string'
              ) >= 0,
            allowedLookups: this.host.validationSetting.allowedLookups,
            allowedStringFormats:
              this.host.validationSetting.allowedStringFormats,
          };

          if (
            !validationSpec.isRequired &&
            !validationSpec.allowFunctionExpression &&
            !validationSpec.allowXPath &&
            !validationSpec.xPathMustBeSimple &&
            !validationSpec.allowInterpolatedString
          ) {
            return of(null);
          }

          return this.resource
            .validateExpression({
              expression: checkValue,
              validationSpecification: validationSpec,
            })
            .pipe(
              switchMap((result: ExpressionValidationResult) => {
                if (result.isValid) {
                  return of(null);
                } else {
                  if (result.explanation) {
                    return of({ message: result.explanation });
                  } else {
                    return of({ message: 'key_invalidExpression' });
                  }
                }
              }),
              catchError(() => {
                return of({ message: 'key_failedToCallValidationService' });
              })
            );
        }
      } else {
        return of(null);
      }
    } catch {
      return of(null);
    }
  }
}
