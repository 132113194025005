<div fxLayout="row" fxLayoutAlign="start center" style="width: 100%;">
  <div class="k-window-title">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      [style.padding-left.px]="leftPadding"
    >
      <mat-icon *ngIf="icon" fxFlex="none" style="margin-right: 10px;">{{
        icon
      }}</mat-icon>
      <div fxFlex="none">
        {{ title | translate }}
      </div>
      <div fxFlex="auto"></div>
    </div>
  </div>

  <!-- Buttons -->
  <button *ngIf="canResize" kendoWindowRestoreAction [window]="win"></button>
  <button *ngIf="canResize" kendoWindowMaximizeAction [window]="win"></button>
  <button kendoWindowCloseAction [window]="win"></button>
</div>
