<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    {{ 'key_addComponent' | translate }}
  </h3>
  <div mat-dialog-content fxLayout="column" fxLayoutAlign="start stretch">
    <mat-form-field fxFlex="auto" style="margin-top: 20px">
      <mat-select
        [placeholder]="'key_type' | translate"
        [(ngModel)]="config.type"
        cdkFocusInitial
      >
        <mat-option *ngFor="let type of types" [value]="type.value">{{
          type.text
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <mat-form-field fxFlex="auto">
      <input matInput [placeholder]="'key_componentName' | translate" [(ngModel)]="config.name" />
    </mat-form-field> -->
    <mat-form-field fxFlex="auto">
      <input
        matInput
        required
        [placeholder]="'key_attributeName' | translate"
        [(ngModel)]="config.attributeName"
      />
    </mat-form-field>
    <div fxFlex="auto" fxLayout="column">
      <div
        fxFlex="none"
        style="
          font-size: 12px;
          font-weight: 400;
          height: 18px;
          color: rgba(0, 0, 0, 0.54);
        "
      >
        {{ 'key_layoutUnits' | translate }}
      </div>
      <mat-slider
        fxFlex="none"
        [max]="12"
        [min]="1"
        [step]="1"
        [thumbLabel]="true"
        [tickInterval]="1"
        [(ngModel)]="config.layoutUnits"
        style="margin-top: 2px; padding-left: 0"
      >
      </mat-slider>
      <mat-checkbox [(ngModel)]="config.importSetting">{{
        'key_importSetting' | translate
      }}</mat-checkbox>
      <mat-form-field
        *ngIf="config.importSetting"
        [appearance]="'fill'"
        style="margin-top: 10px"
      >
        <textarea matInput [rows]="3" [(ngModel)]="config.setting"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="20px"
    style="margin-top: 10px"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="config"
      [disabled]="!config.type || !config.attributeName"
    >
      {{ 'key_submit' | translate }}
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
