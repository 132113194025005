<!-- ngIf will not be used any more, use ngClass for display control  -->
<!-- *ngIf="configMode || showEditor(resource.rightSets)" -->
<div
  fxLayout="column"
  [ngClass]="{
    'config-hidden': configMode && !showEditor(resource.rightSets),
    'edit-mode':
      !creationMode &&
      config.attributeName &&
      !config.attributeName.endsWith('#')
  }"
  style="width: 100%; margin-top: -8px"
>
  <div
    fxFlex="none"
    [ngClass]="{ 'label-attribute-focused': focused }"
    class="label-attribute-name"
  >
    {{ displayName | translate }}
  </div>
  <kendo-datepicker
    fxFlex="none"
    [(value)]="value"
    (focus)="onFocuse()"
    (blur)="onBlur()"
    (valueChange)="onChange()"
    [disabled]="disabled(resource.rightSets)"
    [matTooltip]="tooltip"
    [navigation]="config.showNavigation"
    [weekNumber]="config.showWeekNumber"
    [format]="format"
    [min]="config.minDate | stringToDate"
    [max]="config.maxDate | stringToDate"
    formatPlaceholder="formatPattern"
    style="font-weight: 400; width: 100%; font-size: 1em"
  >
    <kendo-datepicker-messages
      [today]="'key_calendarToday' | translate"
      [toggle]="'key_calendarToggle' | translate"
    ></kendo-datepicker-messages>
  </kendo-datepicker>
  <div
    *ngIf="!control || (control && control.valid)"
    fxFlex="none"
    class="label-attribute-desc"
  >
    <div fxLayout="row">
      <div
        fxFlex="auto"
        *ngIf="description"
        style="margin-right: 10px; line-height: initial"
        [matTooltip]="description | translate"
      >
        {{ description | translate | ellipsis: config.hintLimit }}
      </div>
      <div
        fxFlex="none"
        *ngIf="!hideNoWriteAccessMessage && readAccess && !writeAccess"
        style="font-size: 14px; color: #d8bb2e"
      >
        <mat-icon
          style="font-size: 14px"
          [matTooltip]="'key_noWriteAccess' | translate"
          >error</mat-icon
        >
      </div>
      <div fxFlex="none" *ngIf="!hideNoReadAccessMessage && !readAccess">
        <mat-icon
          [matTooltip]="'key_noReadAccess' | translate"
          style="font-size: 14px; color: red; padding-top: 4px"
        >
          error</mat-icon
        >
      </div>
    </div>
  </div>
  <div
    fxFlex="none"
    *ngIf="control && control.invalid"
    class="label-attribute-desc"
    [matTooltip]="control.errors['message'] | translate"
  >
    {{ control.errors['message'] | translate | ellipsis: config.hintLimit }}
  </div>
</div>
