import {
  Component,
  ViewChild,
  TemplateRef,
  AfterViewInit,
  Injector,
  Input,
} from '@angular/core';
import { WindowRef } from '@progress/kendo-angular-dialog';
import { PopupConfig } from '../../models/dataContract.model';
import { Keys } from '@progress/kendo-angular-common';

@Component({
  selector: 'app-popup-body',
  templateUrl: './popup-body.component.html',
  styleUrls: ['./popup-body.component.scss'],
})
export class PopupBodyComponent implements AfterViewInit {
  private windowRef: WindowRef;

  @ViewChild('titleBar')
  titleBar: TemplateRef<any>;

  @Input()
  config: PopupConfig = {};

  @Input()
  title = '';

  @Input()
  style = 'standard';

  constructor(private injector: Injector) {}

  ngAfterViewInit() {
    this.windowRef = this.injector.get(WindowRef);
    if (this.titleBar && this.windowRef) {
      this.windowRef.window.instance.titleBarTemplate = this.titleBar;
    }
  }

  canSubmit(): boolean {
    if (this.config.data) {
      for (const key in this.config.data) {
        if (
          this.config.data[key].required &&
          this.config.data[key].visible !== false
        ) {
          if (
            this.config.data[key].type === 'reference' ||
            this.config.data[key].type === 'date'
          ) {
            if (
              !this.config.data[key].value ||
              !this.config.data[key].value.value
            ) {
              return false;
            }
          } else {
            if (!this.config.data[key].value) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  onCancel() {
    if (this.windowRef) {
      this.windowRef.close();
    }
  }

  onClose() {
    Object.keys(this.config.data).forEach((key: string) => {
      if (
        this.config.data[key].type === 'reference' ||
        this.config.data[key].type === 'date'
      ) {
        if (this.config.data[key].value) {
          this.config.data[key].value = this.config.data[key].value.value;
        }
      }
    });

    if (this.windowRef) {
      this.windowRef.close(this.config.data);
    }
  }

  onKeyDown(event: KeyboardEvent, element: any) {
    if (element.validation && event.key !== 'Backspace') {
      const regEx = new RegExp(element.validation);
      if (!regEx.test(element.value + event.key)) {
        event.preventDefault();
      }
    }
  }
}
