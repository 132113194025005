import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { mergeWith as _mergeWith } from 'lodash-es';
import { assignInWith as _assignInWith } from 'lodash-es';

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(`MSAL Angular - ${message} `);
}

function bootstrapWithoutMSAL() {
  const configuration = new PublicClientApplication({
    auth: {
      clientId: '',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });

  const settings = {
    interactionType: InteractionType.Popup,
    protectedResourceMap: new Map<string, Array<string> | null>([
      ['assets/', null],
      ['uploadSaveUrl', null],
    ]),
  } as MsalInterceptorConfiguration;

  const providers = [
    { provide: MSAL_INSTANCE, useValue: configuration },
    { provide: MSAL_INTERCEPTOR_CONFIG, useValue: settings },
  ];

  platformBrowserDynamic([providers])
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

function bootstrapWithMSAL(config: any) {
  if (config.msalSettings && config.msalSettings.enableMsal) {
    const msalSettings = config.msalSettings;

    const configuration = new PublicClientApplication({
      auth: {
        clientId: msalSettings.clientId,
        authority: msalSettings.authority,
        redirectUri: msalSettings.redirectUri,
        postLogoutRedirectUri: msalSettings.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
      },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: config.msalSettings.logLevel ?? LogLevel.Error,
          piiLoggingEnabled: false,
        },
      },
    });

    const protectedResources = new Map<string, Array<string> | null>(
      msalSettings.protectedResources
    );
    if (protectedResources && msalSettings.unprotectedResources) {
      msalSettings.unprotectedResources.forEach((path: string) => {
        protectedResources.set(path, null);
      });
    }

    const guard = {
      interactionType: InteractionType.Popup,
      authRequest: {
        scopes: ['user.read'],
      },
      loginFailedRoute: '/login',
    } as MsalGuardConfiguration;

    const settings = {
      interactionType: InteractionType.Popup,
      protectedResourceMap: protectedResources,
      // extraQueryParameters: {
      //   domain_hint: msalSettings.domainHint ?? '',
      // },
    } as MsalInterceptorConfiguration;

    const providers = [
      { provide: MSAL_INSTANCE, useValue: configuration },
      { provide: MSAL_GUARD_CONFIG, useValue: guard },
      { provide: MSAL_INTERCEPTOR_CONFIG, useValue: settings },
    ];

    platformBrowserDynamic(providers)
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  } else {
    bootstrapWithoutMSAL();
  }
}

function isReplaceMode(element: any) {
  if (typeof element === 'string') {
    if (/^\s*overwritemode\s*:\s*replace\s*$/i.test(element)) {
      return true;
    } else if (/^\s*overwritemode\s*:\s*merge\s*$/i.test(element)) {
      return false;
    } else {
      return null;
    }
  }

  return null;
}

if (environment.production) {
  enableProdMode();
}

let customConfigExists = true;
let customConfigValid = true;

fetch(
  `${document.getElementsByTagName('base')[0].href}/assets/config/config.${
    environment.env
  }.json?t=${new Date().getTime()}`
)
  .then((response) => response.json())
  .then((json) => {
    fetch(
      `${
        document.getElementsByTagName('base')[0].href
      }/app/customisation/assets/config/customConfig.${
        environment.env
      }.json?t=${new Date().getTime()}`
    )
      .then((customResponse) => {
        if (customResponse.status === 404) {
          customConfigExists = false;
        }
        return customResponse.json();
      })
      .catch(() => {
        customConfigValid = false;
      })
      .then((customJson) => {
        if (customConfigExists) {
          if (customConfigValid) {
            let config = undefined;
            if (customJson.overwriteMode === 'merge') {
              config = _mergeWith({}, json, customJson, (obj, src) => {
                if (Array.isArray(src) && src.length > 0) {
                  const mode = isReplaceMode(src[0]);
                  if (mode === true) {
                    src.shift();
                    return src;
                  } else if (mode === false) {
                    src.shift();
                  }
                }
              });
            } else {
              // config = { ...json, ...customJson };
              config = _assignInWith(json, customJson, (obj, src) => {
                if (Array.isArray(src) && src.length > 0) {
                  const mode = isReplaceMode(src[0]);
                  if (mode !== null) {
                    src.shift();
                    return src;
                  }
                }
              });
            }
            localStorage.setItem('LS_CONFIGURATION', JSON.stringify(config));
            bootstrapWithMSAL(config);
          } else {
            localStorage.setItem('LS_CONFIGURATION', 'LS_CONFIGURATIONERROR');
            bootstrapWithoutMSAL();
          }
        } else {
          localStorage.setItem('LS_CONFIGURATION', JSON.stringify(json));
          bootstrapWithMSAL(json);
        }
      });
  })
  .catch(() => {
    localStorage.setItem('LS_CONFIGURATION', 'LS_CONFIGURATIONERROR');
    bootstrapWithoutMSAL();
  });
