import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-activity-status',
  templateUrl: './activity-status.component.html',
  styleUrls: ['./activity-status.component.scss'],
})
export class ActivityStatusComponent {
  activityStatus: string;

  @Input()
  get status() {
    return this.activityStatus;
  }
  set status(value: string) {
    this.activityStatus = value;
    this.statusChange.emit(this.activityStatus);
  }
  @Output()
  statusChange = new EventEmitter();

  constructor() {}
}
