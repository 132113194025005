<ng-template #nestedSteps let-steps>
  <div
    *ngFor="let step of steps; let stepIndex = index"
    fxLayout="column"
    class="primary-text-color"
    [ngClass]="{ 'disabled-builder': readOnly }"
  >
    <div *ngIf="stepIndex === 0">
      <div fxLayout="row">
        <div>{{ 'key_xpathString1' | translate }}&nbsp;</div>
        <a
          [ngClass]="{ 'disabled-action': readOnly || valueChangeOnly }"
          class="xpath-link primary-color"
          [matMenuTriggerFor]="menuTypes"
          [matMenuTriggerData]="{ data: step }"
          (click)="currentStep = step"
          >{{ getTypeName(step) }}</a
        >
        <div>&nbsp;{{ 'key_xpathString2' | translate }}&nbsp;</div>
        <a
          [ngClass]="{ 'disabled-action': readOnly || valueChangeOnly }"
          class="xpath-link primary-color"
          [matMenuTriggerFor]="menuStepLogic"
          [matMenuTriggerData]="{ data: step }"
          >{{ getStepLogicName(step) | translate }}</a
        >
        <div>&nbsp;{{ 'key_xpathString3' | translate }}</div>
      </div>
      <ng-template #nestedParameters let-parameters>
        <div
          *ngFor="let parameter of parameters"
          [style.margin-left.px]="margin"
        >
          <div
            *ngIf="parameter.operator === 'or' || parameter.operator === 'and'"
          >
            <div fxLayout="row">
              <div style="width: 30px">
                <div class="vertical-line"></div>
                <div class="horizontal-line"></div>
                <div class="children-line"></div>
              </div>
              <div fxLayout="row" class="query-line">
                <a
                  [ngClass]="{ 'disabled-action': readOnly || valueChangeOnly }"
                  class="xpath-link primary-color"
                  [matMenuTriggerFor]="menuParameterLogic"
                  [matMenuTriggerData]="{ data: parameter }"
                  (click)="currentParameter = parameter"
                  >{{ getLogicName(parameter.operator) | translate }}</a
                >
                <div>&nbsp;{{ 'key_xpathString4' | translate }}</div>
                <div fxFlex="auto"></div>
                <button
                  *ngIf="!readOnly && !valueChangeOnly"
                  mat-icon-button
                  class="parameter-button"
                  (click)="onRemoveStatement(parameters, parameter)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
            <ng-container
              *ngTemplateOutlet="
                nestedParameters;
                context: { $implicit: parameter.parameters }
              "
            ></ng-container>
          </div>
          <div *ngIf="parameter.operator === 'not'">
            <div fxLayout="row">
              <div style="width: 30px">
                <div class="vertical-line"></div>
                <div class="horizontal-line"></div>
                <div class="children-line"></div>
              </div>
              <div fxLayout="row" class="query-line warn-bg-color">
                <div class="xpath-link primary-color">
                  {{ 'key_not' | translate }}
                </div>
                <div>&nbsp;{{ 'key_xpathString4' | translate }}</div>
                <div fxFlex="auto"></div>
                <button
                  *ngIf="!readOnly && !valueChangeOnly"
                  mat-icon-button
                  class="parameter-button"
                  (click)="onRemoveStatement(parameters, parameter)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
            <ng-container
              *ngTemplateOutlet="
                nestedParameters;
                context: { $implicit: parameter.parameters }
              "
            ></ng-container>
          </div>
          <div
            *ngIf="
              parameter.operator !== 'or' &&
              parameter.operator !== 'and' &&
              parameter.operator !== 'not'
            "
          >
            <div fxLayout="row">
              <div style="width: 30px">
                <div class="vertical-line"></div>
                <div class="horizontal-line"></div>
                <div class="children-line"></div>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
                [ngClass]="{
                  'warn-bg-color': isNegation(parameters, step),
                  'query-line': getParameterRightType(parameter) === 'value',
                  'step-line': getParameterRightType(parameter) === 'step'
                }"
              >
                <a
                  [ngClass]="{ 'disabled-action': readOnly || valueChangeOnly }"
                  class="xpath-link primary-color"
                  [matMenuTriggerFor]="menuAttributes"
                  [matMenuTriggerData]="{ data: parameter }"
                  (click)="onShowAttributes(parameter, step)"
                  >{{ getParameterLeft(parameter, step) | translate }}</a
                >
                <a
                  [ngClass]="{ 'disabled-action': readOnly || valueChangeOnly }"
                  class="xpath-link primary-color"
                  [matMenuTriggerFor]="menuOperators"
                  [matMenuTriggerData]="{ data: parameter }"
                  (click)="onShowOperator(parameter, step)"
                  >{{ getParameterOperator(parameter) | translate }}</a
                >
                <div
                  *ngIf="getParameterRightType(parameter) === 'value'"
                  fxFlex="auto"
                >
                  <a
                    [ngClass]="{ 'disabled-action': readOnly }"
                    *ngIf="
                      currentParameter !== parameter &&
                      !isGuid(getParameterRight(parameter))
                    "
                    class="xpath-link primary-color"
                    (click)="onClickParameterRight(parameter, step)"
                    >{{ getParameterRight(parameter) | translate }}</a
                  >
                  <!-- <app-editor-identity
                    *ngIf="
                      currentParameter !== parameter &&
                      isGuid(getParameterRight(parameter))
                    "
                    [ngModel]="{
                      dataType: 'Reference',
                      displayName: '',
                      multivalued: false,
                      permissionHint:
                        'Add, Create, Modify, Delete, Read, Remove',
                      systemName: '',
                      value: getParameterRight(parameter),
                      values: [getParameterRight(parameter)]
                    }"
                    [creationMode]="true"
                    [config]="idpPlaceholderConfig"
                    (valueChange)="onIdentityChange($event, parameter)"
                  ></app-editor-identity> -->
                  <a
                    [ngClass]="{ 'disabled-action': readOnly }"
                    *ngIf="currentParameter === parameter && !currentType"
                    class="xpath-link primary-color"
                    (click)="onClickParameterRight(parameter, step, true)"
                    >{{ 'key_clickToSelectValue' | translate }}</a
                  >
                  <div
                    *ngIf="
                      currentParameter === parameter && currentType === 'string'
                    "
                  >
                    <mat-form-field style="margin-top: -5px">
                      <input matInput [(ngModel)]="parameter.parameters[1]" />
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="
                      currentParameter === parameter &&
                      currentType === 'boolean'
                    "
                  >
                    <mat-form-field style="margin-top: -5px">
                      <mat-select [(ngModel)]="parameter.parameters[1]">
                        <mat-option
                          *ngFor="let booleanItem of booleanItems"
                          [value]="booleanItem.value"
                        >
                          {{ booleanItem.text }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="
                      currentParameter === parameter &&
                      currentType === 'integer'
                    "
                  >
                    <mat-form-field style="margin-top: -5px">
                      <input
                        matInput
                        type="number"
                        [value]="getNumberParameter(parameter)"
                        (input)="onNumberChange($event, parameter)"
                      />
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="
                      currentParameter === parameter &&
                      currentType === 'datetime'
                    "
                  >
                    <div *ngIf="!currentDateType">
                      <mat-form-field style="margin-top: -5px">
                        <mat-select
                          [(ngModel)]="parameter.parameters[1]"
                          (selectionChange)="
                            onDateTimeTypeChange($event, parameter)
                          "
                        >
                          <mat-option
                            *ngFor="let dateType of dateTypes"
                            [value]="dateType.value"
                          >
                            {{ dateType.text }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div *ngIf="currentDateType === 'date'">
                      <kendo-datepicker
                        [format]="dtFormat"
                        [value]="convertToDate(parameter.parameters[1])"
                        (valueChange)="onDateTimeChange($event, parameter)"
                        formatPlaceholder="formatPattern"
                        class="input-element"
                      ></kendo-datepicker>
                    </div>
                    <div *ngIf="currentDateType === 'today'">
                      <a
                        [ngClass]="{ 'disabled-action': readOnly }"
                        class="xpath-link primary-color"
                        (click)="currentDateType = null"
                        >{{ getParameterRight(parameter) | translate }}</a
                      >
                    </div>
                    <div *ngIf="currentDateType === 'ago'" fxLayout="row">
                      <mat-form-field
                        style="
                          width: 60px;
                          margin-right: 10px;
                          padding-top: 2px;
                        "
                      >
                        <input
                          matInput
                          [min]="0"
                          type="number"
                          [value]="getDateParameter(parameter)"
                          (input)="onDaysChange($event, parameter)"
                        />
                      </mat-form-field>
                      <a
                        [ngClass]="{ 'disabled-action': readOnly }"
                        class="xpath-link primary-color"
                        (click)="currentDateType = null"
                        >{{ 'key_daysAgo' | translate }}</a
                      >
                    </div>
                    <div *ngIf="currentDateType === 'from'" fxLayout="row">
                      <mat-form-field
                        style="
                          width: 60px;
                          margin-right: 10px;
                          padding-top: 2px;
                        "
                      >
                        <input
                          matInput
                          [min]="0"
                          type="number"
                          [value]="getDateParameter(parameter)"
                          (input)="onDaysChange($event, parameter)"
                        />
                      </mat-form-field>
                      <a
                        [ngClass]="{ 'disabled-action': readOnly }"
                        class="xpath-link primary-color"
                        (click)="currentDateType = null"
                        >{{ 'key_daysFromToday' | translate }}</a
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      currentParameter === parameter &&
                      currentType === 'reference'
                    "
                  >
                    <div fxLayout="row">
                      <a
                        [ngClass]="{ 'disabled-action': readOnly }"
                        class="xpath-link primary-color"
                        (click)="onInputID(parameter)"
                        >{{ 'key_useID' | translate }}</a
                      >
                      <div>&nbsp;{{ 'key_or' | translate }}&nbsp;</div>
                      <a
                        [ngClass]="{ 'disabled-action': readOnly }"
                        class="xpath-link primary-color"
                        (click)="onAddStep(parameter)"
                        >{{ 'key_useQuery' | translate }}</a
                      >
                    </div>
                  </div>
                  <div
                    *ngIf="
                      isGuid(getParameterRight(parameter)) ||
                      currentType === 'identity'
                    "
                  >
                    <app-editor-identity
                      [ngModel]="{
                        dataType: 'Reference',
                        displayName: '',
                        multivalued: false,
                        permissionHint:
                          'Add, Create, Modify, Delete, Read, Remove',
                        systemName: '',
                        value: null
                      }"
                      [instantValue]="parameter.parameters[1]"
                      [creationMode]="true"
                      [config]="idpConfig"
                      (valueChange)="onIdentityChange($event, parameter)"
                    ></app-editor-identity>
                  </div>
                </div>
                <div
                  *ngIf="getParameterRightType(parameter) === 'step'"
                  fxFlex="auto"
                >
                  <ng-container
                    *ngTemplateOutlet="
                      nestedSteps;
                      context: { $implicit: parameter.parameters[1].steps }
                    "
                  ></ng-container>
                </div>
                <!-- <div fxFlex="auto"></div> -->
                <button
                  *ngIf="!readOnly"
                  mat-icon-button
                  (click)="onApplyStatement()"
                  class="parameter-button"
                >
                  <mat-icon>check</mat-icon>
                </button>
                <button
                  *ngIf="!readOnly && !valueChangeOnly"
                  mat-icon-button
                  class="parameter-button"
                  (click)="onRemoveStatement(parameters, parameter)"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          fxLayout="row"
          [style.margin-left.px]="margin"
          *ngIf="!readOnly && !valueChangeOnly"
        >
          <div style="width: 30px">
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
            <div class="children-line"></div>
          </div>
          <div fxLayout="row" class="query-line">
            <a
              class="xpath-link primary-color"
              (click)="onAddStatement(parameters, step)"
              >{{ 'key_addStatement' | translate }}</a
            >
            <div>&nbsp;{{ 'key_or' | translate }}&nbsp;</div>
            <a
              class="xpath-link primary-color"
              (click)="onAddSubCondition(parameters, step)"
              >{{ 'key_addSubCondition' | translate }}</a
            >
            <div>&nbsp;{{ 'key_or' | translate }}&nbsp;</div>
            <a
              class="xpath-link primary-color"
              (click)="onAddNegation(parameters, step)"
              >{{ 'key_addNegation' | translate }}</a
            >
          </div>
        </div>
      </ng-template>
      <div
        *ngIf="step.predicate && step.predicate.parameters; else noPredicate"
      >
        <ng-container
          *ngTemplateOutlet="
            nestedParameters;
            context: {
              $implicit:
                step.predicate.operator === 'and' ||
                step.predicate.operator === 'or'
                  ? step.predicate.parameters
                  : [step.predicate]
            }
          "
        ></ng-container>
      </div>
      <ng-template #noPredicate>
        <div
          fxLayout="row"
          [style.margin-left.px]="margin"
          *ngIf="!readOnly && !valueChangeOnly"
        >
          <div style="width: 30px">
            <div class="vertical-line"></div>
            <div class="horizontal-line"></div>
            <div class="children-line"></div>
          </div>
          <div fxLayout="row" class="query-line">
            <a
              class="xpath-link primary-color"
              (click)="onAddStatement(null, step)"
              >{{ 'key_addStatement' | translate }}</a
            >
            <div>&nbsp;{{ 'key_or' | translate }}&nbsp;</div>
            <a
              class="xpath-link primary-color"
              (click)="onAddSubCondition(null, step)"
              >{{ 'key_addSubCondition' | translate }}</a
            >
            <div>&nbsp;{{ 'key_or' | translate }}&nbsp;</div>
            <a
              class="xpath-link primary-color"
              (click)="onAddNegation(null, step)"
              >{{ 'key_addNegation' | translate }}</a
            >
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>
<ng-container
  *ngTemplateOutlet="nestedSteps; context: { $implicit: query.steps }"
></ng-container>
<mat-menu #menuTypes="matMenu" style="max-height: 300px">
  <ng-template matMenuContent let-data="data">
    <button
      *ngFor="let type of types"
      mat-menu-item
      (click)="onTypeClick(type[1], data)"
    >
      {{ type[1].displayName }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #menuStepLogic="matMenu">
  <ng-template matMenuContent let-data="data">
    <button
      *ngFor="let logic of logics | keyvalue"
      mat-menu-item
      (click)="onStepLogicClick(logic.key, data)"
    >
      {{ logic.value | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #menuParameterLogic="matMenu">
  <ng-template matMenuContent let-data="data">
    <button
      *ngFor="let logic of logics | keyvalue"
      mat-menu-item
      (click)="onParameterLogicClick(logic.key, data)"
    >
      {{ logic.value | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #menuAttributes="matMenu">
  <ng-template matMenuContent let-data="data">
    <button
      *ngFor="let attribute of attributes"
      mat-menu-item
      (click)="onAttributeClick(attribute[0], data)"
    >
      {{ attribute[1].displayName | translate }}
    </button>
  </ng-template>
</mat-menu>
<mat-menu #menuOperators="matMenu">
  <ng-template matMenuContent let-data="data">
    <button
      *ngFor="let operator of operators | keyvalue"
      mat-menu-item
      (click)="onOperatorClick(operator.key, data)"
    >
      {{ operator.value | translate }}
    </button>
  </ng-template>
</mat-menu>
