<div class="splash-cover" [style.backgroundColor]="bgColor">
  <div style="flex: none">
    <img
      [src]="imageSource"
      alt=""
      class="splash-image"
      [style.height.px]="imageHeight"
    />
  </div>
  <div style="flex: none">
    <div class="animlayoutCycle">
      <div
        id="animcycleblock1"
        class="animcycle"
        [style.backgroundColor]="bgColorLoading"
      ></div>
      <div
        id="animcycleblock2"
        class="animcycle"
        [style.backgroundColor]="bgColorLoading"
      ></div>
      <div
        id="animcycleblock3"
        class="animcycle"
        [style.backgroundColor]="bgColorLoading"
      ></div>
      <div
        id="animcycleblock4"
        class="animcycle"
        [style.backgroundColor]="bgColorLoading"
      ></div>
      <div
        id="animcycleblock5"
        class="animcycle"
        [style.backgroundColor]="bgColorLoading"
      ></div>
    </div>
  </div>
</div>
