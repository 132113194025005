import {
  Component,
  OnInit,
  forwardRef,
  AfterViewInit,
  Injector,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { of } from 'rxjs';
import { tap, switchMap, finalize } from 'rxjs/operators';

import { AttributeEditor } from '../../models/dynamicEditor.interface';
import { BooleanEditorConfig } from '../../models/editorContract.model';

import { createBooleanEditorValidator } from '../../validators/validators';

import { SwapService } from '../../services/swap.service';

import { EditorBooleanConfigComponent } from './editor-boolean-config.component';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';
import { EditorEvent } from '../../models/dataContract.model';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-editor-boolean',
  templateUrl: './editor-boolean.component.html',
  styleUrls: ['./editor-boolean.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorBooleanComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorBooleanComponent),
      multi: true,
    },
  ],
})
export class EditorBooleanComponent
  extends AttributeEditor
  implements OnInit, AfterViewInit
{
  @Input()
  loadAsReadonly = false;

  private conf = new BooleanEditorConfig();
  public get config() {
    return this.conf;
  }
  public set config(value) {
    this.conf = value;
    this.configChange.emit(this.conf);
  }

  get value() {
    if (!this.editorAttribute) {
      return undefined;
    }

    if (!this.simpleMode) {
      if (this.config.customValue) {
        if (this.config.customValueIngoreCase) {
          if (
            String(this.editorAttribute.value).toLowerCase() ===
            this.config.trueValue.toLowerCase()
          ) {
            return true;
          } else if (
            String(this.editorAttribute.value).toLowerCase() ===
            this.config.falseValue.toLowerCase()
          ) {
            return false;
          } else {
            return undefined;
          }
        } else {
          if (String(this.editorAttribute.value) === this.config.trueValue) {
            return true;
          } else if (
            String(this.editorAttribute.value) === this.config.falseValue
          ) {
            return false;
          } else {
            return undefined;
          }
        }
      }
    }

    return this.editorAttribute.value;
  }
  set value(value) {
    this.editorAttribute.value = value;

    if (!this.simpleMode) {
      if (this.config.customValue) {
        this.editorAttribute.value = value
          ? this.config.trueValue
          : this.config.falseValue;
        this.editorAttribute.values = [this.editorAttribute.value];
      }
    }

    this.propagateChange(this.editorAttribute);
  }

  hideNoReadAccessMessage: boolean = this.configService.getConfig(
    'hideNoReadAccessMessage',
    false
  );
  hideNoWriteAccessMessage: boolean = this.configService.getConfig(
    'hideNoWriteAccessMessage',
    false
  );

  simpleEditing = false;

  constructor(
    public injector: Injector,
    private swap: SwapService,
    private window: WindowService,
    private configService: ConfigService
  ) {
    super(injector);
  }

  setDisplay(usedFor: string = null, optionValue: boolean = null) {
    this.applyDisplaySettings(this.swap, this.resource, usedFor, optionValue);
  }

  applyConfig() {
    setTimeout(() => {
      this.setDisplay();
    });
  }

  ngOnInit() {
    if (!this.simpleMode) {
      this.initComponent();
    }
  }

  ngAfterViewInit() {
    if (this.simpleMode) {
      this.setDisplay(null, true);
    } else {
      setTimeout(() => {
        this.validationFn = createBooleanEditorValidator(this.config);
        this.applyConfig();

        if (this.creationMode && !this.configMode) {
          if (this.config.initExpression) {
            const resolved = this.resolveExpression(this.config.initExpression);
            if (resolved) {
              if (typeof resolved === 'string') {
                if (resolved.toLowerCase() === 'true') {
                  this.value = true;
                } else {
                  this.value = false;
                }
              } else if (typeof resolved === 'boolean') {
                this.value = resolved;
              } else {
                this.value = false;
              }
            } else {
              this.value = false;
            }
            // trigger init value building for wizard view
            // this doesn't apply for editing view because initExpression doesn't exist
            this.swap.editorEvent(
              new EditorEvent(
                'change',
                this.config.attributeName,
                this.currentID,
                this.currentType,
                this.value
              )
            );
          }
        }
      });
    }
  }

  // #region AttributeEditor implementation

  initComponent() {
    this.validationFn = createBooleanEditorValidator(this.config);

    if (this.editorAttribute && this.editorAttribute.required) {
      this.config.required = true;
      this.config.requiredFromSchema = true;
    }

    const initConfig = new BooleanEditorConfig();
    this.utils.CopyInto(this.config, initConfig, true, true, [
      'calculatedDisplayable',
      'calculatedEditable',
    ]);
    this.config = initConfig;

    return this.config;
  }

  configure() {
    const configCopy = this.utils.DeepCopy(this.config);

    this.swap.broadcast({ name: 'show-overlay', parameter: undefined });

    const windowRef = this.window.open({
      content: EditorBooleanConfigComponent,
      width: 720,
    });
    const windowIns = windowRef.content.instance;
    windowIns.data = {
      component: this,
      config: this.config,
      attribute: this.editorAttribute,
      creationMode: this.creationMode,
      viewMode: this.viewMode,
    };

    return windowRef.result.pipe(
      tap((result: any) => {
        if (result instanceof WindowCloseResult) {
          this.config = configCopy;
        } else {
          this.validationFn = createBooleanEditorValidator(this.config);
          this.applyConfig();
        }
      }),
      switchMap(() => {
        return of(this.config);
      }),
      finalize(() => {
        this.swap.broadcast({ name: 'hide-overlay', parameter: undefined });
      })
    );
  }

  // #endregion

  // #region Event handler

  onFocuse() {
    this.propagateTouched();
  }

  onChange() {
    if (this.valueChange.observers.length > 0) {
      this.valueChange.emit(this.value);
    }

    this.swap.editorEvent(
      new EditorEvent(
        'change',
        this.config.attributeName,
        this.currentID,
        this.currentType,
        this.value
      )
    );
  }

  onStartEdit() {
    this.simpleEditing = true;
  }

  // #endregion
}
