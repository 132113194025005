import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(
    private sanitizer: DomSanitizer,
    private dompurifySanitizer: NgDompurifySanitizer
  ) {}

  transform(html: any) {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.dompurifySanitizer.sanitize(SecurityContext.HTML, html)
    );
  }
}
