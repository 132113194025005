<div
  *ngIf="!reportConfig; else configExists"
  fxLayout="column"
  fxLayoutAlign="center center"
  style="height: 90%; margin-top: 20px"
>
  <mat-icon fxFlex="none" style="font-size: 100px; width: 100px; color: #cccccc"
    >error</mat-icon
  >
  <div
    fxFlex="none"
    style="margin-top: 50px; padding-top: 50px; font-size: 18px; color: #b0b0b0"
  >
    {{ 'key_noConfiguration' | translate }}
  </div>
</div>
<ng-template #configExists>
  <div fxLayout="column" class="main-layout">
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="auto" style="margin-right: 20px">
        <div
          *ngIf="reportConfig.text"
          class="primary-text-color"
          style="margin-bottom: 5px; font-size: 20px; font-weight: 400"
        >
          {{ reportConfig.text | translate }}
        </div>
        <div
          *ngIf="reportConfig.description"
          class="accent-text-color"
          style="font-size: 13px"
        >
          {{ reportConfig.description | translate }}
        </div>
      </div>
      <div fxFlex="none">
        <span
          [matTooltip]="'key_refresh' | translate"
          class="material-icons accent-text-color"
          style="margin-right: 10px; font-size: 28px; cursor: pointer"
          (click)="onRefresh()"
        >
          refresh
        </span>
      </div>
      <div fxFlex="none">
        <span
          [matTooltip]="'key_settings' | translate"
          class="material-icons accent-text-color"
          style="margin-right: 10px; font-size: 30px; cursor: pointer"
          (click)="onToggleSettings()"
        >
          vertical_split
        </span>
      </div>
      <div
        *ngIf="!reportConfig.isHistorical"
        style="margin-top: -5px; margin-left: 10px"
      >
        <app-action-menu
          #actionMenu
          [menuType]="'button'"
          [menuIcon]="'menu_open'"
          [menuText]="'key_action' | translate"
          [menuColor]="'primary'"
          (action)="onMenuAction($event)"
          (openMenu)="onMenuOpen()"
        ></app-action-menu>
      </div>
    </div>
    <div fxFill>
      <kendo-splitter
        style="height: 100%; background-color: #eeeeee; margin-top: 10px"
      >
        <kendo-splitter-pane>
          <div *ngIf="tableConfig" style="height: 100%">
            <app-resource-table
              #resourceTable
              [config]="tableConfig"
              [historyTimestamp]="historyTimestamp"
              [historyReportName]="reportConfig.name"
            ></app-resource-table>
          </div>
        </kendo-splitter-pane>
        <kendo-splitter-pane
          size="600px"
          [collapsible]="true"
          [collapsed]="hideConfig"
        >
          <div fxLayout="column" style="height: 100%; padding: 10px">
            <div fxFlex="none">
              <mat-form-field style="width: 100%">
                <textarea
                  matInput
                  [rows]="3"
                  [placeholder]="'key_query' | translate"
                  [value]="
                    appliedQuery === '' ? reportConfig.query : appliedQuery
                  "
                  [disabled]="true"
                ></textarea>
              </mat-form-field>
            </div>
            <div
              *ngIf="reportConfig.isHistorical"
              style="font-size: 16px; margin-bottom: 20px; font-weight: 400"
            >
              {{ 'key_specifyTimePoint' | translate }}
            </div>
            <div fxLayout="row" *ngIf="reportConfig.isHistorical">
              <kendo-datepicker
                fxFlex="auto"
                [format]="dateFormat"
                [max]="maxDate"
                [(ngModel)]="reportDate"
                formatPlaceholder="formatPattern"
                style="margin-bottom: 30px"
                (valueChange)="onDateChange()"
              >
                <kendo-datepicker-messages
                  [today]="'key_calendarToday' | translate"
                  [toggle]="'key_calendarToggle' | translate"
                ></kendo-datepicker-messages>
              </kendo-datepicker>
              <div
                *ngIf="reportConfig.isRelativeDate && !dateChanged"
                fxFlex="none"
                style="font-size: 16px; margin-left: 20px; margin-top: 2px"
              >
                ( {{ reportConfig.relativeDate }}
                {{ 'key_daysAgo' | translate }} )
              </div>
            </div>
            <div
              *ngIf="parameterConfig && parameterConfig.length > 0"
              style="font-size: 16px; margin-bottom: 20px; font-weight: 400"
            >
              {{ 'key_inputParameters' | translate }}
            </div>
            <div *ngFor="let parameter of parameterConfig">
              <div
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="10px"
              >
                <div fxFlex="30">{{ parameter.title | translate }}</div>
                <div fxFlex="70" [ngSwitch]="parameter.type">
                  <div *ngSwitchCase="'text'">
                    <mat-form-field style="width: 100%">
                      <input
                        matInput
                        [(ngModel)]="parameter.value"
                        [disabled]="parameter.readonly"
                      />
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'number'">
                    <mat-form-field style="width: 100%">
                      <input
                        matInput
                        type="number"
                        [(ngModel)]="parameter.value"
                        [disabled]="parameter.readonly"
                      />
                    </mat-form-field>
                  </div>
                  <div
                    *ngSwitchCase="'boolean'"
                    style="margin-top: 15px; margin-bottom: 15px"
                  >
                    <mat-checkbox
                      [(ngModel)]="parameter.value"
                      [disabled]="parameter.readonly"
                    ></mat-checkbox>
                  </div>
                  <div *ngSwitchCase="'reference'">
                    <app-editor-identity
                      #idpParameter
                      [(ngModel)]="parameter.value"
                      [creationMode]="true"
                      [config]="parameter.config"
                    ></app-editor-identity>
                  </div>
                </div>
              </div>
            </div>
            <div fxFlex="auto"></div>
            <div fxFlex="none" style="margin-top: 30px">
              <button mat-stroked-button (click)="onApplyParameter()">
                {{ 'key_apply' | translate }}
              </button>
            </div>
          </div>
        </kendo-splitter-pane>
      </kendo-splitter>
    </div>
  </div>
</ng-template>
