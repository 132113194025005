import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import {
  ReportCardConfig,
  ResourceColumnConfig,
  ResourceTableConfig,
} from '../core/models/componentContract.model';
import { ResourceService } from '../core/services/resource.service';
import { UtilsService } from '../core/services/utils.service';
import { IdentityEditorConfig } from '../core/models/editorContract.model';
import { EditorIdentityComponent } from '../core/components/editor-identity/editor-identity.component';
import { TransService } from '../core/models/translation.model';

import * as moment from 'moment';
import {
  ApiDef,
  HistoricalReportConfig,
  MenuEvent,
} from '../core/models/dataContract.model';
import { ConfigService } from '../core/services/config.service';
import { tap } from 'rxjs/operators';
import { SwapService } from '../core/services/swap.service';
import { ActionMenuComponent } from '../core/components/action-menu/action-menu.component';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription = new Subscription();

  private reportUrl = 'reporting/search';

  @ViewChild('resourceTable') resourceTable: ResourceTableComponent;

  @ViewChild('actionMenu')
  actionMenu: ActionMenuComponent;

  @ViewChildren('idpParameter')
  idpParameters: QueryList<EditorIdentityComponent>;

  reportConfig: ReportCardConfig;

  tableConfig: ResourceTableConfig;

  hideConfig = true;

  queryError = '';

  parameterConfig: Array<any> = [];

  idpConfig = new IdentityEditorConfig();

  maxDate = new Date();
  reportDate: Date = undefined;

  appliedQuery = '';

  dateChanged = false;

  get dateFormat() {
    const dFormat = this.translate.instant('key_dateFormat');
    const tFormat = this.translate.instant('key_timeFormat');

    return `${dFormat} ${tFormat}`;
  }

  get historyTimestamp() {
    if (this.reportDate) {
      // const retVal = moment(this.reportDate).format();
      const retVal =
        moment(this.reportDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') +
        'Z';
      return retVal;
    }
    return undefined;
  }

  get selectedItems() {
    if (this.resourceTable) {
      return this.resourceTable.selection.length > 0
        ? this.resourceTable.selection
        : undefined;
    } else {
      return undefined;
    }
  }

  private prepareApiConfig(): HistoricalReportConfig {
    const query = this.applyParameters();
    if (!query || !this.reportDate) {
      return null;
    }

    const hrConfig = new HistoricalReportConfig();
    if (this.reportConfig.absoluteDate && !this.dateChanged) {
      if (this.reportConfig.absoluteDate.endsWith('Z')) {
        hrConfig.dateTimeUtc = this.reportConfig.absoluteDate;
      } else {
        hrConfig.dateTimeUtc =
          moment(this.reportConfig.absoluteDate)
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') + 'Z';
      }
    } else {
      hrConfig.dateTimeUtc =
        moment(this.reportDate).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS') +
        'Z';
    }
    hrConfig.pageSize = this.tableConfig.pageSize;
    hrConfig.attributes = [];
    hrConfig.historicalAttributes = [];

    if (this.tableConfig.initSort && this.tableConfig.initSort.length > 0) {
      let sortOrder = '';
      switch (this.tableConfig.initSort[0].dir) {
        case 'asc':
        case 'ascending':
          sortOrder = 'ascending';
          break;
        case 'desc':
        case 'descending':
          sortOrder = 'descending';
          break;
        default:
          break;
      }
      hrConfig.orderBy = {
        attribute: this.tableConfig.initSort[0].field,
        order: sortOrder,
      };
    }

    if (this.tableConfig.columns) {
      this.tableConfig.columns.forEach((c: ResourceColumnConfig) => {
        if (c.reportType === 'historical') {
          hrConfig.historicalAttributes.push(c.field);
        } else {
          hrConfig.attributes.push(c.field);
        }
      });
    }

    return hrConfig;
  }

  private prepareParameters() {
    if (!this.reportConfig || !this.reportConfig.parameterDef) {
      return;
    }

    for (const [key, value] of Object.entries(this.reportConfig.parameterDef)) {
      value['name'] = key;
      if (value['default'] === undefined) {
        value['value'] = null;
      } else {
        value['value'] = value['default'];
      }
      if (value['type'] === 'reference') {
        const controlConfig: IdentityEditorConfig = this.utils.DeepCopy(
          this.idpConfig
        );
        controlConfig.name = key;
        controlConfig.attributeName = key;
        controlConfig.showDisplayName = false;
        controlConfig.showDescription = false;
        controlConfig.readOnly = value['readonly'] ?? false;
        value['config'] = controlConfig;

        value['value'] = {
          dataType: 'Reference',
          displayName: key,
          multivalued: false,
          permissionHint: 'Add, Create, Modify, Delete, Read, Remove',
          systemName: key,
          value: value['default'],
        };
      }
      this.parameterConfig.push(value);
    }
  }

  private applyParameters() {
    if (this.reportConfig && this.reportConfig.query) {
      let query = this.reportConfig.query;
      if (query.indexOf('[#') < 0) {
        return query;
      } else {
        const regExValue = /\[#\w+(-\w+)?\]/g;
        const arrValue = query.match(regExValue);
        const arrMap = arrValue.map((n: string) => {
          const name = n.substring(2, n.length - 1);
          const result = {
            replace: name,
          };
          const def = this.reportConfig.parameterDef[name];
          if (def) {
            if (def.value !== null && def.value !== undefined) {
              if (def.type === 'reference') {
                if (def.value instanceof String) {
                  result['replaceWith'] = def.value;
                } else if (def.value.value) {
                  result['replaceWith'] = this.utils.ExtraValue(
                    def.value.value,
                    'ObjectID'
                  );
                }
              } else {
                result['replaceWith'] = def.value;
              }
            }
          }
          return result;
        });
        arrMap.forEach((a: { replace: string; replaceWith?: string }) => {
          if (
            a.replaceWith !== null &&
            a.replaceWith !== undefined &&
            a.replaceWith !== ''
          ) {
            query = query.replace(
              new RegExp(`\\[#${a.replace}\\]`),
              this.utils.encodeSearchText(a.replaceWith)
            );
          }
        });

        query = query.replace(
          /\[#LoginID\]/gi,
          this.utils.ExtraValue(this.resource.loginUser, 'ObjectID:value')
        );

        if (query.indexOf('[#') < 0) {
          return query;
        } else {
          return '';
        }
      }
    }

    return '';
  }

  private fetchReportData() {
    this.appliedQuery = this.applyParameters();

    if (this.reportConfig && this.reportConfig.isHistorical) {
      const hrConfig = this.prepareApiConfig();
      if (hrConfig) {
        const apiConfig: ApiDef = {
          method: 'POST',
          path: this.reportUrl,
          pathContinue: `${this.reportUrl}/continue`,
          pathRefresh: `${this.reportUrl}/refreshtokens`,
          param: {
            xPathQuery: this.appliedQuery,
          },
          body: hrConfig,
        };
        this.tableConfig.api = apiConfig;
        if (this.resourceTable) {
          this.resourceTable.updateDataSource(true);
        }
      }
    } else {
      this.tableConfig.query = this.appliedQuery;
      if (this.resourceTable) {
        this.resourceTable.updateDataSource(true);
      }
    }
  }

  private initComponent() {
    this.reportConfig = undefined;
    this.tableConfig = undefined;
    this.hideConfig = true;
    this.queryError = '';
    this.parameterConfig = [];
    this.idpConfig = new IdentityEditorConfig();
    this.maxDate = new Date();
    this.reportDate = undefined;
    this.appliedQuery = '';
    this.dateChanged = false;

    const currentNavigation = this.router.getCurrentNavigation();
    if (
      currentNavigation &&
      currentNavigation.extras &&
      currentNavigation.extras.state
    ) {
      this.reportConfig = currentNavigation.extras.state as ReportCardConfig;

      this.hideConfig = this.reportConfig.hideConfig;

      this.tableConfig = this.reportConfig.tableConfig;
    }

    if (!this.reportConfig) {
      this.router.navigate(['/app/reports']);
    }

    this.idpConfig.browserShowTypePicker = true;
    this.idpConfig.browserDefaultType = 'Person';
    this.idpConfig.allowEmptySearch = true;
    this.idpConfig.queryNormalSearch = `/*[starts-with(DisplayName,'%SearchText%')]`;
    this.idpConfig.queryExactSearch = `/*[DisplayName='%SearchText%']`;
    this.idpConfig.attributesToShow = [
      {
        field: 'DisplayName',
        sortable: true,
        filterable: true,
        filter: 'text',
        width: 0,
      },
      {
        field: 'ObjectType',
        sortable: true,
        filterable: true,
        filter: 'text',
        width: 0,
      },
    ];

    if (this.reportConfig && this.reportConfig.isHistorical) {
      if (this.reportConfig.isRelativeDate) {
        if (this.reportConfig.relativeDate) {
          this.reportDate = moment()
            .subtract(this.reportConfig.relativeDate, 'days')
            .utc()
            .toDate();
        } else {
          this.reportDate = moment().utc().toDate();
        }
      } else {
        if (this.reportConfig.absoluteDate) {
          if (this.reportConfig.absoluteDate.endsWith('Z')) {
            this.reportDate = moment(
              this.reportConfig.absoluteDate,
              'YYYY-MM-DDTHH:mm:ss.SSSSSSSZ'
            ).toDate();
          } else {
            this.reportDate = moment(
              this.reportConfig.absoluteDate,
              'YYYY-MM-DDTHH:mm:ss.SSS'
            )
              .utc()
              .toDate();
          }
        }
      }
    }

    this.prepareParameters();

    setTimeout(() => {
      if (this.idpParameters) {
        this.idpParameters.forEach((p: EditorIdentityComponent) => {
          if (p.config && p.config.name) {
            const def = this.reportConfig.parameterDef[p.config.name];
            if (def && def.default) {
              if (def.default.toLowerCase() === '[#loginid]') {
                p.value = this.utils.ExtraValue(
                  this.resource.loginUser,
                  'ObjectID'
                );
              } else {
                p.value = def.default;
              }
            }
          }
        });
      }

      setTimeout(() => {
        this.fetchReportData();
      }, this.config.getConfig('intervalSmall', 300));
    });
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private resource: ResourceService,
    private utils: UtilsService,
    private translate: TransService,
    private config: ConfigService,
    private swap: SwapService
  ) {
    this.initComponent();
  }

  ngOnInit(): void {
    console.log();
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.route.params
        .pipe(
          tap(() => {
            const currentNavigation = this.router.getCurrentNavigation();
            if (currentNavigation) {
              this.initComponent();
            }
          })
        )
        .subscribe()
    );

    // Send build menu event
    setTimeout(() => {
      this.swap.menuEvent(
        new MenuEvent('build', 'report', '', null, this.actionMenu, '')
      );
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onToggleSettings() {
    this.hideConfig = !this.hideConfig;
  }

  onRefresh() {
    this.fetchReportData();
  }

  onApplyParameter() {
    this.fetchReportData();
  }

  onDateChange() {
    this.dateChanged = true;
  }

  onMenuAction(actionName: string) {
    this.swap.menuEvent(
      new MenuEvent('action', 'report', actionName, {
        selectedItems: this.selectedItems,
        table: this.resourceTable,
      })
    );
  }

  onMenuOpen() {
    this.swap.menuEvent(
      new MenuEvent(
        'open',
        'report',
        '',
        this.selectedItems,
        this.actionMenu,
        ''
      )
    );
  }
}
