<!-- Brand View -->
<div *ngIf="iconMode; else fullMode">
  <div fxFlex fxLayout="row">
    <div fxFlex="auto"></div>
    <div fxFlex="none" style="margin-top: 12px">
      <app-action-menu
        #actionMenu
        class="info-icon"
        [ngClass]="{ 'init-hidden': initHidden }"
        [iconMode]="true"
        [menuIcon]="'menu_open'"
        [menuItems]="actionItems"
        [menuTooltip]="'key_action' | translate"
        (action)="onMenuAction($event)"
        (openMenu)="onMenuOpen()"
      >
      </app-action-menu>
    </div>
  </div>
</div>
<ng-template #fullMode>
  <div
    class="info-body"
    [class.mat-elevation-z1]="true"
    ngxUiLoaderBlurred
    [blur]="blurLevel"
  >
    <!-- PhotoEditor -->
    <app-photo-editor
      *ngIf="currentResource"
      [initials]="initial"
      [photoAttribute]="getPhotoAttribute()"
      (photoDeleted)="onPhotoDeleted()"
      (photoUpdated)="onPhotoUpdated($event)"
    ></app-photo-editor>

    <!-- Main-/Secondary-Attribute -->
    <div
      *ngIf="!options.useCustomComponent"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
      style="margin-top: 10px"
    >
      <!-- SecondaryAttribute -->
      <div
        *ngIf="
          currentResource | examValue: options.secondaryAttribute + ':value'
        "
        fxFlex="none"
        class="info-title accent-text-color"
      >
        {{
          currentResource | extraValue: options.secondaryAttribute + ':value'
        }}
      </div>

      <!-- MainAttribute -->
      <div
        *ngIf="currentResource | examValue: options.mainAttribute + ':value'"
        fxFlex="none"
        class="info-name"
      >
        {{ currentResource | extraValue: options.mainAttribute + ':value' }}
      </div>
    </div>

    <!-- Additional attributes to be displayed -->
    <div *ngIf="!options.useCustomComponent">
      <ng-container *ngFor="let a of options.attributes">
        <div
          fxLayout="row"
          class="info-text accent-text-color"
          *ngIf="!a.hidden && a.hasValue"
          [matTooltip]="a.name"
        >
          <!-- Icon -->
          <div *ngIf="a.icon" fxFlex="none">
            <mat-icon
              *ngIf="a.value === undefined || a.value === true"
              style="margin-right: 10px"
              >{{ a.icon }}</mat-icon
            >
            <mat-icon *ngIf="a.value === false" style="margin-right: 10px">{{
              a.iconAlt
            }}</mat-icon>
          </div>

          <!-- Value -->
          <div *ngIf="a.value === undefined; else lblDisplayName">
            <div *ngIf="a.isRef; else lblString">
              <app-identity-link
                [identity]="currentResource | extraValue: a.name + ':value'"
                [linkActions]="['native', 'popup', 'navigate']"
              ></app-identity-link>
            </div>
            <ng-template #lblString>
              {{ currentResource | extraValue: a.name + ':value' }}
            </ng-template>
          </div>
          <ng-template #lblDisplayName>
            {{
              a.value === true
                ? a.text
                  ? (a.text | translate)
                  : (currentResource | extraValue: a.name + ':displayName')
                : a.textAlt
                ? (a.textAlt | translate)
                : (currentResource | extraValue: a.name + ':displayName')
            }}
            <!-- {{ currentResource | extraValue: a.name + ':displayName' }} -->
          </ng-template>
        </div>
      </ng-container>
    </div>

    <!-- Custom component -->
    <div *ngIf="options.useCustomComponent">
      <ng-container appDynamicContainer></ng-container>
    </div>

    <!-- Footer -->
    <div *ngIf="options.showButtons" fxLayout="column" class="info-footer">
      <!-- Divider -->
      <hr style="border: 0; border-top: 1px solid #eee; width: 80%" />

      <!-- Buttons -->
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="space-evenly center"
        fxLayoutGap="10px"
        fxLayoutAlign.lt-md="center center"
        fxLayoutGap.lt-md="30px"
      >
        <!-- Actions -->
        <app-action-menu
          #actionMenu
          class="info-button accent-bg-color primary-contrast-color"
          [menuIcon]="'menu_open'"
          [menuItems]="actionItems"
          [menuTooltip]="'key_action' | translate"
          [xPosition]="options.menuPositionX"
          [yPosition]="options.menuPositionY"
          (action)="onMenuAction($event)"
          (openMenu)="onMenuOpen()"
        >
        </app-action-menu>
      </div>
    </div>
  </div>
</ng-template>
