<mat-form-field [appearance]="appearance" style="width: 100%">
  <mat-select
    [(ngModel)]="value"
    [multiple]="multiple"
    [placeholder]="placeHolder | translate"
    [matTooltip]="tooltip"
    [disabled]="disabled"
    (selectionChange)="onSelectionChange()"
  >
    <mat-option
      *ngFor="let type of types | async"
      [value]="type.systemName"
      style="font-size: 14px; height: 32px"
    >
      {{ type.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
