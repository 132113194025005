<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px grid">
  <div fxFlex="200px" style="font-weight: 400; line-height: 35px">
    {{ 'key_timeToDelay' | translate }}
  </div>
  <mat-form-field fxFlex="80px">
    <input
      matInput
      type="number"
      min="0"
      [placeholder]="'key_hours' | translate"
      [(ngModel)]="delayHours"
      (change)="onHoursChange()"
    />
  </mat-form-field>
  <div>:</div>
  <mat-form-field fxFlex="80px">
    <input
      matInput
      type="number"
      min="0"
      max="59"
      [placeholder]="'key_minutes' | translate"
      [(ngModel)]="delayMinutes"
      (change)="onMinutesChange()"
    />
  </mat-form-field>
  <div>:</div>
  <mat-form-field fxFlex="80px">
    <input
      matInput
      type="number"
      min="0"
      max="59"
      [placeholder]="'key_seconds' | translate"
      [(ngModel)]="delaySeconds"
      (change)="onSecondsChange()"
    />
  </mat-form-field>
</div>
