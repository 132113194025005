import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
} from '@angular/core';

import { switchMap, tap, finalize, catchError } from 'rxjs/operators';
import { BehaviorSubject, Subscription, of, throwError } from 'rxjs';
import * as moment from 'moment';

import { ResourceService } from '../../services/resource.service';
import { TransService } from '../../models/translation.model';
import { UtilsService } from '../../services/utils.service';
import { ModalService } from '../../services/modal.service';
import {
  ModalType,
  ReportCardConfig,
} from '../../models/componentContract.model';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { MglTimelineEntryComponent } from 'angular-mgl-timeline/src/timeline/timeline-entry/timeline-entry.component';
import { ConfigService } from '../../services/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BasicResource, Resource } from '../../models/dataContract.model';
import { GridsterComponentItem } from '../../models/dynamicComponent.interface';
import { ActionMenuItem } from '../../models/dataContract.model';
import { Router } from '@angular/router';
import { SwapService } from '../../services/swap.service';

@Component({
  selector: 'app-object-history',
  templateUrl: './object-history.component.html',
  styleUrls: ['./object-history.component.scss'],
})
export class ObjectHistoryComponent implements OnInit, OnDestroy {
  private refreshToken = new BehaviorSubject(undefined);
  private subscription = new Subscription();
  private ignoredAttributes = ['lastupdatetime', 'internalmodificationcounter'];
  private dtFormat: string;

  @ViewChildren('entryComponent')
  entryComponents: QueryList<MglTimelineEntryComponent>;

  // event or resource
  @Input()
  mode = 'event';

  @Input()
  targetId: string;

  @Input()
  targetType: string;

  @Input()
  count = 100;

  @Input()
  startTime = '';

  @Input()
  endTime = '';

  @Input()
  statusTime = '';

  @Input()
  historySidenav = false;

  @Input()
  dotSize = 60;

  @Input()
  side = 'left';

  @Input()
  alternate = true;

  @Input()
  toggle = false;

  @Input()
  contentAnimation = true;

  @Input()
  dotAnimation = true;

  @Input()
  reportName = '';

  @Output()
  restore = new EventEmitter();

  entries: Array<any> = [];
  status: string;
  statusResource: BasicResource;

  linkedReports: Array<ReportCardConfig> = [];
  reportMenuItems: Array<ActionMenuItem> = [];

  previewAllowed = false;

  private getReports(target: Resource) {
    if (
      this.resource.primaryViewSetting &&
      this.resource.primaryViewSetting.reports
    ) {
      const gridsterItems: Array<GridsterComponentItem> =
        this.resource.primaryViewSetting.reports.components;
      if (gridsterItems && gridsterItems.length > 0) {
        const reportItems: Array<ReportCardConfig> = gridsterItems.map(
          (g) => g.componentConfig
        );
        if (reportItems && reportItems.length > 0) {
          this.linkedReports = reportItems.filter((r: ReportCardConfig) => {
            if (
              r.linkedObjectType &&
              r.linkedObjectType.toLowerCase() ===
                this.utils.ExtraValue(target, 'ObjectType').toLowerCase()
            ) {
              return r;
            }
          });
        }
      }
    }

    return [];
  }

  private buildReportMenu() {
    if (this.historySidenav) {
      this.reportMenuItems.push({
        name: 'timemachine',
        text: 'l10n_timeMachine',
        icon: 'history',
        hint: 'l10n_viewObjectHistory',
        color: 'primary',
      });
    }

    if (this.linkedReports && this.linkedReports.length > 0) {
      if (this.historySidenav) {
        this.reportMenuItems.push({
          name: 'mat-divider',
        });
      }
      this.linkedReports.sort((a, b) => {
        if (a.text.toLowerCase() > b.text.toLowerCase()) {
          return 1;
        } else {
          return -1;
        }
      });
      this.linkedReports.forEach((report: ReportCardConfig) => {
        if (report.name !== this.reportName) {
          this.reportMenuItems.push({
            name: report.name,
            icon: report.icon,
            text: report.text,
            hint: report.description,
          });
        }
      });
    }
  }

  private adjustReport(report: ReportCardConfig, item: any) {
    const reportConfig: ReportCardConfig = this.utils.DeepCopy(report);

    if (reportConfig.parameterDef) {
      if (item.detail) {
        reportConfig.absoluteDate = item.committedtime;

        Object.keys(reportConfig.parameterDef).forEach((p: string) => {
          switch (item.detail.type) {
            case 'full':
              {
                const pos = item.detail.attributes.findIndex(
                  (res: any) => res.name === p
                );
                if (pos >= 0) {
                  reportConfig.parameterDef[p].default =
                    item.detail.attributes[pos].after;
                  reportConfig.parameterDef[p].readonly = true;
                }
              }
              break;
            case 'resource':
              {
                const pos = item.detail.resource.findIndex(
                  (res: any) => res.name === p
                );
                if (pos >= 0) {
                  reportConfig.parameterDef[p].default =
                    item.detail.resource[pos].after;
                  reportConfig.parameterDef[p].readonly = true;
                }
              }
              break;
            default:
              break;
          }
        });
      } else if (Array.isArray(item)) {
        reportConfig.absoluteDate = this.statusTime;

        Object.keys(reportConfig.parameterDef).forEach((p: string) => {
          const pos = item.findIndex((res: any) => res.name === p);
          if (pos >= 0) {
            reportConfig.parameterDef[p].default = item[pos].value;
            reportConfig.parameterDef[p].readonly = true;
          }
        });
      }
    }

    return reportConfig;
  }

  constructor(
    private resource: ResourceService,
    private translate: TransService,
    private utils: UtilsService,
    private modal: ModalService,
    private config: ConfigService,
    private router: Router,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.dtFormat = `${this.translate.instant(
      'key_dateFormat'
    )} ${this.translate.instant('key_timeFormat')}`;

    this.previewAllowed = this.resource.rightSets.some(
      (r) => this.config.getConfig('previewPermission', []).indexOf(r) >= 0
    );

    let process: MatDialogRef<ModalComponent, any>;

    this.subscription.add(
      this.refreshToken
        .pipe(
          tap(() => {
            this.entries = [];
            this.linkedReports = [];
            this.reportMenuItems = [];
            this.status = 'created';
            this.statusResource = {
              DisplayName: '',
              ObjectID: '',
              ObjectType: '',
            };

            process = this.modal.show(
              ModalType.progress,
              'key_processing',
              '',
              '300px'
            );
          }),
          switchMap(() => {
            if (this.targetId) {
              if (this.targetType) {
                return of({
                  objecttype: this.targetType,
                  objectid: this.targetId,
                });
              } else {
                return this.resource.getResourceByID(this.targetId, [
                  'DisplayName',
                ]);
              }
            } else {
              return of(null);
            }
          }),
          // if resource is already deleted
          catchError(() => {
            return of({});
          }),
          switchMap((targetResource: Resource) => {
            if (targetResource && Object.keys(targetResource).length > 0) {
              this.getReports(targetResource);
              this.buildReportMenu();
            }

            if (this.targetId) {
              if (this.mode === 'event') {
                return this.resource.getEventHistory(
                  this.targetId,
                  this.count,
                  this.startTime,
                  this.endTime
                );
              } else {
                return this.resource
                  .getResourceHistory(this.targetId, this.statusTime)
                  .pipe(
                    catchError((error: HttpErrorResponse) => {
                      if (error.status === 404) {
                        this.status = 'deleted';
                        return this.resource.getDeletedHistory(this.targetId);
                      } else {
                        return throwError(error);
                      }
                    }),
                    catchError((error: HttpErrorResponse) => {
                      if (error.status === 404) {
                        this.status = 'notfound';
                        return of([]);
                      } else {
                        return throwError(error);
                      }
                    })
                  );
              }
            } else {
              return of([]);
            }
          }),
          tap((result) => {
            if (this.mode === 'event') {
              if (result && result.results) {
                this.entries = result.results;
                this.entries.forEach((entry) => {
                  const changedAttributes: Array<string> = [];
                  if (
                    entry.attributeassignments &&
                    entry.previousattributeassignments
                  ) {
                    Object.keys(entry.attributeassignments).forEach((key) => {
                      if (
                        !key.startsWith('$') &&
                        this.ignoredAttributes.indexOf(key) < 0
                      ) {
                        changedAttributes.push(key);
                      }
                    });
                  }
                  if (entry.multivalueinsertions) {
                    Object.keys(entry.multivalueinsertions).forEach((key) => {
                      changedAttributes.push(key);
                    });
                  }
                  if (entry.multivalueremovals) {
                    Object.keys(entry.multivalueremovals).forEach((key) => {
                      if (changedAttributes.indexOf(key) < 0) {
                        changedAttributes.push(key);
                      }
                    });
                  }
                  entry.changedAttributes = changedAttributes.join(', ');
                });
              }
            } else {
              for (const [key, value] of Object.entries(result)) {
                if (
                  !key.startsWith('$') &&
                  this.ignoredAttributes.indexOf(key) < 0
                ) {
                  const element: any = {
                    name: key,
                    displayName: this.resource.getAttributeDisplayName(
                      result.objecttype,
                      key
                    ),
                  };
                  if (value instanceof Array) {
                    if (value.length > 0) {
                      element.multivalued = true;
                      element.value = value;
                    } else {
                      element.value = 'null';
                    }
                  } else {
                    element.value = value
                      ? String(value).length < 256
                        ? String(value)
                        : `[text]`
                      : 'null';
                  }
                  this.entries.push(element);

                  if (key.toLowerCase() === 'objectid') {
                    this.statusResource.ObjectID = String(value);
                  }
                  if (key.toLowerCase() === 'objecttype') {
                    this.statusResource.ObjectType = String(value);
                  }
                  if (key.toLowerCase() === 'displayname') {
                    this.statusResource.DisplayName = String(value);
                  }
                }
              }
            }
          })
        )
        .subscribe(
          () => {
            if (process) {
              process.close();
            }
          },
          () => {
            if (process) {
              process.close();
            }
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.refreshToken.unsubscribe();
  }

  refresh() {
    this.refreshToken.next(undefined);
  }

  getElementSide(index: number): string {
    if (this.alternate) {
      if (index % 2 === 0) {
        return this.side;
      } else {
        return this.side === 'left' ? 'right' : 'left';
      }
    } else {
      return this.side;
    }
  }

  getIcon(entry: any) {
    if (entry) {
      switch (entry.resourcechangedtype) {
        case 'Create':
          return 'add';
        case 'Delete':
          return 'delete';
        case 'Modify':
          return 'create';
        default:
          break;
      }
    } else {
      return 'report';
    }
  }

  getDotClass(entry: any) {
    if (entry) {
      switch (entry.resourcechangedtype) {
        case 'Create':
          return 'dot-create';
        case 'Delete':
          return 'dot-delete';
        case 'Modify':
          return 'dot-modify';
        default:
          break;
      }
    } else {
      return 'dot-normal';
    }
  }

  getContentClass(entry: any) {
    if (entry) {
      switch (entry.resourcechangedtype) {
        case 'Create':
          return 'content-create';
        case 'Delete':
          return 'content-delete';
        case 'Modify':
          return 'content-modify';
        default:
          break;
      }
    } else {
      return 'content-normal';
    }
  }

  formatDate(dateString: any) {
    if (typeof dateString === 'string') {
      if (this.utils.IsDateString(dateString)) {
        const dt = moment(dateString);
        if (dt) {
          return dt.format(this.utils.ToMomentFormat(this.dtFormat));
        } else {
          return dateString;
        }
      } else {
        return dateString;
      }
    } else {
      return dateString;
    }
  }

  buildDetail(entry: any) {
    const result: any = {
      type: '',
      attributes: [],
      multivalueChanges: [],
    };

    if (entry) {
      switch (entry.resourcechangedtype) {
        case 'Modify':
          result.type = 'delta';
          break;
        case 'Create':
        case 'Delete':
        default:
          result.type = 'full';
          break;
      }
    }

    if (result.type === 'full' && entry.fullresource) {
      for (const [key, value] of Object.entries(entry.fullresource)) {
        if (!key.startsWith('$') && this.ignoredAttributes.indexOf(key) < 0) {
          const element: any = {
            name: key,
            displayName: this.resource.getAttributeDisplayName(
              entry.targetobjecttype,
              key
            ),
          };
          if (value instanceof Array) {
            if (value.length > 0) {
              element.multivalued = true;
              element.after = value;
            } else {
              element.after = 'null';
            }
          } else {
            element.after =
              value !== null && value !== undefined
                ? String(value).length < 256
                  ? String(value)
                  : `[text]`
                : 'null';
          }
          result.attributes.push(element);
        }
      }
    }

    if (result.type === 'delta') {
      if (entry.attributeassignments && entry.previousattributeassignments) {
        for (const [key, value] of Object.entries(entry.attributeassignments)) {
          if (!key.startsWith('$') && this.ignoredAttributes.indexOf(key) < 0) {
            const attr: any = {
              name: key,
              displayName: this.resource.getAttributeDisplayName(
                entry.targetobjecttype,
                key
              ),
              after:
                value !== null && value !== undefined
                  ? String(value).length < 256
                    ? String(value)
                    : `[text]`
                  : 'null',
            };
            let before = entry.previousattributeassignments[key];
            if (before === null || before === undefined) {
              before = 'null';
            }
            if (String(before).length < 256) {
              attr.before = String(before);
            } else {
              attr.before = `[text]`;
            }

            result.attributes.push(attr);
          }
        }
      }
      if (entry.multivalueinsertions) {
        for (const [key, value] of Object.entries(entry.multivalueinsertions)) {
          const attr: any = {
            name: key,
            displayName: this.resource.getAttributeDisplayName(
              entry.targetobjecttype,
              key
            ),
            changes: [],
          };
          const arrayValue = value as Array<any>;
          if (arrayValue) {
            arrayValue.forEach((v) => {
              attr.changes.push({ type: 'add', value: v });
            });
          }
          result.multivalueChanges.push(attr);
        }
      }
      if (entry.multivalueremovals) {
        for (const [key, value] of Object.entries(entry.multivalueremovals)) {
          let attr: any = result.multivalueChanges.find(
            (e: any) => e.name === key
          );
          const found = attr ? true : false;
          if (!found) {
            attr = {
              name: key,
              displayName: this.resource.getAttributeDisplayName(
                entry.targetobjecttype,
                key
              ),
              changes: [],
            };
          }
          const arrayValue = value as Array<any>;
          if (arrayValue) {
            arrayValue.forEach((v) => {
              attr.changes.push({ type: 'remove', value: v });
            });
          }
          if (!found) {
            result.multivalueChanges.push(attr);
          }
        }
      }
    }

    return result;
  }

  expandAll() {
    this.entryComponents.forEach((e: MglTimelineEntryComponent) => {
      e.expand();
    });
  }

  collapseAll() {
    this.entryComponents.forEach((e: MglTimelineEntryComponent) => {
      e.collapse();
    });
  }

  reloadEntry(index: number) {
    if (this.entryComponents) {
      const selectedEntry = this.entryComponents.toArray()[
        index
      ] as MglTimelineEntryComponent;
      if (selectedEntry) {
        let caChanged = false;
        let daChanged = false;
        if (this.contentAnimation) {
          this.contentAnimation = false;
          caChanged = true;
        }
        if (this.dotAnimation) {
          this.dotAnimation = false;
          daChanged = true;
        }
        selectedEntry.collapse();
        setTimeout(() => {
          selectedEntry.expand();
          if (caChanged) {
            this.contentAnimation = true;
          }
          if (daChanged) {
            this.dotAnimation = true;
          }
        }, this.config.getConfig('intervalSmall', 300));
      }
    }
  }

  isGuid(name: string, value: string) {
    return (
      name.toLowerCase() !== 'objectid' &&
      name.toLowerCase() !== 'azureid' &&
      this.utils.IsGuid(value)
    );
  }

  onHeaderClick(entry: any) {
    if (!entry.detail) {
      entry.detail = this.buildDetail(entry);
    }
  }

  onFullResourceView(entry: any, index: number) {
    if (entry.detail) {
      entry.detail.type = 'resource';
      if (!entry.detail.resource) {
        const process = this.modal.show(
          ModalType.progress,
          'key_processing',
          '',
          '300px'
        );

        entry.detail.resource = [];

        this.subscription.add(
          this.resource
            .getResourceHistory(entry.targetid, entry.committedtime)
            .pipe(
              tap((result: any) => {
                if (result) {
                  for (const [key, value] of Object.entries(result)) {
                    if (
                      !key.startsWith('$') &&
                      this.ignoredAttributes.indexOf(key) < 0
                    ) {
                      const element: any = {
                        name: key,
                        displayName: this.resource.getAttributeDisplayName(
                          entry.targetobjecttype,
                          key
                        ),
                      };
                      if (value instanceof Array) {
                        if (value.length > 0) {
                          element.multivalued = true;
                          element.after = value;
                        } else {
                          element.after = 'null';
                        }
                      } else {
                        element.after = value
                          ? String(value).length < 256
                            ? String(value)
                            : `[text]`
                          : 'null';
                      }
                      entry.detail.resource.push(element);
                    }
                  }
                  this.reloadEntry(index);
                }
              }),
              finalize(() => {
                if (process) {
                  process.close();
                }
              })
            )
            .subscribe()
        );
      } else {
        this.reloadEntry(index);
      }
    }
  }

  onDeltaResourceView(entry: any, index: number) {
    if (entry.detail) {
      entry.detail.type = 'delta';
      this.reloadEntry(index);
    }
  }

  onEmptyEntryClick(event: MouseEvent) {
    event.stopPropagation();
  }

  onRestoreFromEvent(data: any) {
    if (data && data.committedtime && data.targetid) {
      this.restore.emit({
        id: data.targetid,
        time: data.committedtime,
      });
    }
  }

  onRestoreFromStatus(resource: any, time: string) {
    if (resource && resource.ObjectID && time) {
      this.restore.emit({
        id: resource.ObjectID,
        time,
      });
    }
  }

  onReportMenuAction(reportName: string, item: any) {
    if (reportName === 'timemachine') {
      this.swap.broadcast({
        name: 'close-sidenav',
        parameter: {
          path: 'app/timemachine',
          queryParams: {
            id: this.targetId,
            mode: 'event',
          },
        },
      });
    } else {
      const pos = this.linkedReports.findIndex((r) => r.name === reportName);
      if (pos >= 0) {
        const report = this.adjustReport(this.linkedReports[pos], item);
        if (this.historySidenav) {
          this.swap.broadcast({
            name: 'close-sidenav',
            parameter: {
              path: `/app/report/${report.name}`,
              extras: { state: report },
            },
          });
        } else {
          this.router.navigate([`/app/report/${report.name}`], {
            state: report,
          });
        }
      }
    }
  }
}
