import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { SwapService } from './swap.service';

import { mergeWith as _mergeWith } from 'lodash-es';
import { assignInWith as _assignInWith } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configLocation = 'assets/config';
  private customConfigLocation = 'app/customisation/assets/config';

  private config: any = null;

  private loaded = false;
  get isLoaded() {
    return this.loaded;
  }

  private isReplaceMode(element: any) {
    if (typeof element === 'string') {
      if (/^\s*overwritemode\s*:\s*replace\s*$/i.test(element)) {
        return true;
      } else if (/^\s*overwritemode\s*:\s*merge\s*$/i.test(element)) {
        return false;
      } else {
        return null;
      }
    }

    return null;
  }

  constructor(private http: HttpClient, private swap: SwapService) {}

  public load() {
    if (this.loaded) {
      return of(null);
    }

    const cachedConfig = localStorage.getItem('LS_CONFIGURATION');
    if (cachedConfig) {
      localStorage.removeItem('LS_CONFIGURATION');
      if (cachedConfig === 'LS_CONFIGURATIONERROR') {
        this.swap.setError('key_configError');
        return of(null);
      } else {
        this.config = JSON.parse(cachedConfig);
        this.loaded = true;
        return of(null);
      }
    } else {
      const configFilePath = `${this.configLocation}/config.${environment.env}.json`;
      const customConfigFilePath = `${this.customConfigLocation}/customConfig.${
        environment.env
      }.json?t=${new Date().getTime()}`;
      return this.http.get(configFilePath).pipe(
        tap((config) => {
          this.config = config;
          this.loaded = true;
        }),
        switchMap(() => {
          return this.http.get(customConfigFilePath);
        }),
        tap((customConfig: any) => {
          if (customConfig) {
            if (customConfig.overwriteMode === 'merge') {
              this.config = _mergeWith(
                {},
                this.config,
                customConfig,
                (obj, src) => {
                  if (Array.isArray(src) && src.length > 0) {
                    const mode = this.isReplaceMode(src[0]);
                    if (mode === true) {
                      src.shift();
                      return src;
                    } else if (mode === false) {
                      src.shift();
                    }
                  }
                }
              );
            } else {
              // this.config = { ...this.config, ...customConfig };
              this.config = _assignInWith(
                this.config,
                customConfig,
                (obj, src) => {
                  if (Array.isArray(src) && src.length > 0) {
                    const mode = this.isReplaceMode(src[0]);
                    if (mode !== null) {
                      src.shift();
                      return src;
                    }
                  }
                }
              );
            }
          }
        }),
        catchError(() => {
          this.swap.setError('key_configError');
          return of(null);
        })
      );
    }
  }

  public getConfig(key: string, fallback?: any): any {
    if (this.loaded && this.config.hasOwnProperty(key)) {
      return this.config[key];
    } else {
      return fallback ? fallback : undefined;
    }
  }

  public getConfigEx(path: string, fallback?: any): any {
    if (this.loaded && path) {
      const pathArray = path.split(':');
      let value: any;
      for (const p of pathArray) {
        if (value) {
          if (value.hasOwnProperty(p)) {
            value = value[p];
          } else {
            return fallback ? fallback : undefined;
          }
        } else {
          if (this.config.hasOwnProperty(p)) {
            value = this.config[p];
          } else {
            return fallback ? fallback : undefined;
          }
        }
      }
      return value;
    } else {
      return fallback ? fallback : undefined;
    }
  }

  public getCulture(route: string, settingName = 'supportedLanguages') {
    const supportedLanguages: Array<any> = this.getConfig(settingName, []);

    const language = supportedLanguages.find((l) => l.route === route);
    if (language && language.culture) {
      return language.culture;
    }

    return 'en-US';
  }

  public patchConfig(patch: any) {
    if (patch) {
      if (
        patch.overwriteMode &&
        patch.overwriteMode.toLowerCase() === 'merge'
      ) {
        this.config = _mergeWith({}, this.config, patch, (obj, src) => {
          if (Array.isArray(src) && src.length > 0) {
            const mode = this.isReplaceMode(src[0]);
            if (mode === true) {
              src.shift();
              return src;
            } else if (mode === false) {
              src.shift();
            }
          }
        });
      } else {
        // Object.keys(patch).forEach((key) => {
        //   if (this.config[key] !== null || this.config[key] !== undefined) {
        //     this.config[key] = patch[key];
        //   }
        // });
        this.config = _assignInWith(this.config, patch, (obj, src) => {
          if (Array.isArray(src) && src.length > 0) {
            const mode = this.isReplaceMode(src[0]);
            if (mode !== null) {
              src.shift();
              return src;
            }
          }
        });
      }
    }
  }
}
