import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ResourceTableComponent } from '../core/components/resource-table/resource-table.component';
import { ResourceTableConfig } from '../core/models/componentContract.model';
import { ConfigService } from '../core/services/config.service';
import { ResourceService } from '../core/services/resource.service';

@Component({
  selector: 'app-all-resources',
  templateUrl: './all-resources.component.html',
  styleUrls: ['./all-resources.component.scss'],
})
export class AllResourcesComponent implements OnInit, AfterViewInit {
  @ViewChild('resourceTable')
  resourceTable: ResourceTableComponent;

  tableConfig: ResourceTableConfig;

  constructor(
    private config: ConfigService,
    private resource: ResourceService
  ) {}

  ngOnInit(): void {
    this.tableConfig = new ResourceTableConfig();
    this.tableConfig.title = 'key_allResources';
    this.tableConfig.cellPadding = 5;
    this.tableConfig.selectable = false;
    this.tableConfig.resizable = true;
    this.tableConfig.pageSize = this.config.getConfig('pageSize', 50);
    this.tableConfig.linkActions = ['navigate'];
    this.tableConfig.defaultLinkAction = 'navigate';
    this.tableConfig.columns = [
      {
        field: 'DisplayName',
        width: 0,
      },
      {
        field: 'Description',
        width: 0,
      },
    ];
  }

  ngAfterViewInit(): void {
    const schemaResoruces: Array<{
      ObjectID: string;
      DisplayName: string;
      ObjectType: string;
      Description: string;
    }> = [];
    Object.entries(this.resource.schema).forEach(([key, value]) => {
      schemaResoruces.push({
        ObjectID: key,
        ObjectType: 'schema',
        DisplayName: value['displayName'],
        Description: value['description'],
      });
    });
    schemaResoruces.sort((a, b) => {
      if (a.DisplayName.toLowerCase() > b.DisplayName.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });

    this.tableConfig.resources = schemaResoruces;
    if (this.resourceTable) {
      setTimeout(() => {
        this.resourceTable.updateDataSource(true);
      });
    }
  }
}
