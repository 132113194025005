import { Injectable } from '@angular/core';

import { ComponentDef } from '../models/componentContract.model';

import { StateCardComponent } from '../components/state-card/state-card.component';
import { ResourceTableComponent } from '../components/resource-table/resource-table.component';
import { ResourceChartComponent } from '../components/resource-chart/resource-chart.component';
import { ApprovalCheckComponent } from '../components/approval-check/approval-check.component';
import { FrameViewComponent } from '../components/frame-view/frame-view.component';
import { HtmlCardComponent } from '../components/html-card/html-card.component';
import { ApprovalCheckCloudComponent } from '../components/approval-check-cloud/approval-check-cloud.component';

/**
 * Service for dynamic component creation
 */
@Injectable({
  providedIn: 'root',
})
export class ComponentIndexService {
  /**
   * A dictionary of available components, which can be created dynamically
   */
  componentIndex: { [id: string]: ComponentDef } = {
    StateCardComponent: {
      id: 'stateCard',
      name: 'key_stateCard',
      type: 'widget',
      icon: 'assessment',
      height: 3,
      width: 4,
      description: 'key_stateCardDes',
      instance: StateCardComponent,
      instanceName: 'StateCardComponent',
    },
    HtmlCardComponent: {
      id: 'htmlCard',
      name: 'key_htmlCard',
      type: 'widget',
      icon: 'article',
      height: 3,
      width: 4,
      description: 'key_htmlCardDes',
      instance: HtmlCardComponent,
      instanceName: 'HtmlCardComponent',
    },
    ResourceTableComponent: {
      id: 'resourceTable',
      name: 'key_resourceTable',
      type: 'widget',
      icon: 'table_chart',
      height: 8,
      width: 8,
      description: 'key_resourceTableDes',
      instance: ResourceTableComponent,
      instanceName: 'ResourceTableComponent',
    },
    ResourceChartComponent: {
      id: 'resourceChart',
      name: 'key_resourceChart',
      type: 'widget',
      icon: 'pie_chart',
      height: 6,
      width: 8,
      description: 'key_resourceChartDes',
      instance: ResourceChartComponent,
      instanceName: 'ResourceChartComponent',
    },
    ApprovalCheckComponent: {
      id: 'approvalCheck',
      name: 'key_approvalCheck',
      type: 'widget',
      icon: 'flaky',
      height: 6,
      width: 8,
      description: 'key_approvalCheckDes',
      instance: ApprovalCheckComponent,
      instanceName: 'ApprovalCheckComponent',
    },
    ApprovalCheckCloudComponent: {
      id: 'approvalCheckCloud',
      name: 'key_approvalCheckCloud',
      type: 'widget',
      icon: 'flaky',
      height: 6,
      width: 8,
      description: 'key_approvalCheckDes',
      instance: ApprovalCheckCloudComponent,
      instanceName: 'ApprovalCheckCloudComponent',
    },
    FrameViewComponent: {
      id: 'frameView',
      name: 'key_frameView',
      type: 'widget',
      icon: 'crop',
      height: 6,
      width: 8,
      description: 'key_frameViewDes',
      instance: FrameViewComponent,
      instanceName: 'FrameViewComponent',
    },
  };

  /** @ignore */
  constructor() {}

  public getInstance(typeName: string) {
    const def = this.componentIndex[typeName];
    return def ? def.instance : null;
  }
}
