import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DynamicComponent } from '../../models/dynamicComponent.interface';
import { ApprovalViewConfig } from '../../models/componentContract.model';

@Component({
  selector: 'app-approval-check-config',
  templateUrl: './approval-check-config.component.html',
  styleUrls: ['./approval-check-config.component.scss'],
})
export class ApprovalCheckConfigComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      component: DynamicComponent;
      config: ApprovalViewConfig;
    }
  ) {}

  onRefresh() {
    this.data.component.updateDataSource();
  }
}
