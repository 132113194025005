<div *ngIf="displayComponent" class="full-size-relative" style="padding: 10px">
  <mat-card
    class="full-height"
    [style.width.px]="localConfig.width"
    [style.height.px]="localConfig.height"
    [style.backgroundColor]="localConfig.backgroundColor"
  >
    <mat-card-content class="full-height">
      <div
        class="full-height"
        fxLayout="row wrap"
        fxLayoutAlign="start stretch"
      >
        <div class="mouse-link" fxFlex="70px" (click)="onPrimaryAction()">
          <mat-icon
            *ngIf="!localConfig.primaryImage"
            style="font-size: 52px"
            [style.color]="localConfig.primaryIconColor"
          >
            {{ localConfig.primaryIcon }}</mat-icon
          >
          <img
            *ngIf="localConfig.primaryImage"
            [src]="'assets/img/' + localConfig.primaryImage"
            style="width: 45px"
          />
        </div>
        <div
          fxFlex="auto"
          fxLayout="column"
          [style.width.px]="localConfig.textWidth"
          class="mouse-link"
          (click)="onPrimaryAction()"
        >
          <div
            fxFlex="none"
            [style.color]="localConfig.titleColor"
            [style.margin-top.px]="
              localConfig.description !== undefined ? 0 : 12
            "
            style="font-size: 20px; font-weight: bolder"
          >
            {{ localConfig.title | translate }}
          </div>
          <div
            fxFlex="none"
            [style.color]="localConfig.descriptionColor"
            class="card-desc"
            [innerHTML]="localConfig.description | translate"
          ></div>
        </div>
        <div
          *ngIf="displayAction"
          fxFlex="none"
          style="border-left: solid 1px lightgray; margin: 30px 10px 10px 10px"
        ></div>
        <div
          *ngIf="displayAction"
          fxFlex="60px"
          fxLayout="column"
          fxLayoutAlign="center center"
        >
          <!-- <mat-icon style="font-size: 32px;" [style.color]="localConfig.secondaryIconColor">
            {{ localConfig.secondaryIcon }}</mat-icon
          > -->
          <button mat-icon-button (click)="onSecondaryAction()">
            <mat-icon
              style="font-size: 32px; margin-left: -8px"
              [style.color]="localConfig.secondaryIconColor"
              >{{ localConfig.secondaryIcon }}</mat-icon
            >
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
