<form autocomplete="off" #activityForm="ngForm">
  <div fxLayout="column">
    <div fxLayout="row" style="margin-top: 10px">
      <app-editor-text
        fxFlex="100"
        name="txtIfCondition"
        [(ngModel)]="ifCondition"
        [config]="{
          isSimpleValue: true,
          hintLimit: 100,
          customDisplayName: 'key_condition',
          updateOn: updateOnBlure,
          validationKey: 'workflow/ifthenelse/ifcondition'
        }"
        appExpressionValidator
      ></app-editor-text>
    </div>
  </div>
</form>
