<mat-form-field [appearance]="appearance" style="width: 100%;">
  <mat-select
    [(ngModel)]="value"
    [multiple]="multiple"
    [placeholder]="placeHolder | translate"
    [matTooltip]="tooltip"
    [disabled]="disabled"
    (selectionChange)="onSelectionChange()"
  >
    <mat-option
      *ngFor="let attribute of attributes | async"
      [value]="attribute"
      style="font-size: 14px; height: 32px;"
    >
      {{ attribute.displayName }}
    </mat-option>
  </mat-select>
</mat-form-field>
