import { Injectable } from '@angular/core';

import { ComponentItem } from '../models/componentContract.model';

import { CustomService } from 'src/app/customisation/services/custom.service';

import { WorkflowDesignComponent } from '../components/workflow-design/workflow-design.component';
import { DataFlowRuleTypeComponent } from '../components/data-flow-rule-type/data-flow-rule-type.component';
import { ApprovalDelegationComponent } from '../components/approval-delegation/approval-delegation.component';

@Injectable({
  providedIn: 'root',
})
export class ComponentService {
  constructor(private custom: CustomService) {}

  public getCustomComponent(name: string): ComponentItem {
    let retVal: ComponentItem;

    if (!name) {
      return null;
    }
    switch (name.toLowerCase()) {
      case 'workflowdesign':
        retVal = { component: WorkflowDesignComponent, data: {} };
        break;
      case 'dataflowruletype':
        retVal = { component: DataFlowRuleTypeComponent, data: {} };
        break;
      case 'approvaldelegation':
        retVal = { component: ApprovalDelegationComponent, data: {} };
        break;
      default:
        retVal = null;
    }

    if (!retVal) {
      return this.custom.getCustomComponent(name);
    } else {
      return retVal;
    }
  }

  public callCustomFunction(name: string, param: any) {
    if (this.custom.hasOwnProperty(`${name}Override`)) {
      this.custom[`${name}Override`](param);
      return true;
    } else if (this.custom.hasOwnProperty(name)) {
      // custom event handler can return true to overreide the pre-defined code
      return this.custom[name](param);
    }
    return false;
  }

  public getCustomVersion(): string {
    return this.custom.hasOwnProperty('getVersion')
      ? this.custom['getVersion']()
      : '1.0.0';
  }
}
