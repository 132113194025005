import { AfterViewInit, Component, Injector } from '@angular/core';
import { AttributeResource } from '../../models/dataContract.model';
import {
  AttributeEditorConfig,
  DynamicEditor,
} from '../../models/dynamicEditor.interface';
import { FrameEditorConfig } from '../../models/editorContract.model';

@Component({
  selector: 'app-editor-frame-config',
  templateUrl: './editor-frame-config.component.html',
  styleUrls: ['./editor-frame-config.component.scss'],
})
export class EditorFrameConfigComponent
  extends AttributeEditorConfig
  implements AfterViewInit
{
  data: {
    component: DynamicEditor;
    config: FrameEditorConfig;
    attribute: AttributeResource;
    viewMode: string;
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngAfterViewInit() {
    this.initComponent();
  }
}
