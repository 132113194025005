import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  BroadcastEvent,
  NavigationItem,
  SearchScope,
} from '../../models/dataContract.model';
import { TransService } from '../../models/translation.model';
import { ConfigService } from '../../services/config.service';
import { SwapService } from '../../services/swap.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss'],
})
export class BreadCrumbComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  unitLength = 12;
  searchScopes: SearchScope[] = [];

  constructor(
    public utils: UtilsService,
    private router: Router,
    private config: ConfigService,
    private translate: TransService,
    private swap: SwapService
  ) {}

  ngOnInit(): void {
    this.unitLength = this.config.getConfigEx('breadCrumb:unitLength', 20);

    this.subscription.add(
      this.swap.broadcasted
        .pipe(
          tap((event: BroadcastEvent) => {
            if (event && event.name === 'delete-resource') {
              if (event.parameter && typeof event.parameter === 'string') {
                const pos = this.utils.navigationPath.findIndex(
                  (path: NavigationItem) => path.objectID === event.parameter
                );
                if (pos >= 0) {
                  this.utils.navigationPath.splice(pos, 1);
                }
              }
            }
          })
        )
        .subscribe()
    );
  }

  ngAfterViewInit() {
    this.searchScopes = this.config.getConfig('searchScopes', []);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onItemClicked(item: NavigationItem) {
    const pos = this.utils.navigationPath.findIndex(
      (entry: NavigationItem) => entry.objectID === item.objectID
    );
    if (pos >= 0 && pos !== this.utils.navigationPath.length - 1) {
      this.utils.navigationPath = this.utils.navigationPath.slice(0, pos + 1);
    }
    if (item.path) {
      this.router.navigate([item.path]);
    }
  }

  onHomeClicked() {
    const startPath = this.config.getConfig('startPath', '/app');
    this.utils.navigationPath = [];
    this.router.navigate([startPath]);
  }

  buildDisplayName(name: string) {
    if (!name) {
      name = this.translate.instant('key_noDisplayNamePlaceHolder');
    }
    if (this.unitLength < 3) {
      this.unitLength = 3;
    }
    if (name.length > this.unitLength) {
      return `${name.substr(0, this.unitLength - 2)}..`;
    } else {
      return name;
    }
  }

  getIcon(objectType: string) {
    const pos = this.searchScopes.findIndex((scope: SearchScope) => {
      return (
        scope.type &&
        scope.type.toLowerCase() === objectType.toLowerCase() &&
        !scope.isSubScope
      );
    });
    return pos >= 0 && this.searchScopes[pos].icon
      ? this.searchScopes[pos].icon
      : 'article';
  }

  lengthExceeded(name: string) {
    return name && name.length > this.unitLength;
  }
}
