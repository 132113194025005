import { Injectable } from '@angular/core';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import {
  WindowCloseResult,
  WindowService,
} from '@progress/kendo-angular-dialog';

import { PopupConfig } from '../models/dataContract.model';
import { ModalButton, ModalType } from '../models/componentContract.model';
import { TransService } from '../models/translation.model';

import { SwapService } from './swap.service';

import { ModalComponent } from '../components/modal/modal.component';
import { PopupBodyComponent } from '../components/popup-body/popup-body.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private dialog: MatDialog,
    private translate: TransService,
    private window: WindowService,
    private swap: SwapService
  ) {}

  public show(
    type: ModalType,
    title: string,
    content: string,
    width?: string | number,
    buttons?: Array<ModalButton>
  ): MatDialogRef<ModalComponent> {
    return this.dialog.open(ModalComponent, {
      minWidth: width ? width : 250,
      maxWidth: width ? width : 600,
      disableClose: true,
      data: {
        type,
        title: title ? this.translate.instant(title) : '',
        content: content ? this.translate.instant(content) : undefined,
        buttons,
      },
    });
  }

  public popup(popupConfig: PopupConfig): Observable<WindowCloseResult> {
    this.swap.broadcast({
      name: 'show-overlay',
      parameter: undefined,
    });

    const windowRef = this.window.open({
      title: '',
      content: PopupBodyComponent,
      titleBarContent: null,
      width: popupConfig.width,
    });

    const windowInst = windowRef.content.instance;
    windowInst.config = popupConfig;

    return windowRef.result.pipe(
      finalize(() => {
        this.swap.broadcast({
          name: 'hide-overlay',
          parameter: undefined,
        });
      })
    );
  }
}
