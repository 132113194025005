<gridster [options]="gdConfig" class="gridster">
  <gridster-item
    [class.mat-elevation-z1]="true"
    [item]="item"
    *ngFor="let item of gridsterDefs"
    class="gd-item"
  >
    <app-attribute-view
      #attributeView
      [viewType]="'gridster'"
      [configMode]="configMode"
      [(attributeDefs)]="item.attributes"
      [columnNumber]="item.columnNumber"
      [tabName]="item.name"
      [gridsterName]="item.displayName"
      [(attributeArray)]="editorResults[item.name]"
    ></app-attribute-view>
  </gridster-item>
</gridster>
