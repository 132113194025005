<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <h3 mat-dialog-title cdkDragHandle>
    Create your team
  </h3>
  <div mat-dialog-content>
    <mat-horizontal-stepper linear #stepper style="height: 400px;">
      <mat-step>
        <ng-template matStepLabel>General</ng-template>
        <div fxLayout="column" style="width: 100%;">
          <mat-form-field fxFlex="auto" style="margin-top: 10px;">
            <input
              matInput
              placeholder="Give your team a name"
              [(ngModel)]="resourceToCreate.displayname"
              cdkFocusInitial
              required
              style="font-size: 20px;"
            />
          </mat-form-field>
          <div
            fxFlex="auto"
            style="font-size: 12px; font-weight: 400; color: #3f51b5"
          >
            Team Owners
          </div>
          <kendo-multiselect
            #idpOwner
            [data]="idpData"
            [(ngModel)]="resourceToCreate.owners"
            [textField]="'displayname'"
            [valueField]="'accountname'"
            [filterable]="true"
            (filterChange)="handleSearchFilter($event)"
            (focus)="onFocus()"
          >
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <div
                fxLayout="row wrap"
                fxLayoutAlign="start center"
                style="color: gray; margin: 5px;"
              >
                <div fxFlex="none">
                  <mat-icon style="margin-top: 6px;">person</mat-icon>
                </div>
                <div fxFlex="60" style="font-size: 16px;">
                  {{ dataItem.displayname }}
                </div>
                <div fxFlex="100" style="margin-top: -10px; font-size: 12px;">
                  {{ dataItem.accountname }}
                </div>
              </div>
            </ng-template>
          </kendo-multiselect>
          <mat-form-field style="margin-top: 20px;">
            <textarea
              matInput
              placeholder="Team Description"
              rows="2"
              [(ngModel)]="resourceToCreate.description"
            ></textarea>
          </mat-form-field>
        </div>
      </mat-step>
      <mat-step>
        <ng-template matStepLabel>Advanced</ng-template>
        <div fxLayout="row wrap" style="width: 100%;">
          <div fxFlex="45%" style="margin-top: 10px; font-size: 14px;">
            <mat-slide-toggle #chkSet>Members based on Set</mat-slide-toggle>
          </div>
          <div fxFlex="55%">
            <kendo-multiselect
              #idpSet
              [disabled]="!chkSet.checked"
              [data]="idpSetData"
              [textField]="'name'"
              [valueField]="'name'"
              [(ngModel)]="resourceToCreate.set"
              [filterable]="true"
              (filterChange)="handleSearchFilter($event)"
              (focus)="onFocus()"
            >
              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <div
                  fxLayout="row wrap"
                  fxLayoutAlign="start center"
                  style="color: gray; margin: 5px;"
                >
                  <div fxFlex="15%">
                    <mat-icon style="margin-top: 6px;">list</mat-icon>
                  </div>
                  <div fxFlex="60%" style="font-size: 16px;">
                    {{ dataItem.name }}
                  </div>
                  <div fxFlex="100%" style="margin-top: -10px;">
                    {{ dataItem.description }}
                  </div>
                </div>
              </ng-template>
            </kendo-multiselect>
          </div>
          <div
            fxFlex="100%"
            style="font-size: 12px; font-weight: 400; color: #3f51b5; margin-top: 20px;"
          >
            Explicit Members
          </div>
          <div fxFlex="100%">
            <kendo-multiselect
              #idpMember
              [data]="idpData"
              [textField]="'displayname'"
              [valueField]="'accountname'"
              [filterable]="true"
              [(ngModel)]="resourceToCreate.members"
              (filterChange)="handleSearchFilter($event)"
              (focus)="onFocus()"
            >
              <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <div
                  fxLayout="row wrap"
                  fxLayoutAlign="start center"
                  style="color: gray; margin: 5px;"
                >
                  <div fxFlex="none">
                    <mat-icon style="margin-top: 6px;">person</mat-icon>
                  </div>
                  <div fxFlex="60" style="font-size: 16px;">
                    {{ dataItem.displayname }}
                  </div>
                  <div fxFlex="100" style="margin-top: -10px; font-size: 12px;">
                    {{ dataItem.accountname }}
                  </div>
                </div>
              </ng-template>
            </kendo-multiselect>
          </div>
          <div fxFlex="100%" style="margin-top: 30px; font-size: 14px;">
            <mat-slide-toggle>Owner approval required</mat-slide-toggle>
          </div>
          <mat-form-field fxFlex="100%" style="margin-top: 20px;">
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="Expiry date"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
      </mat-step>
    </mat-horizontal-stepper>
  </div>
  <div
    mat-dialog-actions
    fxLayout="row"
    fxLayoutAlign="start center"
    style="margin-top: -10px;"
  >
    <button
      fxFlex="none"
      mat-stroked-button
      (click)="onPrevious(stepper)"
      style="margin-right: 5px;"
      [disabled]="stepper.selectedIndex === 0"
    >
      Previous
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      (click)="onNext(stepper)"
      style="margin-right: 20px;"
      [disabled]="stepper.selectedIndex === 1 || !resourceToCreate.displayname"
    >
      Next
    </button>
    <button
      fxFlex="none"
      mat-stroked-button
      color="primary"
      [mat-dialog-close]="resourceToCreate"
      [disabled]="stepper.selectedIndex !== 1"
    >
      Finish
    </button>
    <div fxFlex="auto"></div>
    <button fxFlex="none" mat-stroked-button mat-dialog-close="cancel">
      {{ 'key_cancel' | translate }}
    </button>
  </div>
</div>
